define('a24-staffshift-subdomain-agency/services/dom-event', ['exports', 'a24-ember-window/services/dom-event'], function (exports, _domEvent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _domEvent.default;
    }
  });
});