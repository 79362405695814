define("a24-staffshift-subdomain-agency/services/rest-shift-status-change", ["exports", "a24-staffshift-subdomain-agency/config/environment"], function (exports, _environment) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),

        /**
         * Route to update shift status
         *
         * @param objContext - The the caller this function
         * @param sProperty - The Property name to get the promise object
         * @param sShiftId - The shift id to change the status for
         * @param objData - The data to create candidate
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         *
         * @author Junior van Wyk <johannes.vanwyk@a24group.com>
         * @since  07 April 2020
         */
        updateShiftStatusChange: function updateShiftStatusChange(objContext, sProperty, sOrderId, sShiftId, objData, funcSuccess, objFailure) {
            var sAgencyId = this.get("_objUserSession").getUserContextReference().mId;

            a24RestUrlConstruct.setBaseUrl("/v1/agency/" + sAgencyId + "/order/" + sOrderId + "/shift/" + sShiftId + "/status");

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "POST", _environment.default.sRestApiBaseUrl + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure, objData, null, false, false, false, 30000));
        }
    });
});