define("a24-staffshift-subdomain-agency/services/rest-agency-consultant-role", ["exports", "a24-ember-candidate-profile/services/rest-agency-consultant-role"], function (exports, _restAgencyConsultantRole) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _restAgencyConsultantRole.default;
    }
  });
});