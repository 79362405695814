define("a24-staffshift-subdomain-agency/helpers/lookup-string-token", ["exports", "a24-ember-lookup/helpers/lookup-string-token"], function (exports, _lookupStringToken) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _lookupStringToken.default;
    }
  });
  Object.defineProperty(exports, "lookupStringToken", {
    enumerable: true,
    get: function () {
      return _lookupStringToken.lookupStringToken;
    }
  });
});