define("a24-staffshift-subdomain-agency/services/rest-agency-chat-candidate-conversations", ["exports", "a24-staffshift-subdomain-agency/config/environment"], function (exports, _environment) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        sContext: null,
        _objStaffshiftCoreConstants: Ember.inject.service("a24-constants-staffshift-core"),
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),

        init: function init() {
            this._super.apply(this, arguments);
            this.set("sContext", this.get("_objStaffshiftCoreConstants.CONTEXT_AGENCY_LOWER"));
        },

        /**
         * Get all candidate Conversation
         *
         * @param objContext - The object on which the property must exist
         * @param sProperty - The property name on which to get and set the promise.
         * @param sCandidateId - The candidate  id
         * @param funcSuccess - The callback function to execute when the service call is successful
         * @param objFailure - The object containing the callback function to execute when the service call fails
         * @param objQueryParams - Any extra query params for the get list
         *
         * @author Ahmed Onawale <ahmedonawale@gmail.com>
         * @since  2 Dec 2019
         */
        getConversations: function getConversations(objContext, sProperty, sCandidateId, funcSuccess, objFailure, objQueryParams) {
            a24RestUrlConstruct.setBaseUrlSwagger("/v1/chat/candidate/" + sCandidateId + "/conversations");

            a24RestUrlConstruct.addQueryParamObject(objQueryParams);

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "GET", _environment.default.sRestApiBaseUrl + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        },

        /**
         * Get a user Conversation
         *
         * @param objContext - The object on which the property must exist
         * @param sProperty - The property name on which to get and set the promise.
         * @param sChatUserId - The user id
         * @param sConversationId - The conversation id
         * @param funcSuccess - The callback function to execute when the service call is successful
         * @param objFailure - The object containing the callback function to execute when the service call fails
         *
         * @author Ahmed Onawale <ahmedonawale@gmail.com>
         * @since  16 Dec 2019
         */
        getConversation: function getConversation(objContext, sProperty, sChatUserId, sConversationId, funcSuccess, objFailure) {
            a24RestUrlConstruct.setBaseUrlSwagger("/v1/chat/candidate/" + sChatUserId + "/conversation/" + sConversationId);

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "GET", _environment.default.sRestApiBaseUrl + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        }
    });
});