define("a24-staffshift-subdomain-agency/controllers/index/agency-client-org-view/exclusions", ["exports", "a24-staffshift-subdomain-agency/mixins/query-param-object-builder"], function (exports, _queryParamObjectBuilder) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_queryParamObjectBuilder.default, {
        queryParams: ["candidate_full_name", "candidate_id", "site_name", "site_id", "ward_name", "client_type", "severity", "complaint_type_name", "complaint_type_id", "date_excluded", "date_excludedFrom", "date_excludedTo", "fully_lifted__status", "page", "sortBy", "vis_cols", "savedSearchKey"],
        candidate_full_name: "",
        candidate_id: "",
        site_name: "",
        site_id: "",
        ward_name: "",
        client_type: "",
        severity: "",
        complaint_type_name: "",
        complaint_type_id: "",
        date_excluded: "",
        date_excludedFrom: "",
        date_excludedTo: "",
        fully_lifted__status: "no",
        page: "1",
        sortBy: "candidate_full_name::asc",
        vis_cols: "1,3,5,6,7,8,10,11",
        savedSearchKey: ""
    });
});