define("a24-staffshift-subdomain-agency/components/candidate-external-general", ["exports", "a24-ember-candidate-profile/components/candidate-external-general"], function (exports, _candidateExternalGeneral) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _candidateExternalGeneral.default;
    }
  });
});