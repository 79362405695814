define("a24-staffshift-subdomain-agency/services/rest-agency-candidate", ["exports", "a24-staffshift-subdomain-agency/config/environment"], function (exports, _environment) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),

        /**
         * Route for agency group candidate create
         *
         * @param objContext - The the caller this function
         * @param sProperty - The Property name to get the promise object
         * @param objData - The data to create candidate
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         *
         * @author Michael Barnard <michael.barnard@a24group.com>
         * @since  13 November 2018
         */
        createCandidate: function createCandidate(objContext, sProperty, objData, funcSuccess, objFailure) {
            var sAgencyId = this.get("_objUserSession").getUserContextReference().mId;

            a24RestUrlConstruct.setBaseUrl("/rest/agency/" + sAgencyId + "/candidate");

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "POST", _environment.default.sTriageBaseRest + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure, objData, null, false, true));
        }
    });
});