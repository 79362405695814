define("a24-staffshift-subdomain-agency/components/page-agency-list-shift-requests", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-staffshift-subdomain-agency/config/environment"], function (exports, _didRenderChangesMixin, _environment) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        objQueryParams: null,
        bDatagridLoading: false,
        _bPageLoading: false,

        _objTitleBarService: Ember.inject.service("title-bar"),
        _objNavigation: Ember.inject.service("navigation"),
        _objSSAgencyStrings: Ember.inject.service("a24-strings-staffshift-agency"),
        _objStringsCandidateProfile: Ember.inject.service("a24-strings-candidate-profile"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objSSAgencyEnum: Ember.inject.service("a24-enums-staffshift-agency"),
        _objAgencyUsersService: Ember.inject.service("rest-agency-users"),
        _objReactEmberUtils: Ember.inject.service("react-ember-utils"),

        _sIFrameUrl: null,
        _bShowReactPopup: false,

        _arrUsers: null,
        init: function init() {
            this._super.apply(this, arguments);

            this.get("_objTitleBarService").setTitleBar(this.get("_objSSAgencyStrings").getString("shiftsRequests"));

            this.set("_sIFrameUrl", _environment.default.sReactGatewayUrl);
            // Build up the url with context id.
            this.set("_sUrlBase", _environment.default.sStaffingGatewayUrl + "/v1/agency/" + this.get("_objNavigation.sContextId") + "/agency-client-shifts-request");

            if (!a24Core.isEmpty(this.get("_objNavigation.objRouteQueryParams.shift_request_id"))) {
                this.set("_bShowReactPopup", true);
                this.set("_objReactPopupPostData", {
                    type: "trigger_route",
                    data: {
                        route: "staffing/shiftRequests/viewPopup",
                        data: {
                            shiftRequestId: this.get("_objNavigation.objRouteQueryParams.shift_request_id")
                        }
                    }
                });
            }

            this._doServiceCalls();
        },


        _doServiceCalls: function _doServiceCalls() {
            this.set("_bPageLoading", true);
            var sAgencyId = this.get("_objNavigation.sContextId");
            a24RestUrlConstruct.setBaseUrl("/v1/agency/" + sAgencyId + "/user");
            a24RestUrlConstruct.addQueryParamObject({
                items_per_page: { mValue: 1000 }
            });

            var sUserEndpoint = _environment.default.sRestApiBaseUrl + a24RestUrlConstruct.getConstructedUrl();

            var objPromiseUsers = this.get("_objReactEmberUtils").makeReactApiRequest({
                sEndpoint: sUserEndpoint,
                objOptions: {
                    noAbortError: true,
                    method: 'GET',
                    fetchAll: true,
                    useCache: 24,
                    cacheTimeUnit: 'hours'
                },
                objParams: {}
            });

            var objPromiseList = {
                _objPromiseUsers: objPromiseUsers
            };

            var objThis = this;

            Ember.copy(a24RSVP).configure(this, objPromiseList, [{
                arrRequiredResponses: ["_objPromiseUsers"],
                funcOnComplete: function funcOnComplete(objResponses) {
                    var arrUsers = objResponses._objPromiseUsers.objResponse;

                    if (a24Core.isEmpty(arrUsers)) {
                        arrUsers = [];
                    }
                    objThis.set("_arrUsers", arrUsers);

                    objThis._setupPageConfig(arrUsers);
                }
            }]);
        },

        _setupPageConfig: function _setupPageConfig(arrUsers) {
            var _this = this;

            var arrShiftRequestStatuses = this.get("_objSSAgencyEnum").getList("shiftRequestStatus");
            var arrShiftRequestStatusesOptions = [];
            for (var i = 0; i < arrShiftRequestStatuses.length; i++) {
                arrShiftRequestStatusesOptions.push({
                    sTitle: arrShiftRequestStatuses[i].sLabel,
                    sValue: arrShiftRequestStatuses[i].sValue
                });
            }

            // This array contain the datagrid columns
            var arrDataGridHeaderConfig = [{
                iUniqueColNum: 1,
                sTitle: this.get("_objSSAgencyStrings").getString("siteName"),
                sProperty: "site_id",
                bSilent: true,
                sFilterType: "suggest",
                bFilterOr: true,
                sReactFilterRoute: "datagrid/filters/client",
                iWidth: 10,
                funcFilterParseQueryValue: function funcFilterParseQueryValue(objQueryObjectParam) {
                    var objQueryObject = Ember.copy(objQueryObjectParam, true);
                    objQueryObject = objQueryObject[0];

                    return objQueryObject;
                }
            }, {
                iUniqueColNum: 2,
                sTitle: this.get("_objSSAgencyStrings").getString("siteName"),
                sProperty: "site_name",
                bText: true,
                iWidth: 30,
                bStopFiltering: true
            }, {
                iUniqueColNum: 3,
                sTitle: this.get("_objStringsService").getString("createdBy"),
                sProperty: "created_by_user_id",
                sFilterType: "suggest",
                iFilterSuggestAt: 3,
                iFilterSuggestDelay: 500,
                sFilterOptionsValueKey: "_id",
                sFilterOptionsLabelKey: "name",
                bFilterShowLoaderDuringSuggest: true,
                bFilterAlwaysShowOnFocus: false,
                bCustom: true,
                bStopColumnSort: true,
                iWidth: 30,
                funcTableCustomVal: function funcTableCustomVal(objHeader, objRowData) {
                    if (!a24Core.isEmpty(objRowData.created_by_user_id)) {
                        var objRowUser = arrUsers.find(function (objUser) {
                            return objUser.id === objRowData.created_by_user_id;
                        });
                        if (a24Core.isEmpty(objRowUser)) {
                            return "";
                        }
                        return Ember.String.htmlSafe("<span title=\"" + objRowUser.sFirstName + " " + objRowUser.sLastName + "}\">" + objRowUser.sFirstName + " " + objRowUser.sLastName + "</span>");
                    }
                    return "";
                },
                funcFilterGetSuggestions: function funcFilterGetSuggestions(sSuggestVal, funcCallback) {
                    _this.get("_objAgencyUsersService").suggestUsers(_this, "_objUsersPromise", _this.get("_objNavigation.sContextId"), sSuggestVal, function (arrData) {
                        var arrResult = [];
                        // If the data is empty, ensure it's an empty array
                        if (a24Core.isEmpty(arrData)) {
                            arrData = [];
                        }

                        for (var i = 0; i < arrData.length; i++) {
                            var sNameUserName = arrData[i].sFirstName + " " + arrData[i].sLastName + " - " + arrData[i].sUsername;

                            arrResult.push({
                                _id: arrData[i].id,
                                name: sNameUserName
                            });
                        }

                        // Call the callback with the formatted data
                        funcCallback(arrResult);
                    }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                        // Call the callback with empty
                        funcCallback([]);
                    }));
                }
            }, {
                iUniqueColNum: 4,
                sTitle: this.get("_objSSAgencyStrings").getString("createdDate"),
                sProperty: "created_at",
                bDate: true,
                sFilterType: "date",
                bFilterAllowTime: true,
                iWidth: 15
            }, {
                iUniqueColNum: 5,
                sTitle: this.get("_objStringsCandidateProfile").getString("status"),
                sProperty: "status",
                sFilterType: "dropdownMulti",
                iWidth: 10,
                bMapping: true,
                arrMappingData: arrShiftRequestStatusesOptions
            }, {
                iUniqueColNum: 6,
                sTitle: this.get("_objSSAgencyStrings").getString("completedDate"),
                sProperty: "completed_at",
                bDate: true,
                sFilterType: "date",
                bFilterAllowTime: true,
                iWidth: 15
            }];
            var objThis = this;
            var arrSingleItemActions = [{
                sTitle: this.get("_objSSAgencyStrings").getString("viewShiftRequest"),
                sIcon: "visibility",
                executeCardAction: function executeCardAction() {
                    var objShiftRequest = objThis.get("_arrSelectedItems")[0];
                    objThis._viewShiftRequest(objShiftRequest._id);
                }
            }];

            this.set("_arrSingleLoadingActions", arrSingleItemActions);

            this.set("_objDatagridConfig", {
                sDatagridHeader: this.get("_objSSAgencyStrings").getString("shiftsRequests"),
                iMaxHeight: 40 * 27,
                bSelectColumn: true,
                bEmitSelectRowEvent: true,
                bHasReloadButton: true,
                bEmitDidDoServiceCall: true,
                bEmitWillDoServiceCall: true,
                bEmitDidDoServiceCallFail: true,
                sReactIFrameUrl: _environment.default.sReactGatewayUrl,
                arrSingleItemSelectActions: arrSingleItemActions,
                arrHeaderConfig: arrDataGridHeaderConfig,
                bFormatDatesToContextTimezone: true
            });

            this.set("_bPageLoading", false);
        },
        _viewShiftRequest: function _viewShiftRequest(sShiftRequestId) {
            this.set("_bShowReactPopup", true);
            this.set("_objReactPopupPostData", {
                type: "trigger_route",
                data: {
                    route: "staffing/shiftRequests/viewPopup",
                    data: {
                        shiftRequestId: sShiftRequestId
                    }
                }
            });
            // to be implemented
        },
        // For selecting items
        _onItemsSelect: function _onItemsSelect(arrSelectedItems) {
            if (!a24Core.isEmpty(arrSelectedItems) && arrSelectedItems.length === 1) {
                this.set("_objSelectedItem", arrSelectedItems[0]);
            }
            // Set the selected items
            this.set("_arrSelectedItems", arrSelectedItems);
        },
        _refresh: function _refresh() {
            var _this2 = this;

            this.set("_bShowReactPopup", false);
            // Put the page in loading
            this.set("_bPageLoading", true);

            Ember.run.next(function () {
                if (a24Core.isEmpty(_this2)) {
                    return;
                }
                Ember.run.schedule("afterRender", function () {
                    // Take page out of loading
                    _this2.set("_bPageLoading", false);
                });
            });
        },
        actions: {
            cardDatagridTableNoneSelected: function cardDatagridTableNoneSelected() {
                this._onItemsSelect(null);
            },
            cardDatagridTableOneSelected: function cardDatagridTableOneSelected(arrSelectedItems) {
                this._onItemsSelect(arrSelectedItems);
            },
            cardDatagridTableMultiSelected: function cardDatagridTableMultiSelected(arrSelectedItems) {
                this._onItemsSelect(arrSelectedItems);
            },
            cardDatagridTableCell: function cardDatagridTableCell() /*sColumnName, objRowData*/{},
            onPageReload: function onPageReload() {
                this._doServiceCalls();
            },
            onWillDoServiceCall: function onWillDoServiceCall(arrTableData, objUrlConstantPart, objFilterParamObject) {
                if (!a24Core.isEmpty(objFilterParamObject.created_at)) {
                    if (objFilterParamObject.created_at.bFrom) {
                        objFilterParamObject.created_at_from = {
                            mValue: objFilterParamObject.created_at.mValue,
                            bEncode: true
                        };
                    } else if (objFilterParamObject.created_at.bNotWithinLast) {
                        objFilterParamObject.created_at_from = {
                            mValue: objFilterParamObject.created_at.mValue.sFrom,
                            bEncode: true
                        };
                    } else if (objFilterParamObject.created_at.bNotWithinNext) {
                        objFilterParamObject.created_at_to = {
                            mValue: objFilterParamObject.created_at.mValue.sTo,
                            bEncode: true
                        };
                    } else if (objFilterParamObject.created_at.bTo) {
                        objFilterParamObject.created_at_to = {
                            mValue: objFilterParamObject.created_at.mValue,
                            bEncode: true
                        };
                    } else if (objFilterParamObject.created_at.bFromAndTo) {
                        objFilterParamObject.created_at_from = {
                            mValue: objFilterParamObject.created_at.mValue.sFrom,
                            bEncode: true
                        };
                        objFilterParamObject.created_at_to = {
                            mValue: objFilterParamObject.created_at.mValue.sTo,
                            bEncode: true
                        };
                    }
                    delete objFilterParamObject.created_at;
                }
                if (!a24Core.isEmpty(objFilterParamObject.completed_at)) {

                    if (objFilterParamObject.completed_at.bFrom) {
                        objFilterParamObject.completed_at_from = {
                            mValue: objFilterParamObject.completed_at.mValue,
                            bEncode: true
                        };
                    } else if (objFilterParamObject.completed_at.bNotWithinLast) {
                        objFilterParamObject.completed_at_from = {
                            mValue: objFilterParamObject.completed_at.mValue.sFrom,
                            bEncode: true
                        };
                    } else if (objFilterParamObject.completed_at.bNotWithinNext) {
                        objFilterParamObject.completed_at_to = {
                            mValue: objFilterParamObject.completed_at.mValue.sTo,
                            bEncode: true
                        };
                    } else if (objFilterParamObject.completed_at.bTo) {
                        objFilterParamObject.completed_at_to = {
                            mValue: objFilterParamObject.completed_at.mValue,
                            bEncode: true
                        };
                    } else if (objFilterParamObject.completed_at.bFromAndTo) {
                        objFilterParamObject.completed_at_from = {
                            mValue: objFilterParamObject.completed_at.mValue.sFrom,
                            bEncode: true
                        };
                        objFilterParamObject.completed_at_to = {
                            mValue: objFilterParamObject.completed_at.mValue.sTo,
                            bEncode: true
                        };
                    }
                    delete objFilterParamObject.completed_at;
                }
            },
            // fake empty response, remove when service is done
            onDidDoServiceCallFail: function onDidDoServiceCallFail(sCode) {
                if (sCode === "404") {
                    this.set("bDatagridLoading", false);
                }
            },
            onRecieveData: function onRecieveData(objData) {
                if (objData.type === "app_ready") {
                    this.set("_bReactPopupLoading", false);
                } else if (objData.type === "refresh") {
                    this._refresh();
                } else if (objData.type === "close") {
                    this.set("_bShowReactPopup", false);
                }
            }
        }
    });
});