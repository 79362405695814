define("a24-staffshift-subdomain-agency/components/page-agency-list-booking-restriction-audit", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-staffshift-subdomain-agency/config/environment"], function (exports, _didRenderChangesMixin, _environment) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        objQueryParams: null,
        bDatagridLoading: false,
        _bPageLoading: false,
        _objSelectedItem: null,
        _arrConsultantRoles: null,
        _sUrlBase: null,
        _bShowLoadFailedPopup: false,

        _objTitleBarService: Ember.inject.service("title-bar"),
        _objNavigation: Ember.inject.service("navigation"),
        _objSSAgencyStrings: Ember.inject.service("a24-strings-staffshift-agency"),
        _objStringsCandidateProfile: Ember.inject.service("a24-strings-candidate-profile"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objSSAgencyEnum: Ember.inject.service("a24-enums-staffshift-agency"),
        _objConsultantRoleService: Ember.inject.service("rest-agency-consultant-role"),
        _objQueryParamBuilderService: Ember.inject.service("query-param-object-builder"),
        _objFilterCustomConsultantRoleService: Ember.inject.service("card-datagrid-custom-filter-consultant-role"),
        _objReactEmberUtils: Ember.inject.service("react-ember-utils"),
        _objTrackerTypeLink: Ember.inject.service("tracker-type-link"),

        _objGetConsultantRole: null,
        init: function init() {
            this._super.apply(this, arguments);

            this.get("_objTitleBarService").setTitleBar(this.get("_objSSAgencyStrings").getString("audit"));

            this.set("_sIFrameUrl", _environment.default.sReactGatewayUrl);

            // Build up the url with context id.
            this.set("_sUrlBase", _environment.default.sStaffingGatewayUrl + "/v1/agency/" + this.get("_objNavigation.sContextId") + "/advanced-search/booking-restriction-violation-acceptance-audit");

            this._doServiceCalls();
        },

        _doServiceCalls: function _doServiceCalls() {
            var _this = this;

            this.set("_bPageLoading", true);

            var objPromiseConsultantRole = this.get("_objConsultantRoleService").getConsultantRoles(this, "_objGetConsultantRole", function () {}, // handled in rsvp
            a24RestCallHelper.createJsonAjaxFailureObject(["500", "502", "503", "504"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                _this._handleServerErrors();
            }));

            var sAgencyId = this.get("_objNavigation.sContextId");
            a24RestUrlConstruct.setBaseUrl("/v1/agency/" + sAgencyId + "/user");
            a24RestUrlConstruct.addQueryParamObject({
                items_per_page: { mValue: 1000 }
            });

            var sUserEndpoint = _environment.default.sRestApiBaseUrl + a24RestUrlConstruct.getConstructedUrl();

            var objUsersPromise = this.get("_objReactEmberUtils").makeReactApiRequest({
                sEndpoint: sUserEndpoint,
                objOptions: {
                    noAbortError: true,
                    method: 'GET',
                    fetchAll: true,
                    useCache: 24,
                    cacheTimeUnit: 'hours'
                },
                objParams: {}
            });

            a24RestUrlConstruct.setBaseUrl("/v1/system/booking-restriction-rule");
            a24RestUrlConstruct.addQueryParamObject({
                items_per_page: { mValue: 1000 }
            });

            var sRulesEndpoint = _environment.default.sStaffingGatewayUrl + a24RestUrlConstruct.getConstructedUrl();
            var objRulesPromise = this.get("_objReactEmberUtils").makeReactApiRequest({
                sEndpoint: sRulesEndpoint,
                objOptions: {
                    noAbortError: true,
                    method: 'GET',
                    fetchAll: true,
                    useCache: 24,
                    cacheTimeUnit: 'hours'
                },
                objParams: {}
            });

            var objPromiseList = {
                _objConsultantRolePromise: objPromiseConsultantRole,
                _objUsersPromise: objUsersPromise,
                _objRulesPromise: objRulesPromise
            };

            var objThis = this;

            var arrRequiredResponses = ["_objConsultantRolePromise", "_objUsersPromise",
            // The react ember component needs to be modified to take in an array of
            // error codes that can be silently consumed @see https://github.com/A24Group/react-core/issues/1411   
            "_objRulesPromise"];

            Ember.copy(a24RSVP).configure(this, objPromiseList, [{
                arrRequiredResponses: arrRequiredResponses,
                funcOnComplete: function funcOnComplete(objResponses) {
                    if (!a24Core.isEmpty(objResponses._objConsultantRolePromise)) {
                        var arrConsultantRoles = objResponses._objConsultantRolePromise.objResponse;

                        if (a24Core.isEmpty(arrConsultantRoles)) {
                            arrConsultantRoles = [];
                        }
                        objThis.set("_arrConsultantRoles", arrConsultantRoles);
                    } else {
                        objThis.set("_bShowLoadFailedPopup", true);
                    }
                    if (!a24Core.isEmpty(objResponses._objUsersPromise)) {
                        var arrUsers = objResponses._objUsersPromise.objResponse;

                        if (a24Core.isEmpty(arrUsers)) {
                            arrUsers = [];
                        }
                    } else {
                        objThis.set("_bShowLoadFailedPopup", true);
                    }
                    if (!a24Core.isEmpty(objResponses._objRulesPromise)) {

                        var arrRules = objResponses._objRulesPromise.objResponse;

                        if (a24Core.isEmpty(arrRules)) {
                            arrRules = [];
                        }
                    } else {
                        objThis.set("_bShowLoadFailedPopup", true);
                    }
                    if (!a24Core.isEmpty(objResponses._objUsersPromise) && !a24Core.isEmpty(objResponses._objConsultantRolePromise)) {
                        objThis._setupPageConfig(arrConsultantRoles, arrUsers, arrRules);
                    }
                }
            }]);
        },

        _setupPageConfig: function _setupPageConfig(arrConsultantRoles, arrUsers, arrRules) {
            var _this2 = this;

            var arrUsersDropdown = [];
            arrUsersDropdown.push({
                sTitle: this.get("_objStringsService").getString("any"),
                sValue: "*"
            });

            for (var k = 0; k < arrUsers.length; k++) {
                arrUsersDropdown.push({
                    sTitle: arrUsers[k].sFirstName + " " + arrUsers[k].sLastName,
                    sValue: arrUsers[k].id
                });
            }

            var arrConsultantRolesDropdown = [];
            arrConsultantRolesDropdown.push({
                sTitle: this.get("_objStringsService").getString("any"),
                sValue: "*"
            });

            for (var l = 0; l < arrConsultantRoles.length; l++) {
                arrConsultantRolesDropdown.push({
                    sTitle: arrConsultantRoles[l].name,
                    sValue: arrConsultantRoles[l]._id
                });
            }

            var arrBookingRestrictionTracker = this.get("_objSSAgencyEnum").getList("bookingRestrictionTracker");
            var arrBookingRestrictionTrackerOptions = [];
            for (var m = 0; m < arrBookingRestrictionTracker.length; m++) {
                arrBookingRestrictionTrackerOptions.push({
                    sTitle: arrBookingRestrictionTracker[m].sLabel,
                    sValue: arrBookingRestrictionTracker[m].sValue
                });
            }

            var arrBookingRulesDropdown = [];
            arrBookingRulesDropdown.push({
                sTitle: this.get("_objStringsService").getString("any"),
                sValue: "*"
            });

            for (var n = 0; n < arrRules.length; n++) {
                arrBookingRulesDropdown.push({
                    sTitle: arrRules[n].rule_name,
                    sValue: arrRules[n]._id
                });
            }

            var arrViolationTypes = this.get("_objSSAgencyEnum").getList("violationTypes");
            var arrViolationTypesDropdown = [];
            for (var y = 0; y < arrViolationTypes.length; y++) {
                arrViolationTypesDropdown.push({
                    sTitle: arrViolationTypes[y].sLabel,
                    sValue: arrViolationTypes[y].sValue
                });
            }

            // This array contain the datagrid columns
            var arrDataGridHeaderConfig = [{
                iUniqueColNum: 1,
                sTitle: this.get("_objStringsCandidateProfile").getString("candidate"),
                sProperty: "candidate_id",
                bStopColumnSort: true,
                iWidth: 15,
                bCustom: true,
                bFilterOr: true,
                sFilterType: "suggest",
                sReactFilterRoute: "datagrid/filters/candidate",
                funcTableCustomVal: function funcTableCustomVal(objHeader, objRowData) {
                    if (!a24Core.isEmpty(objRowData.candidate_first_name) && !a24Core.isEmpty(objRowData.candidate_last_name)) {
                        return Ember.String.htmlSafe("<span title=\"" + objRowData.candidate_first_name + " " + objRowData.candidate_last_name + "\">" + objRowData.candidate_first_name + " " + objRowData.candidate_last_name + "</span>");
                    }
                    return "";
                }
            }, {
                iUniqueColNum: 2,
                sTitle: this.get("_objStringsCandidateProfile").getString("client"),
                sProperty: "client_id",
                sFilterType: "suggest",
                bCustom: true,
                sReactFilterRoute: "datagrid/filters/client",
                bFilterOr: true,
                iWidth: 10,
                bStopColumnSort: true,
                funcTableCustomVal: function funcTableCustomVal(objHeader, objRowData) {
                    if (!a24Core.isEmpty(objRowData.site_name)) {
                        var sSiteName = objRowData.site_name;
                        if (!a24Core.isEmpty(objRowData.ward_name)) {
                            sSiteName = objRowData.site_name + " " + objRowData.ward_name;
                        }
                        return Ember.String.htmlSafe("<span title=\"" + sSiteName + "\">" + sSiteName + "</span>");
                    }
                    return "";
                }
            }, {
                iUniqueColNum: 3,
                sTitle: this.get("_objSSAgencyStrings").getString("checkedFor"),
                sProperty: "tracker_type",
                bMapping: true,
                arrMappingData: arrBookingRestrictionTrackerOptions,
                iWidth: 10,
                bIsClickable: true,
                bHideEmptyCheck: true,
                bStopFiltering: true,
                bStopColumnSort: true
            }, {
                iUniqueColNum: 4,
                sTitle: this.get("_objStringsService").getString("createdBy"),
                sProperty: "tracker_type_created_by_user_id",
                sFilterType: "suggest",
                iWidth: 10,
                bCustom: true,
                bStopColumnSort: true,
                bHideEmptyCheck: true,
                bFilterOr: true,
                iFilterSuggestAt: 3,
                iFilterSuggestDelay: 500,
                sFilterOptionsValueKey: "_id",
                sFilterOptionsLabelKey: "name",
                bFilterShowLoaderDuringSuggest: true,
                bFilterAlwaysShowOnFocus: false,
                funcTableCustomVal: function funcTableCustomVal(objHeader, objRowData) {
                    // look up user
                    var objUser = arrUsersDropdown.find(function (objUser) {
                        return objUser.sValue === objRowData.tracker_type_created_by_user_id;
                    });
                    if (!a24Core.isEmpty(objUser)) {
                        return Ember.String.htmlSafe("<span title=\"" + objUser.sTitle + "\">" + objUser.sTitle + "</span>");
                    }
                },
                funcFilterGetSuggestions: function funcFilterGetSuggestions(sSuggestVal, funcCallback) {
                    var sAgencyId = _this2.get("_objNavigation.sContextId");
                    a24RestUrlConstruct.setBaseUrl("/v1/agency/" + sAgencyId + "/user");
                    var sSearch = "*" + encodeURIComponent(sSuggestVal) + "*";

                    var sUserEndpoint = _environment.default.sRestApiBaseUrl + a24RestUrlConstruct.getConstructedUrl();
                    var objUsersPromise = _this2.get("_objReactEmberUtils").makeReactApiRequest({
                        sEndpoint: sUserEndpoint,
                        objOptions: {
                            noAbortError: true,
                            method: 'GET',
                            fetchAll: false,
                            useCache: 24,
                            cacheTimeUnit: 'hours'
                        },
                        objParams: {
                            items_per_page: 10,
                            q: sSearch
                        }
                    });
                    objUsersPromise.then(function (arrData) {
                        var arrResult = [];
                        // If the data is empty, ensure it's an empty array
                        if (a24Core.isEmpty(arrData)) {
                            arrData = [];
                        }

                        for (var i = 0; i < arrData.length; i++) {
                            var sNameUserName = arrData[i].sFirstName + " " + arrData[i].sLastName + " - " + arrData[i].sUsername;

                            arrResult.push({
                                _id: arrData[i].id,
                                name: sNameUserName
                            });
                        }

                        // Call the callback with the formatted data
                        funcCallback(arrResult);
                    }).catch(function () {
                        a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                            // Call the callback with empty
                            funcCallback([]);
                        });
                    });
                }
            }, {
                iUniqueColNum: 5,
                sTitle: this.get("_objSSAgencyStrings").getString("firstShiftDate"),
                sProperty: "tracker_type_first_shift_date",
                bDate: true,
                bFilterAllowTime: true,
                iWidth: 10,
                bStopColumnSort: true,
                bStopFiltering: true
            }, {
                iUniqueColNum: 6,
                sTitle: this.get("_objSSAgencyStrings").getString("lastShiftDate"),
                sProperty: "tracker_type_last_shift_date",
                bDate: true,
                bFilterAllowTime: true,
                iWidth: 10,
                bStopColumnSort: true,
                bStopFiltering: true
            }, {
                iUniqueColNum: 7,
                sTitle: this.get("_objSSAgencyStrings").getString("shiftStartDate"),
                sProperty: "offending_shifts___start_date",
                sFilterType: "date",
                bDate: true,
                bFilterAllowTime: true,
                iWidth: 10,
                bSilent: true,
                bHideEmptyCheck: true,
                bStopColumnSort: true

            }, {
                iUniqueColNum: 8,
                sTitle: this.get("_objStringsCandidateProfile").getString("consultantRole"),
                sProperty: "client_consultants___consultant_role_id",
                bMapping: true,
                sFilterType: "custom",
                arrConsultantRoles: arrConsultantRolesDropdown,
                iWidth: 15,
                bSilent: true,
                bFilterAnd: true,
                bFilterOr: true,
                sSecondaryProperty: "consultant_id",
                sSecondaryValueProperty: "_id",
                sFilterCustomComponentName: "card-datagrid-custom-filter-consultant-role",
                objFilterCustomService: this.get("_objFilterCustomConsultantRoleService"),
                funcFilterGetSuggestions: function funcFilterGetSuggestions(sSuggestVal, funcCallback) {
                    var sAgencyId = _this2.get("_objNavigation.sContextId");
                    a24RestUrlConstruct.setBaseUrl("/v1/agency/" + sAgencyId + "/user");
                    var sSearch = "*" + encodeURIComponent(sSuggestVal) + "*";

                    var sUserEndpoint = _environment.default.sRestApiBaseUrl + a24RestUrlConstruct.getConstructedUrl();
                    var objUsersPromise = _this2.get("_objReactEmberUtils").makeReactApiRequest({
                        sEndpoint: sUserEndpoint,
                        objOptions: {
                            noAbortError: true,
                            method: 'GET',
                            fetchAll: false,
                            useCache: 24,
                            cacheTimeUnit: 'hours'
                        },
                        objParams: {
                            items_per_page: 10,
                            q: sSearch
                        }
                    });
                    objUsersPromise.then(function (arrData) {
                        var arrResult = [];
                        // If the data is empty, ensure it's an empty array
                        if (a24Core.isEmpty(arrData)) {
                            arrData = [];
                        }

                        for (var i = 0; i < arrData.length; i++) {
                            var sNameUserName = arrData[i].sFirstName + " " + arrData[i].sLastName + " - " + arrData[i].sUsername;

                            arrResult.push({
                                _id: arrData[i].id,
                                name: sNameUserName
                            });
                        }

                        // Call the callback with the formatted data
                        funcCallback(arrResult);
                    }).catch(function () {
                        a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                            // Call the callback with empty
                            funcCallback([]);
                        });
                    });
                }
            }, {
                iUniqueColNum: 9,
                sTitle: this.get("_objSSAgencyStrings").getString("rule"),
                sProperty: "restriction_rule_id",
                sFilterType: "dropdownMulti",
                bCustom: true,
                bStopColumnSort: true,
                bHideEmptyCheck: true,
                arrMappingData: arrBookingRulesDropdown,
                iWidth: 10,
                funcTableCustomVal: function funcTableCustomVal(objHeader, objRowData) {
                    // look up rule
                    var objBookingRule = arrBookingRulesDropdown.find(function (objRule) {
                        return objRule.sValue === objRowData.restriction_rule_id;
                    });

                    if (!a24Core.isEmpty(objBookingRule)) {
                        return Ember.String.htmlSafe("<span title=\"" + objBookingRule.sTitle + "\">" + objBookingRule.sTitle + "</span>");
                    }
                    return "";
                }
            }, {
                iUniqueColNum: 10,
                sTitle: this.get("_objSSAgencyStrings").getString("acceptedBy"),
                sProperty: "accepted_by_user_id",
                sFilterType: "suggest",
                iWidth: 10,
                bCustom: true,
                bStopColumnSort: true,
                bHideEmptyCheck: true,
                bFilterOr: true,
                iFilterSuggestAt: 3,
                iFilterSuggestDelay: 500,
                sFilterOptionsValueKey: "_id",
                sFilterOptionsLabelKey: "name",
                bFilterShowLoaderDuringSuggest: true,
                bFilterAlwaysShowOnFocus: false,
                funcTableCustomVal: function funcTableCustomVal(objHeader, objRowData) {
                    // look up user

                    var objUser = arrUsersDropdown.find(function (objUser) {
                        return objUser.sValue === objRowData.accepted_by_user_id;
                    });
                    if (!a24Core.isEmpty(objUser)) {
                        return Ember.String.htmlSafe("<span title=\"" + objUser.sTitle + "\">" + objUser.sTitle + "</span>");
                    }
                },
                funcFilterGetSuggestions: function funcFilterGetSuggestions(sSuggestVal, funcCallback) {
                    var sAgencyId = _this2.get("_objNavigation.sContextId");
                    a24RestUrlConstruct.setBaseUrl("/v1/agency/" + sAgencyId + "/user");
                    var sSearch = "*" + encodeURIComponent(sSuggestVal) + "*";

                    var sUserEndpoint = _environment.default.sRestApiBaseUrl + a24RestUrlConstruct.getConstructedUrl();
                    var objUsersPromise = _this2.get("_objReactEmberUtils").makeReactApiRequest({
                        sEndpoint: sUserEndpoint,
                        objOptions: {
                            noAbortError: true,
                            method: 'GET',
                            fetchAll: false,
                            useCache: 24,
                            cacheTimeUnit: 'hours'
                        },
                        objParams: {
                            items_per_page: 10,
                            q: sSearch
                        }
                    });
                    objUsersPromise.then(function (arrData) {
                        var arrResult = [];
                        // If the data is empty, ensure it's an empty array
                        if (a24Core.isEmpty(arrData)) {
                            arrData = [];
                        }

                        for (var i = 0; i < arrData.length; i++) {
                            var sNameUserName = arrData[i].sFirstName + " " + arrData[i].sLastName + " - " + arrData[i].sUsername;

                            arrResult.push({
                                _id: arrData[i].id,
                                name: sNameUserName
                            });
                        }

                        // Call the callback with the formatted data
                        funcCallback(arrResult);
                    }).catch(function () {
                        a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                            // Call the callback with empty
                            funcCallback([]);
                        });
                    });
                }
            }, {
                iUniqueColNum: 11,
                sTitle: this.get("_objSSAgencyStrings").getString("acceptedDate"),
                sProperty: "accepted_date",
                sFilterType: "date",
                bDate: true,
                bFilterAllowTime: true,
                iWidth: 10,
                bHideEmptyCheck: true
            }, {
                iUniqueColNum: 12,
                sTitle: this.get("_objSSAgencyStrings").getString("violationType"),
                sProperty: "violation_type",
                sFilterType: "dropdownMulti",
                bStopColumnSort: true,
                bMapping: true,
                arrMappingData: arrViolationTypesDropdown,
                iWidth: 10
            }];

            if (!a24Core.isEmpty(arrConsultantRoles)) {
                var objQueryParams = this.get("objQueryParams");
                var objQueryParamsUpdate = {};
                var arrQueryParamsNew = [];
                var arrQueryParams = [];
                var arrConsultantRolesToShow = [];

                var _loop = function _loop(c) {
                    var sProp = "client_consultants_" + arrConsultantRoles[c].name.toLowerCase().replace(/ /g, "-") + "_" + arrConsultantRoles[c]._id;
                    arrQueryParams.push(sProp);
                    objQueryParamsUpdate[sProp] = "";

                    objQueryParamsUpdate.queryParams = arrQueryParams;
                    // Because the backend can decide to remove/add any role at any time
                    // we wont know the exact number. So we use the id instead
                    arrQueryParamsNew.push({
                        iUniqueColNum: arrConsultantRoles[c]._id,
                        sTitle: arrConsultantRoles[c].name,
                        sProperty: sProp,
                        bCustom: true,
                        sFilterType: "text",
                        bStopFiltering: true,
                        bStopColumnSort: true,
                        iWidth: 10,
                        funcTableCustomVal: function funcTableCustomVal(objHeader, objRowData) {
                            if (!a24Core.isEmpty(objRowData[sProp])) {
                                var sConsultants = objRowData[sProp].map(function (objConsultant) {
                                    return objConsultant.consultant_name;
                                }).join(", ");
                                return Ember.String.htmlSafe("<span title=\"" + sConsultants + "\">" + sConsultants + "</span>");
                            }
                            return "";
                        }
                    });

                    objQueryParamsUpdate = Ember.Object.create(objQueryParamsUpdate);

                    if (arrConsultantRoles[c].name === _this2.get("_objSSAgencyStrings").getString("portfolioManager") || arrConsultantRoles[c].name === _this2.get("_objSSAgencyStrings").getString("portfolioConsultant")) {
                        arrConsultantRolesToShow.push(arrConsultantRoles[c]._id);
                    }
                };

                for (var c = 0; c < arrConsultantRoles.length; c++) {
                    _loop(c);
                }

                var objQueryParamsExisting = this.get("objQueryParams");
                if (!a24Core.isEmpty(arrConsultantRolesToShow)) {
                    // make portfolio manager and portfolio consultant columns visible
                    objQueryParamsUpdate["vis_cols"] = objQueryParamsExisting.vis_cols.sValue + "," + arrConsultantRolesToShow.join(",");
                    objQueryParamsUpdate.queryParams.push("vis_cols");
                }

                if (!a24Core.isEmpty(this.get("addQueryParamsAction"))) {
                    this.get("addQueryParamsAction")(arrQueryParams);
                }
                arrDataGridHeaderConfig.push.apply(arrDataGridHeaderConfig, arrQueryParamsNew);
                this.set("objQueryParams", this.get("_objQueryParamBuilderService").createQueryParamObject(arrQueryParams, objQueryParamsUpdate, objQueryParams, false, true));
            }

            this.set("_objDatagridConfig", {
                sDatagridHeader: this.get("_objSSAgencyStrings").getString("audit"),
                iMaxHeight: 40 * 27,
                bSelectColumn: false,
                bEmitSelectRowEvent: false,
                bHasReloadButton: true,
                bEmitDidDoServiceCall: true,
                bEmitDidDoServiceCallFail: true,
                sReactIFrameUrl: _environment.default.sReactGatewayUrl,
                arrSingleItemSelectActions: [],
                arrHeaderConfig: arrDataGridHeaderConfig,
                bFormatDatesToContextTimezone: true
            });

            this.set("_bPageLoading", false);
        },

        // For selecting items
        _onItemsSelect: function _onItemsSelect(arrSelectedItems) {
            if (!a24Core.isEmpty(arrSelectedItems) && arrSelectedItems.length === 1) {
                this.set("_objSelectedItem", arrSelectedItems[0]);
            }
            // Set the selected items
            this.set("_arrSelectedItems", arrSelectedItems);
        },
        _handleServerErrors: function _handleServerErrors() {
            var _this3 = this;

            Ember.run.next(function () {
                if (a24Core.isEmpty(_this3)) {
                    return;
                }
                _this3.set("_bShowLoadFailedPopup", true);
            });
        },
        _refresh: function _refresh() {
            var _this4 = this;

            this.set("_bShowReactPopup", false);
            // Put the page in loading
            this.set("_bPageLoading", true);

            Ember.run.next(function () {
                if (a24Core.isEmpty(_this4)) {
                    return;
                }
                Ember.run.schedule("afterRender", function () {
                    // Take page out of loading
                    _this4.set("_bPageLoading", false);
                });
            });
        },
        actions: {
            cardDatagridTableNoneSelected: function cardDatagridTableNoneSelected() {
                this._onItemsSelect(null);
            },
            cardDatagridTableOneSelected: function cardDatagridTableOneSelected(arrSelectedItems) {
                this._onItemsSelect(arrSelectedItems);
            },
            cardDatagridTableMultiSelected: function cardDatagridTableMultiSelected(arrSelectedItems) {
                this._onItemsSelect(arrSelectedItems);
            },
            cardDatagridTableCell: function cardDatagridTableCell(sColumnName, objRowData) {
                if (sColumnName === "tracker_type") {
                    this.get("_objTrackerTypeLink").openTrackerLink(objRowData.tracker_type, objRowData.tracker_type_id);
                }
            },
            onDidDoServiceCall: function onDidDoServiceCall(arrTableData, jqXHR, objPOSTBody) {
                var arrConsultantRoles = this.get("_arrConsultantRoles");
                for (var l = 0; l < arrTableData.length; l++) {
                    if (!a24Core.isEmpty(arrTableData[l].client_consultants)) {
                        var _loop2 = function _loop2(i) {
                            var arrData = arrTableData[l].client_consultants.filter(function (objEntry) {
                                return objEntry.consultant_role_id === arrConsultantRoles[i]._id;
                            });
                            if (!a24Core.isEmpty(arrData)) {
                                var _sProp = "client_consultants_" + arrConsultantRoles[i].name.toLowerCase().replace(/ /g, "-") + "_" + arrConsultantRoles[i]._id;
                                arrTableData[l][_sProp] = arrData;
                            }
                        };

                        for (var i = 0; i < arrConsultantRoles.length; i++) {
                            _loop2(i);
                        }
                    }
                }

                if (!a24Core.isEmpty(this.get("onDidDoServiceCall"))) {
                    this.get("onDidDoServiceCall")(arrTableData, jqXHR, objPOSTBody);
                }
            },

            onPageReload: function onPageReload() {
                this.set("_bShowLoadFailedPopup", false);
                this._doServiceCalls();
            },
            addQueryParamsAction: function addQueryParamsAction(arrAddedParams) {
                if (!a24Core.isEmpty(this.get("addQueryParamsAction"))) {
                    this.get("addQueryParamsAction")(arrAddedParams);
                }
            },

            onDidDoServiceCallFail: function onDidDoServiceCallFail(sCode) {
                if (sCode === "404" || sCode === "405") {
                    this.set("bDatagridLoading", false);
                }
            },
            onRecieveData: function onRecieveData(objData) {
                if (objData.type === "app_ready") {
                    this.set("_bReactPopupLoading", false);
                } else if (objData.type === "refresh") {
                    this._refresh();
                } else if (objData.type === "close") {
                    this.set("_bShowReactPopup", false);
                }
            }
        }
    });
});