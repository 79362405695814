define("a24-staffshift-subdomain-agency/components/page-policy-type-list", ["exports", "a24-ember-policy-library/components/page-policy-type-list"], function (exports, _pagePolicyTypeList) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _pagePolicyTypeList.default;
    }
  });
});