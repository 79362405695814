define("a24-staffshift-subdomain-agency/services/rest-agency-saved-searches", ["exports", "a24-staffshift-subdomain-agency/config/environment"], function (exports, _environment) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),

        /**
         * Route for creating saved-searches
         *
         * @param objContext - The the caller this function
         * @param sProperty - The Property name to get the promise object
         * @param objData - The saved search data
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         *
         * @author Junior van Wyk <johannes.vanwyk@a24group.com>
         * @since  24 June 2020
         */
        createItem: function createItem(objContext, sProperty, objData, funcSuccess, objFailure) {
            var sAgencyId = this.get("_objUserSession").getUserContextReference().mId;
            a24RestUrlConstruct.setBaseUrl("/v1/agency/" + sAgencyId + "/saved-searches");

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "POST", _environment.default.sRestApiBaseUrl + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure, objData));
        },

        /**
         * Route for deleting saved-searches
         *
         * @param objContext - The the caller this function
         * @param sProperty - The Property name to get the promise object
         * @param sItemId - The item to remove
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         *
         * @author Junior van Wyk <johannes.vanwyk@a24group.com>
         * @since  24 June 2020
         */
        deleteItem: function deleteItem(objContext, sProperty, sItemId, funcSuccess, objFailure) {
            var sAgencyId = this.get("_objUserSession").getUserContextReference().mId;
            a24RestUrlConstruct.setBaseUrl("/v1/agency/" + sAgencyId + "/saved-searches/" + sItemId);

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "DELETE", _environment.default.sRestApiBaseUrl + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        },

        /**
         * Route for geting list of saved-searches
         *
         * @param objContext - The the caller this function
         * @param sProperty - The Property name to get the promise object
         * @param objQueryParams - Any extra query params for the get list
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         *
         * @author Junior van Wyk <johannes.vanwyk@a24group.com>
         * @since  24 June 2020
         */
        getList: function getList(objContext, sProperty, objQueryParams, funcSuccess, objFailure) {
            var sAgencyId = this.get("_objUserSession").getUserContextReference().mId;
            a24RestUrlConstruct.setBaseUrl("/v1/agency/" + sAgencyId + "/saved-searches");

            a24RestUrlConstruct.addQueryParamObject(objQueryParams);

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "GET", _environment.default.sRestApiBaseUrl + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        }
    });
});