define("a24-staffshift-subdomain-agency/controllers/index/agency-client-change-report", ["exports", "a24-staffshift-subdomain-agency/mixins/query-param-object-builder"], function (exports, _queryParamObjectBuilder) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_queryParamObjectBuilder.default, {
        queryParams: ["organisation_id", "organisation_name", "site_name", "site_id", "ward_name", "ward_id", "client_type", "client_reference", "actioned_at", "actioned_atTo", "actioned_atFrom", "event_type", "diff", "page", "sortBy", "vis_cols", "savedSearchKey"],
        organisation_id: "",
        organisation_name: "",
        site_name: "",
        site_id: "",
        ward_name: "",
        ward_id: "",
        client_type: "",
        client_reference: "",
        actioned_at: "dateFilterLast30Days",
        actioned_atTo: "",
        actioned_atFrom: "",
        event_type: "",
        diff: "",
        page: "1",
        sortBy: "",
        vis_cols: "1,3,5,7,8,9,10,11",
        savedSearchKey: ""
    });
});