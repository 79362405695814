define("a24-staffshift-subdomain-agency/services/rest-agency-candidate-contact-numbers", ["exports", "a24-ember-candidate-profile/services/rest-agency-candidate-contact-numbers"], function (exports, _restAgencyCandidateContactNumbers) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _restAgencyCandidateContactNumbers.default;
    }
  });
});