define("a24-staffshift-subdomain-agency/services/rest-agency-tag", ["exports", "a24-staffshift-subdomain-agency/config/environment"], function (exports, _environment) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),

        /**
         * Create tag
         *
         * @param objCaller - The the caller this function
         * @param sProperty - The Property name to get the promise object
         * @param objPayload - The payload
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
          * @author Ruan Naude <ruan.naude@a24group.com>
         * @since  16 Nov 2020
         */
        createTag: function createTag(objContext, sProperty, objPayload, funcSuccess, objFailure) {
            var sAgencyId = this.get("_objUserSession").getUserContextReference().mId;
            a24RestUrlConstruct.setBaseUrl("/v1/agency/" + sAgencyId + "/tag");

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "POST", _environment.default.sRestApiBaseUrl + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure, objPayload));
        },

        /**
         * Update tag
         *
         * @param objCaller - The the caller this function
         * @param sProperty - The Property name to get the promise object
         * @param sTagId - The id of the tag to update
         * @param objPayload - The payload
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
          * @author Ruan Naude <ruan.naude@a24group.com>
         * @since  16 Nov 2020
         */
        updateTag: function updateTag(objContext, sProperty, sTagId, objPayload, funcSuccess, objFailure) {
            var sAgencyId = this.get("_objUserSession").getUserContextReference().mId;
            a24RestUrlConstruct.setBaseUrl("/v1/agency/" + sAgencyId + "/tag/" + sTagId);

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "PUT", _environment.default.sRestApiBaseUrl + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure, objPayload));
        },

        /**
         * Delete tag
         *
         * @param objCaller - The the caller this function
         * @param sProperty - The Property name to get the promise object
         * @param sTagId - The id of the tag to delete
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
          * @author Ruan Naude <ruan.naude@a24group.com>
         * @since  16 Nov 2020
         */
        deleteTag: function deleteTag(objContext, sProperty, sTagId, funcSuccess, objFailure) {
            var sAgencyId = this.get("_objUserSession").getUserContextReference().mId;
            a24RestUrlConstruct.setBaseUrl("/v1/agency/" + sAgencyId + "/tag/" + sTagId);

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "DELETE", _environment.default.sRestApiBaseUrl + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        }
    });
});