define("a24-staffshift-subdomain-agency/services/card-datagrid-custom-filter-timesheet-break-hours", ["exports", "a24-ember-lib/services/card-datagrid-filter-custom-base"], function (exports, _cardDatagridFilterCustomBase) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _cardDatagridFilterCustomBase.default.extend({
        _objStringsService: Ember.inject.service("a24-strings"),
        _objEnumsService: Ember.inject.service("a24-enums"),
        _objSSAgencyStrings: Ember.inject.service("a24-strings-staffshift-agency"),

        funcFilterCustomPrimaryQuery: function funcFilterCustomPrimaryQuery(objHeaderItem /* , objHeaderSecondaries */) {
            var objQuery = {};

            if (objHeaderItem._objYesNo.sValue === this.get("_objEnumsService.yesNo.YES.backend")) {
                objQuery.bAfter = true;
                objQuery.mValue = 0;
            } else if (objHeaderItem._objYesNo.sValue === this.get("_objEnumsService.yesNo.NO.backend")) {
                objQuery.bAfter = false;
                objQuery.mValue = 0;
            }

            return objQuery;
        },

        funcFilterCustomTag: function funcFilterCustomTag(objHeaderItem, objQuery, objHeadersSecondaries, bSkipTitle) {
            var sTimesheetBreakHoursTagKey = "timesheetBreakHoursTag";

            if (bSkipTitle) {
                sTimesheetBreakHoursTagKey = "timesheetBreakHoursTagNoTitle";
            }

            var sTimesheetBreakHours = "";

            if (objQuery.bAfter === true) {
                sTimesheetBreakHours = this.get("_objStringsService").getString("yes");
            } else {
                sTimesheetBreakHours = this.get("_objStringsService").getString("no");
            }

            return {
                sTag: this.get("_objSSAgencyStrings").getTokenString(sTimesheetBreakHoursTagKey, sTimesheetBreakHours)
            };
        },

        funcFilterCustomPrimaryReset: function funcFilterCustomPrimaryReset(objHeaderItem) {
            //Reset the state of the custom filter input
            Ember.set(objHeaderItem, "_objYesNo", null);
        },

        funcFilterCustomFixupQuery: function funcFilterCustomFixupQuery() /*objHeader, objHeaderSecondaries */{},

        funcFilterCustomSetEditState: function funcFilterCustomSetEditState(objHeaderItem, iIndex) {
            var objHeaderTag = objHeaderItem.mFilterValue[iIndex];

            if (objHeaderTag.bAfter === true) {
                Ember.set(objHeaderItem, "_objYesNo", {
                    sLabel: this.get("_objEnumsService.yesNo.YES.frontend"),
                    sValue: this.get("_objEnumsService.yesNo.YES.backend")
                });
            } else {
                Ember.set(objHeaderItem, "_objYesNo", {
                    sLabel: this.get("_objEnumsService.yesNo.NO.frontend"),
                    sValue: this.get("_objEnumsService.yesNo.NO.backend")
                });
            }
        }
    });
});