define("a24-staffshift-subdomain-agency/components/shareable-link", ["exports", "a24-ember-lib/components/shareable-link"], function (exports, _shareableLink) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _shareableLink.default;
    }
  });
});