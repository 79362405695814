define("a24-staffshift-subdomain-agency/components/page-agency-compliance-exemptions", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-staffshift-subdomain-agency/config/environment"], function (exports, _didRenderChangesMixin, _environment) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _defineProperty(obj, key, value) {
        if (key in obj) {
            Object.defineProperty(obj, key, {
                value: value,
                enumerable: true,
                configurable: true,
                writable: true
            });
        } else {
            obj[key] = value;
        }

        return obj;
    }

    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        objQueryParams: null,
        sClientType: null,

        _bPageLoading: false,
        _bShowLoadFailedPopup: false,
        _bReactPopupOpen: false,
        _bReactPopupLoading: false,

        _sUrlBase: null,
        _sClientId: null,

        _objSelectedItem: null,

        _arrConsultantRoles: null,
        _arrSelectedItems: null,

        _objTitleBarService: Ember.inject.service("title-bar"),
        _objNavigation: Ember.inject.service("navigation"),
        _objUserSession: Ember.inject.service("user-session"),
        _objStringsCandidateProfile: Ember.inject.service("a24-strings-candidate-profile"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objSSAgencyEnum: Ember.inject.service("a24-enums-staffshift-agency"),

        _objReactEmberUtils: Ember.inject.service("react-ember-utils"),
        _objQueryParamBuilderService: Ember.inject.service("query-param-object-builder"),
        _objRestEntityActionSection: Ember.inject.service("rest-entity-action-section"),
        _objFabButtonService: Ember.inject.service("fab-button"),

        _getDefaultNowDateFilter: function _getDefaultNowDateFilter() {
            var objNowDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
            objNowDate.setDateFromRestAllowTimeAlter(momentHelper.getCurrentDateString(a24Constants.DATE_FORMAT_REST_FORMAT));

            return objNowDate.getDateTimeFormat();
        },

        init: function init() {
            this._super.apply(this, arguments);

            this.get("_objTitleBarService").setTitleBar(this.get("_objStringsCandidateProfile").getString("complianceExemptions"));

            var sDefaultNowDate = this._getDefaultNowDateFilter();
            if (a24Core.isEmpty(this.get("objQueryParams.effective_to.sValue"))) {
                this.set("objQueryParams.effective_toFrom.sValue", sDefaultNowDate);
            }

            // Build up the url with context id.
            this.set("_sUrlBase", _environment.default.sRestApiBaseUrl + "/v1/agency/" + this.get("_objNavigation.sContextId") + "/advanced-search/compliance-rule-exemption");

            this.set("_sIFrameUrl", _environment.default.sReactGatewayUrl);

            this._doServiceCalls();
        },

        _doServiceCalls: function _doServiceCalls() {
            this.set("_bPageLoading", true);

            var sAgencyId = this.get("_objNavigation.sContextId");
            var sClientId = "";
            if ("index.agency-client-org-view" in this.get("_objNavigation.objRouteParams")) {
                sClientId = this.get("_objNavigation.objRouteParams")["index.agency-client-org-view"].organisation_id;
            } else if ("index.agency-client-site-view" in this.get("_objNavigation.objRouteParams")) {
                sClientId = this.get("_objNavigation.objRouteParams")["index.agency-client-site-view"].site_id;
            } else if ("index.agency-client-ward-view" in this.get("_objNavigation.objRouteParams")) {
                sClientId = this.get("_objNavigation.objRouteParams")["index.agency-client-ward-view"].ward_id;
            }
            this.set("_sClientId", sClientId);

            a24RestUrlConstruct.setBaseUrl("/v1/agency/" + sAgencyId + "/candidate-type");
            a24RestUrlConstruct.addQueryParamObject({
                items_per_page: { mValue: 1000 },
                name: {
                    bSortBy: true,
                    mValue: "asc"
                }
            });

            var sCandidateTypesEndpoint = _environment.default.sRestApiBaseUrl + a24RestUrlConstruct.getConstructedUrl();

            var objPromiseCandidateTypes = this.get("_objReactEmberUtils").makeReactApiRequest({
                sEndpoint: sCandidateTypesEndpoint,
                objOptions: {
                    noAbortError: true,
                    method: 'GET',
                    fetchAll: true,
                    useCache: 24,
                    cacheTimeUnit: 'hours'
                },
                objParams: {}
            });

            var objPromiseEntityAction = this.get("_objRestEntityActionSection").getEntityActionsForSection(this, "_objPromiseEntityAction", "compliance-rule-exemption", function () {}, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode , sStatus, objErrorThrown, objError*/{}));

            var objPromiseList = {
                _objCandidateTypesPromise: objPromiseCandidateTypes,
                _objPromiseEntityAction: objPromiseEntityAction
            };

            var objThis = this;

            var arrRequiredResponses = ["_objCandidateTypesPromise", "_objPromiseEntityAction"];

            if (this.get("sClientType") !== this.get("_objSSAgencyEnum.nodeLevel.ORGANISATION.backend")) {
                a24RestUrlConstruct.setBaseUrl("/v1/agency/" + sAgencyId + "/advanced-search/agency-client");
                a24RestUrlConstruct.addQueryParamObject({
                    items_per_page: { mValue: 1000 }
                });

                var sClientEndpoint = _environment.default.sRestApiBaseUrl + a24RestUrlConstruct.getConstructedUrl();

                var objClientPromise = this.get("_objReactEmberUtils").makeReactApiRequest({
                    sEndpoint: sClientEndpoint,
                    objOptions: {
                        noAbortError: true,
                        method: 'POST',
                        fetchAll: true,
                        useCache: 1,
                        cacheTimeUnit: 'hours',
                        body: {
                            items_per_page: 1000,
                            query: {
                                and: [{ node_level: { in: ["ward", "site"] } }, _defineProperty({}, this.get("sClientType") === "site" ? "site_id" : "ward_id", {
                                    equal: sClientId
                                })]
                            }
                        }
                    },
                    objParams: {}
                });

                objPromiseList._objClientPromise = objClientPromise;
                arrRequiredResponses.push("_objClientPromise");
            }

            Ember.copy(a24RSVP).configure(this, objPromiseList, [{
                arrRequiredResponses: arrRequiredResponses,
                funcOnComplete: function funcOnComplete(objResponses) {
                    var arrClients = [];
                    var arrCandidateTypes = [];
                    if (!a24Core.isEmpty(objResponses._objPromiseEntityAction) && !a24Core.isEmpty(objResponses._objPromiseEntityAction.objResponse)) {
                        objThis._setFabAction(objResponses._objPromiseEntityAction.objResponse);
                    }

                    if (objThis.get("sClientType") !== objThis.get("_objSSAgencyEnum.nodeLevel.ORGANISATION.backend")) {
                        if (!a24Core.isEmpty(objResponses._objClientPromise)) {
                            arrClients = objResponses._objClientPromise.objResponse;

                            if (a24Core.isEmpty(arrClients)) {
                                arrClients = [];
                            }
                        } else {
                            objThis.set("_bShowLoadFailedPopup", true);
                        }
                    }

                    if (!a24Core.isEmpty(objResponses._objCandidateTypesPromise)) {
                        arrCandidateTypes = objResponses._objCandidateTypesPromise.objResponse;

                        if (a24Core.isEmpty(arrCandidateTypes)) {
                            arrCandidateTypes = [];
                        }
                    } else {
                        objThis.set("_bShowLoadFailedPopup", true);
                    }
                    if (!a24Core.isEmpty(objResponses._objCandidateTypesPromise)) {
                        objThis._setupPageConfig(arrCandidateTypes, sClientId, arrClients);
                    }
                }
            }]);
        },

        _setFabAction: function _setFabAction(arrSectionActions) {
            var _this = this;

            var bAllowAction = false;
            if (!a24Core.isEmpty(arrSectionActions)) {
                var objSectionAction = arrSectionActions.find(function (objActionSection) {
                    return objActionSection.entity_type === "compliance-rule-exemption";
                });
                var arrAllowedActions = [];
                if (!a24Core.isEmpty(objSectionAction.actions)) {
                    objSectionAction.actions.forEach(function (objItem) {
                        if (objItem.allowed) {
                            arrAllowedActions.push(objItem.action);
                        }
                    });
                }
                bAllowAction = arrAllowedActions.includes("create");
            }
            if (bAllowAction) {
                this.get("_objFabButtonService").showFabButton(function () {
                    _this.set("_bReactPopupOpen", true);
                    _this.set("_objReactPopupPostData", {
                        type: "trigger_route",
                        data: {
                            route: "agency/compliance/ruleExemptions/addPopup",
                            data: {
                                subjectId: _this.get("_sClientId"),
                                subjectType: _this.get("sClientType"),
                                headerName: _this.get("sClientType").charAt(0).toUpperCase() + _this.get("sClientType").slice(1)
                            }
                        }
                    });
                });
            }
        },

        _setupPageConfig: function _setupPageConfig(arrCandidateTypes, sClientId, arrClients) {
            var _this2 = this;

            var objThis = this;

            // This array contain the datagrid columns
            var arrDataGridHeaderConfig = [{
                iUniqueColNum: 1,
                sTitle: this.get("_objStringsCandidateProfile").getString("candidate"),
                sProperty: "candidate_id",
                bStopColumnSort: true,
                iWidth: 10,
                bSilent: true,
                sFilterType: "suggest",
                bFilterOr: true,
                sReactFilterRoute: "datagrid/filters/candidate"
            }, {
                iUniqueColNum: 2,
                sTitle: this.get("_objStringsCandidateProfile").getString("candidate"),
                sProperty: "candidate_name",
                bText: true,
                iWidth: 10,
                bStopFiltering: true
            }].concat([this.get("sClientType") !== this.get("_objSSAgencyEnum.nodeLevel.ORGANISATION.backend") ? {
                iUniqueColNum: 3,
                sTitle: this.get("_objStringsCandidateProfile").getString("wardName"),
                sProperty: "ward_name",
                bText: true,
                iWidth: 10,
                bStopFiltering: true,
                bStopColumnSort: true
            } : null], [{
                iUniqueColNum: 4,
                sTitle: this.get("_objStringsCandidateProfile").getString("complianceRule"),
                sProperty: "compliance_rule_id",
                sFilterType: "suggest",
                sReactFilterRoute: "datagrid/filterEditor/complianceRule",
                arrReactFilterExtraDataProps: ["candidate_types"],
                iWidth: 10,
                bSilent: true,
                bFilterAnd: true,
                bFilterOr: true
            }, {
                iUniqueColNum: 5,
                sTitle: this.get("_objStringsCandidateProfile").getString("complianceRule"),
                sProperty: "compliance_rule_name",
                bText: true,
                iWidth: 10,
                bStopFiltering: true,
                bStopColumnSort: true
            }, {
                iUniqueColNum: 6,
                sTitle: this.get("_objStringsCandidateProfile").getString("effectiveFrom"),
                sProperty: "effective_from",
                sFilterType: "date",
                bDate: true,
                bFilterAllowTime: true,
                iWidth: 10
            }, {
                iUniqueColNum: 7,
                sTitle: this.get("_objStringsCandidateProfile").getString("effectiveTo"),
                sProperty: "effective_to",
                sFilterType: "date",
                bDate: true,
                bFilterAllowTime: true,
                iWidth: 10
            }]).filter(Boolean);

            var sOrgId = "";
            var sOrgName = "";
            var sSiteId = "";
            var arrWards = [];

            if (!a24Core.isEmpty(arrClients)) {
                sOrgId = arrClients[0].organisation_id;
                sOrgName = arrClients[0].organisation_name;
                sSiteId = arrClients[0].site_id;
                arrWards = arrClients.map(function (objClient) {
                    return objClient.node_level === _this2.get("_objSSAgencyEnum.nodeLevel.WARD.backend") ? objClient.ward_id : null;
                }).filter(Boolean);
            }

            var objPermanentQuery = {};
            switch (this.get("sClientType")) {
                case this.get("_objSSAgencyEnum.nodeLevel.ORGANISATION.backend"):
                    objPermanentQuery = {
                        organisation_id: {
                            mCstmQry: [{
                                sProp: "organisation_id",
                                mValue: sClientId,
                                bEncode: true
                            }]
                        }
                    };
                    break;
                case this.get("_objSSAgencyEnum.nodeLevel.SITE.backend"):
                    objPermanentQuery = {
                        site_id: {
                            mCstmQry: [{
                                sProp: "organisation_id",
                                mValue: sOrgId,
                                sFilterValue: sOrgName,
                                arSec: [{
                                    sProp: "applies_to",
                                    mValue: "OrganisationStructure"
                                }],
                                bEncode: true
                            }, {
                                sProp: "organisation_id",
                                mValue: sOrgId,
                                sFilterValue: sOrgName,
                                arSec: [{
                                    mValue: sClientId,
                                    sProp: "site_id"
                                }, {
                                    sProp: "applies_to",
                                    mValue: "SiteStructure"
                                }],
                                bO: true,
                                bEncode: true
                            }, {
                                sProp: "organisation_id",
                                mValue: sOrgId,
                                sFilterValue: sOrgName,
                                arSec: [{
                                    mValue: sClientId,
                                    sProp: "site_id"
                                }, {
                                    sProp: "applies_to",
                                    mValue: "SiteSpecific"
                                }],
                                bO: true,
                                bEncode: true
                            }].concat([!a24Core.isEmpty(arrWards) ? {
                                sProp: "organisation_id",
                                mValue: sOrgId,
                                sFilterValue: sOrgName,
                                arSec: [{
                                    mValue: sClientId,
                                    sProp: "site_id"
                                }, {
                                    sProp: "ward_id",
                                    mValue: arrWards.join(","),
                                    bDelimited: true
                                }, {
                                    sProp: "applies_to",
                                    mValue: "WardSpecific"
                                }],
                                bO: true,
                                bEncode: true
                            } : null]).filter(Boolean)
                        }
                    };
                    break;
                case this.get("_objSSAgencyEnum.nodeLevel.WARD.backend"):
                    objPermanentQuery = {
                        ward_id: {
                            mCstmQry: [{
                                sProp: "organisation_id",
                                mValue: sOrgId,
                                sFilterValue: sOrgName,
                                arSec: [{
                                    sProp: "applies_to",
                                    mValue: "OrganisationStructure"
                                }],
                                bEncode: true
                            }, {
                                sProp: "organisation_id",
                                mValue: sOrgId,
                                sFilterValue: sOrgName,
                                arSec: [{
                                    mValue: sSiteId,
                                    sProp: "site_id"
                                }, {
                                    sProp: "applies_to",
                                    mValue: "SiteStructure"
                                }],
                                bO: true,
                                bEncode: true
                            }, {
                                sProp: "organisation_id",
                                mValue: sOrgId,
                                sFilterValue: sOrgName,
                                arSec: [{
                                    mValue: sSiteId,
                                    sProp: "site_id"
                                }, {
                                    sProp: "ward_id",
                                    mValue: sClientId
                                }, {
                                    sProp: "applies_to",
                                    mValue: "WardSpecific"
                                }],
                                bO: true,
                                bEncode: true
                            }]
                        }
                    };
                    break;
                default:
                    break;
            }

            var arrSingleItemActions = [{
                sTitle: this.get("_objStringsCandidateProfile").getString("viewExemption"),
                sIcon: "visibility",
                executeCardAction: function executeCardAction() {
                    var objExemption = objThis.get("_arrSelectedItems")[0];
                    objThis._viewExemption(objExemption);
                }
            }];

            this.set("_objDatagridConfig", {
                sDatagridHeader: this.get("_objStringsCandidateProfile").getString("complianceExemptions"),
                iMaxHeight: 40 * 27,
                bSelectColumn: true,
                bEmitSelectRowEvent: true,
                bHasReloadButton: true,
                bEmitWillDoServiceCall: true,
                candidate_types: arrCandidateTypes,
                sReactIFrameUrl: _environment.default.sReactGatewayUrl,
                arrSingleItemSelectActions: arrSingleItemActions,
                arrHeaderConfig: arrDataGridHeaderConfig,
                objPermanentQuery: objPermanentQuery
            });

            this.set("_bPageLoading", false);
        },

        _viewExemption: function _viewExemption(objExemption) {
            this.set("_bReactPopupOpen", true);
            this.set("_objReactPopupPostData", {
                type: "trigger_route",
                data: {
                    route: "agency/compliance/ruleExemptions/viewPopup",
                    data: {
                        complianceRuleExemptionId: objExemption.compliance_rule_exemption_id,
                        rangeId: objExemption.range_id,
                        agencyId: this.get("sContext") === "agency-group" ? objExemption.agency_id : undefined
                    }
                }
            });
        },

        _onItemsSelect: function _onItemsSelect(arrSelectedItems) {
            if (!a24Core.isEmpty(arrSelectedItems) && arrSelectedItems.length === 1) {
                this.set("_objSelectedItem", arrSelectedItems[0]);
            }
            // Set the selected items
            this.set("_arrSelectedItems", arrSelectedItems);
        },
        _refresh: function _refresh() {
            var _this3 = this;

            this.set("_bReactPopupOpen", false);
            // Put the page in loading
            this.set("_bPageLoading", true);

            Ember.run.next(function () {
                if (a24Core.isEmpty(_this3)) {
                    return;
                }
                Ember.run.schedule("afterRender", function () {
                    // Take page out of loading
                    _this3.set("_bPageLoading", false);
                });
            });
        },
        actions: {
            cardDatagridTableNoneSelected: function cardDatagridTableNoneSelected() {
                this._onItemsSelect(null);
            },
            cardDatagridTableOneSelected: function cardDatagridTableOneSelected(arrSelectedItems) {
                this._onItemsSelect(arrSelectedItems);
            },
            cardDatagridTableMultiSelected: function cardDatagridTableMultiSelected(arrSelectedItems) {
                this._onItemsSelect(arrSelectedItems);
            },
            cardDatagridTableCell: function cardDatagridTableCell() /*sColumnName, objRowData*/{},
            onPageReload: function onPageReload() {
                this.set("_bShowLoadFailedPopup", false);
                this._doServiceCalls();
            },
            onRecieveReactData: function onRecieveReactData(objData) {
                if (objData.type === "app_ready") {
                    this.set("_bReactPopupLoading", false);
                } else if (objData.type === "refresh") {
                    this._refresh();
                } else if (objData.type === "close") {
                    this.set("_bReactPopupOpen", false);
                }
            },
            onWillDoServiceCall: function onWillDoServiceCall()
            /*arrTableData,
            objUrlConstantPart,
            objFilterParamObject,
            objTableParamObject,
            iPage,
            iPerPage,
            objSuccessCallback,
            objFailure,
            objProject*/
            {
                if (!a24Core.isEmpty(this.get("onWillDoServiceCall"))) {
                    this.get("onWillDoServiceCall").apply(undefined, arguments);
                }
            }
        }
    });
});