define("a24-staffshift-subdomain-agency/components/page-agency-whitelist-user-ip", ["exports", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        _objAgencyWhitelistIpService: Ember.inject.service("rest-agency-ip-whitelist"),
        _objAgencyWhitelistUserService: Ember.inject.service("rest-agency-user-whitelist"),
        _objAgencyUserService: Ember.inject.service("rest-agency-users"),
        _objTitleBarService: Ember.inject.service("title-bar"),
        _objUsersStringsService: Ember.inject.service("a24-strings-staffshift-users"),
        _objNavigationService: Ember.inject.service("navigation"),

        init: function init() {
            this._super.apply(this, arguments);

            this.setTitleBar(this.get("_objUsersStringsService").getString("userIpwhitelists"));
        },
        setTitleBar: function setTitleBar(sText) {
            var _this = this;

            this.get("_objTitleBarService").setTitleBar(sText, function () {
                _this.get("_objNavigationService").navigateWithinContext("whitelist-user-ip");
            });
        }
    });
});