define("a24-staffshift-subdomain-agency/controllers/index/agency-linked-client-search", ["exports", "a24-staffshift-subdomain-agency/mixins/query-param-object-builder"], function (exports, _queryParamObjectBuilder) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_queryParamObjectBuilder.default, {
        queryParams: ["organisation_id", "organisation_name", "site_id", "site_name", "ward_id", "ward_name", "node_level", "client_reference", "site_type_id", "site_type_name", "ward_type_id", "ward_type_name", "site_address_line_1", "site_address_line_2", "site_province", "site_post_code", "site_city", "site_country_code", "site_reference_number", "ward_reference_number", "site_physical_geo_location", "compliance_contract_id", "compliance_contract_name", "compliance_contract_status", "client_consultants___consultant_role_id", "previous_shift_at", "previous_shift_atFrom", "previous_shift_atTo", "next_shift_at", "next_shift_atFrom", "next_shift_atTo", "previous_booking_at", "previous_booking_atFrom", "previous_booking_atTo", "next_booking_at", "next_booking_atFrom", "next_booking_atTo", "first_shift_at", "first_shift_atFrom", "first_shift_atTo", "first_booking_at", "first_booking_atFrom", "first_booking_atTo", "activities___activity_type", "payment_term", "payment_term_inherited", "financial_hold", "financial_hold_inherited", "booking_preferences", "page", "sortBy", "vis_cols", "savedSearchKey"],
        organisation_id: "",
        organisation_name: "",
        site_id: "",
        site_name: "",
        ward_id: "",
        ward_name: "",
        node_level: "site|ward",
        client_reference: "",
        site_type_id: "",
        site_type_name: "",
        ward_type_id: "",
        ward_type_name: "",
        site_address_line_1: "",
        site_address_line_2: "",
        site_province: "",
        site_post_code: "",
        site_city: "",
        site_country_code: "",
        site_reference_number: "",
        ward_reference_number: "",
        site_physical_geo_location: "",
        compliance_contract_id: "",
        compliance_contract_name: "",
        compliance_contract_status: "",
        client_consultants___consultant_role_id: "",
        previous_shift_at: "",
        previous_shift_atFrom: "",
        previous_shift_atTo: "",
        next_shift_at: "",
        next_shift_atFrom: "",
        next_shift_atTo: "",
        previous_booking_at: "",
        previous_booking_atFrom: "",
        previous_booking_atTo: "",
        next_booking_at: "",
        next_booking_atFrom: "",
        next_booking_atTo: "",
        first_shift_at: "",
        first_shift_atFrom: "",
        first_shift_atTo: "",
        first_booking_at: "",
        first_booking_atFrom: "",
        first_booking_atTo: "",
        activities___activity_type: "",
        payment_term: "",
        payment_term_inherited: "",
        financial_hold: "",
        financial_hold_inherited: "",
        booking_preferences: "",
        page: "1",
        sortBy: "site_name::asc",
        vis_cols: "1,3,5,6,7,8,9,13",
        savedSearchKey: "",
        actions: {
            addQueryParamsAction: function addQueryParamsAction(arrParams) {
                for (var i = 0; i < arrParams.length; i++) {
                    this.set(arrParams[i], "");

                    // We are manually adding the observer here from the mixin as this observes
                    // the url query params
                    this.addObserver(arrParams[i], this, this.get("_funcObserverCallback"));
                }
            }

        }
    });
});