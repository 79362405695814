define("a24-staffshift-subdomain-agency/services/a24-enums-staffshift-agency", ["exports", "a24-ember-lookup/services/a24-enums-base"], function (exports, _a24EnumsBase) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _a24EnumsBase.default.extend({
        _objStringsService: Ember.inject.service("a24-strings"),
        _objSSAgencyStringsService: Ember.inject.service("a24-strings-staffshift-agency"),

        init: function init() {
            this._super.apply(this, arguments);

            var objStrings = this.get("_objStringsService");
            var objSSAgencyStringsService = this.get("_objSSAgencyStringsService");

            this.set("hoursDiff", {
                "EQUAL": {
                    "backend": objSSAgencyStringsService.getString("equal"),
                    "frontend": objSSAgencyStringsService.getString("equal")
                },
                "MORE_THAN": {
                    "backend": objSSAgencyStringsService.getString("moreThan"),
                    "frontend": objSSAgencyStringsService.getString("moreThan")
                },
                "LESS_THAN": {
                    "backend": objSSAgencyStringsService.getString("lessThan"),
                    "frontend": objSSAgencyStringsService.getString("lessThan")
                }
            });

            this.set("availabilityOptions", {
                "AVAILABLE": {
                    "backend": "Available",
                    "frontend": objSSAgencyStringsService.getString("available")
                },
                "BOOKED": {
                    "backend": "Booked",
                    "frontend": objSSAgencyStringsService.getString("booked")
                },
                "UNAVAILABLE": {
                    "backend": "Unavailable",
                    "frontend": objSSAgencyStringsService.getString("unavailable")
                },
                "UNKNOWN": {
                    "backend": "Unknown",
                    "frontend": objSSAgencyStringsService.getString("unknown")
                }
            });

            this.set("intervalStatus", {
                "NEW": {
                    "backend": "New",
                    "frontend": objSSAgencyStringsService.getString("new")
                },
                "CONFIRMED": {
                    "backend": "Confirmed",
                    "frontend": objSSAgencyStringsService.getString("confirmed")
                },
                "AUTHORISED": {
                    "backend": "Authorised",
                    "frontend": objSSAgencyStringsService.getString("authorised")
                },
                "APPROVED": {
                    "backend": "Approved",
                    "frontend": objSSAgencyStringsService.getString("approved")
                },
                "CANCELLED": {
                    "backend": "Cancelled",
                    "frontend": objSSAgencyStringsService.getString("cancelled")
                },
                "REJECTED": {
                    "backend": "Rejected",
                    "frontend": objSSAgencyStringsService.getString("rejected")
                },
                "CLOSED": {
                    "backend": "Closed",
                    "frontend": objSSAgencyStringsService.getString("closed")
                }
            });

            this.set("timesheetStatus", {
                "NEW": {
                    "backend": "New",
                    "frontend": objSSAgencyStringsService.getString("new")
                },
                "CONFIRMED": {
                    "backend": "Confirmed",
                    "frontend": objSSAgencyStringsService.getString("confirmed")
                },
                "AUTHORISED": {
                    "backend": "Authorised",
                    "frontend": objSSAgencyStringsService.getString("authorised")
                },
                "OPEN": {
                    "backend": "Open",
                    "frontend": objSSAgencyStringsService.getString("open")
                },
                "APPROVED": {
                    "backend": "Approved",
                    "frontend": objSSAgencyStringsService.getString("approved")
                },
                "CANCELLED": {
                    "backend": "Cancelled",
                    "frontend": objSSAgencyStringsService.getString("cancelled")
                },
                "REJECTED": {
                    "backend": "Rejected",
                    "frontend": objSSAgencyStringsService.getString("rejected")
                },
                "CLOSED": {
                    "backend": "Closed",
                    "frontend": objSSAgencyStringsService.getString("closed")
                }
            });

            this.set("shiftOptions", {
                "EARLY": {
                    "backend": "Early",
                    "frontend": objSSAgencyStringsService.getString("early")
                },
                "LATE": {
                    "backend": "Late",
                    "frontend": objSSAgencyStringsService.getString("late")
                },
                "NIGHT": {
                    "backend": "Night",
                    "frontend": objSSAgencyStringsService.getString("night")
                }
            });

            this.set("availabilityFilterDateOptions", {
                "DATE_FILTER_TODAY": {
                    "backend": "dateFilterToday",
                    "frontend": objStrings.getString("dateFilterToday")
                },
                "DATE_FILTER_TOMORROW": {
                    "backend": "dateFilterTomorrow",
                    "frontend": objSSAgencyStringsService.getString("dateFilterTomorrow")
                },
                "DATE_FILTER_NEXT_5_DAYS": {
                    "backend": "dateFilterNext5Days",
                    "frontend": objSSAgencyStringsService.getString("dateFilterNext5Days")
                },
                "DATE_FILTER_SPECIFIC_DATE": {
                    "backend": "dateFilterSpecificDate",
                    "frontend": objSSAgencyStringsService.getString("dateFilterSpecificDate")
                },
                "DATE_FILTER_ALL_DATES_IN_RANGE": {
                    "backend": "dateFilterAllDatesInRange",
                    "frontend": objSSAgencyStringsService.getString("dateFilterAllDatesInRange")
                },
                "DATE_FILTER_ANY_DATES_IN_RANGE": {
                    "backend": "dateFilterAnyDatesInRange",
                    "frontend": objSSAgencyStringsService.getString("dateFilterAnyDatesInRange")
                }
            });

            this.set("nodeLevel", {
                "ORGANISATION": {
                    "backend": "organisation",
                    "frontend": objStrings.getString("organisation")
                },
                "SITE": {
                    "backend": "site",
                    "frontend": objSSAgencyStringsService.getString("site")
                },
                "WARD": {
                    "backend": "ward",
                    "frontend": objSSAgencyStringsService.getString("ward")
                }
            });

            this.set("complianceContractStatus", {
                "ACTIVE": {
                    "backend": "active",
                    "frontend": objStrings.getString("active")
                },
                "INACTIVE": {
                    "backend": "inactive",
                    "frontend": objStrings.getString("inactive")
                }
            });

            this.set("paymentTerms", {
                "CREDIT": {
                    "backend": "credit",
                    "frontend": objSSAgencyStringsService.getString("credit")
                },
                "PAYINADVANCE": {
                    "backend": "pay_in_advance",
                    "frontend": objSSAgencyStringsService.getString("payInAdvance")
                }
            });

            this.set("financialHold", {
                "APPLIED": {
                    "backend": "applied",
                    "frontend": objSSAgencyStringsService.getString("applied")
                },
                "CLEARED": {
                    "backend": "cleared",
                    "frontend": objStrings.getString("no")
                }
            });

            this.set("complianceStatuses", {
                "COMPLIANT": {
                    "backend": "COMPLIANT",
                    "frontend": objSSAgencyStringsService.getString("compliant")
                },
                "INCOMPLIANT": {
                    "backend": "INCOMPLIANT",
                    "frontend": objSSAgencyStringsService.getString("incompliant")
                },
                "NOCOMPLIANCECONTRACTSET": {
                    "backend": "NO_COMPLIANCE_CONTRACT_SET",
                    "frontend": objSSAgencyStringsService.getString("noComplianceContractSet")
                },
                "NOTYETCOMPUTED": {
                    "backend": "NOT_YET_COMPUTED",
                    "frontend": objSSAgencyStringsService.getString("notYetComputed")
                },
                "NOTAPPLICABLE": {
                    "backend": "NOT_APPLICABLE",
                    "frontend": objSSAgencyStringsService.getString("notApplicable")
                }
            });

            this.set("exclusionStatuses", {
                "EXCLUDED": {
                    "backend": "EXCLUDED",
                    "frontend": objSSAgencyStringsService.getString("excluded")
                },
                "NOT_EXCLUDED": {
                    "backend": "NOT_EXCLUDED",
                    "frontend": objSSAgencyStringsService.getString("notExcluded")
                }
            });

            this.set("bookingPreferences", {
                "REQUIRESPONUMBER": {
                    "backend": "requires_po_number",
                    "frontend": objSSAgencyStringsService.getString("requiresPONumber")
                },
                "REQUIRESUNIQUEPONUMBER": {
                    "backend": "requires_unique_po_number",
                    "frontend": objSSAgencyStringsService.getString("requiresUniquePONumber")
                },
                "REQUIRESBOOKINGPASSWORD": {
                    "backend": "requires_booking_password",
                    "frontend": objSSAgencyStringsService.getString("requiresPassword")
                },
                "REQUIRESSHIFTREFNUMBER": {
                    "backend": "requires_shift_ref_number",
                    "frontend": objSSAgencyStringsService.getString("requiresShiftRefNumber")
                }
            });

            this.set("bookingRequestStatus", {
                "BOOKING_REQUEST_CREATED": {
                    "backend": "booking_request_created",
                    "frontend": objSSAgencyStringsService.getString("created")
                },
                "BOOKING_REQUEST_CANCELLED": {
                    "backend": "booking_request_cancelled",
                    "frontend": objSSAgencyStringsService.getString("cancelled")
                },
                "BOOKING_PROCESS_INITIATED": {
                    "backend": "booking_process_initiated",
                    "frontend": objSSAgencyStringsService.getString("initiated")
                },
                "BOOKING_PROCESS_RETRY": {
                    "backend": "booking_process_retry",
                    "frontend": objSSAgencyStringsService.getString("retrying")
                },
                "BOOKING_REQUEST_COMPLETED": {
                    "backend": "booking_request_completed",
                    "frontend": objSSAgencyStringsService.getString("completed")
                },
                "BOOKING_REQUEST_FAILED_COMPLETELY": {
                    "backend": "booking_request_failed_completely",
                    "frontend": objSSAgencyStringsService.getString("failedCompletely")
                },
                "BOOKING_REQUEST_REQUIRES_USER_INTERVENTION": {
                    "backend": "booking_request_requires_user_intervention",
                    "frontend": objSSAgencyStringsService.getString("requiresUserIntervention")
                },
                "BOOKING_REQUEST_COMPLETED_WITH_FAILURES": {
                    "backend": "booking_request_completed_with_failures",
                    "frontend": objSSAgencyStringsService.getString("completedWithFailures")
                }
            });

            this.set("shiftRequestStatus", {
                "SHIFT_REQUEST_CREATED": {
                    "backend": "created",
                    "frontend": objSSAgencyStringsService.getString("created")
                },
                "SHIFT_REQUEST_PROCESSING": {
                    "backend": "processing",
                    "frontend": objSSAgencyStringsService.getString("processing")
                },
                "SHIFT_REQUEST_COMPLETED": {
                    "backend": "completed",
                    "frontend": objSSAgencyStringsService.getString("completed")
                },
                "SHIFT_REQUEST_COMPLETED_WITH_PERMANENT_FAILURES": {
                    "backend": "completed_with_permanent_failures",
                    "frontend": objSSAgencyStringsService.getString("completedWithPermanentFailures")
                }
            });

            this.set("violationTypes", {
                "FAILURE": {
                    "backend": "failure",
                    "frontend": objSSAgencyStringsService.getString("failure")
                },
                "ERROR": {
                    "backend": "error",
                    "frontend": objSSAgencyStringsService.getString("error")
                }
            });

            this.set("bookingRestrictionTracker", {
                "BOOKING_REQUEST": {
                    "backend": "booking_request",
                    "frontend": objSSAgencyStringsService.getString("bookingRequest")
                }
            });
        }
    });
});