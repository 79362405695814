define("a24-staffshift-subdomain-agency/services/left-menu-service", ["exports", "a24-ember-menu/services/left-menu-service"], function (exports, _leftMenuService) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _leftMenuService.default;
    }
  });
});