define('a24-staffshift-subdomain-agency/components/input-form-tel-number', ['exports', 'a24-ember-form-inputs/components/input-form-tel-number'], function (exports, _inputFormTelNumber) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _inputFormTelNumber.default;
    }
  });
});