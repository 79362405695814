define("a24-staffshift-subdomain-agency/services/ss-utils", ["exports", "a24-ember-lib/services/ss-utils"], function (exports, _ssUtils) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _ssUtils.default;
    }
  });
});