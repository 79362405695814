define("a24-staffshift-subdomain-agency/controllers/index/agency-search-shift-query-params", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Object.create({
        queryParams: ["order_reference", "start_date", "start_dateFrom", "start_dateTo", "end_date", "end_dateFrom", "end_dateTo", "organisation_id", "site_id", "ward_name", "job_family_id", "job_title_id", "position_description", "status_code", "county", "city", "post_code", "candidate_full_name", "date_created", "date_createdFrom", "date_createdTo", "payroll_number", "shift_reference_number", "po_number", "page", "booked_assignment_created_date", "booked_assignment_created_dateFrom", "booked_assignment_created_dateTo", "booked_assignment_created_user_fullname", "booked_assignment_created_user_id", "booked_assignment_created_user_username", "client_consultants___consultant_role_id", "agency_shift_compliance_contract_id", "site_geo_location", "contract_name", "contract_id", "has_proposed_candidate", "shift_id", "order_id", "workforce_name", "sortBy", "vis_cols", "savedSearchKey"],
        order_reference: "",
        start_date: "",
        start_dateFrom: "",
        start_dateTo: "",
        end_date: "",
        end_dateFrom: "",
        end_dateTo: "",
        organisation_name: "",
        organisation_id: "",
        site_name: "",
        site_id: "",
        ward_name: "",
        job_family_id: "",
        job_title_id: "",
        position_description: "",
        status_code: "New|Reopened",
        county: "",
        city: "",
        post_code: "",
        candidate_full_name: "",
        date_created: "",
        date_createdFrom: "",
        date_createdTo: "",
        payroll_number: "",
        shift_reference_number: "",
        po_number: "",
        booked_assignment_created_date: "",
        booked_assignment_created_dateFrom: "",
        booked_assignment_created_dateTo: "",
        booked_assignment_created_user_fullname: "",
        booked_assignment_created_user_id: "",
        booked_assignment_created_user_username: "",
        client_consultants___consultant_role_id: "",
        agency_shift_compliance_contract_id: "",
        site_geo_location: "",
        contract_name: "",
        contract_id: "",
        has_proposed_candidate: "false",
        shift_id: "",
        order_id: "",
        workforce_name: "",
        page: "1",
        sortBy: "start_date::asc",
        vis_cols: "1,2,3,5,6,8,10,11,12",
        savedSearchKey: "",
        actions: {
            addQueryParamsAction: function addQueryParamsAction(arrParams) {
                for (var i = 0; i < arrParams.length; i++) {
                    this.set(arrParams[i], "");
                    // We are manually adding the observer here from the mixin as this observes
                    // the url query params
                    this.addObserver(arrParams[i], this, this.get("_funcObserverCallback"));
                }
            }
        }
    });
});