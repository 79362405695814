define('a24-staffshift-subdomain-agency/services/rest-candidate-plugin', ['exports', 'a24-ember-candidate-profile/services/rest-candidate-plugin'], function (exports, _restCandidatePlugin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _restCandidatePlugin.default;
    }
  });
});