define("a24-staffshift-subdomain-agency/helpers/availability-class", ["exports", "a24-ember-staffshift-candidate-availability/helpers/availability-class"], function (exports, _availabilityClass) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _availabilityClass.default;
    }
  });
  Object.defineProperty(exports, "availabilityClass", {
    enumerable: true,
    get: function () {
      return _availabilityClass.availabilityClass;
    }
  });
});