define("a24-staffshift-subdomain-agency/components/right-panel-agency-candidate-calendar", ["exports", "a24-ember-candidate-profile/components/right-panel-agency-candidate-calendar"], function (exports, _rightPanelAgencyCandidateCalendar) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _rightPanelAgencyCandidateCalendar.default;
    }
  });
});