define("a24-staffshift-subdomain-agency/routes/index/agency-candidate-profile", ["exports", "a24-ember-staffshift-core/routes/abstractRoute"], function (exports, _abstractRoute) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _abstractRoute.default.extend({
        _objSettingsGetPromise: null,
        _objNavigation: Ember.inject.service("navigation"),
        _objSettingsService: Ember.inject.service("rest-agency-candidate-settings"),

        beforeModel: function beforeModel(objTrans) {
            this.replaceWith("index.agency-candidate-search-view", {
                candidate_id: objTrans.params["index.agency-candidate-profile"].candidate_id,
                context_id: objTrans.params["index"].context_id
            });
        },


        /**
         * This function will be called after the abstract model code before it resolves, thus devs can use this
         * function the same way that they would have used the model function
         *
         * @returns Null or a promise object, in case of promise abstract model will wait till promise is resolved
         *     before resolving its own promise
         */
        modelHook: function modelHook() {
            var _this = this;

            // We used a custom promise here because we never want the return promise
            // to be rejected since the api call can return a 404 which will fail the
            // promise and transition the app to the error page
            return new Promise(function (funcResolve /*, funcReject */) {
                var objSettingsService = _this.get("_objSettingsService");
                var objRouteParams = _this.get("_objNavigation.objRouteParams")["index.agency-candidate-profile"];
                objSettingsService.getSettings(_this, "_objSettingsGetPromise", objRouteParams.candidate_id, function (objData) {
                    objSettingsService.set("objSettings", objData);
                    funcResolve(objData);
                }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                    objSettingsService.set("objSettings", null);
                    funcResolve();
                }));
            });
        }
    });
});