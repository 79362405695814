define("a24-staffshift-subdomain-agency/components/tag-component-shrink", ["exports", "a24-ember-lib/components/tag-component-shrink"], function (exports, _tagComponentShrink) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _tagComponentShrink.default;
    }
  });
});