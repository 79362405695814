define("a24-staffshift-subdomain-agency/helpers/availability-icon", ["exports", "a24-ember-staffshift-candidate-availability/helpers/availability-icon"], function (exports, _availabilityIcon) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _availabilityIcon.default;
    }
  });
  Object.defineProperty(exports, "availabilityIcon", {
    enumerable: true,
    get: function () {
      return _availabilityIcon.availabilityIcon;
    }
  });
});