define("a24-staffshift-subdomain-agency/mixins/availability-core", ["exports", "a24-ember-staffshift-candidate-availability/mixins/availability-core"], function (exports, _availabilityCore) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _availabilityCore.default;
    }
  });
});