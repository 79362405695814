define("a24-staffshift-subdomain-agency/controllers/index/agency-candidate-search-view/references", ["exports", "a24-staffshift-subdomain-agency/mixins/query-param-object-builder"], function (exports, _queryParamObjectBuilder) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_queryParamObjectBuilder.default, {
        queryParams: ["referencepage", "viewReference"],
        referencepage: null,
        viewReference: null,
        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);
            Ember.run.next(function () {
                _this.set("referencepage", null);
                _this.set("viewReference", null);
            });
        },
        bIsV2: Ember.computed("referencepage", "viewReference", function () {
            if (!a24Core.isEmpty(this.get("viewReference")) || this.get("referencepage") === "v2") {
                return true;
            }
            return false;
        })
    });
});