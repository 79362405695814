define("a24-staffshift-subdomain-agency/services/a24-enums-policy-library", ["exports", "a24-ember-policy-library/services/a24-enums-policy-library"], function (exports, _a24EnumsPolicyLibrary) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _a24EnumsPolicyLibrary.default;
    }
  });
});