define("a24-staffshift-subdomain-agency/components/agency-group-view-add-user-card", ["exports", "a24-ember-staffshift-users/components/agency-group-view-add-user-card"], function (exports, _agencyGroupViewAddUserCard) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _agencyGroupViewAddUserCard.default;
    }
  });
});