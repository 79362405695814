define("a24-staffshift-subdomain-agency/services/rest-ward-type", ["exports", "a24-staffshift-subdomain-agency/config/environment"], function (exports, _environment) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),

        /**
         * Get ward types
         *
         * @param objCaller - The the caller this function
         * @param sProperty - The Property name to get the promise object
         * @param objQuery - The query params object
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         *
         * @author Deon De Wet <deon.dewet@a24group.com>
         * @since  18 Sept 2020
         */
        getWardTypes: function getWardTypes(objContext, sProperty, objQuery, funcSuccess, objFailure) {

            a24RestUrlConstruct.setBaseUrl("/v1/ward-type");

            a24RestUrlConstruct.addQueryParamObject(objQuery);

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "GET", _environment.default.sRestApiBaseUrl + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        }
    });
});