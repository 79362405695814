define("a24-staffshift-subdomain-agency/components/page-agency-plugin", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-staffshift-subdomain-agency/config/environment"], function (exports, _didRenderChangesMixin, _environment) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        _bLoading: true,
        _sIFrameUrl: "",
        _objUrlCall: "",
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),
        _objRestPlugin: Ember.inject.service("rest-agency-user-plugin"),
        init: function init() {
            this._super.apply(this, arguments);
            this.getPluginDetails();
        },
        getPluginDetails: function getPluginDetails() {
            var _this = this;

            this.set("_bLoading", true);
            this.get("_objRestPlugin").getPlugin(this, "_objUrlCall", this.get("_objNavigation.objRouteParams")["index.plugin"].plugin_id, function (objResponse) {
                if (!a24Core.isEmpty(objResponse)) {
                    _this.set("_bLoading", false);

                    _this.set("_sIFrameUrl", _environment.default.sTriageBaseUrl + "/plugin-loader?plugin_id=" + objResponse.id + "&plugin_state=form_url");
                } else {
                    _this.get("_objNavigation").navigateOutsideContext("404");
                }
            }, {
                funcCallback: function funcCallback() {
                    _this.get("_objNavigation").navigateToGWTPage("error-general");
                },
                arrErrorCodes: ["*MALFORMED"]
            });
        },
        //We have to observe the route param. Since all plugins are on the same route ember wont reload the component since
        //ember only reloads the needed bits that change during route changes
        observeRouteParams: Ember.observer("_objNavigation.objRouteParams", function () {
            //Only do the service call if the current instance is not busy destroying and if we have
            //a candidate plugin route
            if (!a24Core.isEmpty(this) && !a24Core.isEmpty(this.get("_objNavigation.objRouteParams")["index.plugin"])) {
                this.getPluginDetails();
            }
        })
    });
});