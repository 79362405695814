define("a24-staffshift-subdomain-agency/components/size-limit-component", ["exports", "a24-ember-lib/components/size-limit-component"], function (exports, _sizeLimitComponent) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _sizeLimitComponent.default;
    }
  });
});