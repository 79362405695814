define("a24-staffshift-subdomain-agency/services/rest-system-admin-policy", ["exports", "a24-ember-policy-library/services/rest-system-admin-policy"], function (exports, _restSystemAdminPolicy) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _restSystemAdminPolicy.default;
    }
  });
});