define('a24-staffshift-subdomain-agency/services/rest-agency-group-lookup', ['exports', 'a24-ember-candidate-profile/services/rest-agency-group-lookup'], function (exports, _restAgencyGroupLookup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _restAgencyGroupLookup.default;
    }
  });
});