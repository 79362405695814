define("a24-staffshift-subdomain-agency/components/image-cropper-modal", ["exports", "a24-ember-lib/components/image-cropper-modal"], function (exports, _imageCropperModal) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _imageCropperModal.default;
    }
  });
});