define('a24-staffshift-subdomain-agency/components/input-text', ['exports', 'a24-ember-lib/components/input-text'], function (exports, _inputText) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _inputText.default;
    }
  });
});