define("a24-staffshift-subdomain-agency/components/candidate-equality-monitoring-section-component", ["exports", "a24-ember-candidate-profile/components/candidate-equality-monitoring-section-component"], function (exports, _candidateEqualityMonitoringSectionComponent) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _candidateEqualityMonitoringSectionComponent.default;
    }
  });
});