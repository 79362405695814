define("a24-staffshift-subdomain-agency/components/page-agency-tags", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-staffshift-subdomain-agency/config/environment"], function (exports, _didRenderChangesMixin, _environment) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        objQueryParams: null,
        _objUserSession: Ember.inject.service("user-session"),
        _sUrlBase: null,
        _objUsersService: Ember.inject.service("rest-agency-users"),
        _objTagService: Ember.inject.service("rest-agency-tag"),
        init: function init() {
            this._super.apply(this, arguments);
            this.set("_sUrlBase", _environment.default.sRestApiBaseUrl + "/v1/agency/" + this.get("_objUserSession").getUserContextReference().mId + "/tag");
        }
    });
});