define("a24-staffshift-subdomain-agency/services/card-datagrid-custom-filter-hours-difference", ["exports", "a24-ember-lib/services/card-datagrid-filter-custom-base"], function (exports, _cardDatagridFilterCustomBase) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _cardDatagridFilterCustomBase.default.extend({
        _objSSAgencyStrings: Ember.inject.service("a24-strings-staffshift-agency"),
        _objSSAgencyEnumsService: Ember.inject.service("a24-enums-staffshift-agency"),

        funcFilterCustomPrimaryQuery: function funcFilterCustomPrimaryQuery(objHeaderItem /* , objHeaderSecondaries */) {
            var objQuery = {
                iHour: objHeaderItem._iHour,
                iMinute: objHeaderItem._iMinute,
                mValue: this._funcCalcTotalTime(objHeaderItem._iHour, objHeaderItem._iMinute, false)
            };

            if (objHeaderItem._objHoursDiff.sValue === this.get("_objSSAgencyEnumsService.hoursDiff.MORE_THAN.backend")) {
                objQuery.bAfter = true;
            } else if (objHeaderItem._objHoursDiff.sValue === this.get("_objSSAgencyEnumsService.hoursDiff.LESS_THAN.backend")) {
                objQuery.bBefore = true;
            }

            return objQuery;
        },

        funcFilterCustomTag: function funcFilterCustomTag(objHeaderItem, objQuery, objHeadersSecondaries, bSkipTitle) {
            var sHoursDifferenceTagKey = "hoursDifferenceTag";

            if (bSkipTitle) {
                sHoursDifferenceTagKey = "hoursDifferenceTagNoTitle";
            }

            var sHoursDifference = "";
            var iMinutes = this._funcCalcTotalTime(objQuery.iHour, objQuery.iMinute, true);

            var sTime = "";

            if (objQuery.mValue === 0) {
                sTime = "0m";
            } else {
                sTime = this._formatTime(iMinutes);
            }

            if (objQuery.mValue < 0) {
                sTime = "-" + sTime;
            }

            if (objQuery.bAfter === true) {
                sHoursDifference = this.get("_objSSAgencyEnumsService.hoursDiff.MORE_THAN.frontend") + " " + sTime;
            } else if (objQuery.bBefore === true) {
                sHoursDifference = this.get("_objSSAgencyEnumsService.hoursDiff.LESS_THAN.frontend") + " " + sTime;
            } else {
                sHoursDifference = this.get("_objSSAgencyEnumsService.hoursDiff.EQUAL.frontend") + " " + sTime;
            }

            return {
                sTag: this.get("_objSSAgencyStrings").getTokenString(sHoursDifferenceTagKey, sHoursDifference)
            };
        },

        _funcCalcTotalTime: function _funcCalcTotalTime(iHour, iMinute, bAbsoluteValue) {
            var iTotal = 0;

            if (!a24Core.isEmpty(iHour)) {
                iTotal += (bAbsoluteValue ? Math.abs(iHour) : iHour) * 60;
            }

            if (!a24Core.isEmpty(iMinute)) {
                iTotal += bAbsoluteValue ? Math.abs(iMinute) : iMinute;
            }

            return iTotal;
        },

        _formatTime: function _formatTime(iMinutes) {
            if (!a24Core.isEmpty(iMinutes)) {
                var iHours = parseInt(iMinutes / 60);
                var iMin = iMinutes % 60;
                var sHours = "";
                var sMin = "";
                if (iHours > 0) {
                    sHours = iHours + "h";
                    if (iMin > 0) {
                        sHours += " ";
                    }
                }
                if (iMin > 0) {
                    sMin = iMin + "m";
                }
                var sTime = sHours + sMin;
                return sTime;
            }
            return "";
        },

        funcFilterCustomPrimaryReset: function funcFilterCustomPrimaryReset(objHeaderItem) {
            //Reset the state of the custom filter input
            Ember.set(objHeaderItem, "_objHoursDiff", null);
            Ember.set(objHeaderItem, "_iHour", null);
            Ember.set(objHeaderItem, "_iMinute", null);
        },

        funcFilterCustomFixupQuery: function funcFilterCustomFixupQuery() /*objHeader, objHeaderSecondaries */{},

        funcFilterCustomSetEditState: function funcFilterCustomSetEditState(objHeaderItem, iIndex) {
            var objHeaderTag = objHeaderItem.mFilterValue[iIndex];

            if (objHeaderTag.bAfter === true) {
                Ember.set(objHeaderItem, "_objHoursDiff", {
                    sLabel: this.get("_objSSAgencyEnumsService.hoursDiff.MORE_THAN.frontend"),
                    sValue: this.get("_objSSAgencyEnumsService.hoursDiff.MORE_THAN.backend")
                });
            } else if (objHeaderTag.bBefore === true) {
                Ember.set(objHeaderItem, "_objHoursDiff", {
                    sLabel: this.get("_objSSAgencyEnumsService.hoursDiff.LESS_THAN.frontend"),
                    sValue: this.get("_objSSAgencyEnumsService.hoursDiff.LESS_THAN.backend")
                });
            } else {
                Ember.set(objHeaderItem, "_objHoursDiff", {
                    sLabel: this.get("_objSSAgencyEnumsService.hoursDiff.EQUAL.frontend"),
                    sValue: this.get("_objSSAgencyEnumsService.hoursDiff.EQUAL.backend")
                });
            }

            if (!a24Core.isEmpty(objHeaderTag.iHour)) {
                Ember.set(objHeaderItem, "_iHour", objHeaderTag.iHour);
            }
            if (!a24Core.isEmpty(objHeaderTag.iMinute)) {
                Ember.set(objHeaderItem, "_iMinute", objHeaderTag.iMinute);
            }
        }
    });
});