define("a24-staffshift-subdomain-agency/helpers/lookup-enum-list", ["exports", "a24-ember-lookup/helpers/lookup-enum-list"], function (exports, _lookupEnumList) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _lookupEnumList.default;
    }
  });
  Object.defineProperty(exports, "lookupEnumList", {
    enumerable: true,
    get: function () {
      return _lookupEnumList.lookupEnumList;
    }
  });
});