define("a24-staffshift-subdomain-agency/services/a24-enums-staffshift-users", ["exports", "a24-ember-staffshift-users/services/a24-enums-staffshift-users"], function (exports, _a24EnumsStaffshiftUsers) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _a24EnumsStaffshiftUsers.default;
    }
  });
});