define("a24-staffshift-subdomain-agency/components/icon-label", ["exports", "a24-ember-staffshift-candidate-availability/components/icon-label"], function (exports, _iconLabel) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _iconLabel.default;
    }
  });
});