define("a24-staffshift-subdomain-agency/controllers/index/agency-client-ward-view/list-shifts", ["exports", "a24-staffshift-subdomain-agency/mixins/query-param-object-builder", "a24-staffshift-subdomain-agency/controllers/index/agency-search-shift-query-params"], function (exports, _queryParamObjectBuilder, _agencySearchShiftQueryParams) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_queryParamObjectBuilder.default, _agencySearchShiftQueryParams.default, {
        status_code: "New|Reopened|Accepted",
        vis_cols: "1,2,3,8,9,13,14,18"
    });
});