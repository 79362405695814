define("a24-staffshift-subdomain-agency/components/page-agency-candidate-view", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-staffshift-subdomain-agency/templates/components/page-agency-candidate-view", "a24-ember-lib/mixins/input-form-element", "a24-staffshift-subdomain-agency/config/environment"], function (exports, _didRenderChangesMixin, _pageAgencyCandidateView, _inputFormElement, _environment) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, _inputFormElement.default, {
        layout: _pageAgencyCandidateView.default,
        classNames: ["candidate-notifications-container"],
        _sCandidateId: null,
        _sCandidateName: null,

        _sIFrameUrl: null,
        _objReactPopupPostData: null,
        _bShowReactPopup: false,
        _bReactPopupLoading: true,
        _objOrderEntityActionResponse: null,
        _arrOrderEntityActionsSection: null,
        _bEntityActionLoading: true,
        _bHasBookingRequestCreatePermission: false,

        _arrReloadActions: null,
        _bServerError: false,
        _bDisplayReloadCard: false,
        _objTitleBarService: Ember.inject.service("title-bar"),
        _objNavigationService: Ember.inject.service("navigation"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objSettingsRestService: Ember.inject.service("rest-agency-candidate-settings"),
        _objBasicInfoRestService: Ember.inject.service("rest-agency-candidate-basic-info"),
        _objStaffshiftCoreEnums: Ember.inject.service("a24-enums-staffshift-core"),
        _objStringsCandidateProfile: Ember.inject.service("a24-strings-candidate-profile"),
        _objSSAgencyStringsService: Ember.inject.service("a24-strings-staffshift-agency"),
        _objStringsCandidateAvailability: Ember.inject.service("a24-strings-staffshift-availability"),
        _objStringsSSCore: Ember.inject.service("a24-strings-staffshift-core"),
        _objRestAgencyGroupCandidateInvite: Ember.inject.service("rest-invitation-verification"),
        _objRestAgencyNotificationTypes: Ember.inject.service("rest-agency-notification-types"),
        _objRestAgencyCandidateNotification: Ember.inject.service("rest-agency-candidate-notifications"),
        _objRestAgencyNotificationTypeTemplates: Ember.inject.service("rest-agency-notification-type-templates"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objUserSession: Ember.inject.service("user-session"),
        _objRestPermissionedActions: Ember.inject.service("rest-permissioned-actions"),
        _objConstantsSSCore: Ember.inject.service("a24-constants-staffshift-core"),
        _objCandidateProfileEnumsService: Ember.inject.service("a24-enums-candidate-profile"),

        _sNotificationId: null,
        _objSpecialNotificationTypePreviewPopupAction: null,
        _bShowSendNotificationPopup: false,
        _bDisplayPreviewSend: false,
        _arrNotificationTypeDropdown: null,
        _bSendNotificationPopupLoading: false,
        _bShowSentMessagePopup: false,
        _bShowNotificationPreviewPopup: false,
        _bShowNotificationTypes: false,
        _bNotificationTypeLoading: false,
        _arrNotificationCache: null,
        _objBasicInfoGetPromise: null,

        _sPreviewLabel: "",

        _bShowField1: false,
        _bShowField2: false,
        _bShowField3: false,
        _bShowField4: false,
        _bShowField5: false,
        _bShowField6: false,

        _bShowFieldMaxLength1: false,
        _bShowFieldMaxLength2: false,
        _bShowFieldMaxLength3: false,
        _bShowFieldMaxLength4: false,
        _bShowFieldMaxLength5: false,
        _bShowFieldMaxLength6: false,

        _sLabelField1: "",
        _sLabelField2: "",
        _sLabelField3: "",
        _sLabelField4: "",
        _sLabelField5: "",
        _sLabelField6: "",

        _bRequiredField1: false,
        _bRequiredField2: false,
        _bRequiredField3: false,
        _bRequiredField4: false,
        _bRequiredField5: false,

        _iMaxCharactersField1: 10,
        _iMaxCharactersField2: 10,
        _iMaxCharactersField3: 20,
        _iMaxCharactersField4: 200,
        _iMaxCharactersField5: 50,
        _iMaxCharactersField6: 100,

        _bShiftNotification: false,
        _bAvailUpdateNotification: false,
        _bComplianceUpdateNotification: false,
        _bCandidateCallbackRequestedNotification: false,
        _bCandidateReminderNotification: false,
        _bCandidateTimesheetNotification: false,
        _bCandidateAnnualLeaveNotification: false,

        _bShowNotificationInputs: Ember.computed("_bShiftNotification", "_bAvailUpdateNotification", "_bComplianceUpdateNotification", "_bCandidateCallbackRequestedNotification", "_bCandidateReminderNotification", "_bCandidateTimesheetNotification", "_bCandidateAnnualLeaveNotification", function () {
            return this.get("_bShiftNotification") || this.get("_bAvailUpdateNotification") || this.get("_bComplianceUpdateNotification") || this.get("_bCandidateCallbackRequestedNotification") || this.get("_bCandidateReminderNotification") || this.get("_bCandidateTimesheetNotification") || this.get("_bCandidateAnnualLeaveNotification");
        }),

        init: function init() {
            var _this = this;

            if (a24Core.isEmpty(this.get("_sIFrameUrl"))) {
                this.set("_sIFrameUrl", _environment.default.sReactGatewayUrl);
            }

            this._super.apply(this, arguments);
            this.set("_objBasicInfoRestService.objLastCandidateDetails", null);

            var objCandidateViewParams = this.get("_objNavigationService.objRouteParams")["index.agency-candidate-profile"];

            var objCandidateSearchViewParams = this.get("_objNavigationService.objRouteParams")["index.agency-candidate-search-view"];

            if (!a24Core.isEmpty(objCandidateViewParams)) {
                this.set("_sCandidateId", objCandidateViewParams.candidate_id);
            }
            if (!a24Core.isEmpty(objCandidateSearchViewParams)) {
                this.set("_sCandidateId", objCandidateSearchViewParams.candidate_id);
            }

            this.set("_arrReloadActions", [{
                sTitle: this.get("_objStringsCandidateProfile").getString("reload"),
                sIcon: "refresh",
                executeCardAction: function executeCardAction() {
                    // For now this does nothing since nothing sets _bServerError as true, but leaving this and the hbs
                    // parts there if we need this in future again.
                }
            }]);

            this.set("_objSpecialNotificationPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: function executeCardAction() {
                    _this.onPopupNotificationCancel();
                }
            });

            this.set("_objSpecialNotificationTypePreviewPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: function executeCardAction() {
                    _this.set("_bShowNotificationPreviewPopup", false);
                }
            });

            this.get("_objTitleBarService").setTitleBar(this.get("_objStringsCandidateProfile").getString("candidateProfile"), function () {
                _this.get("_objNavigationService").navigateBack();
            }, false);
            this._setTitleBarActions(true);

            var objValidationConfig = {};

            this.set("objChildConfig", objValidationConfig);

            this.set("_arrNotificationCache", {});

            // This is just to get the basic info so all other pages has the candidate info
            var objBasicInfoPromise = this.get("_objBasicInfoRestService").getBasicInfo(this, "_objBasicInfoGetPromise", this.get("_sCandidateId"), function () {
                // Do nothing since the service will store the candidate data and we use it from there
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], // Handle all errors currently
            function () {
                //Do nothing
            }));

            var objBookingRequestPermissionedActionsPromise = this.get("_objRestPermissionedActions").getPermissionedActionsForSection(this, "_objGetPermissionedActions", "booking-request", function () {
                // do nothing since rsvp will handle it
            }, a24RestCallHelper.createJsonAjaxFailureObject(["401", "500", "502", "503", "504"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                _this.set("_bHasBookingRequestCreatePermission", false);
                _this.set("_bEntityActionLoading", false);
            }));

            var objPromiseList = {
                _objRSVPBookingRequestPermissionedAction: objBookingRequestPermissionedActionsPromise,
                _objBasicInfoPromise: objBasicInfoPromise
            };
            var arrRequiredResponses = ["_objRSVPBookingRequestPermissionedAction", "_objBasicInfoPromise"];

            Ember.copy(a24RSVP).configure(this, objPromiseList, [{
                arrRequiredResponses: arrRequiredResponses,
                funcOnComplete: function funcOnComplete(objResponses) {

                    var arrEntityActions = [];
                    if (!a24Core.isEmpty(objResponses._objRSVPBookingRequestPermissionedAction)) {
                        arrEntityActions = objResponses._objRSVPBookingRequestPermissionedAction.objResponse;
                    }

                    _this.set("_bHasBookingRequestCreatePermission", false);
                    if (!a24Core.isEmpty(arrEntityActions)) {
                        var objPermissionedAction = arrEntityActions.find(function (objPermissionedActionSection) {
                            return objPermissionedActionSection.entity_type === "booking-request";
                        });
                        var arrAllowedActions = [];
                        var bAllowAction = false;
                        if (!a24Core.isEmpty(objPermissionedAction.actions)) {
                            objPermissionedAction.actions.forEach(function (objItem) {
                                if (objItem.allowed) {
                                    arrAllowedActions.push(objItem.action);
                                }
                            });
                        }
                        bAllowAction = arrAllowedActions.includes("create");
                        _this.set("_bHasBookingRequestCreatePermission", bAllowAction);
                    }
                    _this.set("_bEntityActionLoading", false);

                    _this._setTitleBarActions(false);
                }
            }]);
        },

        _observeSelectionChanged: Ember.observer("_objBasicInfoRestService.objLastCandidateDetails", function () {
            if (!a24Core.isEmpty(this.get("_objBasicInfoRestService.objLastCandidateDetails"))) {
                this._setTitleBarActions(false);
            }
        }),

        _setTitleBarActions: function _setTitleBarActions(bShowLoader) {
            var _this2 = this;

            // since the titlebar does not allow us to update the actions on the fly
            // so we are forced to clear the action component and set it again
            this.get("_objTitleBarService").setTitleBarPartially(null, null, null, this.get("_objTitleBarService.CLEAR_VALUE"), this.get("_objTitleBarService.CLEAR_VALUE"));
            var objThis = this;
            var objActionItems = {
                arrActionItems: []
            };
            objActionItems.arrActionItems.pushObject({
                sTitle: this.get("_objStringsCandidateProfile").getString("sendNotification"),
                sIcon: "notifications",
                executeCardAction: function executeCardAction() {
                    objThis.onNoticationEmailClick();
                }
            });
            if (bShowLoader || this.get("_bEntityActionLoading")) {
                objActionItems.arrActionItems.pushObject({
                    bLoader: true
                });
            } else if (this.get("_bHasBookingRequestCreatePermission")) {
                var sPunchClockSvg = this.get("_objStaffshiftCoreEnums").getMaterialSvgIcon("punch_clock");

                objActionItems.arrActionItems.pushObject({
                    sTitle: this.get("_objSSAgencyStringsService").getString("addBookingRequest"),
                    sIconSvg: sPunchClockSvg,
                    executeCardAction: function executeCardAction() {
                        objThis.set("_bShowReactPopup", true);
                        var sCandidateName = objThis.get("_objBasicInfoRestService.objLastCandidateDetails.first_name") + " " + objThis.get("_objBasicInfoRestService.objLastCandidateDetails.last_name");

                        objThis.set("_objReactPopupPostData", {
                            type: "trigger_route",
                            data: {
                                route: "staffing/directBookingWizard",
                                data: {
                                    subjectId: objThis.get("_sCandidateId"),
                                    subjectType: "candidate",
                                    headerName: sCandidateName
                                }
                            }
                        });
                    }
                });
            }

            Ember.run.next(function () {
                if (a24Core.isEmpty(_this2)) {
                    return;
                }
                _this2.get("_objTitleBarService").setTitleBarPartially(null, null, null, "card-actions-component", objActionItems);
            });
        },
        _funcResetNotificationFields: function _funcResetNotificationFields() {
            this.set("_sPreviewLabel", "");

            this.set("_bShowField1", false);
            this.set("_bShowField2", false);
            this.set("_bShowField3", false);
            this.set("_bShowField4", false);
            this.set("_bShowField5", false);
            this.set("_bShowField6", false);

            this.set("_bShowFieldMaxLength1", false);
            this.set("_bShowFieldMaxLength2", false);
            this.set("_bShowFieldMaxLength3", false);
            this.set("_bShowFieldMaxLength4", false);
            this.set("_bShowFieldMaxLength5", false);
            this.set("_bShowFieldMaxLength6", false);

            this.set("_sLabelField1", "");
            this.set("_sLabelField2", "");
            this.set("_sLabelField3", "");
            this.set("_sLabelField4", "");
            this.set("_sLabelField5", "");
            this.set("_sLabelField6", "");

            this.set("_bRequiredField1", false);
            this.set("_bRequiredField2", false);
            this.set("_bRequiredField3", false);
            this.set("_bRequiredField4", false);
            this.set("_bRequiredField5", false);
            this.set("_bRequiredField6", false);
        },
        onNoticationEmailClick: function onNoticationEmailClick() {
            var _this3 = this;

            this.clearForm();

            this.set("_bShowSendNotificationPopup", true);
            this.set("_bDisplayPreviewSend", false);

            var objPromiseList = {};
            var arrRequiredResponses = [];

            if (a24Core.isEmpty(this.get("_arrNotificationTypeDropdown"))) {
                this.set("_bSendNotificationPopupLoading", true);
                var objExtraParams = {
                    enabled: {
                        mValue: true
                    }
                };

                var objNotificationsPromise = this.get("_objRestAgencyNotificationTypes").getNotificationTypes(this, "_objGetNotificationTypesServiceCall", function (arrData) {
                    if (!a24Core.isEmpty(arrData)) {

                        var arrNotificationTypeDropdown = [];
                        for (var l = 0; l < arrData.length; l++) {
                            arrNotificationTypeDropdown.push({
                                sTitle: arrData[l].name,
                                sValue: arrData[l].metadata.type
                            });
                        }

                        _this3.set("_arrNotificationTypeDropdown", arrNotificationTypeDropdown);
                    }
                }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                    var sServerError = _this3.get("_objStringsService").getString("serverError");
                    _this3.get("_objSnackbarService").showSnackbarMessage(sServerError);

                    _this3.set("_bShowSendNotificationPopup", false);
                }), objExtraParams);

                objPromiseList._objNotificationsPromise = objNotificationsPromise;
                arrRequiredResponses.push("_objNotificationsPromise");
            }

            if (a24Core.isEmpty(this.get("_objBasicInfoRestService.objLastCandidateDetails"))) {
                this.set("_bSendNotificationPopupLoading", true);
                var objBasicInfoPromise = this.get("_objBasicInfoRestService").getBasicInfo(this, "_objBasicInfoGetPromise", this.get("_sCandidateId"), function () {
                    // Do nothing since the service will store the candidate data and we use it from there
                }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], // Handle all errors currently
                function () {
                    //Do nothing, we simply wont be able to autocomplete the candidate name
                }));

                objPromiseList._objBasicInfoPromise = objBasicInfoPromise;
                arrRequiredResponses.push("_objBasicInfoPromise");
            }

            if (arrRequiredResponses.length > 0) {
                Ember.copy(a24RSVP).configure(this, objPromiseList, [{
                    arrRequiredResponses: arrRequiredResponses,
                    funcOnComplete: function funcOnComplete() {
                        _this3.set("_bSendNotificationPopupLoading", false);
                    }
                }]);
            }
        },
        onInputValueChanged: function onInputValueChanged(arrPathToInputMixin) {
            var _this4 = this;

            var sLookup = arrPathToInputMixin.join(".");

            if (sLookup === "objChildren._objNotificationType" && !a24Core.isEmpty(this.get("objChildren._objNotificationType.mValue"))) {
                this.set("_sNotificationId", this.get("objChildren._objNotificationType.mValue.sValue"));

                this.set("_bShiftNotification", false);
                this.set("_bAvailUpdateNotification", false);
                this.set("_bComplianceUpdateNotification", false);
                this.set("_bCandidateCallbackRequestedNotification", false);
                this.set("_bCandidateReminderNotification", false);
                this.set("_bCandidateTimesheetNotification", false);
                this.set("_bCandidateAnnualLeaveNotification", false);

                this._funcResetNotificationFields();

                if (this.get("_sNotificationId") === "possible_work_notification") {
                    this.set("_bShiftNotification", true);
                } else if (this.get("_sNotificationId") === "availability_update_request") {
                    this.set("_bAvailUpdateNotification", true);
                } else if (this.get("_sNotificationId") === "compliance_update_request") {
                    this.set("_bComplianceUpdateNotification", true);
                } else if (this.get("_sNotificationId") === "callback_requested_notification") {
                    this.set("_bCandidateCallbackRequestedNotification", true);
                } else if (this.get("_sNotificationId") === "candidate_reminder_on_action") {
                    this.set("_bCandidateReminderNotification", true);
                } else if (this.get("_sNotificationId") === "timesheet_update_requested") {
                    this.set("_bCandidateTimesheetNotification", true);
                } else if (this.get("_sNotificationId") === "annual_leave_notification") {
                    this.set("_bCandidateAnnualLeaveNotification", true);
                }

                this.set("_bShowNotificationTypes", true);
                if (a24Core.isEmpty(this.get("_arrNotificationCache." + this.get("_sNotificationId")))) {

                    var arrAgencyGroups = this.get("_objSettingsRestService.objSettings.agency_groups");

                    var arrCandidateTypes = [];
                    if (!a24Core.isEmpty(arrAgencyGroups)) {
                        for (var i = 0; i < arrAgencyGroups.length; i++) {
                            if (!a24Core.isEmpty(arrAgencyGroups[i].candidate_type)) {
                                arrCandidateTypes.push(arrAgencyGroups[i].candidate_type);
                            }
                        }
                    }

                    var objExtraParams = {
                        position_types: {
                            mValue: arrCandidateTypes
                        },
                        context_notification_type: {
                            mValue: this.get("_sNotificationId")
                        },
                        status: {
                            mValue: "enabled"
                        }
                    };

                    this.set("_bNotificationTypeLoading", true);
                    this.set("_bDisplayPreviewSend", false);
                    this.get("_objRestAgencyNotificationTypeTemplates").getNotificationTypeTemplates(this, "_objGetNotificationTypesServiceCall", function (arrData) {
                        if (!a24Core.isEmpty(arrData)) {
                            _this4.set("_arrNotificationTypeComponents", _this4._setupTagComponents(arrData));
                            _this4.set("_bDisplayPreviewSend", true);
                        } else {
                            _this4.set("_arrNotificationTypeComponents", []);
                        }
                        _this4.set("_arrNotificationCache." + _this4.get("_sNotificationId"), _this4.get("_arrNotificationTypeComponents"));
                        _this4._funcPrepopulateNotificationFields();
                        _this4.set("_bNotificationTypeLoading", false);
                        _this4.set("_bShowNotificationTypes", true);
                    }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                        var sServerError = _this4.get("_objStringsService").getString("serverError");
                        _this4.get("_objSnackbarService").showSnackbarMessage(sServerError);
                        _this4.set("_bNotificationTypeLoading", false);
                        _this4.set("_bDisplayPreviewSend", false);
                        _this4.set("_bShowNotificationTypes", false);
                    }), objExtraParams);
                } else {
                    this.set("_arrNotificationTypeComponents", this.get("_arrNotificationCache." + this.get("_sNotificationId")));
                    this._funcPrepopulateNotificationFields();
                    this.set("_bDisplayPreviewSend", true);
                    this.set("_bNotificationTypeLoading", false);
                }
            } else if (a24Core.isEmpty(this.get("objChildren._objNotificationType.mValue"))) {
                this.set("_bDisplayPreviewSend", false);
                this.set("_bShowNotificationTypes", false);
            }
        },
        setPriorityGroupNames: function setPriorityGroupNames(arrGroupings) {
            // replace priority 1 with Primary Delivery
            // replace 2 with 1st Fallback
            // replace 3 with 2nd Fallback
            // replace 4 with 3rd Fallback
            arrGroupings[0].sTitle = this.get("_objStringsCandidateProfile").getString("primaryDelivery");
            if (arrGroupings.length > 1) {
                arrGroupings[1].sTitle = this.get("_objStringsCandidateProfile").getString("firstFallback");
            }
            if (arrGroupings.length > 2) {
                arrGroupings[2].sTitle = this.get("_objStringsCandidateProfile").getString("secondFallback");
            }
            if (arrGroupings.length > 3) {
                arrGroupings[3].sTitle = this.get("_objStringsCandidateProfile").getString("thirdFallback");
            }
            if (arrGroupings.length > 4) {
                for (var i = 4; i < arrGroupings.length; i++) {
                    arrGroupings[i].sTitle = i + this.get("_objStringsCandidateProfile").getString("th") + " " + this.get("_objStringsCandidateProfile").getString("fallback");
                }
            }
            return arrGroupings;
        },
        _setupTagComponents: function _setupTagComponents(arrNotificationTypeTemplates) {
            var _this5 = this;

            var objThis = this;
            var funcGroupFilterExecute = function funcGroupFilterExecute(objItem) {
                return function (objComponent) {
                    return objItem.priority === objComponent.objData.mValue.priority;
                };
            };

            var arrGroupings = arrNotificationTypeTemplates.uniqBy("priority").sortBy("priority").map(function (objItem) {
                return {
                    sTitle: objItem.priority,
                    funcGroupFilter: funcGroupFilterExecute(objItem)
                };
            });

            arrGroupings = this.setPriorityGroupNames(arrGroupings);
            var arrComponents = arrNotificationTypeTemplates.map(function (objEntry) {
                return {
                    sComponent: "tag-component-shrink",
                    objData: {
                        sTitle: _this5.get("_objStaffshiftCoreEnums").getFrontendValue("mediumType", objEntry.type),
                        mValue: objEntry,
                        sColor: "a24-primary-color-hue-3-force white-text",
                        sActionIcon: "remove_red_eye",
                        sActionIconColor: "white-text"
                    },
                    objActions: {
                        onTagClicked: function onTagClicked(sTitle, objItem) {

                            if (objThis.get("_bShowNotificationInputs")) {
                                objThis.clearAllErrorMessages();

                                if (!objThis.doFullValidation()) {
                                    var objItemData = Ember.copy(objItem.data, true);
                                    var objData = objThis.getPlainFormValueObject();

                                    if (objItem.type === objThis.get("_objStaffshiftCoreEnums.mediumType.EMAIL.backend")) {
                                        objItemData.html = objThis._funcTokenReplaceNotificationPreview(objData, objItemData.html);
                                    } else {
                                        objItemData.text = objThis._funcTokenReplaceNotificationPreview(objData, objItemData.text);
                                    }

                                    // show medium preview
                                    objThis.activityNotificationFromMedium(objItem.type, objItemData);

                                    objThis.set("_bShowNotificationPreviewPopup", true);
                                }
                            } else {
                                // show medium preview
                                objThis.activityNotificationFromMedium(objItem.type, objItem.data);

                                objThis.set("_bShowNotificationPreviewPopup", true);
                            }
                        }
                    }
                };
            });

            return {
                arrComponents: arrComponents,
                arrGroupings: arrGroupings,
                sGroupTitleStyle: "font-md a24-grey-text-darken-1"
            };
        },
        _funcTokenReplaceNotificationPreview: function _funcTokenReplaceNotificationPreview(objData, sTokenString) {
            if (this.get("_bShiftNotification")) {
                var sShiftDetails = this.get("_objStringsCandidateProfile").getString("for") + " " + objData.field1 + " " + (this.get("_objStringsService").getString("in").toLowerCase() + " " + objData.field2 + ". " + objData.field3);

                if (!a24Core.isEmpty(objData.field4)) {
                    sShiftDetails += ". " + objData.field4;
                }
                sTokenString = sTokenString.replace("{{shift_details}}", sShiftDetails);
                sTokenString = sTokenString.replace("{{reply_with}}", objData.field5);

                return sTokenString;
            } else if (this.get("_bAvailUpdateNotification")) {
                // {{greeting}} Please update {{update_entity}} as {{update_reason}}. Regards {{regards}}{{area}}
                var sRegards = objData.field4;
                if (!a24Core.isEmpty(objData.field5)) {
                    sRegards = sRegards + ", " + objData.field5;
                }
                sTokenString = sTokenString.replace("{{greeting}}", objData.field1 + ".");
                sTokenString = sTokenString.replace("{{update_entity}}", objData.field2);
                sTokenString = sTokenString.replace("{{update_reason}}", objData.field3);
                sTokenString = sTokenString.replace("{{regards}}", sRegards);
                return sTokenString;
            } else if (this.get("_bComplianceUpdateNotification")) {
                // {{greeting}} Please update {{update_entity}} as {{update_reason}}. Regards {{regards}}{{reply_with}}
                var _sRegards = objData.field4;
                if (!a24Core.isEmpty(objData.field5)) {
                    _sRegards = _sRegards + ". " + this.get("_objStringsCandidateProfile").getString("pleaseReplyWith") + ": " + objData.field5;
                }
                sTokenString = sTokenString.replace("{{greeting}}", objData.field1 + ".");
                sTokenString = sTokenString.replace("{{update_entity}}", objData.field2);
                sTokenString = sTokenString.replace("{{update_reason}}", objData.field3);
                sTokenString = sTokenString.replace("{{regards}}", _sRegards);
                return sTokenString;
            } else if (this.get("_bCandidateCallbackRequestedNotification") || this.get("_bCandidateReminderNotification") || this.get("_bCandidateTimesheetNotification") || this.get("_bCandidateAnnualLeaveNotification")) {
                var sTokenStringReplace = "";
                var sTitle = this.get("_objCandidateProfileEnumsService").getFrontendValue("title", this.get("_objBasicInfoRestService.objLastCandidateDetails.title"));
                if ((this.get("_objBasicInfoRestService.objLastCandidateDetails.candidate_type_id") === this.get("_objConstantsSSCore.UK_GP") || this.get("_objBasicInfoRestService.objLastCandidateDetails.candidate_type_id") === this.get("_objConstantsSSCore.UK_LOCUM") || this.get("_objBasicInfoRestService.objLastCandidateDetails.candidate_type_id") === this.get("_objConstantsSSCore.UK_LOCUM_ALLIED") || this.get("_objBasicInfoRestService.objLastCandidateDetails.candidate_type_id") === this.get("_objConstantsSSCore.ZA_LOCUM") || this.get("_objBasicInfoRestService.objLastCandidateDetails.candidate_type_id") === this.get("_objConstantsSSCore.ZA_LOCUM_ALLIED")) && !a24Core.isEmpty(this.get("_objBasicInfoRestService.objLastCandidateDetails.title"))) {
                    sTokenStringReplace = sTitle + " " + this.get("_objBasicInfoRestService.objLastCandidateDetails.last_name");
                } else {
                    sTokenStringReplace = this.get("_objBasicInfoRestService.objLastCandidateDetails.first_name");
                }

                if (this.get("_bCandidateCallbackRequestedNotification")) {
                    sTokenString = sTokenString.replace("{{candidate_first_name}}", sTokenStringReplace);
                    sTokenString = sTokenString.replace("{{content}}", objData.fieldMaxLength4);
                    sTokenString = sTokenString.replace("{{reply_with}}", objData.fieldMaxLength5);
                    sTokenString = sTokenString.replace("{{regards}}", objData.fieldMaxLength6 + ". " + this.get("_objUserSession.objContextDetails.sDisplayName") + ", " + this.get("_objStringsCandidateProfile").getString("aMemberOf"));

                    return sTokenString;
                } else if (this.get("_bCandidateReminderNotification")) {

                    sTokenString = sTokenString.replace("{{candidate_first_name}}", sTokenStringReplace);
                    var sContent = objData.fieldMaxLength4 + ". " + this.get("_objStringsCandidateProfile").getString("pleaseReplyWith") + " " + objData.fieldMaxLength5;
                    sTokenString = sTokenString.replace("{{content}}", sContent);
                    sTokenString = sTokenString.replace("{{regards}}", objData.fieldMaxLength6 + ". " + this.get("_objUserSession.objContextDetails.sDisplayName") + ", " + this.get("_objStringsCandidateProfile").getString("aMemberOf"));
                    return sTokenString;
                } else if (this.get("_bCandidateTimesheetNotification")) {
                    sTokenString = sTokenString.replace("{{candidate_first_name}}", sTokenStringReplace);
                    sTokenString = sTokenString.replace("{{timesheet_number}}", objData.fieldMaxLength1);
                    sTokenString = sTokenString.replace("{{candidate_instructions}}", objData.fieldMaxLength4);
                    sTokenString = sTokenString.replace("{{regards}}", objData.fieldMaxLength6 + ". " + this.get("_objUserSession.objContextDetails.sDisplayName"));

                    return sTokenString;
                } else if (this.get("_bCandidateAnnualLeaveNotification")) {
                    // Hi {{candidate_first_name}}. According to our records you have {{hours}} hours leave available which is {{days}} annual leave. Your forfeit date is {{date}} every year. Kind Regards, {{regards}} A member of the A24Group.
                    sTokenString = sTokenString.replace("{{candidate_first_name}}", sTokenStringReplace);
                    sTokenString = sTokenString.replace("{{hours}}", objData.fieldMaxLength1);
                    sTokenString = sTokenString.replace("{{days}}", objData.fieldMaxLength2);
                    sTokenString = sTokenString.replace("{{date}}", objData.fieldMaxLength3);
                    sTokenString = sTokenString.replace("{{regards}}", objData.fieldMaxLength6 + ". " + this.get("_objUserSession.objContextDetails.sDisplayName"));

                    return sTokenString;
                }
            }
        },
        _funcSetInputStates: function _funcSetInputStates(sVal1, sVal2, sVal3, sVal4, sVal5, bDisabled1, bDisabled2, bDisabled3, bDisabled4, bDisabled5) {
            // Reset all inputs
            var objField1 = this.get("objChildren.field1");
            var objField2 = this.get("objChildren.field2");
            var objField3 = this.get("objChildren.field3");
            var objField4 = this.get("objChildren.field4");
            var objField5 = this.get("objChildren.field5");

            if (!a24Core.isEmpty(objField1)) {
                objField1.setValueFromOutside(sVal1 ? sVal1 : null);
                this.$("#" + objField1.get("_sInputId")).prop('disabled', bDisabled1 ? bDisabled1 : false);
                a24Validation.addRequired(objField1, this.get("_bRequiredField1"));
            }
            if (!a24Core.isEmpty(objField2)) {
                objField2.setValueFromOutside(sVal2 ? sVal2 : null);
                this.$("#" + objField2.get("_sInputId")).prop('disabled', bDisabled2 ? bDisabled2 : false);
                a24Validation.addRequired(objField2, this.get("_bRequiredField2"));
            }
            if (!a24Core.isEmpty(objField3)) {
                objField3.setValueFromOutside(sVal3 ? sVal3 : null);
                this.$("#" + objField3.get("_sInputId")).prop('disabled', bDisabled3 ? bDisabled3 : false);
                a24Validation.addRequired(objField3, this.get("_bRequiredField3"));
            }
            if (!a24Core.isEmpty(objField4)) {
                objField4.setValueFromOutside(sVal4 ? sVal4 : null);
                this.$("#" + objField4.get("_sInputId")).prop('disabled', bDisabled4 ? bDisabled4 : false);
                a24Validation.addRequired(objField4, this.get("_bRequiredField4"));
            }
            if (!a24Core.isEmpty(objField5)) {
                objField5.setValueFromOutside(sVal5 ? sVal5 : null);
                this.$("#" + objField5.get("_sInputId")).prop('disabled', bDisabled5 ? bDisabled5 : false);
                a24Validation.addRequired(objField5, this.get("_bRequiredField5"));
            }
        },
        _funcPrepopulateNotificationFields: function _funcPrepopulateNotificationFields() {
            var _this6 = this;

            //Reset error states when we switch notification types
            this.clearAllErrorMessages();

            if (this.get("_bAvailUpdateNotification")) {
                this.set("_bShowField1", true);
                this.set("_bShowField2", true);
                this.set("_bShowField3", true);
                this.set("_bShowField4", true);
                this.set("_bShowField5", true);

                this.set("_sLabelField1", this.get("_objStringsCandidateProfile").getString("greeting"));
                this.set("_sLabelField2", this.get("_objStringsCandidateProfile").getString("updateEntity"));
                this.set("_sLabelField3", this.get("_objStringsCandidateProfile").getString("updateReason"));
                this.set("_sLabelField4", this.get("_objStringsCandidateProfile").getString("regards"));
                this.set("_sLabelField5", this.get("_objStringsCandidateProfile").getString("area"));

                this.set("_bRequiredField1", true);
                this.set("_bRequiredField2", true);
                this.set("_bRequiredField3", true);
                this.set("_bRequiredField4", true);
                this.set("_bRequiredField5", false);

                Ember.run.next(function () {
                    if (a24Core.isEmpty(_this6)) {
                        return;
                    }
                    Ember.run.schedule("afterRender", function () {
                        var objField1 = _this6.get("objChildren.field1");
                        var objField2 = _this6.get("objChildren.field2");
                        var objField3 = _this6.get("objChildren.field3");
                        var objField4 = _this6.get("objChildren.field4");

                        a24Validation.addRequired(objField1, true);
                        a24Validation.addRequired(objField2, true);
                        a24Validation.addRequired(objField3, true);
                        a24Validation.addRequired(objField4, true);

                        _this6._funcSetInputStates(_this6.get("_objStringsCandidateProfile").getString("hi") + " " + _this6.get("_objBasicInfoRestService.objLastCandidateDetails.first_name"), _this6.get("_objStringsCandidateProfile").getString("yourAvailability").toLowerCase(), _this6.get("_objStringsCandidateProfile").getString("iHaveTempWorkAvail"), _this6.get("_objUserSession").getUserFirstName(), null, false, true, true);
                    });
                });
            } else if (this.get("_bShiftNotification")) {
                this.set("_sPreviewLabel", this.get("_objSSAgencyStringsService").getString("shiftsAvailable") + ":");

                this.set("_bShowField1", true);
                this.set("_bShowField2", true);
                this.set("_bShowField3", true);
                this.set("_bShowField4", true);
                this.set("_bShowField5", true);

                this.set("_sLabelField1", this.get("_objStringsCandidateProfile").getString("for"));
                this.set("_sLabelField2", this.get("_objStringsService").getString("in"));
                this.set("_sLabelField3", this.get("_objSSAgencyStringsService").getString("shiftPattern"));
                this.set("_sLabelField4", this.get("_objSSAgencyStringsService").getString("rates"));
                this.set("_sLabelField5", this.get("_objSSAgencyStringsService").getString("replyWith"));

                this.set("_bRequiredField1", true);
                this.set("_bRequiredField2", true);
                this.set("_bRequiredField3", true);
                this.set("_bRequiredField4", false);
                this.set("_bRequiredField5", true);

                Ember.run.next(function () {
                    if (a24Core.isEmpty(_this6)) {
                        return;
                    }
                    Ember.run.schedule("afterRender", function () {
                        var objField1 = _this6.get("objChildren.field1");
                        var objField2 = _this6.get("objChildren.field2");
                        var objField3 = _this6.get("objChildren.field3");
                        var objField4 = _this6.get("objChildren.field4");
                        var objField5 = _this6.get("objChildren.field5");

                        a24Validation.addRequired(objField1, true);
                        a24Validation.addRequired(objField2, true);
                        a24Validation.addRequired(objField3, true);
                        a24Validation.addRequired(objField4, true);
                        a24Validation.addRequired(objField5, true);
                        //Reset fields
                        _this6._funcSetInputStates();
                    });
                });
            } else if (this.get("_bComplianceUpdateNotification")) {
                this.set("_bShowField1", true);
                this.set("_bShowField2", true);
                this.set("_bShowField3", true);
                this.set("_bShowField4", true);
                this.set("_bShowField5", true);

                this.set("_sLabelField1", this.get("_objStringsCandidateProfile").getString("greeting"));
                this.set("_sLabelField2", this.get("_objStringsCandidateProfile").getString("updateEntity"));
                this.set("_sLabelField3", this.get("_objStringsCandidateProfile").getString("updateReason"));
                this.set("_sLabelField4", this.get("_objStringsCandidateProfile").getString("regards"));
                this.set("_sLabelField5", this.get("_objSSAgencyStringsService").getString("replyWith"));

                this.set("_bRequiredField1", true);
                this.set("_bRequiredField2", true);
                this.set("_bRequiredField3", true);
                this.set("_bRequiredField4", true);
                this.set("_bRequiredField5", true);

                Ember.run.next(function () {
                    if (a24Core.isEmpty(_this6)) {
                        return;
                    }
                    Ember.run.schedule("afterRender", function () {

                        var objField1 = _this6.get("objChildren.field1");
                        var objField2 = _this6.get("objChildren.field2");
                        var objField3 = _this6.get("objChildren.field3");
                        var objField4 = _this6.get("objChildren.field4");
                        var objField5 = _this6.get("objChildren.field5");

                        a24Validation.addRequired(objField1, true);
                        a24Validation.addRequired(objField2, true);
                        a24Validation.addRequired(objField3, true);
                        a24Validation.addRequired(objField4, true);
                        a24Validation.addRequired(objField5, true);

                        _this6._funcSetInputStates(_this6.get("_objStringsCandidateProfile").getString("hi") + " " + _this6.get("_objBasicInfoRestService.objLastCandidateDetails.first_name"), null, null, _this6.get("_objUserSession").getUserFirstName());
                    });
                });
            } else if (this.get("_bCandidateCallbackRequestedNotification")) {
                this.set("_bShowFieldMaxLength4", true);
                this.set("_bShowFieldMaxLength5", true);
                this.set("_bShowFieldMaxLength6", true);

                this.set("_sLabelField4", this.get("_objStringsCandidateProfile").getString("content"));
                this.set("_sLabelField5", this.get("_objStringsCandidateProfile").getString("replyWith"));
                this.set("_sLabelField6", this.get("_objStringsCandidateProfile").getString("regards"));

                this.set("_bRequiredField4", true);
                this.set("_bRequiredField5", true);
                this.set("_bRequiredField6", true);

                // prepopulate the regards input with user's name
                Ember.run.next(function () {
                    if (a24Core.isEmpty(_this6)) {
                        return;
                    }
                    var sFullUsername = _this6.get("_objUserSession.objAuthData.identity.sFirstName") + " " + _this6.get("_objUserSession.objAuthData.identity.sLastName");
                    Ember.run.schedule("afterRender", function () {

                        var objField1 = _this6.get("objChildren.fieldMaxLength4");
                        var objField2 = _this6.get("objChildren.fieldMaxLength5");
                        var objField3 = _this6.get("objChildren.fieldMaxLength6");

                        a24Validation.addRequired(objField1, true);
                        a24Validation.addMaxLength(objField1, 200);
                        a24Validation.addRequired(objField2, true);
                        a24Validation.addMaxLength(objField2, 50);
                        a24Validation.addRequired(objField3, true);
                        a24Validation.addMaxLength(objField3, 100);

                        objField3.setValueFromOutside(sFullUsername);
                    });
                });
            } else if (this.get("_bCandidateReminderNotification")) {
                this.set("_bShowFieldMaxLength4", true);
                this.set("_bShowFieldMaxLength5", true);
                this.set("_bShowFieldMaxLength6", true);

                this.set("_sLabelField4", this.get("_objStringsCandidateProfile").getString("content"));
                this.set("_sLabelField5", this.get("_objStringsCandidateProfile").getString("replyWith"));
                this.set("_sLabelField6", this.get("_objStringsCandidateProfile").getString("regards"));

                this.set("_bRequiredField4", true);
                this.set("_bRequiredField5", true);
                this.set("_bRequiredField6", true);

                // prepopulate the regards input with user's name
                Ember.run.next(function () {
                    if (a24Core.isEmpty(_this6)) {
                        return;
                    }
                    var sFullUsername = _this6.get("_objUserSession.objAuthData.identity.sFirstName") + " " + _this6.get("_objUserSession.objAuthData.identity.sLastName");
                    Ember.run.schedule("afterRender", function () {
                        var objField1 = _this6.get("objChildren.fieldMaxLength4");
                        var objField2 = _this6.get("objChildren.fieldMaxLength5");
                        var objField3 = _this6.get("objChildren.fieldMaxLength6");

                        a24Validation.addRequired(objField1, true);
                        a24Validation.addMaxLength(objField1, 200);
                        a24Validation.addRequired(objField2, true);
                        a24Validation.addMaxLength(objField2, 50);
                        a24Validation.addRequired(objField3, true);
                        a24Validation.addMaxLength(objField3, 100);

                        objField3.setValueFromOutside(sFullUsername);
                    });
                });
            } else if (this.get("_bCandidateTimesheetNotification")) {
                this.set("_bShowFieldMaxLength1", true);
                this.set("_bShowFieldMaxLength4", true);
                this.set("_bShowFieldMaxLength6", true);

                this.set("_sLabelField1", this.get("_objStringsCandidateProfile").getString("timesheetNumber"));
                this.set("_sLabelField4", this.get("_objStringsCandidateProfile").getString("candidateInstructions"));
                this.set("_sLabelField6", this.get("_objStringsCandidateProfile").getString("regards"));

                this.set("_bRequiredField1", true);
                this.set("_bRequiredField4", true);
                this.set("_bRequiredField6", true);

                // prepopulate the regards input with user's name
                Ember.run.next(function () {
                    if (a24Core.isEmpty(_this6)) {
                        return;
                    }
                    var sFullUsername = _this6.get("_objUserSession.objAuthData.identity.sFirstName") + " " + _this6.get("_objUserSession.objAuthData.identity.sLastName");
                    Ember.run.schedule("afterRender", function () {
                        var objField1 = _this6.get("objChildren.fieldMaxLength1");
                        var objField2 = _this6.get("objChildren.fieldMaxLength4");
                        var objField3 = _this6.get("objChildren.fieldMaxLength6");

                        a24Validation.addRequired(objField1, true);
                        a24Validation.addMaxLength(objField1, 10);
                        a24Validation.addRequired(objField2, true);
                        a24Validation.addMaxLength(objField2, 200);
                        a24Validation.addRequired(objField3, true);
                        a24Validation.addMaxLength(objField3, 100);

                        objField3.setValueFromOutside(sFullUsername);
                    });
                });
            } else if (this.get("_bCandidateAnnualLeaveNotification")) {
                this.set("_bShowFieldMaxLength1", true);
                this.set("_bShowFieldMaxLength2", true);
                this.set("_bShowFieldMaxLength3", true);
                this.set("_bShowFieldMaxLength6", true);

                this.set("_sLabelField1", this.get("_objStringsCandidateProfile").getString("hours"));
                this.set("_sLabelField2", this.get("_objStringsCandidateProfile").getString("days"));
                this.set("_sLabelField3", this.get("_objStringsCandidateProfile").getString("date"));
                this.set("_sLabelField6", this.get("_objStringsCandidateProfile").getString("regards"));

                this.set("_bRequiredField1", true);
                this.set("_bRequiredField2", true);
                this.set("_bRequiredField3", true);
                this.set("_bRequiredField6", true);

                // prepopulate the regards input with user's name
                Ember.run.next(function () {
                    if (a24Core.isEmpty(_this6)) {
                        return;
                    }
                    var sFullUsername = _this6.get("_objUserSession.objAuthData.identity.sFirstName") + " " + _this6.get("_objUserSession.objAuthData.identity.sLastName");
                    Ember.run.schedule("afterRender", function () {
                        var objField1 = _this6.get("objChildren.fieldMaxLength1");
                        var objField2 = _this6.get("objChildren.fieldMaxLength2");
                        var objField3 = _this6.get("objChildren.fieldMaxLength3");
                        var objField4 = _this6.get("objChildren.fieldMaxLength6");

                        a24Validation.addRequired(objField1, true);
                        a24Validation.addMaxLength(objField1, 10);
                        a24Validation.addRequired(objField2, true);
                        a24Validation.addMaxLength(objField2, 10);
                        a24Validation.addRequired(objField3, true);
                        a24Validation.addMaxLength(objField3, 20);
                        a24Validation.addRequired(objField4, true);
                        a24Validation.addMaxLength(objField4, 100);

                        objField4.setValueFromOutside(sFullUsername);
                    });
                });
            }
        },
        activityNotificationFromMedium: function activityNotificationFromMedium(sType, objData) {
            if (sType === this.get("_objStaffshiftCoreEnums.mediumType.EMAIL.backend")) {
                this.set("_bIsEmail", true);
                this.set("_objNotificationPreview", {
                    sSubject: objData.subject,
                    objFrom: objData.send_by,
                    objTo: null,
                    arrCC: null,
                    arrBCC: null,
                    objReplyTo: objData.reply_to,
                    sHTML: objData.html
                });
            } else {
                this.set("_bIsEmail", false);
                this.set("_objNotificationPreview", {
                    collected_tokens: [],
                    sender_phone_number_formatted: null,
                    recipient_mobile_number_formatted: null,
                    medium: sType,
                    data: {
                        body: objData.text
                    },
                    created_at: null,
                    status: null
                });
            }
        },
        sendNotification: function sendNotification() {
            var _this7 = this;

            var sCandidateId = this.get("_sCandidateId");
            var sNotificationId = this.get("_sNotificationId");

            var objData = {
                type: sNotificationId,
                data: {}
            };
            if (this.get("_bShiftNotification") || this.get("_bAvailUpdateNotification") || this.get("_bComplianceUpdateNotification") || this.get("_bCandidateCallbackRequestedNotification") || this.get("_bCandidateReminderNotification") || this.get("_bCandidateTimesheetNotification") || this.get("_bCandidateAnnualLeaveNotification")) {
                if (!this.doFullValidation()) {
                    // call send notification service
                    this.set("_bSendNotificationPopupLoading", true);

                    var objInputData = this.getPlainFormValueObject();
                    if (this.get("_bShiftNotification")) {
                        var sShiftDetails = this.get("_objStringsCandidateProfile").getString("for") + " " + objInputData.field1 + " " + (this.get("_objStringsService").getString("in").toLowerCase() + " " + objInputData.field2 + ". " + objInputData.field3);

                        if (!a24Core.isEmpty(objInputData.field4)) {
                            sShiftDetails += ". " + objInputData.field4;
                        }

                        objData.data = {
                            shift_details: sShiftDetails,
                            reply_with: objInputData.field5
                        };
                    } else if (this.get("_bAvailUpdateNotification")) {
                        // {{greeting}} Please update {{update_entity}} as {{update_reason}}. Regards {{regards}}{{area}}
                        var sRegards = objInputData.field4;
                        if (!a24Core.isEmpty(objInputData.field5)) {
                            sRegards = sRegards + ", " + objInputData.field5;
                        }
                        objData.data = {
                            greeting: objInputData.field1 + ".",
                            update_entity: this.get("_objStringsCandidateProfile").getString("yourAvailability").toLowerCase(),
                            update_reason: this.get("_objStringsCandidateProfile").getString("iHaveTempWorkAvail"),
                            regards: sRegards
                        };
                    } else if (this.get("_bComplianceUpdateNotification")) {
                        // {{greeting}} Please update {{update_entity}} as {{update_reason}}. Regards {{regards}}{{area}}
                        var _sRegards2 = objInputData.field4;
                        if (!a24Core.isEmpty(objInputData.field5)) {
                            _sRegards2 = _sRegards2 + ". " + this.get("_objStringsCandidateProfile").getString("pleaseReplyWith") + ": " + objInputData.field5;
                        }
                        objData.data = {
                            greeting: objInputData.field1 + ".",
                            update_entity: objInputData.field2,
                            update_reason: objInputData.field3,
                            regards: _sRegards2
                        };
                    } else if (this.get("_bCandidateCallbackRequestedNotification") || this.get("_bCandidateReminderNotification") || this.get("_bCandidateTimesheetNotification") || this.get("_bCandidateAnnualLeaveNotification")) {
                        var sTokenStringReplace = "";
                        var sTitle = this.get("_objCandidateProfileEnumsService").getFrontendValue("title", this.get("_objBasicInfoRestService.objLastCandidateDetails.title"));
                        if ((this.get("_objBasicInfoRestService.objLastCandidateDetails.candidate_type_id") === this.get("_objConstantsSSCore.UK_GP") || this.get("_objBasicInfoRestService.objLastCandidateDetails.candidate_type_id") === this.get("_objConstantsSSCore.UK_LOCUM") || this.get("_objBasicInfoRestService.objLastCandidateDetails.candidate_type_id") === this.get("_objConstantsSSCore.UK_LOCUM_ALLIED") || this.get("_objBasicInfoRestService.objLastCandidateDetails.candidate_type_id") === this.get("_objConstantsSSCore.ZA_LOCUM") || this.get("_objBasicInfoRestService.objLastCandidateDetails.candidate_type_id") === this.get("_objConstantsSSCore.ZA_LOCUM_ALLIED")) && !a24Core.isEmpty(this.get("_objBasicInfoRestService.objLastCandidateDetails.title"))) {
                            sTokenStringReplace = sTitle + " " + this.get("_objBasicInfoRestService.objLastCandidateDetails.last_name");
                        } else {
                            sTokenStringReplace = this.get("_objBasicInfoRestService.objLastCandidateDetails.first_name");
                        }
                        if (this.get("_bCandidateCallbackRequestedNotification")) {
                            objData.data = {
                                candidate_first_name: sTokenStringReplace,
                                content: objInputData.fieldMaxLength4,
                                reply_with: objInputData.fieldMaxLength5,
                                regards: objInputData.fieldMaxLength6 + ". " + this.get("_objUserSession.objContextDetails.sDisplayName") + ", " + this.get("_objStringsCandidateProfile").getString("aMemberOf")
                            };
                        } else if (this.get("_bCandidateReminderNotification")) {
                            objData.data = {
                                candidate_first_name: sTokenStringReplace,
                                content: objInputData.fieldMaxLength4 + ". " + this.get("_objStringsCandidateProfile").getString("pleaseReplyWith") + " " + objInputData.fieldMaxLength5,
                                regards: objInputData.fieldMaxLength6 + ". " + this.get("_objUserSession.objContextDetails.sDisplayName") + ", " + this.get("_objStringsCandidateProfile").getString("aMemberOf")
                            };
                        } else if (this.get("_bCandidateTimesheetNotification")) {
                            objData.data = {
                                candidate_first_name: sTokenStringReplace,
                                timesheet_number: objInputData.fieldMaxLength1,
                                candidate_instructions: objInputData.fieldMaxLength4,
                                regards: objInputData.fieldMaxLength6 + ". " + this.get("_objUserSession.objContextDetails.sDisplayName")
                            };
                        } else {
                            objData.data = {
                                candidate_first_name: sTokenStringReplace,
                                hours: objInputData.fieldMaxLength1,
                                days: objInputData.fieldMaxLength2,
                                date: objInputData.fieldMaxLength3,
                                regards: objInputData.fieldMaxLength6 + ". " + this.get("_objUserSession.objContextDetails.sDisplayName")
                            };
                        }
                    }

                    this.get("_objRestAgencyCandidateNotification").sendNotification(this, "_objSendNotificationServiceCall", sCandidateId, objData, function () {
                        _this7.set("_bSendNotificationPopupLoading", false);
                        _this7.set("_bShowSendNotificationPopup", false);
                        _this7.set("_bShowSentMessagePopup", true);
                    }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode /*, sStatus, objErrorThrown, objError*/) {
                        var sServerError = "";
                        if (sCode === "404") {
                            sServerError = _this7.get("_objStringsCandidateProfile").getString("noNotificationForType");
                        } else {
                            sServerError = _this7.get("_objStringsService").getString("serverError");
                        }
                        _this7.get("_objSnackbarService").showSnackbarMessage(sServerError);

                        _this7.set("_bSendNotificationPopupLoading", false);
                    }));
                }
            } else {
                this.set("_bSendNotificationPopupLoading", true);
                this.get("_objRestAgencyCandidateNotification").sendNotification(this, "_objSendNotificationServiceCall", sCandidateId, objData, function () {
                    _this7.set("_bSendNotificationPopupLoading", false);
                    _this7.set("_bShowSendNotificationPopup", false);
                    _this7.set("_bShowSentMessagePopup", true);
                }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode /*, sStatus, objErrorThrown, objError*/) {
                    var sServerError = "";
                    if (sCode === "404") {
                        sServerError = _this7.get("_objStringsCandidateProfile").getString("noNotificationForType");
                    } else {
                        sServerError = _this7.get("_objStringsService").getString("serverError");
                    }
                    _this7.get("_objSnackbarService").showSnackbarMessage(sServerError);

                    _this7.set("_bSendNotificationPopupLoading", false);
                }));
            }
        },
        onPopupNotificationCancel: function onPopupNotificationCancel() {
            if (this.get("_bSendNotificationPopupLoading")) {
                return;
            }

            this.set("_bShowSendNotificationPopup", false);
        },
        actions: {
            onRecieveData: function onRecieveData(objData) {
                if (objData.type === "app_ready") {
                    this.set("_bReactPopupLoading", false);
                } else if (objData.type === "refresh") {
                    // for if we need to do refresh
                } else if (objData.type === "close") {
                    this.set("_bShowReactPopup", false);
                }
            },
            onPopupSendNotificationCancel: function onPopupSendNotificationCancel() {
                this.onPopupNotificationCancel();
            },
            onPopupSendNotificationPreviewSend: function onPopupSendNotificationPreviewSend() {
                this.sendNotification();
            },
            onNotificationPreviewPopupClose: function onNotificationPreviewPopupClose() {
                this.set("_bShowNotificationPreviewPopup", false);
            },
            onSentMessagePopupCancel: function onSentMessagePopupCancel() {
                this.set("_bShowSentMessagePopup", false);
            }
        }
    });
});