define("a24-staffshift-subdomain-agency/components/page-agency-candidate-search", ["exports", "a24-staffshift-subdomain-agency/config/environment", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _environment, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        objQueryParams: null,

        _bPageLoading: true,
        _bShowCandidateAddPopup: false,
        _bShowPossibleNonShiftsPopup: false,

        _bShowLinkedInfoPopup: false,
        _bLinkedInfoPopupLoading: false,
        _sLinkedInfoHeaderPopup: "",
        _objLinkedInfoSpecialPopupAction: null,
        _objAgencyCandidateProfileAgencyInfoPromise: null,

        _bShowPossibleShiftsPopup: false,
        _sPossibleShiftNotifPreview: "",
        _arrPossibleShifts: null,
        _sPossibleShiftNotifErrorMessage: "",
        _bShowSentMessagePopup: false,

        _bShowReactPopup: false,
        _bReactPopupLoading: true,
        _sIFrameUrl: null,
        _objReactPopupPostData: null,

        _sUrlBase: "",

        _objDatagridConfig: null,
        _objCandidateTypesResponse: null,
        _objSelectedItem: null,

        _arrCandidateTypes: null,
        _arrCandidateStatus: null,
        _arrSelectedItems: null,
        _arrExternalDocumentTypes: null,
        _arrExternalVerificationTypes: null,
        _arrExternalDaysTypes: null,

        // Services
        _objUserSession: Ember.inject.service("user-session"),
        _objTitleBarService: Ember.inject.service("title-bar"),
        _objNavigation: Ember.inject.service("navigation"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objEmberLibString: Ember.inject.service("a24-strings-ember-lib"),
        _objFabButtonService: Ember.inject.service("fab-button"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objAvailabilityStrings: Ember.inject.service("a24-strings-staffshift-availability"),
        _objSSCoreStringsService: Ember.inject.service("a24-strings-staffshift-core"),
        _objSSUsersStringsService: Ember.inject.service("a24-strings-staffshift-users"),
        _objSSAgencyStringsService: Ember.inject.service("a24-strings-staffshift-agency"),
        _objRestAgencyClientExclusionsService: Ember.inject.service("rest-agency-client-exclusions"),
        _objRestCandidateType: Ember.inject.service("rest-agency-candidate-type"),
        _objRestCandidate: Ember.inject.service("rest-agency-candidate"),
        _objRestSystem: Ember.inject.service("rest-system"),
        _objSSAgencyEnumsService: Ember.inject.service("a24-enums-staffshift-agency"),
        _objSSCoreEnum: Ember.inject.service("a24-enums-staffshift-core"),
        _objCandidateProfileEnumsService: Ember.inject.service("a24-enums-candidate-profile"),
        _objConstantsLib: Ember.inject.service("a24-constants-ember-lib"),
        _objFilterCustomService: Ember.inject.service("card-datagrid-custom-filter-availability"),
        _objFilterCustomCandidatePositionHeldService: Ember.inject.service("card-datagrid-custom-filter-candidate-position-held"),
        _objFilterCustomCandidateDistanceService: Ember.inject.service("card-datagrid-custom-filter-candidate-distance"),
        _objFilterCustomCandidateProfessionalRegistrationService: Ember.inject.service("card-datagrid-custom-filter-candidate-professional-registration"),
        _objCandidateProfileStringsService: Ember.inject.service("a24-strings-candidate-profile"),
        _objRestAgencyStatus: Ember.inject.service("rest-agency-status-details"),
        _objRestAgencyCandidateProfileAgencyInfo: Ember.inject.service("rest-agency-candidate-profile-agency-info"),
        _objRestActivityType: Ember.inject.service("rest-advanced-search-activity-type"),
        _objStaffshiftCoreConstants: Ember.inject.service("a24-constants-staffshift-core"),
        _objRestAgencyJobTitle: Ember.inject.service("rest-agency-job-title"),
        _objRestAgencyShift: Ember.inject.service("rest-agency-advanced-search-agency-shift"),
        _objRestAgencyClient: Ember.inject.service("rest-agency-advanced-search-client"),
        _objAgencySavedSearchService: Ember.inject.service("rest-agency-saved-searches"),
        _objLinkedClientsService: Ember.inject.service("rest-agency-site"),
        _objRestLookup: Ember.inject.service("rest-agency-lookup"),
        _objRestAgencyShiftPromise: null,
        _objRestAgencyClientPromise: null,
        _objRestAgencyClientExclusionsPromise: null,
        _objRestBulkNotificationsService: Ember.inject.service("rest-agency-candidate-bulk-notifications"),
        _objEnumEmberLibService: Ember.inject.service("a24-enums-ember-lib"),
        _objMenuService: Ember.inject.service("triage-menu"),
        _objRestBulkNotificationsPromise: null,
        _bShowLoadFailedPopup: false,
        _bShowClientExclusionNotFoundPopup: false,
        _objSystemSkillsResponse: null,
        _objPossibleShiftSpecialPopupAction: null,
        // Commented out for this ticket https://github.com/A24Group/react-core/issues/2994
        // _objFilterCustomCandidateExternalDaysToExpiryService: inject("card-datagrid-custom-filter-candidate-external-days-to-expiry"),
        _objAgencyAutocompleteService: Ember.inject.service("rest-agency-autocomplete"),
        _objRestPermissionedActions: Ember.inject.service("rest-permissioned-actions"),
        _objExternalDocumentTypesResponse: null,
        _objExternalVerificationResponse: null,
        _objGetExternalDaysToExpiry: null,
        _objSelectedRightSide: null,
        _objGetCitySuggestions: null,
        _objGetCountySuggestions: null,
        _objShiftWithOrderDetails: null,

        _bEntityActionLoading: true,
        _arrBookingRequestPermissionedAction: null,
        _arrSingleActions: null,

        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            this.get("_objTitleBarService").setTitleBar(this.get("_objSSCoreStringsService").getString("candidateSearch"));

            if (a24Core.isEmpty(this.get("_sIFrameUrl"))) {
                this.set("_sIFrameUrl", _environment.default.sReactGatewayUrl);
            }

            // Build up the url with context id.
            this.set("_sUrlBase", _environment.default.sRestApiBaseUrl + "/v1/agency/" + this.get("_objNavigation.sContextId") + "/advanced-search/candidate");

            this.set("_objFilterCustomCandidatePositionHeldService._objJobTitleService", this.get("_objRestAgencyJobTitle"));

            this.set("_objLinkedInfoSpecialPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: function executeCardAction() {
                    _this._popupLinkedInfoClose();
                }
            });

            this.set("_objPossibleShiftSpecialPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: function executeCardAction() {
                    _this._closePossibleShiftsPopup();
                }
            });

            this.set("_objMenuService.bRightPanelInitClosed", true);
            this.set("_objMenuService.sRightPanelComponent", "right-panel-candidate-profile");
            this.set("_objMenuService.mRightPanelComponentHideOverflow", true);
            this._observeSelectionChanged();

            // this.set("_objFilterCustomCandidateExternalDaysToExpiryService._objLookupService", this.get("_objRestLookup"));

            this.doServiceCalls();
        },

        _handleServerErrors: function _handleServerErrors() {
            this.set("_bShowLoadFailedPopup", true);
        },

        _handleClientExclusionNotFound: function _handleClientExclusionNotFound() {
            this.set("_bShowClientExclusionNotFoundPopup", true);
        },

        doServiceCalls: function doServiceCalls() {
            var _this2 = this;

            this.set("_bPageLoading", true);
            var objPromiseCandidateTypes = this.get("_objRestCandidateType").getCandidateTypes(this, "_objCandidateTypesResponse", this.get("_objUserSession").getUserContextReference().mId, function () {
                // Handled by RSVP
            }, a24RestCallHelper.createJsonAjaxFailureObject(["500", "502", "503", "504"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                _this2._handleServerErrors();
            }));

            var objPromiseCandidateStatus = this.get("_objRestAgencyStatus").getStatusList(this, "_objCandidateStatusResponse", function () {
                // Handled by RSVP
            }, a24RestCallHelper.createJsonAjaxFailureObject(["412", "500", "502", "503", "504"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                _this2._handleServerErrors();
            }));

            var objActivityTypesQueryParams = {
                subject_type: { mValue: this.get("_objSSCoreEnum.activitySubjectTypes.CANDIDATE.backend") },
                name: { mValue: "asc", bSortBy: true },
                page: { mValue: 1 },
                items_per_page: { mValue: 1000 }
            };

            var objPromiseActivityTypes = this.get("_objRestActivityType").getActivityTypes(this, "_objActivityTypeResponse", function () {
                // Handled by RSVP
            }, a24RestCallHelper.createJsonAjaxFailureObject(["500", "502", "503", "504"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                _this2._handleServerErrors();
            }), objActivityTypesQueryParams);

            var objExternalDocumentTypes = this.get("_objRestLookup").getExternalDocuments(this, "_objExternalDocumentTypesResponse", function () {
                // Handled by RSVP
            }, a24RestCallHelper.createJsonAjaxFailureObject(["500", "502", "503", "504"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                _this2._handleServerErrors();
            }), {
                items_per_page: {
                    mValue: 1000
                },
                sortBy: {
                    mValue: "name"
                }
            });

            var objExternalVerificationTypes = this.get("_objRestLookup").getExternalVerification(this, "_objExternalVerificationResponse", function () {
                // Handled by RSVP
            }, a24RestCallHelper.createJsonAjaxFailureObject(["500", "502", "503", "504"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                _this2._handleServerErrors();
            }), {
                items_per_page: {
                    mValue: 1000
                }
            });

            var objPromiseExternalDaysTypes = this.get("_objRestLookup").getExternalDaysToExpiry(this, "_objGetExternalDaysToExpiry", function () {
                // Handled by RSVP
            }, a24RestCallHelper.createJsonAjaxFailureObject(["500", "502", "503", "504"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                _this2._handleServerErrors();
            }), {
                sortBy: {
                    mValue: "name"
                },
                items_per_page: {
                    mValue: 1000
                }
            });

            var objBookingRequestPermissionedActionsPromise = this.get("_objRestPermissionedActions").getPermissionedActionsForSection(this, "_objGetPermissionedActions", "booking-request", function () {
                // do nothing since rsvp will handle it
            }, a24RestCallHelper.createJsonAjaxFailureObject(["401", "500", "502", "503", "504"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                _this2.set("_bEntityActionLoading", false);
                _this2.set("_arrBookingRequestPermissionedAction", []);
                _this2._setDatagridActions();
            }));

            var objPromiseList = {
                _objRSVPCandidateTypes: objPromiseCandidateTypes,
                _objRSVPCandidateStatus: objPromiseCandidateStatus,
                _objRSVPActivityTypes: objPromiseActivityTypes,
                _objRSVPExternalDocumentTypes: objExternalDocumentTypes,
                _objRSVPExternalVerificationTypes: objExternalVerificationTypes,
                _objRSVPExternalDaysTypes: objPromiseExternalDaysTypes,
                _objRSVPBookingRequestPermissionedAction: objBookingRequestPermissionedActionsPromise
            };

            var arrRequiredResponses = ["_objRSVPCandidateTypes", "_objRSVPCandidateStatus", "_objRSVPActivityTypes", "_objRSVPExternalDocumentTypes", "_objRSVPExternalVerificationTypes", "_objRSVPExternalDaysTypes"];

            if (!a24Core.isEmpty(this.get("_objNavigation.objRouteQueryParams.pos_shift_order_id"))) {
                var objPromiseAgencyShifts = this.get("_objRestAgencyShift").getShifts(this, "_objRestAgencyShiftPromise", {
                    order_id: {
                        bEncode: true,
                        mValue: this.get("_objNavigation.objRouteQueryParams.pos_shift_order_id")
                    }
                },
                //TODO add filter for end date > today
                //TODO add filter for status "New|Reopened",
                //The above 2 TODO's will need to be done manually for now since we need at
                //at least one shift to come back so that we can apply the goe/city filter correct
                { start_date: { mValue: "asc", bSortBy: true } }, 1, 1000, function () {
                    // Handled by RSVP
                }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                    _this2._handleServerErrors();
                }));

                objPromiseList._objRSVPAgencyShift = objPromiseAgencyShifts;
                arrRequiredResponses.push("_objRSVPAgencyShift");
            }

            if (!a24Core.isEmpty(this.get("_objNavigation.objRouteQueryParams.pos_shift_site_id"))) {
                var sClientId = this.get("_objNavigation.objRouteQueryParams.pos_shift_site_id");
                var objQuery = {
                    site_id: {
                        bEncode: true,
                        mValue: this.get("_objNavigation.objRouteQueryParams.pos_shift_site_id")
                    }
                };
                if (!a24Core.isEmpty(this.get("_objNavigation.objRouteQueryParams.pos_shift_ward_id"))) {
                    objQuery.ward_id = {
                        bEncode: true,
                        mValue: this.get("_objNavigation.objRouteQueryParams.pos_shift_ward_id")
                    };
                    objQuery.node_level = {
                        bEncode: true,
                        mValue: this.get("_objSSAgencyStringsService").getString("ward").toLowerCase()
                    };
                    sClientId = this.get("_objNavigation.objRouteQueryParams.pos_shift_ward_id");
                } else {
                    objQuery.node_level = {
                        bEncode: true,
                        mValue: this.get("_objSSAgencyStringsService").getString("site").toLowerCase()
                    };
                }

                var objPromiseAgencyClients = this.get("_objRestAgencyClient").getClients(this, "_objRestAgencyClientPromise", objQuery, null, 1, 25, function () {
                    // Handled by RSVP
                }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                    _this2._handleServerErrors();
                }));

                objPromiseList._objRSVPAgencyClients = objPromiseAgencyClients;
                arrRequiredResponses.push("_objRSVPAgencyClients");

                var objPromiseAgencyClientExclusions = this.get("_objRestAgencyClientExclusionsService").getAgencyClientExcludedCandidates(this, "_objRestAgencyClientExclusionsPromise", sClientId, function () {
                    // Handled by RSVP
                }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode /*, sStatus, objErrorThrown, objError*/) {
                    if (sCode === "404") {
                        _this2._handleClientExclusionNotFound();
                        return;
                    }
                    _this2._handleServerErrors();
                }));

                objPromiseList._objRSVPAgencyClientExclusions = objPromiseAgencyClientExclusions;
                arrRequiredResponses.push("_objRSVPAgencyClientExclusions");
            }

            var objThis = this;

            // Adding a rsvp here for when we do the statuses later on so that we dont have to add it
            Ember.copy(a24RSVP).configure(this, objPromiseList, [{
                arrRequiredResponses: arrRequiredResponses,
                funcOnComplete: function funcOnComplete(objResponses) {
                    var arrCandidateTypes = objResponses._objRSVPCandidateTypes.objResponse;
                    var arrCandidateStatus = objResponses._objRSVPCandidateStatus.objResponse;
                    var arrActivityTypes = objResponses._objRSVPActivityTypes.objResponse;
                    var arrExternalDocumentTypes = objResponses._objRSVPExternalDocumentTypes.objResponse;
                    var arrExternalVerificationTypes = objResponses._objRSVPExternalVerificationTypes.objResponse;
                    var arrExternalDaysTypes = objResponses._objRSVPExternalDaysTypes.objResponse;
                    var arrAgencyShifts = [];
                    if (!a24Core.isEmpty(objResponses._objRSVPAgencyShift)) {
                        arrAgencyShifts = objResponses._objRSVPAgencyShift.objResponse;
                    }
                    var arrAgencyClients = [];
                    if (!a24Core.isEmpty(objResponses._objRSVPAgencyClients)) {
                        arrAgencyClients = objResponses._objRSVPAgencyClients.objResponse;
                    }

                    var arrAgencyClientExclusions = [];
                    if (!a24Core.isEmpty(objResponses._objRSVPAgencyClientExclusions)) {
                        arrAgencyClientExclusions = objResponses._objRSVPAgencyClientExclusions.objResponse;
                    }
                    if (a24Core.isEmpty(arrAgencyClientExclusions)) {
                        arrAgencyClientExclusions = [];
                    }

                    if (a24Core.isEmpty(arrCandidateTypes)) {
                        arrCandidateTypes = [];
                    }
                    if (a24Core.isEmpty(arrCandidateStatus)) {
                        arrCandidateStatus = [];
                    }
                    if (a24Core.isEmpty(arrActivityTypes)) {
                        arrActivityTypes = [];
                    }
                    if (a24Core.isEmpty(arrExternalDocumentTypes)) {
                        arrExternalDocumentTypes = [];
                    }
                    if (a24Core.isEmpty(arrExternalVerificationTypes)) {
                        arrExternalVerificationTypes = [];
                    }
                    if (a24Core.isEmpty(arrExternalDaysTypes)) {
                        arrExternalDaysTypes = [];
                    }

                    if (a24Core.isEmpty(objResponses._objRSVPAgencyShift)) {
                        objThis.setupPageConfig(arrCandidateTypes, arrCandidateStatus, arrActivityTypes, arrExternalDocumentTypes, arrExternalVerificationTypes, arrExternalDaysTypes, null);
                    } else {
                        if (a24Core.isEmpty(arrAgencyShifts)) {
                            objThis._handleServerErrors();
                        } else {
                            objThis._buildPossibleShiftNotif(arrAgencyShifts);
                            objThis.setupPageConfig(arrCandidateTypes, arrCandidateStatus, arrActivityTypes, arrExternalDocumentTypes, arrExternalVerificationTypes, arrExternalDaysTypes, arrAgencyShifts[0], arrAgencyClients, arrAgencyClientExclusions);
                        }
                    }
                }
            }, {
                arrRequiredResponses: ["_objRSVPBookingRequestPermissionedAction"],
                funcOnComplete: function funcOnComplete(objResponses) {
                    var arrBookingRequestPermissionedAction = objResponses._objRSVPBookingRequestPermissionedAction.objResponse;

                    if (a24Core.isEmpty(arrBookingRequestPermissionedAction)) {
                        arrBookingRequestPermissionedAction = [];
                    }
                    objThis.set("_bEntityActionLoading", false);

                    objThis.set("_arrBookingRequestPermissionedAction", arrBookingRequestPermissionedAction);

                    objThis._setDatagridActions();
                }
            }]);
        },
        _setDatagridActions: function _setDatagridActions() {
            var _this3 = this;

            var objThis = this;

            var arrSingleItemActions = [{
                sTitle: this.get("_objSSAgencyStringsService").getString("possibleWorkNotification"),
                sIcon: "event_note",
                executeCardAction: function executeCardAction() {
                    objThis.set("_arrCandidateIds", objThis.get("_arrSelectedItems").getEach("candidate_id"));
                    objThis.set("_bShowPossibleNonShiftsPopup", true);
                }
            }, {
                sTitle: this.get("_objSSCoreStringsService").getString("openCalendarInNewTab"),
                sIcon: "today",
                executeCardAction: function executeCardAction() {
                    objThis.viewCandidateCalendarInNewWindow(objThis.get("_arrSelectedItems")[0].candidate_id);
                }
            }, {
                sTitle: this.get("_objSSCoreStringsService").getString("openInNewTab"),
                sIcon: "open_in_new",
                executeCardAction: function executeCardAction() {
                    objThis.viewCandidateInNewWindow(objThis.get("_arrSelectedItems")[0].candidate_id);
                }
            }, {
                sTitle: this.get("_objSSCoreStringsService").getString("viewCandidateCalendar"),
                sIcon: "perm_contact_calendar",
                executeCardAction: function executeCardAction() {
                    objThis.doCandidateCalendarView(objThis.get("_arrSelectedItems")[0].candidate_id);
                }
            }, {
                sTitle: this.get("_objSSCoreStringsService").getString("viewCandidate"),
                sIcon: "visibility",
                executeCardAction: function executeCardAction() {
                    objThis.doCandidateView(objThis.get("_arrSelectedItems")[0].candidate_id);
                }
            }, {
                sId: "directBookingWizard",
                bLoader: true
                // This is commented out because its not needed to show the linked info at the moment
                // Check issue https://github.com/A24Group/react-core/issues/353
                // {
                //     sTitle: this.get("_objCandidateProfileStringsService").getString("linkedInfo"),
                //     sIcon: "info",
                //     executeCardAction: function () {
                //         objThis.doCandidateLinkedInfoView(objThis.get("_arrSelectedItems")[0]);
                //     }
                // }
            }];

            var arrMultiItemActions = [{
                sTitle: this.get("_objSSAgencyStringsService").getString("possibleWorkNotification"),
                sIcon: "event_note",
                executeCardAction: function executeCardAction() {
                    objThis.set("_arrCandidateIds", objThis.get("_arrSelectedItems").getEach("candidate_id"));
                    objThis.set("_bShowPossibleNonShiftsPopup", true);
                }
            }, {
                sTitle: this.get("_objSSCoreStringsService").getString("openCalendarInNewTab"),
                sIcon: "today",
                executeCardAction: function executeCardAction() {
                    objThis.viewAllCandidateCalendarsInNewWindows(objThis.get("_arrSelectedItems"));
                },
                funcFilterFunc: function funcFilterFunc(arrSelectedItems) {
                    // Only show when selected items is not more than 10
                    return !a24Core.isEmpty(arrSelectedItems) && arrSelectedItems.length <= 10;
                }
            }, {
                sTitle: this.get("_objSSCoreStringsService").getString("openInNewTab"),
                sIcon: "open_in_new",
                executeCardAction: function executeCardAction() {
                    objThis.viewAllCandidatesInNewWindows(objThis.get("_arrSelectedItems"));
                },
                funcFilterFunc: function funcFilterFunc(arrSelectedItems) {
                    // Only show when selected items is not more than 10
                    return !a24Core.isEmpty(arrSelectedItems) && arrSelectedItems.length <= 10;
                }
            }];

            if (!a24Core.isEmpty(this.get("_objShiftWithOrderDetails"))) {
                if (!a24Core.isEmpty(this.get("_arrPossibleShifts"))) {
                    arrSingleItemActions.insertAt(0, {
                        sTitle: this.get("_objSSAgencyStringsService").getString("sendAvailShiftNotif"),
                        sIcon: "event_available",
                        executeCardAction: function executeCardAction() {
                            objThis._showPossibleShiftsPopup();
                        }
                    });
                    arrMultiItemActions.insertAt(0, {
                        sTitle: this.get("_objSSAgencyStringsService").getString("sendAvailShiftNotif"),
                        sIcon: "event_available",
                        executeCardAction: function executeCardAction() {
                            objThis._showPossibleShiftsPopup();
                        }
                    });
                }
            }

            if (!this.get("_bEntityActionLoading")) {
                var sPunchClockSvg = this.get("_objSSCoreEnum").getMaterialSvgIcon("punch_clock");

                var objDirectWizardAction = {
                    sTitle: this.get("_objSSAgencyStringsService").getString("addBookingRequest"),
                    sIconSvg: sPunchClockSvg,
                    executeCardAction: function executeCardAction() {
                        objThis.set("_bShowReactPopup", true);
                        if (!a24Core.isEmpty(objThis.get("_objNavigation.objRouteQueryParams.pos_shift_order_id")) && !a24Core.isEmpty(objThis.get("_objShiftWithOrderDetails"))) {
                            var objOrderDetails = objThis.get("_objShiftWithOrderDetails");
                            objThis.set("_objReactPopupPostData", {
                                type: "trigger_route",
                                data: {
                                    route: "staffing/directShiftBookingWizard",
                                    data: {
                                        clientId: objOrderDetails.ward_id ? objOrderDetails.ward_id : objOrderDetails.site_id,
                                        clientDetails: {
                                            siteName: objOrderDetails.site_name,
                                            orgName: objOrderDetails.organisation_name,
                                            clientType: objOrderDetails.ward_id ? 'ward' : 'site',
                                            wardName: objOrderDetails.ward_name
                                        },
                                        positionDetails: {
                                            jobFamily: objOrderDetails.job_family_name,
                                            jobFamilyId: objOrderDetails.job_family_id,
                                            jobTitle: objOrderDetails.job_title_name,
                                            jobTitleId: objOrderDetails.job_title_id
                                        },
                                        shiftDetails: [{
                                            shiftId: objOrderDetails.shift_id,
                                            orderId: objOrderDetails.order_id,
                                            shiftStartDate: objOrderDetails.start_date,
                                            shiftEndDate: objOrderDetails.end_date
                                        }],
                                        candidateId: objThis.get("_arrSelectedItems")[0].candidate_id,
                                        candidateDetails: {
                                            fullName: objThis.get("_arrSelectedItems")[0].full_name
                                        }
                                    }
                                }
                            });
                            return;
                        }
                        objThis.set("_objReactPopupPostData", {
                            type: "trigger_route",
                            data: {
                                route: "staffing/directBookingWizard",
                                data: {
                                    subjectId: objThis.get("_arrSelectedItems")[0].candidate_id,
                                    subjectType: "candidate",
                                    headerName: objThis.get("_arrSelectedItems")[0].full_name
                                }
                            }
                        });
                    },
                    funcFilterFunc: function funcFilterFunc() {
                        var arrEntityActions = _this3.get("_arrBookingRequestPermissionedAction");
                        var bAllowAction = false;
                        if (!a24Core.isEmpty(arrEntityActions)) {
                            var objPermissionedAction = arrEntityActions.find(function (objPermissionedActionSection) {
                                return objPermissionedActionSection.entity_type === "booking-request";
                            });
                            var arrAllowedActions = [];

                            if (!a24Core.isEmpty(objPermissionedAction.actions)) {
                                objPermissionedAction.actions.forEach(function (objItem) {
                                    if (objItem.allowed) {
                                        arrAllowedActions.push(objItem.action);
                                    }
                                });
                            }
                            bAllowAction = arrAllowedActions.includes("create");
                        }
                        return bAllowAction;
                    }
                };

                arrSingleItemActions = arrSingleItemActions.map(function (objItem) {
                    if (!a24Core.isEmpty(objItem.sId) && objItem.sId === "directBookingWizard") {
                        return objDirectWizardAction;
                    }
                    return objItem;
                });
            }

            // Doing this here since the entity actions call could be done before the config is setup
            if (!a24Core.isEmpty(this.get("_objDatagridConfig.arrSingleItemSelectActions"))) {
                this.set("_objDatagridConfig.arrSingleItemSelectActions", arrSingleItemActions);
            }
            this.set("_arrSingleActions", arrSingleItemActions);
            this.set("_arrMultiItemActions", arrMultiItemActions);
        },
        _buildPossibleShiftNotif: function _buildPossibleShiftNotif(arrData) {
            var objShiftItem = arrData[0];

            var objDateInstance = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
            var objCurrentDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
            var sEndTime = void 0;
            var sStartTime = void 0;
            var arrPayRates = [];

            //TODO cater for overlap to next day
            var sShiftDateTime = this.get("_objSSAgencyStringsService").getString("shiftsAvailable") + ": ";
            var arrPossibleShifts = [];
            for (var i = 0; i < arrData.length; i++) {
                objDateInstance.setDateFromRest(arrData[i].end_date);

                if (objDateInstance.isAfter(objCurrentDate) && (arrData[i].status_code === this.get("_objSSCoreEnum.assignmentStatus.NEW.backend") || arrData[i].status_code === this.get("_objSSCoreEnum.assignmentStatus.REOPENED.backend"))) {
                    arrPossibleShifts.push(arrData[i]);
                    sEndTime = objDateInstance.getTimeFormat();
                    objDateInstance.setDateFromRest(arrData[i].start_date);
                    sStartTime = objDateInstance.getTimeFormat();
                    sShiftDateTime += objDateInstance.getDateFormat() + " " + sStartTime + "-" + sEndTime + ", ";

                    if (!a24Core.isEmpty(arrData[i].contract_breakdown)) {
                        for (var j = 0; j < arrData[i].contract_breakdown.length; j++) {
                            if (!a24Core.isEmpty(arrData[i].contract_breakdown[j].pay_rate)) {
                                arrPayRates.push(arrData[i].contract_breakdown[j].pay_rate);
                            }
                        }
                    }
                }
            }

            if (a24Core.isEmpty(arrPossibleShifts)) {
                return;
            } else {
                this.set("_arrPossibleShifts", arrPossibleShifts);
            }

            var sAddress = "";
            if (!a24Core.isEmpty(objShiftItem.city)) {
                sAddress = objShiftItem.city + ", ";
            }
            if (!a24Core.isEmpty(objShiftItem.post_code) && !a24Core.isEmpty(objShiftItem.country_code) && (objShiftItem.country_code.toLowerCase() === "gb" || objShiftItem.country_code.toLowerCase() === "uk")) {
                sAddress += objShiftItem.post_code.split(" ")[0];
            } else if (!a24Core.isEmpty(objShiftItem.post_code)) {
                sAddress += objShiftItem.post_code;
            }

            var sPayRate = "";

            if (!a24Core.isEmpty(arrPayRates)) {
                sPayRate = ". " + this.get("_objSSAgencyStringsService").getString("payRate") + ": ";
                arrPayRates.sort(function (a, b) {
                    return a - b;
                });

                var sFirstRate = arrPayRates[0].toFixed(2);
                var sLastRate = arrPayRates[arrPayRates.length - 1].toFixed(2);
                var sUnit = objShiftItem.contract_unit === "hourly" ? "p/h" : "";

                sPayRate += objShiftItem.contract_currency + sFirstRate + sUnit;

                if (sFirstRate !== sLastRate) {
                    sPayRate += " - " + objShiftItem.contract_currency + sLastRate + sUnit;
                }
            }

            var sReplyWith = ". " + this.get("_objSSAgencyStringsService").getString("replyWithOrder") + " #" + objShiftItem.order_reference;
            var sAgency = ". " + this.get("_objUserSession.objContextDetails.sDisplayName");

            this.set("_sPossibleShiftNotifPreview", sShiftDateTime + sAddress + sPayRate + sReplyWith + sAgency);
        },
        setupPageConfig: function setupPageConfig(arrCandidateTypes, arrCandidateStatus, arrActivityTypes, arrExternalDocumentTypes, arrExternalVerificationTypes, arrExternalDaysTypes, objShiftWithOrderDetails, arrAgencyClients, arrAgencyClientExclusions) {
            var _this4 = this;

            this.set("_objShiftWithOrderDetails", objShiftWithOrderDetails);
            var objThis = this;

            this.set("_arrCandidateTypes", arrCandidateTypes);
            this.set("_arrCandidateStatus", arrCandidateStatus);
            this.set("_arrActivityTypes", arrActivityTypes);
            this.set("_arrExternalDocumentTypes", arrExternalDocumentTypes);
            this.set("_arrExternalVerificationTypes", arrExternalVerificationTypes);
            this.set("_arrExternalDaysTypes", arrExternalDaysTypes);

            var arrLanguageOptions = objThis.get("_objCandidateProfileEnumsService").getList("language");
            var arrMediumTypeOptions = objThis.get("_objCandidateProfileEnumsService").getList("mediumTypeDnc");
            var arrTransportOptions = objThis.get("_objCandidateProfileEnumsService").getList("transportType");
            var arrDBSWorkforceOptions = objThis.get("_objCandidateProfileEnumsService").getList("dbsWorforce");

            var arrCandidateTypesDropdown = [];
            var arrCandidateTypeIds = [];
            for (var i = 0; i < arrCandidateTypes.length; i++) {
                arrCandidateTypeIds.push(arrCandidateTypes[i]._id);
                arrCandidateTypesDropdown.push({
                    sTitle: arrCandidateTypes[i].name,
                    sValue: arrCandidateTypes[i]._id
                });
            }

            var arrStatusesDropdown = [];
            for (var j = 0; j < arrCandidateStatus.length; j++) {
                arrStatusesDropdown.push({
                    sTitle: arrCandidateStatus[j].name,
                    sValue: arrCandidateStatus[j].name // This is done to comply with api
                });
            }

            var arrLanguagesDropdown = [];
            for (var k = 0; k < arrLanguageOptions.length; k++) {
                arrLanguagesDropdown.push({
                    sTitle: arrLanguageOptions[k].sLabel,
                    sValue: arrLanguageOptions[k].sLabel // This is done to comply with api
                });
            }

            var arrActivityTypeDropdown = [];
            for (var l = 0; l < arrActivityTypes.length; l++) {
                arrActivityTypeDropdown.push({
                    sTitle: arrActivityTypes[l].name,
                    sValue: arrActivityTypes[l].code
                });
            }

            var arrCandidateStatusesDropdown = [];
            arrCandidateStatusesDropdown.push({
                sTitle: objThis.get("_objEmberLibString").getString("empty"),
                sValue: "null"
            });
            for (var r = 0; r < arrCandidateStatus.length; r++) {
                arrCandidateStatusesDropdown.push({
                    sTitle: arrCandidateStatus[r].name,
                    sValue: arrCandidateStatus[r].name // This is done to comply with api
                });
            }

            var arrMediaTypesDropdown = [];
            for (var s = 0; s < arrMediumTypeOptions.length; s++) {
                arrMediaTypesDropdown.push({
                    sTitle: arrMediumTypeOptions[s].sLabel,
                    sValue: arrMediumTypeOptions[s].sValue
                });
            }

            var arrTransportOptionsDropdown = [];
            for (var t = 0; t < arrTransportOptions.length; t++) {
                arrTransportOptionsDropdown.push({
                    sTitle: arrTransportOptions[t].sLabel,
                    sValue: arrTransportOptions[t].sValue
                });
            }
            var arrExternalDocumentsDropdown = [];
            for (var u = 0; u < arrExternalDocumentTypes.length; u++) {
                arrExternalDocumentsDropdown.push({
                    name: arrExternalDocumentTypes[u].name,
                    _id: arrExternalDocumentTypes[u].metadata.agency_group_lookup_id
                });
            }

            var arrExternalVerificationDropdown = [];
            for (var m = 0; m < arrExternalVerificationTypes.length; m++) {
                if (arrExternalVerificationTypes[m].name.toLowerCase() !== "limited work hours visa") {
                    arrExternalVerificationDropdown.push({
                        sTitle: arrExternalVerificationTypes[m].name,
                        sValue: arrExternalVerificationTypes[m].metadata.agency_group_lookup_id
                    });
                }
            }

            var arrExternalDaysToExpiryDropdown = [];
            for (var o = 0; o < arrExternalDaysTypes.length; o++) {
                arrExternalDaysToExpiryDropdown.push({
                    name: arrExternalDaysTypes[o].name,
                    _id: arrExternalDaysTypes[o].metadata.agency_group_lookup_id
                });
            }

            var arrDBSWorkforceDropdown = [];
            for (var p = 0; p < arrDBSWorkforceOptions.length; p++) {
                arrDBSWorkforceDropdown.push({
                    sTitle: arrDBSWorkforceOptions[p].sLabel,
                    sValue: arrDBSWorkforceOptions[p].sValue
                });
            }

            var arrDefaultComplianceOptionsDropdown = [{
                sValue: "1",
                sTitle: this.get("_objCandidateProfileStringsService").getString("compliant")
            }, {
                sValue: "0",
                sTitle: this.get("_objCandidateProfileStringsService").getString("notCompliant")
            }, {
                sValue: "-1",
                sTitle: this.get("_objCandidateProfileStringsService").getString("notApplicable")
            }];

            var arrQueryType = this.get("_objEnumEmberLibService").getList("filterOptionsTextType");
            var objConstants = this.get("_objConstantsLib");
            arrQueryType.push({
                "sValue": objConstants.CARD_DATAGRID_FILTER_QUERY_TYPE_CONTAINS,
                "sLabel": this.get("_objStringsService").getString("contains")
            });

            var sCountryId = this.get("_objUserSession.objContextDetails.sCountryId");
            var sCountryCode = sCountryId === "710" ? "ZA" : sCountryId === "840" ? "US" : "GB";

            // This array contain the datagrid columns
            // Some filters were removed where users can make use of the global search @see https://github.com/A24Group/react-core/issues/1396
            var arrDataGridHeaderConfig = [{
                iUniqueColNum: 1,
                sTitle: this.get("_objSSCoreStringsService").getString("fullName"),
                sProperty: "full_name",
                sFilterType: "text",
                bText: true,
                iWidth: 15,
                bIsClickable: true,
                bFilterOr: true,
                arrQueryType: Ember.copy(arrQueryType, true)
            }, {
                iUniqueColNum: 2,
                sTitle: this.get("_objStringsService").getString("firstName"),
                sProperty: "first_name",
                bText: true,
                iWidth: 8,
                bIsClickable: true,
                bStopColumnSort: true,
                bStopFiltering: true
            }, {
                iUniqueColNum: 3,
                sTitle: this.get("_objStringsService").getString("lastName"),
                sProperty: "last_name",
                bText: true,
                iWidth: 8,
                bIsClickable: true,
                bStopColumnSort: true,
                bStopFiltering: true
            }, {
                iUniqueColNum: 4,
                sTitle: this.get("_objStringsService").getString("gender"),
                sProperty: "gender",
                sFilterType: "dropdownMulti",
                bMapping: true,
                arrMappingData: [{
                    sValue: "male",
                    sTitle: this.get("_objStringsService").getString("male")
                }, {
                    sValue: "female",
                    sTitle: this.get("_objStringsService").getString("female")
                }],
                bStopColumnSort: true,
                iWidth: 8
            }, {
                iUniqueColNum: 5,
                sTitle: this.get("_objSSCoreStringsService").getString("type"),
                sProperty: "candidate_type_id",
                sFilterType: "dropdownMulti",
                bMapping: true,
                arrMappingData: arrCandidateTypesDropdown,
                iWidth: 15
            }, {
                iUniqueColNum: 6,
                sTitle: this.get("_objCandidateProfileStringsService").getString("currentStatus"),
                sProperty: "current_status__status",
                sFilterType: "dropdownMulti",
                bMapping: true,
                arrMappingData: arrCandidateStatusesDropdown,
                iWidth: 15
            }, {
                iUniqueColNum: 7,
                sTitle: this.get("_objCandidateProfileStringsService").getString("currentStatusSince"),
                sProperty: "current_status__date",
                sFilterType: "date",
                bDate: true,
                iWidth: 15,
                bStopColumnSort: true
            }, {
                iUniqueColNum: 8,
                sTitle: this.get("_objCandidateProfileStringsService").getString("linkedToAgencySince"),
                sProperty: "linked_to_agency_at",
                sFilterType: "date",
                bDate: true,
                iWidth: 15
            }, {
                iUniqueColNum: 9,
                sTitle: this.get("_objSSAgencyStringsService").getString("city"),
                sProperty: "city",
                sFilterType: "text",
                bText: true,
                iWidth: 15,
                bFilterOr: true,
                arrQueryType: Ember.copy(arrQueryType, true)
                // NOTE: We are reverting this to a text filter due to garbage data
                // but in the future we will change this back to a suggest thus its
                // commented out and not removed
                /*
                sFilterOptionsValueKey: "name",
                sFilterOptionsLabelKey: "name",
                iFilterSuggestAt: 3,
                iFilterSuggestDelay: 500,
                bFilterShowLoaderDuringSuggest: true,
                bFilterAlwaysShowOnFocus: false,
                funcFilterGetSuggestions: (sSuggestVal, funcCallback) => {
                    var objQuery = {
                        search_term: {
                             mValue: sSuggestVal
                        },
                        size: {
                            mValue: 10
                        },
                    };
                     this.get("_objAgencyAutocompleteService").getCities(
                        this,
                        "_objGetCitySuggestions",
                        objQuery,
                        (arrData) => {
                            // If the data is empty, ensure it's an empty array
                            if (a24Core.isEmpty(arrData)) {
                                 arrData = [];
                            }
                             let arrReformatted = [];
                            if (!a24Core.isEmpty(arrData["results"])) {
                                arrData["results"].forEach(function(sValue) {
                                    let objCity = {
                                        name: sValue
                                    };
                                    arrReformatted.pushObject(objCity);
                                });
                            }
                             // Call the callback with the formatted data
                            funcCallback(arrReformatted);
                        },
                        a24RestCallHelper.createJsonAjaxFailureObject(
                            ["*", "*CODE", "*MALFORMED"],
                            (sCode, sStatus, objErrorThrown, objError) => {
                                // Call the callback with empty
                                funcCallback([]);
                            }
                        )
                    );
                }
                */
            }, {
                iUniqueColNum: 10,
                sTitle: this.get("_objStringsService").getLocalisedString("province", sCountryCode),
                sProperty: "province",
                sFilterType: "text",
                bText: true,
                iWidth: 15,
                bFilterOr: true,
                arrQueryType: Ember.copy(arrQueryType, true)
                // NOTE: We are reverting this to a text filter due to garbage data
                // but in the future we will change this back to a suggest thus its
                // commented out and not removed
                /*
                sFilterOptionsValueKey: "name",
                sFilterOptionsLabelKey: "name",
                iFilterSuggestAt: 3,
                iFilterSuggestDelay: 500,
                bFilterShowLoaderDuringSuggest: true,
                bFilterAlwaysShowOnFocus: false,
                funcFilterGetSuggestions: (sSuggestVal, funcCallback) => {
                    var objQuery = {
                        search_term: {
                             mValue: sSuggestVal
                        },
                        size: {
                            mValue: 10
                        }
                    };
                     this.get("_objAgencyAutocompleteService").getCounties(
                        this,
                        "_objGetCountySuggestions",
                        objQuery,
                        (arrData) => {
                            // If the data is empty, ensure it's an empty array
                            if (a24Core.isEmpty(arrData)) {
                                 arrData = [];
                            }
                             let arrReformatted = [];
                            if (!a24Core.isEmpty(arrData["results"])) {
                                arrData["results"].forEach(function(sValue) {
                                    let objCounty = {
                                        name: sValue
                                    };
                                    arrReformatted.pushObject(objCounty);
                                });
                            }
                             // Call the callback with the formatted data
                            funcCallback(arrReformatted);
                        },
                        a24RestCallHelper.createJsonAjaxFailureObject(
                            ["*", "*CODE", "*MALFORMED"],
                            (sCode, sStatus, objErrorThrown, objError) => {
                                // Call the callback with empty
                                funcCallback([]);
                            }
                        )
                    );
                }
                */
            }, {
                iUniqueColNum: 11,
                sTitle: this.get("_objStringsService").getLocalisedString("postCode", sCountryCode),
                sProperty: "post_code",
                sFilterType: "text",
                bText: true,
                iWidth: 8,
                bFilterOr: true,
                arrQueryType: Ember.copy(arrQueryType, true)
            }, {
                iUniqueColNum: 12,
                sTitle: this.get("_objStringsService").getString("username"),
                sProperty: "username",
                sFilterType: "text",
                bText: true,
                iWidth: 8,
                bFilterOr: true,
                arrQueryType: Ember.copy(arrQueryType, true),
                bStopColumnSort: true
            }, {
                iUniqueColNum: 16,
                sTitle: this.get("_objCandidateProfileStringsService").getString("currentPayrollNumber"),
                sProperty: "current_employment_reference",
                bText: true,
                iWidth: 8,
                bStopFiltering: true,
                bStopColumnSort: true
            }, {
                iUniqueColNum: 36,
                sTitle: this.get("_objCandidateProfileStringsService").getString("historicalPayrollNumber"),
                sProperty: "employment_references___reference",
                sFilterType: "text",
                bSilent: true,
                bText: true,
                iWidth: 8,
                bFilterOr: true,
                arrQueryType: Ember.copy(arrQueryType, true)
            }, {
                iUniqueColNum: 15,
                sTitle: this.get("_objStringsService").getString("language"),
                sProperty: "languages___name",
                sFilterType: "dropdown",
                bSilent: true,
                bMapping: true,
                arrMappingData: arrLanguagesDropdown,
                iWidth: 8,
                bFilterSecondary: true
            }, {
                iUniqueColNum: 17,
                sTitle: this.get("_objSSAgencyStringsService").getString("availabilityLastUpdated"),
                sProperty: "availability_last_updated_at",
                sFilterType: "date",
                bDate: true,
                iWidth: 15
            }, {
                iUniqueColNum: 18,
                sTitle: this.get("_objSSAgencyStringsService").getString("availability"),
                sProperty: "availability___status",
                sFilterType: "custom",
                bStopColumnSort: true,
                iWidth: 15,
                bFilterAnd: true,
                bFilterOr: true,
                iMinWidth: 220,

                bCustom: true,
                sTableCustomComponent: "card-datagrid-table-cell-custom-availability",

                sFilterCustomComponentName: "card-datagrid-custom-filter-availability",
                objFilterCustomService: this.get("_objFilterCustomService")
            }, {
                iUniqueColNum: 19,
                sTitle: this.get("_objCandidateProfileStringsService").getString("distance"),
                sProperty: "physical_address_geo_location",
                bText: true,
                sFilterType: "custom",
                bSilent: true,
                iWidth: 15,
                bHideEmptyCheck: true,
                sContext: this.get("_objStaffshiftCoreConstants.CONTEXT_AGENCY"),
                objLinkedClientsService: this.get("_objLinkedClientsService"),
                sFilterCustomComponentName: "card-datagrid-custom-filter-candidate-distance",
                objFilterCustomService: this.get("_objFilterCustomCandidateDistanceService")
            }, {
                iUniqueColNum: 20,
                sTitle: this.get("_objSSAgencyStringsService").getString("positionHeld"),
                sProperty: "employment_position_held___job_family_id",
                bText: true,
                sFilterType: "custom",
                bSilent: true,
                iWidth: 15,
                bFilterAnd: true,
                bFilterOr: true,
                sReactFilterRoute: "datagrid/filterEditor/positionHeld",

                sFilterCustomComponentName: "card-datagrid-custom-filter-candidate-position-held",
                objFilterCustomService: this.get("_objFilterCustomCandidatePositionHeldService")
            }, {
                iUniqueColNum: 21,
                sTitle: this.get("_objSSCoreStringsService").getString("lastShiftDate"),
                sProperty: "last_shift_worked_at",
                sFilterType: "date",
                bDate: true,
                iWidth: 15
            }, {
                iUniqueColNum: 22,
                sTitle: this.get("_objSSCoreStringsService").getString("nextShiftDate"),
                sProperty: "next_shift_at",
                sFilterType: "date",
                bDate: true,
                iWidth: 15
            }, {
                iUniqueColNum: 23,
                sTitle: this.get("_objSSCoreStringsService").getString("lastLoggedIn"),
                sProperty: "last_login_at",
                sFilterType: "date",
                bDate: true,
                iWidth: 15,
                bStopColumnSort: true
            }, {
                iUniqueColNum: 25,
                sTitle: this.get("_objCandidateProfileStringsService").getString("activities"),
                sProperty: "activities___activity_type",
                sFilterType: "suggest",
                arrMappingData: arrActivityTypeDropdown,
                iWidth: 8,
                bMapping: true,
                bSilent: true,
                bFilterAnd: true,
                bFilterOr: true,
                bFilterSecondary: true,
                sReactFilterRoute: "datagrid/filterEditor/activities",
                funcFilterParseFilterValue: function funcFilterParseFilterValue(objFilter) {
                    var objFitlerCopy = Ember.copy(objFilter, true);

                    var _loop = function _loop(_i) {
                        var objActivityType = arrActivityTypeDropdown.find(function (activity) {
                            return activity.sValue === objFitlerCopy.mFilterValue[_i].mValue;
                        });
                        if (!a24Core.isEmpty(objActivityType)) {
                            objFitlerCopy.mFilterValue[_i].sFilterValue = objActivityType.sTitle;
                        } else {
                            objFitlerCopy.mFilterValue[_i].sFilterValue = objFitlerCopy.mFilterValue[_i].mValue;
                        }
                    };

                    for (var _i = 0; _i < objFitlerCopy.mFilterValue.length; _i++) {
                        _loop(_i);
                    }
                    return objFitlerCopy;
                }
            }, {
                iUniqueColNum: 35,
                sTitle: this.get("_objCandidateProfileStringsService").getString("tags"),
                sProperty: "tags_links___tag_id",
                sFilterType: "suggest",
                sReactFilterRoute: "datagrid/filterEditor/candidate-tags",
                iWidth: 15,
                bSilent: true,
                bFilterAnd: true,
                bFilterOr: true
            }, {
                iUniqueColNum: 26,
                sTitle: this.get("_objCandidateProfileStringsService").getString("skill"),
                sProperty: "skill_details___skill_id",
                sFilterType: "suggest",
                sReactFilterRoute: "datagrid/filterEditor/skills",
                //Whatever property is listed in the arrReactFilterExtraDataPropswill be pulled from
                //_objDatagridConfig config and send on to react popup
                arrReactFilterExtraDataProps: ["candidate_types"],
                iWidth: 15,
                bSilent: true,
                bFilterAnd: true,
                bFilterOr: true,
                bFilterSecondary: true,
                funcFilterParseQueryValue: function funcFilterParseQueryValue(objQueryObject) {
                    var objNewQueryObject = Ember.copy(objQueryObject, true);

                    for (var _i2 = 0; _i2 < objNewQueryObject.length; _i2++) {
                        if (objNewQueryObject[_i2].mValue === "*") {
                            objNewQueryObject[_i2].bAny = true;
                        }
                        if (!a24Core.isEmpty(objNewQueryObject[_i2].arSec)) {
                            for (var _j = 0; _j < objNewQueryObject[_i2].arSec.length; _j++) {
                                if (objNewQueryObject[_i2].arSec[_j].sProp === "duration") {
                                    objNewQueryObject[_i2].arSec[_j].mValue = Math.ceil(objNewQueryObject[_i2].arSec[_j].mValue * 12);
                                }
                            }
                        }
                    }

                    return objNewQueryObject;
                }
            }, {
                iUniqueColNum: 27,
                sTitle: this.get("_objCandidateProfileStringsService").getString("qualification"),
                sProperty: "qualification_details___qualification_id",
                sFilterType: "suggest",
                sReactFilterRoute: "datagrid/filterEditor/qualifications",
                //Whatever property is listed in the arrReactFilterExtraDataProps will be pulled from
                //_objDatagridConfig config and send on to react popup
                arrReactFilterExtraDataProps: ["candidate_types"],
                iWidth: 15,
                bSilent: true,
                bFilterAnd: true,
                bFilterOr: true,
                bFilterSecondary: true,
                funcFilterParseQueryValue: function funcFilterParseQueryValue(objQueryObjectParam) {
                    var objQueryObject = Ember.copy(objQueryObjectParam, true);
                    for (var _i3 = 0; _i3 < objQueryObject.length; _i3++) {
                        if (objQueryObject[_i3].mValue === "*") {
                            objQueryObject[_i3].bAny = true;
                        }
                    }

                    return objQueryObject;
                }
            }, {
                iUniqueColNum: 28,
                sTitle: this.get("_objCandidateProfileStringsService").getString("professionalRegistration"),
                sProperty: "registration_body_details___registration_body_id",
                bText: true,
                sFilterType: "custom",
                sReactFilterRoute: "datagrid/filterEditor/professional-registration",
                //Whatever property is listed in the arrReactFilterExtraDataProps will be pulled from
                //_objDatagridConfig config and send on to react popup
                arrReactFilterExtraDataProps: ["candidate_types"],
                bSilent: true,
                bFilterAnd: true,
                bFilterOr: true,
                iWidth: 15,
                bHideEmptyCheck: true,
                sFilterCustomComponentName: "card-datagrid-custom-filter-candidate-professional-registration",
                objFilterCustomService: this.get("_objFilterCustomCandidateProfessionalRegistrationService")
            }, {
                iUniqueColNum: 33,
                sTitle: this.get("_objCandidateProfileStringsService").getString("profRegEntries"),
                sProperty: "registration_body_details___registration_body_entry_id",
                bText: true,
                sFilterType: "custom",
                sReactFilterRoute: "datagrid/filterEditor/professional-registration-entry",
                //Whatever property is listed in the arrReactFilterExtraDataProps will be pulled from
                //_objDatagridConfig config and send on to react popup
                arrReactFilterExtraDataProps: ["candidate_types"],
                bSilent: true,
                bFilterAnd: true,
                bFilterOr: true,
                iWidth: 15,
                bHideEmptyCheck: true,
                sFilterCustomComponentName: "card-datagrid-custom-filter-candidate-professional-registration",
                objFilterCustomService: this.get("_objFilterCustomCandidateProfessionalRegistrationService")
            }, {
                iUniqueColNum: 29,
                sTitle: this.get("_objSSCoreStringsService").getString("keywords"),
                sProperty: "keywords",
                sFilterType: "suggest",
                sReactFilterRoute: "datagrid/filterEditor/keywords",
                arrReactFilterExtraDataProps: ["candidate_types"],
                iWidth: 15,
                bSilent: true,
                bFilterAnd: true,
                bFilterOr: true
            }, {
                iUniqueColNum: 30,
                sTitle: this.get("_objCandidateProfileStringsService").getString("lastSeenInApp"),
                sProperty: "chat_token_last_issued_at",
                sFilterType: "date",
                bDate: true,
                iWidth: 15,
                bStopColumnSort: true
            }, {
                iUniqueColNum: 31,
                sTitle: this.get("_objCandidateProfileStringsService").getString("doNotContact"),
                sProperty: "notification_dnc",
                sFilterType: "bool",
                bHideEmptyCheck: true,
                bBool: true,
                bMapping: true,
                arrMappingData: [{
                    sValue: "true",
                    sTitle: this.get("_objStringsService").getString("yes")
                }, {
                    sValue: "false",
                    sTitle: this.get("_objStringsService").getString("no")
                }],
                iWidth: 8,
                bStopColumnSort: true
            }, {
                iUniqueColNum: 32,
                sTitle: this.get("_objCandidateProfileStringsService").getString("notificationPreference"),
                sProperty: "medium_dnc___type",
                sFilterType: "dropdown",
                bFilterAnd: true,
                bFilterOr: true,
                bFilterSecondary: true,
                bMapping: false,
                arrMappingData: arrMediaTypesDropdown,
                bCustom: true,
                arrQueryType: [{
                    sLabel: this.get("_objStringsService").getString("is"),
                    sValue: this.get("_objConstantsLib").CARD_DATAGRID_FILTER_QUERY_TYPE_IS
                }],
                bStopColumnSort: true,
                funcTableCustomVal: function funcTableCustomVal(objHeader, objRowData) {
                    var sMediumTypes = "";
                    if (!a24Core.isEmpty(objRowData.medium_dnc)) {
                        var arrRowMediumDncData = objRowData.medium_dnc;
                        for (var _i4 = 0; _i4 < arrRowMediumDncData.length; _i4++) {
                            if (!arrRowMediumDncData[_i4].value) {
                                var sMediumType = _this4.get("_objCandidateProfileEnumsService").getFrontendValue("mediumTypeDnc", arrRowMediumDncData[_i4].type);
                                if (a24Core.isEmpty(sMediumType)) {
                                    sMediumType = arrRowMediumDncData[_i4].type;
                                }
                                if (!a24Core.isEmpty(sMediumTypes)) {
                                    sMediumTypes += ", ";
                                }
                                sMediumTypes += sMediumType;
                            }
                        }
                    }
                    return Ember.String.htmlSafe("<span title=\"" + sMediumTypes + "\">" + sMediumTypes + "</span>");
                },
                iWidth: 15
            }, {
                iUniqueColNum: 34,
                sTitle: this.get("_objCandidateProfileStringsService").getString("transport"),
                sProperty: "transport_type",
                sFilterType: "dropdown",
                bHideEmptyCheck: false,
                bSilent: true,
                bMapping: false,
                arrMappingData: arrTransportOptionsDropdown,
                iWidth: 8
            }, {
                iUniqueColNum: 37,
                sTitle: this.get("_objCandidateProfileStringsService").getString("complianceRule"),
                sProperty: "compliance_rule___rule_id",
                sFilterType: "suggest",
                sReactFilterRoute: "datagrid/filterEditor/complianceRule",
                //Whatever property is listed in the arrReactFilterExtraDataPropswill be pulled from
                //_objDatagridConfig config and send on to react popup
                arrReactFilterExtraDataProps: ["candidate_types"],
                iWidth: 15,
                bSilent: true,
                bFilterAnd: true,
                bFilterOr: true,
                bFilterSecondary: true,
                funcFilterParseQueryValue: function funcFilterParseQueryValue(arrQuery) {
                    var arrQueryObject = Ember.copy(arrQuery, true);

                    for (var _l = 0; _l < arrQueryObject.length; _l++) {
                        for (var _i5 = 0; _i5 < arrQueryObject[_l].arSec.length; _i5++) {
                            if (arrQueryObject[_l].arSec[_i5].sProp === "rule_output_change_date_lapse" || arrQueryObject[_l].arSec[_i5].sProp === "rule_output_change_date_compliant") {
                                arrQueryObject[_l].arSec[_i5].sProp = "rule_output_change_date";
                            }
                        }
                    }

                    return arrQueryObject;
                }
            }, {
                iUniqueColNum: 38,
                sTitle: this.get("_objCandidateProfileStringsService").getString("complianceContract"),
                sProperty: "compliance_contract___contract_id",
                sFilterType: "suggest",
                sReactFilterRoute: "datagrid/filterEditor/complianceContract",
                //Whatever property is listed in the arrReactFilterExtraDataPropswill be pulled from
                //_objDatagridConfig config and send on to react popup
                arrReactFilterExtraDataProps: ["candidate_types"],
                iWidth: 15,
                bSilent: true,
                bFilterAnd: true,
                bFilterOr: true,
                bFilterSecondary: true
            }, {
                iUniqueColNum: 43,
                sTitle: this.get("_objCandidateProfileStringsService").getString("defaultCompliance"),
                sProperty: "compliance_default_output",
                sFilterType: "dropdown",
                bHideEmptyCheck: true,
                bSilent: true,
                bMapping: false,
                funcFilterParseQueryValue: function funcFilterParseQueryValue(objQueryObjectParam) {
                    var objQueryObject = Ember.copy(objQueryObjectParam, true);
                    objQueryObject.mValue = parseInt(objQueryObject.mValue);

                    return objQueryObject;
                },
                arrMappingData: arrDefaultComplianceOptionsDropdown,
                iWidth: 8
            }, {
                iUniqueColNum: 39,
                sTitle: this.get("_objCandidateProfileStringsService").getString("externalDocs"),
                sProperty: "external_documents___type_id",
                sFilterType: "suggest",
                bSilent: true,
                iWidth: 8,
                bFilterAnd: true,
                bFilterOr: true,
                bFilterSecondary: true,
                sFilterOptionsValueKey: "_id",
                sFilterOptionsLabelKey: "name",
                iFilterSuggestAt: 0,
                iFilterSuggestDelay: 0,
                bFilterShowLoaderDuringSuggest: false,
                bFilterAlwaysShowOnFocus: true,
                funcFilterGetSuggestions: function funcFilterGetSuggestions(sSuggestVal, funcCallback) {
                    funcCallback(arrExternalDocumentsDropdown.filter(function (objExternalDoc) {
                        return objExternalDoc.name.toLowerCase().indexOf(sSuggestVal.trim().toLowerCase()) !== -1;
                    }));
                }
            },
            // Removed in https://github.com/A24Group/react-core/issues/2491
            // {
            //     iUniqueColNum: 40,
            //     sTitle: this.get("_objCandidateProfileStringsService").getString("externalPortfolio"),
            //     sProperty: "external_portfolios___portfolio_id",
            //     bText: true,
            //     sFilterType: "custom",
            //     bSilent: true,
            //     iWidth: 15,
            //     bFilterAnd: true,
            //     bFilterOr: true,
            //     arrConsultantTypes: arrConsultantTypesDropdown,
            //     sContext: this.get("_objStaffshiftCoreConstants.CONTEXT_AGENCY"),
            //     sFilterCustomComponentName: "card-datagrid-custom-filter-candidate-external-portfolios",
            //     objFilterCustomService: this.get("_objFilterCustomCandidateExternalPortfoliosService")
            // },
            {
                iUniqueColNum: 41,
                sTitle: this.get("_objCandidateProfileStringsService").getString("externalVerification"),
                sProperty: "external_verifications___type_id",
                sFilterType: "dropdown",
                bSilent: true,
                bMapping: true,
                arrMappingData: arrExternalVerificationDropdown,
                iWidth: 8,
                bFilterAnd: true,
                bFilterOr: true,
                bFilterSecondary: true
            },
            // Commented out for this ticket https://github.com/A24Group/react-core/issues/2994
            // {
            //     iUniqueColNum: 42,
            //     sTitle: this.get("_objCandidateProfileStringsService").getString("externalDaysToExpiry"),
            //     sProperty: "external_days_to_expiry___type_id",
            //     bText: true,
            //     sFilterType: "custom",
            //     bSilent: true,
            //     iWidth: 15,
            //     bFilterAnd: true,
            //     bFilterOr: true,
            //     arrExternalDaysTypes: arrExternalDaysToExpiryDropdown,
            //     sContext: this.get("_objStaffshiftCoreConstants.CONTEXT_AGENCY"),
            //     sFilterCustomComponentName: "card-datagrid-custom-filter-candidate-external-days-to-expiry",
            //     objFilterCustomService: this.get("_objFilterCustomCandidateExternalDaysToExpiryService")
            // },
            {
                iUniqueColNum: 47,
                sTitle: this.get("_objCandidateProfileStringsService").getString("verifiedDocuments"),
                sProperty: "allocated_agency_group_verified_documents___document_type_id",
                sFilterType: "suggest",
                sReactFilterRoute: "datagrid/filterEditor/verifiedDocuments",
                bSilent: true,
                iWidth: 8,
                bFilterAnd: true,
                bFilterOr: true,
                bFilterSecondary: true
            }, {
                iUniqueColNum: 48,
                sTitle: this.get("_objCandidateProfileStringsService").getString("documentsWaitingForVerification"),
                sProperty: "has_pending_agency_group_verification_documents",
                sFilterType: "bool",
                bSilent: true,
                bHideEmptyCheck: true,
                bBool: true,
                bMapping: true,
                arrMappingData: [{
                    sValue: "true",
                    sTitle: this.get("_objStringsService").getString("yes")
                }, {
                    sValue: "false",
                    sTitle: this.get("_objStringsService").getString("no")
                }],
                iWidth: 8
            }, {
                iUniqueColNum: 49,
                sTitle: this.get("_objSSAgencyStringsService").getString("limitedWorkHoursVisa"),
                sProperty: "is_limited_work_hours_visa",
                sFilterType: "dropdown",
                bHideEmptyCheck: true,
                bMapping: true,
                bSilent: true,
                arrMappingData: [{
                    sValue: this.get("_objStringsService").getString("yes").toLowerCase(),
                    sTitle: this.get("_objStringsService").getString("yes")
                }, {
                    sValue: this.get("_objStringsService").getString("no").toLowerCase(),
                    sTitle: this.get("_objStringsService").getString("no")
                }],
                iWidth: 8,
                bStopColumnSort: true
            }];

            var arrProficiencyDropdown = this.get("_objCandidateProfileEnumsService").getList("languageProficiency").map(function (objProficiency) {
                return {
                    sTitle: objProficiency.sLabel,
                    sValue: objProficiency.sValue
                };
            });

            var arrSkillsStatusesDropdown = this.get("_objCandidateProfileEnumsService").getList("skillsStatusSearch").map(function (objSkillStatus) {
                return {
                    sTitle: objSkillStatus.sLabel,
                    sValue: objSkillStatus.sValue
                };
            });

            var arrQualificationsStatusesDropdown = this.get("_objCandidateProfileEnumsService").getList("qualificationsStatus").map(function (objQualificationsStatus) {
                return {
                    sTitle: objQualificationsStatus.sLabel,
                    sValue: objQualificationsStatus.sValue
                };
            });

            if (this.get("_objUserSession.objContextDetails.sCountryId") === "826") {
                arrDataGridHeaderConfig.push({
                    iUniqueColNum: 44,
                    sTitle: this.get("_objCandidateProfileStringsService").getString("externalWorkforce"),
                    sProperty: "external_workforce",
                    sFilterType: "dropdownMulti",
                    bMapping: true,
                    arrMappingData: arrDBSWorkforceDropdown,
                    iWidth: 15,
                    bStopColumnSort: true
                });
            }

            var sDatagridHeader = "";
            if (!a24Core.isEmpty(objShiftWithOrderDetails)) {
                var arrCandidatesIds = arrAgencyClientExclusions.map(function (objExclusion) {
                    return objExclusion.candidate_id;
                }).filter(function (sValue, sIndex, arrIds) {
                    return arrIds.indexOf(sValue) === sIndex;
                });
                arrDataGridHeaderConfig.unshift({
                    iUniqueColNum: 45,
                    sTitle: this.get("_objSSAgencyStringsService").getString("hideExcludedCandidates"),
                    sProperty: "candidate_id",
                    sFilterType: "bool",
                    bHideEmptyCheck: true,
                    iWidth: 15,
                    bSilent: true,
                    bStopColumnSort: true,
                    bBool: true,
                    bMapping: true,
                    arrMappingData: [{
                        sValue: "true",
                        sTitle: this.get("_objStringsService").getString("yes")
                    }],
                    funcFilterParseQueryValue: function funcFilterParseQueryValue(objQueryObjectParam) {
                        var objQueryObject = Ember.copy(objQueryObjectParam, true);
                        objQueryObject.mValue = arrCandidatesIds.join("|");
                        objQueryObject.bDelimitedNot = true;

                        return objQueryObject;
                    }
                });

                this.set("objQueryParams.candidate_id.sValue", "true");
                //Header example: Possible Candidates for Order #1234 - Site Name - Ward Name
                sDatagridHeader = this.get("_objSSAgencyStringsService").getString("possibleCandidatesForOrder") + objShiftWithOrderDetails.order_reference + " - " + objShiftWithOrderDetails.site_name;

                if (!a24Core.isEmpty(objShiftWithOrderDetails.ward_name)) {
                    sDatagridHeader += " - " + objShiftWithOrderDetails.ward_name;
                }

                if (a24Core.isEmpty(objShiftWithOrderDetails.site_geo_location) || a24Core.isEmpty(objShiftWithOrderDetails.site_geo_location.coordinates)) {
                    var objCity = {
                        bEncode: true,
                        mValue: objShiftWithOrderDetails.city
                        // sFilterValue: objShiftWithOrderDetails.city
                    };

                    this.set("objQueryParams.city.sValue", btoa(JSON.stringify([objCity])));
                } else {
                    var sUnit = this.get("_objSSAgencyEnumsService.distanceUnitOptions.KM.backend");
                    if (this.get("_objUserSession.objContextDetails.sCountryId") === "826" || this.get("_objUserSession.objContextDetails.sCountryId") === "840") {
                        sUnit = this.get("_objSSAgencyEnumsService.distanceUnitOptions.MILES.backend");
                    }
                    var objGeo = {
                        bWithin: true,
                        sUnit: sUnit,
                        sLocNme: "",
                        fLong: objShiftWithOrderDetails.site_geo_location.coordinates[0],
                        fLat: objShiftWithOrderDetails.site_geo_location.coordinates[1],
                        iMax: 48280,
                        sSite: objShiftWithOrderDetails.site_name,
                        sOrg: objShiftWithOrderDetails.organisation_name
                    };

                    this.set("objQueryParams.physical_address_geo_location.sValue", btoa(JSON.stringify([objGeo])));
                }

                if (!a24Core.isEmpty(arrAgencyClients) && arrAgencyClients.length === 1 && !a24Core.isEmpty(arrAgencyClients[0].compliance_contract_id)) {
                    var objComplianceContract = {
                        bEncode: true,
                        mValue: arrAgencyClients[0].compliance_contract_id,
                        sFilterValue: arrAgencyClients[0].compliance_contract_name,
                        arSec: [{
                            sProp: "contract_output",
                            mValue: 1,
                            sFilterValue: this.get("_objSSAgencyStringsService").getString("isCompliant")
                        }, {
                            sProp: "compliance_contract_output_exemption_override",
                            mValue: true
                        }]
                    };
                    this.set("objQueryParams.compliance_contract___contract_id.sValue", btoa(JSON.stringify([objComplianceContract])));
                }
            } else {
                sDatagridHeader = this.get("_objSSCoreStringsService").getString("candidateSearch");
            }

            this._setDatagridActions();

            var objDatagridConfig = {
                sDatagridHeader: sDatagridHeader,
                iMaxHeight: 62 * 27,
                bSelectColumn: true,
                bEmitSelectRowEvent: true,
                bHasReloadButton: true,
                iResultCountLimit: 251,
                arrSingleItemSelectActions: this.get("_arrSingleActions"),
                arrMultiItemSelectActions: this.get("_arrMultiItemActions"),
                arrHeaderConfig: arrDataGridHeaderConfig, // This array contain the datagrid columns
                sReactIFrameUrl: _environment.default.sReactGatewayUrl,
                bEmitWillDoServiceCall: true,
                candidate_types: arrCandidateTypes,
                objSavedSearchService: this.get("_objAgencySavedSearchService"),
                sSavedSearchRef: "agency-candidate-search",
                sDatagridRoute: "agency-candidate-search",
                arrFilterSecondary_languages___name: [{
                    sTitle: this.get("_objCandidateProfileStringsService").getString("languageProficiency"),
                    sProperty: "proficiency",
                    sFilterType: "dropdown",
                    bHideEmptyCheck: true,
                    arrMappingData: arrProficiencyDropdown
                }],
                arrFilterSecondary_activities___activity_type: [{
                    sTitle: this.get("_objCandidateProfileStringsService").getString("lastOccurredAt"),
                    sProperty: "last_activity_at",
                    bHideEmptyCheck: true,
                    sFilterType: "date"
                }, {
                    sTitle: this.get("_objCandidateProfileStringsService").getString("numberOfOccurrences"),
                    sProperty: "total",
                    bHideEmptyCheck: true,
                    sFilterType: "int"
                }],
                arrFilterSecondary_employment_position_held___job_family_id: [{
                    sTitle: this.get("_objCandidateProfileStringsService").getString("jobTitle"),
                    sProperty: "job_title_id",
                    sFilterType: "suggest"
                }, {
                    sTitle: this.get("_objCandidateProfileStringsService").getString("months"),
                    sProperty: "overall_duration",
                    sFilterType: "int"
                }, {
                    sTitle: this.get("_objCandidateProfileStringsService").getString("months"),
                    sProperty: "status_total_time",
                    sFilterType: "int"
                }, {
                    sTitle: this.get("_objCandidateProfileStringsService").getString("months"),
                    sProperty: "status_last_end_date",
                    sFilterType: "date"
                }, {
                    sTitle: this.get("_objCandidateProfileStringsService").getString("status"),
                    sProperty: "status_name",
                    sFilterType: "suggest"
                }, {
                    sTitle: this.get("_objCandidateProfileStringsService").getString("workSuggest"),
                    sProperty: "work_suggestion_status",
                    sFilterType: "suggest"
                }],
                arrFilterSecondary_skill_details___skill_id: [{
                    sTitle: this.get("_objStringsService").getString("status"),
                    sProperty: "status",
                    bHideEmptyCheck: true,
                    sFilterType: "dropdown",
                    arrMappingData: arrSkillsStatusesDropdown,
                    funcFilterPrimaryChange: function funcFilterPrimaryChange(sPrimaryValue, objPrimary, objSecondary) {
                        Ember.set(objSecondary, "bFilterRequired", sPrimaryValue === "*||" + objThis.get("_objStringsService").getString("any"));
                    }
                }, {
                    sTitle: this.get("_objCandidateProfileStringsService").getString("yearsOfExperience"),
                    sProperty: "duration",
                    bHideEmptyCheck: true,
                    sFilterType: "int",
                    bFilterAllowFloat: true,
                    iFilterFloatPlaces: 1
                }],
                arrFilterSecondary_qualification_details___qualification_id: [{
                    sTitle: this.get("_objStringsService").getString("status"),
                    sProperty: "status",
                    bHideEmptyCheck: true,
                    sFilterType: "dropdown",
                    arrMappingData: arrQualificationsStatusesDropdown,
                    funcFilterPrimaryChange: function funcFilterPrimaryChange(sPrimaryValue, objPrimary, objSecondary) {
                        Ember.set(objSecondary, "bFilterRequired", sPrimaryValue === "*||" + objThis.get("_objStringsService").getString("any"));
                    }
                }, {
                    sTitle: this.get("_objCandidateProfileStringsService").getString("obtainedAt"),
                    sProperty: "obtained_at",
                    bHideEmptyCheck: true,
                    sFilterType: "date"
                }, {
                    sTitle: this.get("_objCandidateProfileStringsService").getString("expiresOn"),
                    sProperty: "expires_at",
                    bHideEmptyCheck: true,
                    sFilterType: "date"
                }],
                arrFilterSecondary_registration_body_details___registration_body_id: [{
                    sTitle: this.get("_objStringsService").getString("status"),
                    sProperty: "status_id",
                    sFilterType: "suggest"
                }, {
                    sTitle: this.get("_objCandidateProfileStringsService").getString("entry"),
                    sProperty: "registration_body_entry_id",
                    sFilterType: "suggest"
                }, {
                    sTitle: this.get("_objCandidateProfileStringsService").getString("entryStatus"),
                    sProperty: "registration_body_entry_status_id",
                    sFilterType: "suggest"
                }, {
                    sTitle: this.get("_objCandidateProfileStringsService").getString("expiresOn"),
                    sProperty: "expires_at",
                    sFilterType: "date",
                    bTimeless: true
                }, {
                    sTitle: this.get("_objCandidateProfileStringsService").getString("obtainedAt"),
                    sProperty: "obtained_at",
                    sFilterType: "date",
                    bTimeless: true
                }, {
                    sTitle: this.get("_objCandidateProfileStringsService").getString("validFrom"),
                    sProperty: "valid_at",
                    sFilterType: "date",
                    bTimeless: true
                }],
                arrFilterSecondary_registration_body_details___registration_body_entry_id: [{
                    sTitle: this.get("_objCandidateProfileStringsService").getString("professionalRegistration"),
                    sProperty: "registration_body_id",
                    sFilterType: "suggest",
                    bFilterHideTag: true
                }, {
                    sTitle: this.get("_objCandidateProfileStringsService").getString("entryStatus"),
                    sProperty: "registration_body_entry_status_id",
                    sFilterType: "suggest"
                }, {
                    sTitle: this.get("_objCandidateProfileStringsService").getString("expiresOn"),
                    sProperty: "expires_at",
                    sFilterType: "date",
                    bTimeless: true
                }, {
                    sTitle: this.get("_objCandidateProfileStringsService").getString("obtainedAt"),
                    sProperty: "obtained_at",
                    sFilterType: "date",
                    bTimeless: true
                }, {
                    sTitle: this.get("_objCandidateProfileStringsService").getString("validFrom"),
                    sProperty: "valid_at",
                    sFilterType: "date",
                    bTimeless: true
                }],
                arrFilterSecondary_medium_dnc___type: [{
                    sTitle: this.get("_objSSUsersStringsService").getString("enabled"),
                    sProperty: "value",
                    bHideEmptyCheck: true,
                    sFilterType: "bool",
                    bBool: true,
                    bFilterRequired: true,
                    arrMappingData: [{
                        sValue: "false",
                        sTitle: this.get("_objStringsService").getString("yes")
                    }, {
                        sValue: "true",
                        sTitle: this.get("_objStringsService").getString("no")
                    }]
                }],
                arrFilterSecondary_external_documents___type_id: [{
                    sTitle: this.get("_objCandidateProfileStringsService").getString("uploaded"),
                    sProperty: "date",
                    bHideEmptyCheck: true,
                    sFilterType: "date"
                }, {
                    sTitle: this.get("_objCandidateProfileStringsService").getString("expiry"),
                    sProperty: "expiry_date",
                    bHideEmptyCheck: true,
                    sFilterType: "date"
                }],
                arrFilterSecondary_external_verifications___type_id: [{
                    sTitle: this.get("_objCandidateProfileStringsService").getString("verified"),
                    sProperty: "value",
                    bHideEmptyCheck: true,
                    bFilterRequired: true,
                    sFilterType: "dropdown",
                    arrMappingData: [{
                        sValue: this.get("_objStringsService").getString("no"),
                        sTitle: this.get("_objStringsService").getString("no")
                    }, {
                        sValue: this.get("_objStringsService").getString("yes"),
                        sTitle: this.get("_objStringsService").getString("yes")
                    }]
                }],
                arrFilterSecondary_compliance_rule___rule_id: [{
                    sTitle: this.get("_objCandidateProfileStringsService").getString("candidate"),
                    sProperty: "rule_output",
                    sFilterType: "suggest"
                }, {
                    sTitle: this.get("_objCandidateProfileStringsService").getString("willLapse"),
                    sProperty: "rule_output_change_date_lapse",
                    sFilterType: "date"
                }, {
                    sTitle: this.get("_objCandidateProfileStringsService").getString("willStillBeCompliant"),
                    sProperty: "rule_output_change_date_compliant",
                    sFilterType: "date"
                }],
                arrFilterSecondary_compliance_contract___contract_id: [{
                    sTitle: this.get("_objCandidateProfileStringsService").getString("candidate"),
                    sProperty: "contract_output",
                    sFilterType: "suggest"
                }],
                arrFilterSecondary_allocated_agency_group_verified_documents___document_type_id: [{
                    sTitle: this.get("_objCandidateProfileStringsService").getString("verifiedAt"),
                    sProperty: "verified_at",
                    bHideEmptyCheck: true,
                    sFilterType: "date"
                }, {
                    sTitle: this.get("_objCandidateProfileStringsService").getString("issuedAt"),
                    sProperty: "issued_at",
                    bHideEmptyCheck: true,
                    sFilterType: "date"
                }, {
                    sTitle: this.get("_objCandidateProfileStringsService").getString("expireAt"),
                    sProperty: "expire_at",
                    bHideEmptyCheck: true,
                    sFilterType: "date"
                }],
                objPermanentQuery: {
                    // Required fields, either by api or UI components
                    agency_id: {
                        mValue: 1,
                        bProject: true
                    },
                    candidate_id: {
                        mValue: 1,
                        bProject: true
                    },
                    full_name: {
                        mValue: 1,
                        bProject: true
                    },
                    first_name: {
                        mValue: 1,
                        bProject: true
                    },
                    last_name: {
                        mValue: 1,
                        bProject: true
                    }

                    // Optional based on column visibility
                    // gender: 1,
                    // candidate_type_id: 1,
                    // city: 1,
                    // province: 1,
                    // post_code: 1,
                    // username: 1,
                    // current_status: 1,
                    // linked_to_agency_at: 1,
                    // employment_reference_number: 1,
                    // availability_last_updated_at: 1,
                    // availability: 1,
                    // last_shift_worked_at: 1,
                    // next_shift_at: 1,
                    // last_login_at: 1,
                    // chat_token_last_issued_at: 1,
                    // notification_dnc: 1,
                    // medium_dnc: 1,
                }
            };

            if (!a24Core.isEmpty(objShiftWithOrderDetails)) {
                objDatagridConfig.arrFilterSecondary_compliance_contract___contract_id.push({
                    sTitle: this.get("_objSSAgencyStringsService").getString("applyExemptions"),
                    sProperty: "compliance_contract_output_exemption_override",
                    sFilterType: "bool",
                    bBool: true,
                    arrMappingData: [{
                        sValue: "true",
                        sTitle: this.get("_objStringsService").getString("yes")
                    }, {
                        sValue: "false",
                        sTitle: this.get("_objStringsService").getString("no")
                    }]
                });
            }

            // Set up datagrid config
            this.set("_objDatagridConfig", objDatagridConfig);

            this.set("_bPageLoading", false);
        },
        onItemsSelect: function onItemsSelect(arrSelectedItems) {
            if (!a24Core.isEmpty(arrSelectedItems) && arrSelectedItems.length === 1) {
                this.set("_objSelectedItem", arrSelectedItems[0]);

                if (a24Core.isEmpty(this.get("_objSelectedRightSide")) || this.get("_objSelectedRightSide.sCandidateId") != arrSelectedItems[0].candidate_id) {
                    this.set("_objSelectedRightSide", {
                        sCandidateId: arrSelectedItems[0].candidate_id
                    });
                }
            } else {
                this.set("_objSelectedRightSide", null);
            }

            this.get("_objMenuService").funcRigthPanelOpen(!a24Core.isEmpty(this.get("_objSelectedRightSide")));

            // Set the selected items
            this.set("_arrSelectedItems", arrSelectedItems);
        },
        doCandidateLinkedInfoView: function doCandidateLinkedInfoView(objCandidate) {
            var _this5 = this;

            Ember.run.next(function () {
                // Open linked info popup
                _this5.set("_bLinkedInfoPopupLoading", true);
                _this5.set("_bShowLinkedInfoPopup", true);

                _this5.set("_sLinkedInfoHeaderPopup", _this5.get("_objCandidateProfileStringsService").getString("linkedInfo") + ": " + objCandidate.full_name);

                _this5.get("_objRestAgencyCandidateProfileAgencyInfo").getCandidateAgencyLinkedInfo(_this5, "_objAgencyCandidateProfileAgencyInfoPromise", objCandidate.candidate_id, function (objData) {
                    _this5.set("arrLinkedData", [{
                        arrItems: [{
                            sLabel: _this5.get("_objCandidateProfileStringsService").getString("employmentReferenceNumber"),
                            sValue: objData.sEmploymentReferenceNumber,
                            bText: true
                        }, {
                            sLabel: _this5.get("_objCandidateProfileStringsService").getString("emailOptOut"),
                            sValue: objData.bEmailOptOut ? _this5.get("_objStringsService").getString("yes") : _this5.get("_objStringsService").getString("no"),
                            bText: true
                        }]
                    }]);
                    _this5.set("_bLinkedInfoPopupLoading", false);
                }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () {
                    // This is the default values that should display for linked info for a candidate
                    // We can decide what we want to do incase it fails. This is just for display purpose now
                    _this5.set("arrLinkedData", [{
                        arrItems: [{
                            sLabel: _this5.get("_objCandidateProfileStringsService").getString("employmentReferenceNumber"),
                            sValue: null,
                            bText: true
                        }, {
                            sLabel: _this5.get("_objCandidateProfileStringsService").getString("emailOptOut"),
                            sValue: _this5.get("_objStringsService").getString("no"),
                            bText: true
                        }]
                    }]);
                    _this5.set("_bLinkedInfoPopupLoading", false);
                }));
            });
        },
        doCandidateView: function doCandidateView(sCandidateId) {
            var _this6 = this;

            Ember.run.next(function () {
                _this6.get("_objNavigation").navigateWithinContext("agency-candidate-search-view", {
                    "agency-candidate-search-view": [sCandidateId]
                });
            });
        },
        doCandidateCalendarView: function doCandidateCalendarView(sCandidateId) {
            var _this7 = this;

            Ember.run.next(function () {
                _this7.get("_objNavigation").navigateWithinContext("agency-candidate-search-view", {
                    "agency-candidate-search-view": [sCandidateId, "calendar"]
                });
            });
        },
        viewAllCandidateCalendarsInNewWindows: function viewAllCandidateCalendarsInNewWindows(arrSelectedItems) {
            var _this8 = this;

            $.each(arrSelectedItems, function (iKey, objCandidate) {
                _this8.viewCandidateCalendarInNewWindow(objCandidate.candidate_id);
            });
        },
        viewAllCandidatesInNewWindows: function viewAllCandidatesInNewWindows(arrSelectedItems) {
            var _this9 = this;

            $.each(arrSelectedItems, function (iKey, objCandidate) {
                _this9.viewCandidateInNewWindow(objCandidate.candidate_id);
            });
        },
        viewCandidateInNewWindow: function viewCandidateInNewWindow(sCandidateId) {
            var sLink = this.get("_objNavigation").navigateWithinContext("agency-candidate-search-view", {
                "agency-candidate-search-view": [sCandidateId]
            }, null, true);
            // Open the candidate link
            this.openLink(sLink);
        },
        viewCandidateCalendarInNewWindow: function viewCandidateCalendarInNewWindow(sCandidateId) {
            var sLink = this.get("_objNavigation").navigateWithinContext("agency-candidate-search-view", {
                "agency-candidate-search-view": [sCandidateId, "calendar"]
            }, null, true);
            // Open the candidate link
            this.openLink(sLink);
        },
        openLink: function openLink(sLink) {
            window.open(sLink);
        },

        addCandidateFabButton: function addCandidateFabButton() {
            // open add contact popup
            this.set("_bShowCandidateAddPopup", true);
        },
        _popupLinkedInfoClose: function _popupLinkedInfoClose() {
            this.set("_bLinkedInfoPopupLoading", false);
            this.set("_bShowLinkedInfoPopup", false);
        },
        _showPossibleShiftsPopup: function _showPossibleShiftsPopup() {
            this.set("_sPossibleShiftNotifErrorMessage", "");
            this.set("_bPossibleShiftsLoading", false);
            this.set("_bShowPossibleShiftsPopup", true);
        },
        _closePossibleShiftsPopup: function _closePossibleShiftsPopup() {
            if (!this.get("_bPossibleShiftsLoading")) {
                this.set("_bShowPossibleShiftsPopup", false);
            }
        },
        _sendBulkNotification: function _sendBulkNotification() {
            var _this10 = this;

            this.set("_bPossibleShiftsLoading", true);

            this.get("_objRestBulkNotificationsService").sendBulkNotification(this, "_objRestBulkNotificationsPromise", {
                type: "possible_shifts",
                data: {
                    order_id: this.get("_objNavigation.objRouteQueryParams.pos_shift_order_id"),
                    shift_ids: this.get("_arrPossibleShifts").getEach("shift_id")
                },
                candidate_ids: this.get("_arrSelectedItems").getEach("candidate_id")
            }, function () {
                _this10.set("_bShowPossibleShiftsPopup", false);
                _this10.set("_bShowSentMessagePopup", true);
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode /*, sStatus, objErrorThrown, objError*/) {
                _this10.set("_bPossibleShiftsLoading", false);

                if (sCode === "INVALID_SHIFT_ID") {
                    _this10.set("_sPossibleShiftNotifErrorMessage", _this10.get("_objSSAgencyStringsService").getString("possibleShiftsNotificationShiftError"));
                } else if (sCode === "403" || sCode === "401") {
                    _this10.get("_objSnackbarService").showSnackbarMessage(_this10.get("_objEmberLibString").getString("requiredPermission"));
                } else {
                    _this10.get("_objSnackbarService").showSnackbarMessage(_this10.get("_objStringsService").getString("serverError"));
                }
            }));
        },
        _observeSelectionChanged: Ember.observer("_objSelectedRightSide", function () {
            this.set("_objMenuService.mRightPanelComponentData", this.get("_objSelectedRightSide"));
        }),
        actions: {
            onRecieveData: function onRecieveData(objData) {
                if (objData.type === "app_ready") {
                    this.set("_bReactPopupLoading", false);
                } else if (objData.type === "refresh") {
                    // for if we need to do refresh
                } else if (objData.type === "close") {
                    this.set("_bShowReactPopup", false);
                }
            },
            onInputLoaded: function onInputLoaded() {
                var _this11 = this;

                this.get("_objFabButtonService").showFabButton(function () {
                    _this11.addCandidateFabButton();
                });
            },
            onCandidateAddPopupClose: function onCandidateAddPopupClose() {
                this.set("_bShowCandidateAddPopup", false);
            },
            onNavigateToCandidateAction: function onNavigateToCandidateAction(sCandidateId) {
                var _this12 = this;

                this.set("_bShowCandidateAddPopup", false);
                Ember.run.later(function () {
                    _this12.doCandidateView(sCandidateId);
                }, 600);
            },
            onCandidateAdded: function onCandidateAdded(objResponse) {
                var _this13 = this;

                this.set("_bShowCandidateAddPopup", false);
                // This is in a run later because of the redirect issue of how the popup closes. This happens because
                // we do a navigate to for the candidate view. Then the url trickery code fire that will do a navigate
                // back to close the popup and then it will navigate back to the listing page. Thus we add this run
                // later because we want the url trickery code to execute first that will close the popup first.
                // Then we do the navigate to the candidate view page
                // Or go read this issue https://github.com/A24Group/dunkin/issues/94
                Ember.run.later(function () {
                    _this13.doCandidateView(objResponse.id);
                }, 600);
            },
            cardDatagridTableNoneSelected: function cardDatagridTableNoneSelected() {
                this.onItemsSelect(null);
            },
            cardDatagridTableOneSelected: function cardDatagridTableOneSelected(arrSelectedItems) {
                this.onItemsSelect(arrSelectedItems);
            },
            cardDatagridTableMultiSelected: function cardDatagridTableMultiSelected(arrSelectedItems) {
                this.onItemsSelect(arrSelectedItems);
            },
            cardDatagridTableCell: function cardDatagridTableCell(sColumnName, objRowData) {
                // If any of the name columns are clicked
                if (sColumnName === "first_name" || sColumnName === "last_name" || sColumnName === "full_name") {

                    var sCandidateId = objRowData.candidate_id;

                    this.set("_objSelectedRightSide", {
                        sCandidateId: sCandidateId
                    });
                }
            },
            onLinkedInfoPopupClose: function onLinkedInfoPopupClose() {
                this._popupLinkedInfoClose();
            },
            onPossibleShiftPopupClose: function onPossibleShiftPopupClose() {
                this._closePossibleShiftsPopup();
            },
            onPossibleShiftPopupSend: function onPossibleShiftPopupSend() {
                this._sendBulkNotification();
            },
            onPageReload: function onPageReload() {
                this.set("_bShowLoadFailedPopup", false);
                this.doServiceCalls();
            },
            onClientExclusionNotFoundConfirm: function onClientExclusionNotFoundConfirm() {
                this.set("_bShowClientExclusionNotFoundPopup", false);
            },
            onPossibleNonShiftPopupClose: function onPossibleNonShiftPopupClose() {
                this.set("_bShowPossibleNonShiftsPopup", false);
            },
            onShowSentMessage: function onShowSentMessage() {
                this.set("_bShowSentMessagePopup", true);
            },
            onWillDoServiceCall: function onWillDoServiceCall(arrTableData, objUrlConstantPart, objFilterParamObject /*,
                                                                                                                     objTableParamObject,
                                                                                                                     iPage,
                                                                                                                     iPerPage,
                                                                                                                     objSuccessCallback,
                                                                                                                     objFailure*/
            ) {
                if (!a24Core.isEmpty(this.get("_objShiftWithOrderDetails")) && !a24Core.isEmpty(objFilterParamObject.compliance_contract___contract_id)) {
                    var objOrderDetails = this.get("_objShiftWithOrderDetails");
                    var sClient = objOrderDetails.site_id;
                    if (!a24Core.isEmpty(objOrderDetails.ward_id)) {
                        sClient = objOrderDetails.ward_id;
                    }
                    var bCustom = false;
                    var arrComplianceFilter = objFilterParamObject.compliance_contract___contract_id;
                    for (var i = 0; i < arrComplianceFilter.length; i++) {
                        var arrSecondary = arrComplianceFilter[i].arSec;
                        for (var l = 0; l < arrSecondary.length; l++) {
                            if (arrSecondary[l].sProp === "compliance_contract_output_exemption_override") {
                                bCustom = arrSecondary[l].mValue;
                                break;
                            }
                        }
                        if (bCustom) {
                            bCustom = false;
                            objFilterParamObject.compliance_contract___contract_id[i].mCstmQry = [{
                                sProp: "compliance_contract___contract_id",
                                arSec: [{
                                    mValue: 1,
                                    sProp: "contract_output"
                                }],
                                mValue: arrComplianceFilter[i].mValue,
                                bEncode: arrComplianceFilter[i].bEncode,
                                sFilterValue: arrComplianceFilter[i].sFilterValue
                            }, {
                                sProp: "compliance_contract_output_exemption_override___contract_id",
                                bO: true,
                                arSec: [{
                                    mValue: 1,
                                    sProp: "contract_output"
                                }, {
                                    mValue: sClient,
                                    sProp: "exempt_clients",
                                    bDelimited: true
                                }, {
                                    bTo: true,
                                    mValue: objOrderDetails.start_date,
                                    sFilterValue: "dateFilterCustomRange",
                                    sProp: "range_start"
                                }, {
                                    bFrom: true,
                                    mValue: objOrderDetails.start_date,
                                    sFilterValue: "dateFilterCustomRange",
                                    sProp: "range_end"
                                }],
                                mValue: arrComplianceFilter[i].mValue,
                                bEncode: true,
                                sFilterValue: arrComplianceFilter[i].sFilterValue
                            }];
                        }
                    }
                }

                if (!a24Core.isEmpty(objFilterParamObject.is_limited_work_hours_visa) && objFilterParamObject.is_limited_work_hours_visa.mValue === "no") {
                    objFilterParamObject.is_limited_work_hours_visa.mCstmQry = [{
                        sProp: "is_limited_work_hours_visa",
                        mValue: null,
                        sFilterValue: objFilterParamObject.is_limited_work_hours_visa.sFilterValue
                    }, {
                        sProp: "is_limited_work_hours_visa",
                        bO: true,
                        mValue: "no",
                        sFilterValue: objFilterParamObject.is_limited_work_hours_visa.sFilterValue
                    }];
                }
            }
        }
    });
});