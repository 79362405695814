define("a24-staffshift-subdomain-agency/services/rest-agency-export", ["exports", "a24-staffshift-subdomain-agency/config/environment"], function (exports, _environment) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),

        /**
         * Export advanced search to logged in users email
         *
         * @param objContext - The the caller this function
         * @param sProperty - The Property name to get the promise object
         * @param sExportRef - The export ref
         * @param sQuery - The json string of query
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         */
        exportData: function exportData(objContext, sProperty, sExportRef, objQuery, funcSuccess, objFailure) {
            var sAgencyId = this.get("_objUserSession").getUserContextReference().mId;

            a24RestUrlConstruct.setBaseUrl("/v1/agency/" + sAgencyId + "/exports/" + sExportRef);

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "POST", _environment.default.sRestApiBaseUrl + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure, objQuery));
        }
    });
});