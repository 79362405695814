define("a24-staffshift-subdomain-agency/services/rest-shift-status-reasons", ["exports", "a24-staffshift-subdomain-agency/config/environment"], function (exports, _environment) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),

        /**
         * Get shifts status reasons
         *
         * @param objContext - The the caller this function
         * @param sProperty - The Property name to get the promise object
         * @param sStatus - The status to get the status change reasons for
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         *
         * @author Junior van Wyk <johannes.vanwyk@a24group.com>
         * @since  07 April 2020
         */
        getShiftsStatusReasons: function getShiftsStatusReasons(objContext, sProperty, sStatus, funcSuccess, objFailure) {
            a24RestUrlConstruct.setBaseUrl("/v1/shift-status/" + sStatus + "/status-reason");

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "GET", _environment.default.sRestApiBaseUrl + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        }
    });
});