define("a24-staffshift-subdomain-agency/components/page-previously-booked", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-staffshift-subdomain-agency/config/environment"], function (exports, _didRenderChangesMixin, _environment) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {

        objQueryParams: null,

        _objStaffingOrderData: null,
        _objDatagridConfig: null,
        _objDateFilter: null,

        _bPageLoading: true,
        _bForceReload: false,
        _bServerError: null,
        _bShowReactPopup: false,
        _bReactPopupLoading: true,

        _sLastBookedValidDateValue: "",
        _sUrlBase: "",
        _sIFrameUrl: null,

        _arrExtraActions: null,

        _iMaxDays: 365,
        _iRowHeight: 40,
        _iMainHeight: 0,
        _iDataGridHeader: 0,

        _arrGroupedCandidatesBooked: null,
        _arrSelectedItems: null,
        _objSelectedRightSide: null,
        _objReactPopupPostData: null,

        _objEmberLibConstants: Ember.inject.service("a24-constants-ember-lib"),
        _objSSAgencyStrings: Ember.inject.service("a24-strings-staffshift-agency"),
        _objSSCoreEnum: Ember.inject.service("a24-enums-staffshift-core"),
        _objSSAgencyEnum: Ember.inject.service("a24-enums-staffshift-agency"),
        _objEmberLibStringService: Ember.inject.service("a24-strings-ember-lib"),
        _objSSCoreStrings: Ember.inject.service("a24-strings-staffshift-core"),

        _objSSUtils: Ember.inject.service("ss-utils"),
        _objNavigation: Ember.inject.service("navigation"),
        _objMenuService: Ember.inject.service("triage-menu"),
        _objUserSession: Ember.inject.service("user-session"),
        _objTitleBarService: Ember.inject.service("title-bar"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objPreviouslyBookedService: Ember.inject.service("previously-booked"),
        _objStaffingOrderService: Ember.inject.service("rest-agency-staffing-order"),
        _objPreviouslyBookedRestService: Ember.inject.service("rest-previously-booked"),
        _objRestPermissionedActions: Ember.inject.service("rest-permissioned-actions"),
        _objQueryParamBuilderService: Ember.inject.service("query-param-object-builder"),

        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            this.set("_sIFrameUrl", _environment.default.sReactGatewayUrl);

            this.set("_arrReloadActions", [{
                sTitle: this.get("_objEmberLibStringService").getString("reload"),
                sIcon: "refresh",
                executeCardAction: function executeCardAction() {
                    _this._doOrderCall();
                }
            }]);

            this.get("_objTitleBarService").setTitleBar(this.get("_objSSAgencyStrings").getString("previouslyBookedCandidates"), function () {
                _this.get("_objNavigation").navigateBack();
            }, false);

            this.set("_objMenuService.sRightPanelComponent", "right-panel-candidate-profile");
            this.set("_objMenuService.mRightPanelComponentHideOverflow", true);
            this._observeSelectionChanged();

            this.set("_arrExtraActions", [{
                sIcon: "refresh",
                sTitle: this.get("_objEmberLibStringService").getString("reload"),
                executeCardAction: function executeCardAction() {
                    _this._reloadAction();
                }
            }]);
            this.set("_bServerError", false);

            this._doServiceCalls();
        },

        _doServiceCalls: function _doServiceCalls() {
            var _this2 = this;

            this.set("_bPageLoading", true);

            var objBookingRequestPermissionedActionsPromise = this.get("_objRestPermissionedActions").getPermissionedActionsForSection(this, "_objGetPermissionedActions", "booking-request", function () {
                // do nothing since rsvp will handle it
            }, a24RestCallHelper.createJsonAjaxFailureObject(["401", "500", "502", "503", "504"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                _this2.set("_arrBookingRequestPermissionedAction", []);
                _this2.set("_bServerError", true);
                _this2.set("_bPageLoading", false);
            }));

            var objPromiseList = {
                _objRSVPBookingRequestPermissionedAction: objBookingRequestPermissionedActionsPromise
            };
            var arrRequiredResponses = ["_objRSVPBookingRequestPermissionedAction"];

            var objQueryParams = this.get("_objNavigation.objRouteQueryParams");
            if (!a24Core.isEmpty(objQueryParams) && objQueryParams.hasOwnProperty("sOrderId")) {
                // do service call
                var objStaffingOrderPromise = this.get("_objStaffingOrderService").getOrder(this, "objStaffingOrderData", objQueryParams.sOrderId, function () {
                    // do nothing since rsvp will handle it
                }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode , sStatus, objErrorThrown, objError*/{
                    _this2.set("_objStaffingOrderData", {});
                    _this2.set("_bServerError", true);
                    _this2.set("_bPageLoading", false);
                }));
                arrRequiredResponses.push("_objRSVPStaffingOrder");

                objPromiseList._objRSVPStaffingOrder = objStaffingOrderPromise;
            }

            Ember.copy(a24RSVP).configure(this, objPromiseList, [{
                arrRequiredResponses: ["_objRSVPStaffingOrder", "_objRSVPBookingRequestPermissionedAction"],
                funcOnComplete: function funcOnComplete(objResponses) {
                    var objOrder = objResponses._objRSVPStaffingOrder.objResponse;

                    if (a24Core.isEmpty(objOrder)) {
                        objOrder = {};
                    }

                    if (!a24Core.isEmpty(objOrder)) {
                        var sClient = objOrder.sSiteName;
                        if (objOrder.hasOwnProperty("sWardName") && !a24Core.isEmpty(objOrder.sWardName)) {
                            sClient = sClient + " - " + objOrder.sWardName;
                        }
                        _this2.get("_objTitleBarService").setTitleBar(_this2.get("_objSSAgencyStrings").getTokenString("previouslyBookedCandidatesForClient", sClient, objOrder.sJobFamilyName, objOrder.sJobTitleName), function () {
                            _this2.get("_objNavigation").navigateBack();
                        }, false);
                        _this2.set("_objStaffingOrderData", objOrder);
                    }
                    _this2.set("_bServerError", false);
                    _this2.set("_bPageLoading", false);

                    var arrBookingRequestPermissionedAction = objResponses._objRSVPBookingRequestPermissionedAction.objResponse;

                    if (a24Core.isEmpty(arrBookingRequestPermissionedAction)) {
                        arrBookingRequestPermissionedAction = [];
                    }
                    _this2.set("_arrBookingRequestPermissionedAction", arrBookingRequestPermissionedAction);

                    _this2._setDatagridConfig(objOrder);
                }
            }]);
        },

        _reloadAction: function _reloadAction() {
            var _this3 = this;

            this.set("_bPageLoading", true);
            this.set("_bForceReload", true);
            this.set("_bServerError", false);
            Ember.run.next(function () {
                Ember.run.scheduleOnce("afterRender", _this3, function () {
                    _this3.set("_bPageLoading", false);
                });
            });
        },

        _observeBookedForDateQueryParam: Ember.observer("objQueryParams", function () {
            Ember.run.once(this, "_resetDtBookedForFrom");
        }),
        _resetDtBookedForFrom: function _resetDtBookedForFrom() {
            //This will stop url from adding history for the empty value and reset it to default
            if (a24Core.isEmpty(this.get("objQueryParams.dtBookedFor.sValue"))) {
                this.set("objQueryParams", this.get("_objPreviouslyBookedService").getBookedDateDefaultQueryValues(Ember.copy(this.get("objQueryParams"), true)));
            }
        },
        _observeRenders: Ember.observer("_objDidRenderService._iCountRender", function () {
            Ember.run.once(this, "_setMainHeight");
        }),
        _setMainHeight: function _setMainHeight() {
            var objContainer = $(".page-previously-booked");
            if (!a24Core.isEmpty(objContainer)) {
                this.set("_iMainHeight", $(window).height() - objContainer.get(0).getBoundingClientRect().top);
            }
            var objDatagridHeader = $(".filterAndPagerContainer");
            if (!a24Core.isEmpty(objDatagridHeader)) {
                this.set("_iDataGridHeader", objDatagridHeader.height());
            }
            if (!this.get("_bPageLoading") && this.get("_iMainHeight") > 0 && this.get("_iDataGridHeader") > 0) {
                Ember.run.scheduleOnce("afterRender", this, this._setDatagridHeight);
            }
        },
        _setDatagridHeight: function _setDatagridHeight() {
            var _this4 = this;

            var iMaxHeight = this.get("_iMainHeight") - this.get("_iDataGridHeader") - 3 * 6 - //Pager icon heights
            2 * 6 - //Pager padding top and bottom
            12 * 6; //To have space above and below datagrid and to not have page scroller

            //The +100 is to draw rows to cover extra 100px so that menu and right side close/open does not show
            //white space in datagrid(this is for the virtual render functionality)
            if (iMaxHeight + 100 > this.get("_objDatagridConfig.iMaxHeight")) {
                this.set("_objDatagridConfig.iMaxHeight", iMaxHeight + 100);
            }

            //Minus one row to allow space for the headers
            Ember.run.scheduleOnce("afterRender", function () {
                $(".cardDatagridTableInner > div").css("max-height", iMaxHeight - _this4.get("_iRowHeight") + "px");
            });
        },
        _observeSelectionChanged: Ember.observer("_objSelectedRightSide", function () {
            this.set("_objMenuService.mRightPanelComponentData", this.get("_objSelectedRightSide"));
            if (!a24Core.isEmpty(this.get("_objSelectedRightSide"))) {
                this.get("_objMenuService").funcRigthPanelOpen(true);
            }
        }),
        _setDatagridConfig: function _setDatagridConfig(objOrderData) {
            var _this5 = this;

            var objThis = this;

            var arrComplianceStatusOptions = objThis.get("_objSSAgencyEnum").getList("complianceStatuses");

            var arrComplianceStatusDropdown = [];
            for (var i = 0; i < arrComplianceStatusOptions.length; i++) {
                arrComplianceStatusDropdown.push({
                    sTitle: arrComplianceStatusOptions[i].sLabel,
                    sValue: arrComplianceStatusOptions[i].sValue
                });
            }

            var arrExclusionStatusOptions = objThis.get("_objSSAgencyEnum").getList("exclusionStatuses");

            var arrExclusionStatusDropdown = [];
            for (var l = 0; l < arrExclusionStatusOptions.length; l++) {
                arrExclusionStatusDropdown.push({
                    sTitle: arrExclusionStatusOptions[l].sLabel,
                    sValue: arrExclusionStatusOptions[l].sValue
                });
            }

            var objDateFrom = {
                iUniqueColNum: 5,
                sTitle: this.get("_objSSAgencyStrings").getString("bookedForClient"),
                sProperty: "dtBookedFor",
                sFilterType: "date",
                bDate: true,
                bSilent: true,
                iWidth: 5,
                bStopColumnSort: true
            };

            this.set("_objDateFilter", objDateFrom);

            var arrDataGridHeaderConfig = [{
                iUniqueColNum: 1,
                sTitle: this.get("_objSSCoreStrings").getString("candidate"),
                sProperty: "candidate_full_name",
                sFilterType: "text",
                bText: true,
                bIsClickable: true,
                iWidth: 40,
                bStopColumnSort: true,
                bStopFiltering: true
            }, {
                iUniqueColNum: 2,
                sTitle: this.get("_objSSAgencyStrings").getString("bookedCount"),
                sProperty: "booking_count",
                sFilterType: "text",
                bText: true,
                iWidth: 10,
                bStopColumnSort: true,
                bStopFiltering: true
            }, {
                iUniqueColNum: 3,
                sTitle: this.get("_objSSAgencyStrings").getString("lastShiftDate"),
                sProperty: "last_shift_date",
                sFilterType: "date",
                bDate: true,
                iWidth: 15,
                bStopColumnSort: true,
                bStopFiltering: true
            }, {
                iUniqueColNum: 4,
                sTitle: this.get("_objSSAgencyStrings").getString("jobTitleMatch"),
                sProperty: "is_job_title_match",
                sFilterType: "bool",
                bBool: true,
                iWidth: 10,
                bStopColumnSort: true,
                bStopFiltering: true
            }, {
                iUniqueColNum: 6,
                sTitle: this.get("_objSSAgencyStrings").getString("compliant"),
                sProperty: "compliance_status",
                sFilterType: "dropdown",
                bMapping: true,
                arrMappingData: arrComplianceStatusDropdown,
                iWidth: 15,
                bStopColumnSort: true,
                bStopFiltering: true
            }, {
                iUniqueColNum: 7,
                sTitle: this.get("_objSSAgencyStrings").getString("excludedStatus"),
                sProperty: "exclusion_status",
                sFilterType: "dropdown",
                bMapping: false,
                arrMappingData: arrExclusionStatusDropdown,
                bCustom: true,
                iWidth: 10,
                bStopColumnSort: true,
                bStopFiltering: true,
                funcTableCustomVal: function funcTableCustomVal(objHeader, objRowData) {
                    var sDisplay = "";
                    if (!a24Core.isEmpty(objRowData[objHeader.sProperty])) {
                        sDisplay = _this5.get("_objSSAgencyEnum").getFrontendValue("exclusionStatuses", objRowData[objHeader.sProperty]);
                        if (a24Core.isEmpty(sDisplay)) {
                            sDisplay = objRowData[objHeader.sProperty];
                        }
                    }
                    return Ember.String.htmlSafe("<span title=\"" + sDisplay + "\">" + sDisplay + "</span>");
                }
            }, objDateFrom];

            var sPunchClockSvg = this.get("_objSSCoreEnum").getMaterialSvgIcon("punch_clock");

            var arrSingleItemActions = [{
                sTitle: this.get("_objSSAgencyStrings").getString("createBookingRequest"),
                sIconSvg: sPunchClockSvg,
                executeCardAction: function executeCardAction() {
                    _this5.set("_bShowReactPopup", true);
                    _this5.set("_objReactPopupPostData", {
                        type: "trigger_route",
                        data: {
                            route: "staffing/directShiftBookingWizard",
                            data: {
                                clientId: objOrderData.sWardId ? objOrderData.sWardId : objOrderData.sSiteId,
                                clientDetails: {
                                    siteName: objOrderData.sSiteName,
                                    orgName: objOrderData.sOrganisationName,
                                    clientType: objOrderData.sWardId ? 'ward' : 'site',
                                    wardName: objOrderData.sWardName
                                },
                                positionDetails: {
                                    jobFamily: objOrderData.sJobFamilyName,
                                    jobFamilyId: objOrderData.sJobFamilyId,
                                    jobTitle: objOrderData.sJobTitleName,
                                    jobTitleId: objOrderData.sJobTitleId
                                },
                                shiftDetails: objOrderData.arrShifts.map(function (objShift) {
                                    return {
                                        shiftId: objShift.sShiftId,
                                        orderId: objOrderData.id,
                                        shiftStartDate: objShift.dtStart,
                                        shiftEndDate: objShift.dtEnd
                                    };
                                }),
                                candidateId: objThis.get("_arrSelectedItems")[0].candidate_id,
                                candidateDetails: {
                                    fullName: objThis.get("_arrSelectedItems")[0].candidate_full_name
                                }
                            }
                        }
                    });
                },
                funcFilterFunc: function funcFilterFunc() {
                    var arrEntityActions = _this5.get("_arrBookingRequestPermissionedAction");
                    var bAllowAction = false;
                    if (!a24Core.isEmpty(arrEntityActions)) {
                        var objPermissionedAction = arrEntityActions.find(function (objPermissionedActionSection) {
                            return objPermissionedActionSection.entity_type === "booking-request";
                        });
                        var arrAllowedActions = [];

                        if (!a24Core.isEmpty(objPermissionedAction.actions)) {
                            objPermissionedAction.actions.forEach(function (objItem) {
                                if (objItem.allowed) {
                                    arrAllowedActions.push(objItem.action);
                                }
                            });
                        }
                        bAllowAction = arrAllowedActions.includes("create");
                    }
                    return bAllowAction;
                }
            }, {
                sTitle: this.get("_objSSCoreStrings").getString("openCalendarInNewTab"),
                sIcon: "today",
                executeCardAction: function executeCardAction() {
                    objThis._viewAllCandidateCalendarsInNewWindows(objThis.get("_arrSelectedItems"));
                }
            }, {
                sTitle: this.get("_objSSCoreStrings").getString("openInNewTab"),
                sIcon: "open_in_new",
                executeCardAction: function executeCardAction() {
                    objThis._viewAllCandidatesInNewWindows(objThis.get("_arrSelectedItems"));
                }
            }, {
                sTitle: this.get("_objSSCoreStrings").getString("viewCandidateCalendar"),
                sIcon: "perm_contact_calendar",
                executeCardAction: function executeCardAction() {
                    objThis._doCandidateCalendarView(objThis.get("_arrSelectedItems")[0].candidate_id);
                }
            }, {
                sTitle: this.get("_objSSCoreStrings").getString("viewCandidate"),
                sIcon: "visibility",
                executeCardAction: function executeCardAction() {
                    objThis._doCandidateView(objThis.get("_arrSelectedItems")[0].candidate_id);
                }
            }];

            var arrMultiItemActions = [{
                sTitle: this.get("_objSSCoreStrings").getString("openCalendarInNewTab"),
                sIcon: "today",
                executeCardAction: function executeCardAction() {
                    objThis._viewAllCandidateCalendarsInNewWindows(objThis.get("_arrSelectedItems"));
                },
                funcFilterFunc: function funcFilterFunc(arrSelectedItems) {
                    // Only show when selected items is not more than 10
                    return !a24Core.isEmpty(arrSelectedItems) && arrSelectedItems.length <= 10;
                }
            }, {
                sTitle: this.get("_objSSCoreStrings").getString("openInNewTab"),
                sIcon: "open_in_new",
                executeCardAction: function executeCardAction() {
                    objThis._viewAllCandidatesInNewWindows(objThis.get("_arrSelectedItems"));
                },
                funcFilterFunc: function funcFilterFunc(arrSelectedItems) {
                    // Only show when selected items is not more than 10
                    return !a24Core.isEmpty(arrSelectedItems) && arrSelectedItems.length <= 10;
                }
            }];

            this.set("_objDatagridConfig", {
                sDatagridHeader: this.get("_objSSAgencyStrings").getTokenString("possibleCandidatesForOrderForClient", objOrderData.sOrderId),
                iPerPage: 10000,
                iRowHeight: this.get("_iRowHeight"),
                bSelectColumn: true,
                bEmitSelectRowEvent: true,
                bEmitWillDoServiceCall: true,
                bHasReloadButton: false,
                arrSingleItemSelectActions: arrSingleItemActions,
                arrMultiItemSelectActions: arrMultiItemActions,
                // objSavedSearchService: this.get("_objAgencySavedSearchService"),
                // sSavedSearchRef: "agency-search-shifts",
                arrHeaderConfig: arrDataGridHeaderConfig
            });
        },

        _doCandidateView: function _doCandidateView(sCandidateId) {
            var _this6 = this;

            Ember.run.next(function () {
                _this6.get("_objNavigation").navigateWithinContext("agency-candidate-search-view", {
                    "agency-candidate-search-view": [sCandidateId]
                });
            });
        },
        _doCandidateCalendarView: function _doCandidateCalendarView(sCandidateId) {
            var _this7 = this;

            Ember.run.next(function () {
                _this7.get("_objNavigation").navigateWithinContext("agency-candidate-search-view", {
                    "agency-candidate-search-view": [sCandidateId, "calendar"]
                });
            });
        },

        _viewAllCandidateCalendarsInNewWindows: function _viewAllCandidateCalendarsInNewWindows(arrSelectedItems) {
            var _this8 = this;

            $.each(arrSelectedItems, function (iKey, objCandidate) {
                _this8._viewCandidateCalendarInNewWindow(objCandidate.candidate_id);
            });
        },
        _viewAllCandidatesInNewWindows: function _viewAllCandidatesInNewWindows(arrSelectedItems) {
            var _this9 = this;

            $.each(arrSelectedItems, function (iKey, objCandidate) {
                _this9._viewCandidateInNewWindow(objCandidate.candidate_id);
            });
        },
        _viewCandidateInNewWindow: function _viewCandidateInNewWindow(sCandidateId) {
            var sLink = this.get("_objNavigation").navigateWithinContext("agency-candidate-search-view", {
                "agency-candidate-search-view": [sCandidateId]
            }, null, true);
            // Open the candidate link
            this.openLink(sLink);
        },
        _viewCandidateCalendarInNewWindow: function _viewCandidateCalendarInNewWindow(sCandidateId) {
            var sLink = this.get("_objNavigation").navigateWithinContext("agency-candidate-search-view", {
                "agency-candidate-search-view": [sCandidateId, "calendar"]
            }, null, true);
            // Open the candidate link
            this.openLink(sLink);
        },

        openLink: function openLink(sLink) {
            window.open(sLink);
        },


        _doPreviouslyBookedCall: function _doPreviouslyBookedCall(objDateQuery, objSuccessCallback) {
            var _this10 = this;

            var bPrevBookedDateMatchCurrent = this.get("_sLastBookedValidDateValue") === objDateQuery.mValue;

            var objOrder = this.get("_objStaffingOrderData");
            this.set("_sWarningMessage", null);
            var objStartDate = this.get("_objPreviouslyBookedService").startDateFilter();
            var objDate = {
                bFrom: true,
                mValue: objStartDate.getDateStringForRest()
            };

            var objMaxDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));

            objMaxDate.subtractTimeFromDate(this.get("_objEmberLibConstants.MOMENT_TIME_DAYS"), this.get("_iMaxDays"));

            var objFromDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
            var objToDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));

            if (!a24Core.isEmpty(objDateQuery)) {
                if (objDateQuery.bFrom) {
                    objFromDate.setDateFromRest(objDateQuery.mValue, true);
                } else if (objDateQuery.bTo) {
                    objToDate.setDateFromRest(objDateQuery.mValue, true);
                } else if (objDateQuery.bFromAndTo) {
                    objFromDate.setDateFromRest(objDateQuery.mValue.sFrom, true);
                    objToDate.setDateFromRest(objDateQuery.mValue.sTo, true);
                }

                if (objMaxDate.isBefore(objFromDate) && objMaxDate.isBefore(objToDate)) {
                    this.set("_sLastBookedValidDateValue", objDateQuery.mValue);
                    var sDateDefualt = this.get("_objPreviouslyBookedService").getBookedDateDefaultQueryValues({}).dtBookedForFrom.sValue;
                    if (objDateQuery.bFrom && objFromDate.getDateTimeFormat() === sDateDefualt) {
                        this.set("_objDateFilter.sTagValueSimple", this.get("_objSSAgencyStrings").getTokenString("bookedForDefaultTag", this.get("_objPreviouslyBookedService.iDefaultDays")));
                        this.set("_objDateFilter.sTagValue", this.get("_objSSAgencyStrings").getTokenString("bookedForDefaultTag", this.get("_objPreviouslyBookedService.iDefaultDays")));
                        if (this.get("_arrGroupedCandidatesBooked") !== null && !this.get("_bForceReload") && bPrevBookedDateMatchCurrent) {
                            objSuccessCallback(this.get("_arrGroupedCandidatesBooked"), null, {
                                getResponseHeader: function getResponseHeader() {
                                    return "" + _this10.get("_arrGroupedCandidatesBooked").length;
                                }
                            });
                            return;
                        }
                    }
                    this.set("_bForceReload", false);
                    objDate = objDateQuery;
                } else {
                    this.get("_objSnackbarService").showSnackbarMessage(this.get("_objSSAgencyStrings").getString("previouslyBookedMaxAllowedFilter"));
                    objSuccessCallback(this.get("_arrGroupedCandidatesBooked"), null, {
                        getResponseHeader: function getResponseHeader() {
                            return "" + _this10.get("_arrGroupedCandidatesBooked").length;
                        }
                    });

                    Ember.run.next(function () {
                        // Set the filter value to null to retrigger the will do service call so that we can reset to default
                        _this10.set("_objDateFilter.sFilterValue", null);
                    });
                    return;
                }
            }

            var objQueryParams = {
                site_id: {
                    bEncode: true,
                    mValue: objOrder.sSiteId
                },
                status_code: {
                    bDelimited: true,
                    mValue: this.get("_objSSCoreEnum.assignmentStatus.ACCEPTED.backend") + "|" + this.get("_objSSCoreEnum.assignmentStatus.CLOSED.backend")
                },
                start_date: objDate
            };
            if (objOrder.hasOwnProperty("sWardId") && !a24Core.isEmpty(objOrder.sWardId)) {
                objQueryParams.ward_id = {
                    bEncode: true,
                    mValue: objOrder.sWardId
                };
            }

            var objData = {
                site_id: objOrder.sSiteId,
                job_title_id: objOrder.sJobTitleId,
                shift_start_date: objOrder.dtFirstShiftStart
            };

            if (!a24Core.isEmpty(objDateQuery)) {
                if (objDateQuery.bFrom) {
                    objData.min_shift_date_booked = objDateQuery.mValue;
                } else if (objDateQuery.bTo) {
                    objData.max_shift_date_booked = objDateQuery.mValue;
                } else if (objDateQuery.bFromAndTo) {
                    objData.min_shift_date_booked = objDateQuery.mValue.sFrom;
                    objData.max_shift_date_booked = objDateQuery.mValue.sTo;
                } else if (objDateQuery.bNotWithinNext) {
                    objData.min_shift_date_booked = objDateQuery.mValue.sTo;
                } else if (objDateQuery.bNotWithinLast) {
                    objData.max_shift_date_booked = objDateQuery.mValue.sFrom;
                }
            }

            if (!a24Core.isEmpty(objOrder.arrComplianceContractInfo)) {
                var objComplianceContract = objOrder.arrComplianceContractInfo.find(function (_objData) {
                    return _objData.sAgencyId === _this10.get("_objNavigation.sContextId");
                });
                if (!a24Core.isEmpty(objComplianceContract)) {
                    objData.compliance_contract_id = objComplianceContract.sComplianceContractId;
                }
            }

            if (objOrder.hasOwnProperty("sWardId") && !a24Core.isEmpty(objOrder.sWardId)) {
                objData.ward_id = objOrder.sWardId;
            }

            this.get("_objPreviouslyBookedRestService").getPreviouslyBookedCandidates(this, "_objRestAgencyClientPromise", objData, function (arrResponse) {
                var arrData = arrResponse;
                if (a24Core.isEmpty(arrResponse)) {
                    arrData = [];
                }
                _this10.set("_arrGroupedCandidatesBooked", arrData);
                objSuccessCallback(_this10.get("_arrGroupedCandidatesBooked"), null, {
                    getResponseHeader: function getResponseHeader() {
                        return "" + _this10.get("_arrGroupedCandidatesBooked").length;
                    }
                });
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                _this10.get("_objSnackbarService").showSnackbarMessage(_this10.get("_objEmberLibStringService").getString("datagridReload"), _this10.get("_objEmberLibStringService").getString("reload"), function () {
                    _this10._reloadAction();
                });
                _this10.set("_arrGroupedCandidatesBooked", []);
                objSuccessCallback(_this10.get("_arrGroupedCandidatesBooked"), null, {
                    getResponseHeader: function getResponseHeader() {
                        return "" + _this10.get("_arrGroupedCandidatesBooked").length;
                    }
                });
            }));
        },
        _onItemsSelect: function _onItemsSelect(arrSelectedItems) {
            this.set("_arrSelectedItems", arrSelectedItems);
            if (a24Core.isEmpty(arrSelectedItems) || arrSelectedItems.length > 1) {
                this.set("_objSelectedRightSide", null);
            } else {
                if (a24Core.isEmpty(this.get("_objSelectedRightSide")) || this.get("_objSelectedRightSide.sCandidateId") != arrSelectedItems[0].candidate_id) {
                    this.set("_objSelectedRightSide", {
                        sCandidateId: arrSelectedItems[0].candidate_id,
                        dtShiftStart: this.get("_objStaffingOrderData.dtFirstShiftStart")
                    });
                }
            }
        },
        actions: {
            cardDatagridTableNoneSelected: function cardDatagridTableNoneSelected() {
                this._onItemsSelect(null);
            },
            cardDatagridTableOneSelected: function cardDatagridTableOneSelected(arrSelectedItems) {
                this._onItemsSelect(arrSelectedItems);
            },
            cardDatagridTableMultiSelected: function cardDatagridTableMultiSelected(arrSelectedItems) {
                this._onItemsSelect(arrSelectedItems);
            },
            cardDatagridTableCell: function cardDatagridTableCell() /*sColumnName, objRowData*/{
                //Do nothing, since this will trigger the cardDatagridTableOneSelected which will load the
                //right side as intended
            },
            onWillDoServiceCall: function onWillDoServiceCall(arrTableData, objUrlConstantPart, objFilterParamObject, objTableParamObject, iPage, iPerPage, objSuccessCallback
            // objFailure
            ) {
                var _this11 = this;

                if (a24Core.isEmpty(objFilterParamObject) || a24Core.isEmpty(objFilterParamObject.dtBookedFor)) {
                    //This will reset the filter value to default when user tries to remove it.
                    //This needs to be in next loop so that the datagrid filters can pick up the change and add back the tag
                    Ember.run.next(function () {
                        _this11.set("objQueryParams", _this11.get("_objPreviouslyBookedService").getBookedDateDefaultQueryValues(Ember.copy(_this11.get("objQueryParams"), true)));
                    });
                } else if (!a24Core.isEmpty(objFilterParamObject.dtBookedFor)) {
                    this._doPreviouslyBookedCall(objFilterParamObject.dtBookedFor, objSuccessCallback);
                }
                return true;
            },
            onRecieveData: function onRecieveData(objData) {
                if (objData.type === "app_ready") {
                    this.set("_bReactPopupLoading", false);
                } else if (objData.type === "refresh") {
                    this._reloadAction();
                } else if (objData.type === "close") {
                    this.set("_bShowReactPopup", false);
                }
            }
        }
    });
});