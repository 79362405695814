define("a24-staffshift-subdomain-agency/services/rest-agency-client-exclusions", ["exports", "a24-staffshift-subdomain-agency/config/environment"], function (exports, _environment) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),

        /**
         * Get excluded candidates for client
         *
         * @param objCaller - The the caller this function
         * @param sProperty - The Property name to get the promise object
         * @param sClientId - The client id to get the excluded candidates for
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         *
         * @author Junior van Wyk <johannes.vanwyk@a24group.com>
         * @since  05 June 2023
         */
        getAgencyClientExcludedCandidates: function getAgencyClientExcludedCandidates(objContext, sProperty, sClientId, funcSuccess, objFailure) {
            var sAgencyId = this.get("_objUserSession").getUserContextReference().mId;

            a24RestUrlConstruct.setBaseUrl("/v1/agency/" + sAgencyId + "/client/" + sClientId + "/client-initiated-exclusion-candidate");

            a24RestUrlConstruct.addQueryParamObject({
                items_per_page: {
                    mValue: 1000
                }
            });

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "GET", _environment.default.sClientApiBaseUrl + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        }
    });
});