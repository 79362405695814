define("a24-staffshift-subdomain-agency/components/page-chatter-inbox", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-staffshift-subdomain-agency/config/environment"], function (exports, _didRenderChangesMixin, _environment) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        objQueryParams: null,

        iInboxVersion: 1,

        _objChatterIframeComService: Ember.inject.service("chatter-iframe-com"),

        //this needs to stay clean to match origin in post message events, so rather copy this and add params where needed
        //and do not alter this property
        _sIFrameUrl: "",
        _sIFramePopupUrl: "",
        _bLoading: true,
        _bShowReactPopup: false,
        _bReactPopupLoading: true,
        _objReactPopupPostData: null,
        _funcUnregister: null,
        _sIframeExtraQueryParams: null,

        _sCandidateId: null,
        _objNavigation: Ember.inject.service("navigation"),
        _objRestAgencyCandidateActivityEmailComm: Ember.inject.service("rest-agency-activity-email-communication-message"),
        _objStaffshiftCoreStrings: Ember.inject.service("a24-strings-staffshift-core"),
        _objRestAgencyCandidateActivityConversation: Ember.inject.service("rest-agency-activity-conversation"),
        _objRestAgencyCandidateActivityNotification: Ember.inject.service("rest-agency-activity-notification"),

        _objReactIframeData: null,

        // inbox popups
        _bShowEmailMessagePopup: false,
        _bShowConversationPreviewPopup: false,
        _bShowNotifcationsSentPopup: false,
        _objEmailActivity: null,
        _objConversationActivity: null,
        _objNotificationActivity: null,
        _objRestCandidateActivityEmailComm: null,
        _objRestCandidateActivityConversation: null,
        _objRestCandidateActivityNotification: null,
        init: function init() {
            this._super.apply(this, arguments);

            // Note this is for react notifications to work as they require same document domain to push notification
            // messages. This does not effect any api calls as the origin does not change. Also all seems to work except
            // on test cases where it seems to break. Thus the env check
            if (_environment.default.environment !== "test") {
                document.domain = "staffshift.com";
            }

            if ("Notification" in window) {
                if (Notification.permission !== "granted") {
                    Notification.requestPermission();
                }
            }

            if (a24Core.isEmpty(this.get("_sIFrameUrl"))) {
                this.set("_sIFrameUrl", _environment.default.sChatInboxUrl);
            }

            if (this.get("iInboxVersion") > 1) {
                this.set("_sIframeExtraQueryParams", "version=" + this.get("iInboxVersion"));
            }

            this.set("_sIFramePopupUrl", _environment.default.sReactGatewayUrl);

            this.set("_objRestCandidateActivityEmailComm", this.get("_objRestAgencyCandidateActivityEmailComm"));
            this.set("_objRestCandidateActivityConversation", this.get("_objRestAgencyCandidateActivityConversation"));
            this.set("_objRestCandidateActivityNotification", this.get("_objRestAgencyCandidateActivityNotification"));
        },


        _postInboxVersion: function _postInboxVersion() {
            this.set("_objReactIframeData", {
                type: "set_inbox_version",
                data: {
                    inboxVersion: 3
                }
            });
        },

        _postSessionData: function _postSessionData() {
            this.get("_objChatterIframeComService").postSessionData(true, this.get("_objStaffshiftCoreStrings").getString("inbox"), {}, this.get("_sIFrameUrl"), this.$("iframe")[0].contentWindow);
        },

        _postEmberQueryParams: function _postEmberQueryParams() {
            if (!a24Core.isEmpty(this.get("objQueryParams.react_query.sValue"))) {
                try {
                    var objReactQuery = JSON.parse(this.get("objQueryParams.react_query.sValue"));
                    var objPostData = {
                        type: "ember_update_query_params",
                        data: objReactQuery
                    };
                    this.get("_objChatterIframeComService").postMessage(JSON.stringify(objPostData), this.get("_sIFrameUrl"), this.$("iframe")[0].contentWindow);
                } catch (e) {
                    // do nothing
                }
            }
        },

        actions: {
            onRecieveData: function onRecieveData(objData) {
                if (objData.type === "app_ready") {
                    this.set("_bLoading", false);
                    this._postInboxVersion();
                    this._postSessionData();
                    this._postEmberQueryParams();
                } else if (objData.type === "desktop_notification") {
                    this.get("_objChatterIframeComService").openDesktopNotification(this.get("_sIFrameUrl"), this.$("iframe")[0].contentWindow, objData.data);
                } else if (objData.type === "open_preview_popup") {
                    var objEventData = objData.data.data;
                    if (objEventData.entity_type === "EmailCommunicationMessage") {
                        this.set("_objEmailActivity", {
                            sSubjectId: objEventData.subject_id,
                            sLinkedEntityId: objEventData.entity_id,
                            sActivityId: objEventData.activity_id,
                            sSubjectType: objEventData.subject_type
                        });
                        this.set("_bShowEmailMessagePopup", true);
                    } else if (objEventData.entity_type === "ChatConversation") {
                        this.set("_bShowReactPopup", true);
                        this.set("_objReactPopupPostData", {
                            type: "trigger_route",
                            data: {
                                route: "agency/conversation/previewPopup",
                                data: { conversationId: objEventData.entity_id }
                            }
                        });
                    } else if (objEventData.entity_type === "CandidateNotifications") {
                        this.set("_objNotificationActivity", {
                            sSubjectId: objEventData.subject_id,
                            sLinkedEntityId: objEventData.entity_id,
                            sActivityId: objEventData.activity_id,
                            sSubjectType: objEventData.subject_type
                        });
                        this.set("_bShowNotifcationsSentPopup", true);
                    }
                } else if (objData.type === "trigger_direct_shift_booking_wizard") {
                    this.set("_bShowReactPopup", true);
                    this.set("_objReactPopupPostData", {
                        type: "trigger_route",
                        data: {
                            route: "staffing/directShiftBookingWizard",
                            data: objData.data
                        }
                    });
                }
            },

            onRecievePopupData: function onRecievePopupData(objData) {
                if (objData.type === "app_ready") {
                    this.set("_bReactPopupLoading", false);
                } else if (objData.type === "refresh") {
                    // this is not being used at the moment
                } else if (objData.type === "close") {
                    this.set("_bShowReactPopup", false);
                }
            },

            onEmailMessagePopupClose: function onEmailMessagePopupClose() {
                this.set("_bShowEmailMessagePopup", false);
            },
            onConversationPreviewPopupClose: function onConversationPreviewPopupClose() {
                this.set("_bShowConversationPreviewPopup", false);
            },
            onNotificationSentPopupClose: function onNotificationSentPopupClose() {
                this.set("_bShowNotifcationsSentPopup", false);
            }
        }
    });
});