define("a24-staffshift-subdomain-agency/components/time-range-selector", ["exports", "a24-ember-staffshift-candidate-availability/components/time-range-selector"], function (exports, _timeRangeSelector) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _timeRangeSelector.default;
    }
  });
});