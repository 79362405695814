define("a24-staffshift-subdomain-agency/components/page-agency-welcome", ["exports", "a24-ember-staffshift-core/components/page-welcome", "a24-ember-staffshift-core/templates/components/page-welcome"], function (exports, _pageWelcome, _pageWelcome2) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _pageWelcome.default.extend({
        layout: _pageWelcome2.default,
        _objStringsService: Ember.inject.service("a24-strings"),
        _objNavigation: Ember.inject.service("navigation"),
        _objTitleBarService: Ember.inject.service("title-bar"),
        _objFabButtonService: Ember.inject.service("fab-button"),
        _objChatterBoxService: Ember.inject.service("chatter-box-service"),
        _objStaffshiftCoreStringsService: Ember.inject.service("a24-strings-staffshift-core"),
        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            this.get("_objTitleBarService").setTitleBar(this.get("_objStringsService").getString("welcome"), function () {
                _this.get("_objNavigation").navigateBack();
            });
            var objThis = this;
            this.get("_objFabButtonService").showFabButton(function () {
                objThis.get("_objChatterBoxService").toggleChatBox();
            }, "question_answer", this.get("_objStaffshiftCoreStringsService").getString("needAnyHelp"));
        }
    });
});