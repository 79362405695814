define("a24-staffshift-subdomain-agency/components/card-datagrid-table-cell-custom-start-date", ["exports", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        _objUserSession: Ember.inject.service("user-session"),
        objHeaderItem: null,
        objRowData: null,
        _sDisplayValue: "",
        _sClass: "",
        init: function init() {
            this._super.apply(this, arguments);

            var sStartDate = this.get("objRowData.start_date");

            var objStartDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
            var objNowDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));

            objStartDate.setDateFromRest(sStartDate);

            var iMinBeforeStart = objStartDate.difference(objNowDate, a24Constants.MOMENT_TIME_MINUTES);

            if (iMinBeforeStart > 0) {
                // If shift start is before now
                this.set("_sClass", "");
            } else if (iMinBeforeStart > -180) {
                // If shift start is between now and 3 hours ago
                this.set("_sClass", "blue-text");
            } else {
                // If shift start was more that 3 hours ago
                this.set("_sClass", "red-text");
            }

            this.set("_sDisplayValue", objStartDate.getDateTimeFormat());
        }
    });
});