define("a24-staffshift-subdomain-agency/controllers/index/welcome", ["exports", "a24-ember-lib/mixins/input-form-element", "a24-staffshift-subdomain-agency/config/environment"], function (exports, _inputFormElement, _environment) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_inputFormElement.default, {

        _objUserSession: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),

        _bShowEasterTime: false,
        funcGetSuggestions: null,
        funcGetSuggestionsProperty: null,

        init: function init() {
            var _this = this;

            // Call super
            this._super.apply(this, arguments);
            this.set("funcGetSuggestions", function (sQueryString, funcOnComplete) {
                var sUrl = _environment.default.sRestApiBaseUrl + "/v1/agency/" + _this.get("_objUserSession").getUserContextReference().mId + "/global-search-suggestions";

                a24RestUrlConstruct.setBaseUrlSwagger(sUrl);

                a24RestCallHelper.doRestServiceCall(_this, "funcGetSuggestionsProperty", a24RestCallHelper.makeJsonAjaxCall(_this, _this.get("_objUserSession"), _this.get("_objNavigation"), "POST", a24RestUrlConstruct.getConstructedUrl(), function (arrData) {
                    // When service call succeeds

                    // If the data is empty, ensure it's an empty array
                    if (a24Core.isEmpty(arrData) || a24Core.isEmpty(arrData.results) || a24Core.isEmpty(arrData.results[0].data)) {
                        arrData = [];
                    } else {
                        //Since we only have the candidate in index_types this index 0 is fine for now
                        arrData = arrData.results[0].data;
                    }

                    // Define the array that will contain formatted data
                    var arrFormatted = [];

                    // Loop over the results
                    for (var i = 0; i < arrData.length; i++) {

                        // Get the item at index i
                        var objDataItem = arrData[i];

                        // Build the label to show
                        var sLabel = objDataItem.full_name;

                        // Push label with id as an object to the search box
                        arrFormatted.push({
                            sLabel: sLabel,
                            id: objDataItem._id
                        });
                    }

                    // Call the callback with the formatted data
                    funcOnComplete(arrFormatted);
                }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                    // Call the callback with empty
                    funcOnComplete([]);
                }), {
                    search_term: sQueryString,
                    index_types: ["candidate"]
                }));
            });
        },

        actions: {
            easterTime: function easterTime(objEvent) {
                if (objEvent.ctrlKey || objEvent.metaKey) {
                    this.set("_bShowEasterTime", true);
                }
            }
        }
    });
});