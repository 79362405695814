define("a24-staffshift-subdomain-agency/controllers/index/agency-candidate-search-view/compliance-passport", ["exports", "a24-staffshift-subdomain-agency/mixins/query-param-object-builder"], function (exports, _queryParamObjectBuilder) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_queryParamObjectBuilder.default, {
        queryParams: ["complianceContractId", "agencyId"],
        complianceContractId: null,
        agencyId: null,
        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);
            Ember.run.next(function () {
                _this.set("complianceContractId", null);
                _this.set("agencyId", null);
            });
        }
    });
});