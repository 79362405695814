define("a24-staffshift-subdomain-agency/services/a24-strings-staffshift-agency", ["exports", "a24-ember-lookup/services/a24-strings-base"], function (exports, _a24StringsBase) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _a24StringsBase.default.extend({
        _objStrings: null,

        init: function init() {
            this._super.apply(this, arguments);
            this.set("_objStrings", {
                contract: "Contract",
                complianceContracts: "Compliance Contracts",
                complianceContract: "Compliance Contract",
                clientSearch: "Client Search",
                contractDetails: "Contract Details",
                viewComplianceContract: "View Compliance Contract",
                editComplianceContract: "Edit Compliance Contract",
                activateComplianceContract: "Activate Compliance Contract",

                hoursDifference: "Hours Difference",
                equal: "Equal",
                lessThan: "Less Than",
                moreThan: "More Than",

                viewSite: "View Site",
                viewWard: "View Ward",
                viewOrg: "View Organisation",

                timesheetShiftReference: "Timesheet Shift Reference",
                timesheetEndDate: "Timesheet End Date",
                timesheetStartDate: "Timesheet Start Date",
                timesheetIntervalStatus: "Timesheet Interval Status",
                timesheetTotalHoursPay: "Timesheet Total Hours Pay",
                timesheetBreakHours: "Timesheet Break Hours",
                timesheetBillableHours: "Timesheet Billable Hours",

                for: "For",
                late: "Late",
                early: "Early",
                night: "Night",
                city: "City",
                postCode: "Post Code",

                orderReference: "Order Reference",
                listShifts: "List Shifts",
                searchShifts: "Search Shifts",
                viewOrder: "View Order",
                cancelShift: "Cancel Shift",
                selectReason: "Select Reason",
                aboutToCancelShifts: "You are about to cancel the following shifts:",
                cancelShiftsConfirmationPopup: "Are you sure you want to cancel these shifts?",
                failedShiftsPopup: "Failed to cancel the following shifts:",

                cancelingShifts: "Cancelling Shifts...",
                successCancelShifts: "Successfully Cancelled Shifts",

                notValidReasonCancelShift: "Not a valid reason for cancelling a shift",
                shiftStatusPreventCancel: "Current shift status prevents cancellation",
                somethingWentWrong: "Something went wrong. Please try again in a few minutes",
                bulkPossibleShiftWarning: "Please note that this will send an availible shift notification to all the selected candidates",

                assignComplianceContract: "Assign Compliance Contract",
                removeComplianceContract: "Remove Compliance Contract",

                previouslyBooked: "Previously Booked",
                previouslyBookedCandidates: "Previously Booked Candidates",

                bookedCount: "Booking Count",
                lastShiftDate: "Last Shift Date",
                jobTitleMatch: "Job Title Match",
                bookedForClient: "Booked for Client",
                previouslyBookedMaxAllowedFilter: "Booked for Client dates can not exceed 365 days in the past",

                booked: "Booked",
                unavailable: "Unavailable",
                available: "Available",
                unknown: "Unknown",
                availability: "Availability",
                dateFilterTomorrow: "Tomorrow",
                dateFilterNext5Days: "Next 5 days",
                dateFilterSpecificDate: "Specific date",
                dateFilterAllDatesInRange: "All dates in range",
                dateFilterAnyDatesInRange: "Any dates in range",
                andShiftPeriod: "and Shift Period",
                availabilityLastUpdated: "Availability Last Updated",
                positionHeld: "Position Held",
                assignments: "Assignments",
                orderId: "Order ID",
                siteName: "Site Name",
                wardName: "Ward Name",
                payrollNumber: "Payroll Number",
                shiftReferenceNumber: "Shift Reference Number",
                poNumber: "PO Number",
                dateFilled: "Date Filled",
                filledBy: "Filled By",
                filledByUsername: "Filled By Username",
                assignmentStartDate: "Assignment Start Date",
                assignmentEndDate: "Assignment End Date",
                assignmentStatus: "Assignment Status",
                sendOutstandingTimesheetNotif: "Send Outstanding Timesheet Notification",
                organisationName: "Organisation Name",
                assignmentBookedHours: "Assignment Booked Hours",
                contractName: "Contract Name",
                contractStatus: "Contract Status",
                timesheetNumber: "Timesheet Number",
                timesheetCandidate: "Timesheet Candidate",
                timesheetPayrollNumber: "Timesheet Payroll Number",
                timesheetWardName: "Timesheet Ward Name",
                timesheetWardReference: "Timesheet Ward Reference",
                timesheetPONumber: "Timesheet PO Number",
                timesheetJobFamily: "Timesheet Job Family",
                timesheetJobTitle: "Timesheet Job Title",
                timesheetWeekEnding: "Timesheet Week Ending",
                wardChange: "Ward Change",
                jobTitleChange: "Job Title Change",
                timesheetPayrollContract: "Timesheet Payroll Contract",
                contractChange: "Contract Change",
                timesheetOverallStatus: "Timesheet Overall Status",
                timesheetTotalExpensesPay: "Timesheet Total Expenses Pay",
                timesheetTotalPay: "Timesheet Total Pay",
                timesheetTotalcharge: "Timesheet Total Charge",
                nodeLevel: "Node Level",
                siteType: "Site Type",
                wardType: "Ward Type",
                site: "Site",
                ward: "Ward",
                clientReference: "Client Reference",
                siteReferenceNumber: "Site Reference Number",
                wardReferenceNumber: "Ward Reference Number",
                isCompliant: "Is Compliant",
                previousShiftDate: "Previous Shift Date",
                nextShiftDate: "Next Shift Date",
                previousBookingDate: "Previous Booking Date",
                nextBookingDate: "Next Booking Date",
                firstShiftDate: "First Shift Date",
                firstBookedDate: "First Booked Date",

                compliant: "Compliant",
                incompliant: "Incompliant",
                notApplicable: "Not Applicable",
                notYetComputed: "Not Yet Computed",
                noComplianceContractSet: "No Compliance Contract Set",

                openTimesheetInNewTab: "Open Timesheet in new tab",
                sendAvailShiftNotif: "Send available shift notification",
                shiftsAvailable: "Shifts available",
                payRate: "Pay Rate",
                replyWithOrder: "Reply with: Order",
                possibleCandidatesForOrder: "Possible Candidates for Order #",
                possibleShiftsNotificationShiftError: "One or more of the shifts are not valid. This could simply mean that the shift has already been filled or cancelled. Please refresh the page and try again.",

                new: "New",
                open: "Open",
                accepted: "Accepted",
                closed: "Closed",
                cancelled: "Cancelled",
                reopened: "Reopened",
                authorised: "Authorised",
                confirmed: "Confirmed",
                rejected: "Rejected",
                approved: "Approved",
                created: "Created",
                completed: "Completed",
                processing: "Processing",
                completedWithPermanentFailures: "Completed With Permanent Failures",
                initiated: "Initiated",
                retrying: "Retrying",
                failedCompletely: "Failed Completely",
                requiresUserIntervention: "Requires User Intervention",
                completedWithFailures: "Completed with Failures",

                shiftFor: "Shifts for",
                shiftPattern: "Shift Pattern",
                rates: "Rates",
                replyWith: "Reply With",
                nonShiftNotification: "Non Shift Specific Notification",
                possibleWorkNotification: "Possible Work - Notification",
                emptyStringSpacer: "_____",
                noAction: "No Action",
                youCanOnlyCloseWarning: "You can only close selected shifts if they all have the same \"Status\"",
                timesheetCurrency: "Timesheet Currency",
                possibleCandidates: "Possible Candidates",
                clientChangeReport: "Client Change Report",
                changeType: "Change Type",
                changed: "Changed",
                dateChanged: "Date Changed",

                assignConsultant: "Assign Consultant",
                change: "Change",
                before: "Before",
                after: "After",
                maxConsultants: "Max Consultants",
                editRole: "Edit Role",
                changeRoleStatus: "Change Role Status",
                clientRoles: "Client Roles",
                somethingWentWrongTryAgain: "Something went wrong. Please try again.",
                payrollContract: "Payroll Contract",

                bulkAssignConsultants: "Bulk Assign Consultants",
                bulkUnassignConsultants: "Bulk Unassign Consultants",
                bulkConsultantManagement: "Bulk Consultant Management",

                applyExemptions: "Apply Exemptions",

                paymentTerm: "Payment Term",
                paymentTermInherited: "Payment Term Inherited",
                financialHold: "Financial Hold",
                financialHoldInherited: "Financial Hold Inherited",
                payInAdvance: "Pay in Advance",
                credit: "Credit",
                notSet: "Not Set",
                applied: "Applied",
                cleared: "Cleared",
                previouslyBookedDataFail: "Failed to fetch all data",

                bookingPreferences: "Booking Preferences",
                requiresPONumber: "Requires PO Number",
                requiresUniquePONumber: "Requires Unique PO Number",
                requiresPassword: "Requires Password",
                requiresShiftRefNumber: "Requires Shift Ref Number",

                bookingRequests: "Booking Requests",
                createdDate: "Created Date",
                viewBookingRequest: "View Booking Request",
                cancelBookingRequest: "Cancel Booking Request",
                portfolioManager: "Portfolio Manager",
                portfolioConsultant: "Portfolio Consultant",
                shiftStartDate: "Shift Start Date",
                shiftsRequests: "Shifts Requests",
                completedDate: "Completed Date",

                listRestrictionChecks: "List Restriction Checks",
                firstShift: "First Shift",
                lastShift: "Last Shift",
                outstandingWarnings: "Outstanding Warnings",
                bookingRequest: "Booking Request",

                addBookingRequest: "Add Booking Request",
                createBookingRequest: "Create Booking Request",
                createBookingRequests: "Create Booking Requests",
                rule: "Rule",
                audit: "Audit",
                checkedFor: "Checked For",
                acceptedBy: "Accepted By",
                acceptedDate: "Accepted Date",

                violationType: "Violation Type",
                failure: "Failure",
                error: "Error",

                hideExcludedCandidates: "Hide Client Excluded Candidates",
                viewShift: "View Shift",
                unableToOpenProfile: "Unable to open this profile as there is no Candidate or Client assigned to the conversation",

                excludedStatus: "Excluded Status",
                excluded: "Excluded",
                notExcluded: "Not Excluded",

                linkedPayrollContract: "Linked Payroll Contract",

                workforce: "Workforce",

                clientExclusionsNotFound: "Client Exclusions not found. This is due to the client not being linked to the agency.",

                documentTypes: 'Document Types',
                documentGroupings: 'Document Groupings',

                verifyCandidateDocuments: "Verify Candidate Documents",

                documentVerification: "Document Verification",

                approveReferences: "Approve References",

                limitedWorkHoursVisa: "Limited Work Hours Visa",
                documentActions: "Document Actions",

                //===== TOKEN STRINGS START =====
                next5Days: "Next 5 days({0} - {1})",
                allDatesRange: "All dates in range({0} - {1})",
                anyDatesRange: "Any dates in range({0} - {1})",
                availabilityTag: "Availability is {0} for {1} with Shift Period in {2}",
                availabilityTagNoTitle: "Is {0} for {1} with Shift Period in {2}",
                timesheetBreakHoursTag: "Has Timesheet Break Hours is {0}",
                timesheetBreakHoursTagNoTitle: "Timesheet Break Hours is {0}",
                hoursDifferenceTag: "Hours Difference {0}",
                hoursDifferenceTagNoTitle: "Hours Difference {0}",

                previouslyBookedDataLoadFail: "Failed to fetch all data, showing partial data from {0} and into future",
                previouslyBookedCandidatesForClient: "Previously Booked Candidates for {0} for {1}: {2}",

                possibleCandidatesForOrderForClient: "Possible Candidates for Order #{0} (Based on previously booked for client)",
                bookedForDefaultTag: "Booked for client in the last {0} days or future days",

                outstandingTimesheetPreview: "According to our records we had a shift booked for you on {0} with details: Order Ref: {1}; Client: {2}; Staffshift Timesheet Number: #{3}. Please forward us the signed timesheet to wages@a24group.com or reply to this message if you have not worked the shift with the above Staffshift Timesheet Number: #{4} not worked. {5}"
                //===== TOKEN STRINGS END =====

                //===== SAFE STRINGS START =====

                //===== SAFE STRINGS END =====
            });
        }
    });
});