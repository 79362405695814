define("a24-staffshift-subdomain-agency/components/page-agency-document-type-groupings", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-staffshift-subdomain-agency/config/environment"], function (exports, _didRenderChangesMixin, _environment) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        objQueryParams: null,

        _sIFrameUrl: "",

        _objUserSession: Ember.inject.service("user-session"),
        _objNavigationService: Ember.inject.service("navigation"),
        _objTitleBarService: Ember.inject.service("title-bar"),
        _objSSAgencyStrings: Ember.inject.service("a24-strings-staffshift-agency"),
        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            if (a24Core.isEmpty(this.get("_sIFrameUrl"))) {
                this.set("_sIFrameUrl", _environment.default.sReactGatewayUrl);
            }

            this.get("_objTitleBarService").setTitleBar(this.get("_objSSAgencyStrings").getString("documentGroupings"), function () {
                _this.get("_objNavigationService").navigateBack();
            }, false);

            this.set("_objReactIframeData", {
                type: "trigger_route",
                data: {
                    route: "documents/settings/client-document-type-groupings",
                    data: {
                        react_datagrid_data: this.get("objQueryParams").datagrid_params.sValue
                    }
                }
            });
        },
        actions: {
            onSetBreadCrumbAction: function onSetBreadCrumbAction() {
                this._setBreadCrumbAction();
            },
            onRecieveData: function onRecieveData() {}
        }
    });
});