define("a24-staffshift-subdomain-agency/services/tracker-type-link", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({

        _objNavigation: Ember.inject.service("navigation"),

        _arrTrackerTypeLinkMap: null,

        init: function init() {
            this._super.apply(this, arguments);

            this.set("_arrTrackerTypeLinkMap", {
                "booking_request": { sRoute: "agency-list-booking-requests", sParamName: "booking_request_id" }
            });
        },
        openLink: function openLink(sLink) {
            window.open(sLink);
        },
        openTrackerLink: function openTrackerLink(sTrackerType, sTrackerTypeId) {

            var objRoute = this.get("_arrTrackerTypeLinkMap");
            if (!a24Core.isEmpty(objRoute[sTrackerType])) {
                var sParamName = objRoute[sTrackerType]["sParamName"];
                var sRouteWithParam = objRoute[sTrackerType]["sRoute"] + "?" + sParamName + "=" + sTrackerTypeId;
                var sLink = this.get("_objNavigation").navigateWithinContext(sRouteWithParam, {}, null, true);

                this.openLink(sLink);
            }
        }

    });
});