define("a24-staffshift-subdomain-agency/components/page-role-management", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-staffshift-subdomain-agency/config/environment"], function (exports, _didRenderChangesMixin, _environment) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        objQueryParams: null,

        _bReactPopupLoading: true,
        _bReactPopupOpen: false,
        _bPageLoading: true,

        _sIFrameUrl: null,
        _sUrlBase: null,

        _arrSelectedItems: null,
        _arrRoleEntityActionsSection: null,
        _arrSingleLoadingActions: null,

        _objReactPopupPostData: null,
        _objSelectedItem: null,
        _objRoleEntityActionResponse: null,

        _objUserSession: Ember.inject.service("user-session"),
        _objFabButtonService: Ember.inject.service("fab-button"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objTitleBarService: Ember.inject.service("title-bar"),
        _objNavigation: Ember.inject.service("navigation"),

        _objStaffshiftUsersEnumsService: Ember.inject.service("a24-enums-staffshift-users"),
        _objSSAgencyStringsService: Ember.inject.service("a24-strings-staffshift-agency"),
        _objEnumEmberLibService: Ember.inject.service("a24-enums-ember-lib"),
        _objConstantsLib: Ember.inject.service("a24-constants-ember-lib"),
        _objStringsService: Ember.inject.service("a24-strings"),

        _objRestEntityActionSection: Ember.inject.service("rest-entity-action-section"),

        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            this.get("_objTitleBarService").setTitleBar(this.get("_objSSAgencyStringsService").getString("clientRoles"), function () {
                _this.get("_objNavigation").navigateBack();
            }, false);

            this.set("_sIFrameUrl", _environment.default.sReactGatewayUrl);

            this.set("_sUrlBase", _environment.default.sClientApiBaseUrl + "/v1/agency/" + this.get("_objUserSession").getUserContextReference().mId + "/consultant-roles");

            this._setupPageConfig();
            this.doServiceCalls();
        },

        doServiceCalls: function doServiceCalls() {
            var _this2 = this;

            this.set("_objDatagridConfig.arrSingleItemSelectActions", Ember.copy(this.get("_arrSingleLoadingActions"), true));

            var objRoleEntityActionSectionPromise = this.get("_objRestEntityActionSection").getEntityActionsForSection(this, "_objRoleEntityActionResponse", "agency-client-consultant-role", function () {
                // Handled by RSVP
            }, a24RestCallHelper.createJsonAjaxFailureObject(["500", "502", "503", "504"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                _this2.set("_arrRoleEntityActionsSection", []);
                objThis.setupPageConfig();
            }));

            var objPromiseList = {
                _objRoleEntityActionSectionPromise: objRoleEntityActionSectionPromise
            };

            var objThis = this;

            Ember.copy(a24RSVP).configure(this, objPromiseList, [{
                arrRequiredResponses: ["_objRoleEntityActionSectionPromise"],
                funcOnComplete: function funcOnComplete(objResponses) {
                    var arrRoleEntityActionsSection = objResponses._objRoleEntityActionSectionPromise.objResponse;

                    if (a24Core.isEmpty(arrRoleEntityActionsSection)) {
                        arrRoleEntityActionsSection = [];
                    }

                    objThis.set("_arrRoleEntityActionsSection", arrRoleEntityActionsSection);
                    objThis._setDatagridActions();

                    var bAllowedFab = false;
                    if (!a24Core.isEmpty(arrRoleEntityActionsSection)) {
                        var objConsultantRoleEntityAction = arrRoleEntityActionsSection.find(function (objEntityActionSection) {
                            return objEntityActionSection.entity_type === "agency-client-consultant-role";
                        });
                        var objAllowAction = objConsultantRoleEntityAction.actions.find(function (objAction) {
                            return objAction.action === "create_role" && objAction.allowed;
                        });
                        if (!a24Core.isEmpty(objAllowAction)) {
                            bAllowedFab = true;
                        }
                    }

                    if (bAllowedFab) {
                        objThis.get("_objFabButtonService").showFabButton(function () {
                            objThis.set("_bReactPopupOpen", true);
                            objThis.set("_objReactPopupPostData", {
                                type: "trigger_route",
                                data: {
                                    route: "agency/client/consultantRolesManagementPopup",
                                    data: {}
                                }
                            });
                        });
                    }
                }
            }]);
        },

        _setDatagridActions: function _setDatagridActions() {
            var _this3 = this;

            var arrEntityActions = this.get("_arrRoleEntityActionsSection");

            var arrSingleItemActions = [];

            if (!a24Core.isEmpty(arrEntityActions)) {
                var objUpdateAction = {
                    sTitle: this.get("_objSSAgencyStringsService").getString("editRole"),
                    sIcon: "edit",
                    executeCardAction: function executeCardAction() {
                        _this3._editRole(_this3.get("_objSelectedItem"));
                    },
                    funcFilterFunc: function funcFilterFunc() {
                        if (!a24Core.isEmpty(arrEntityActions)) {
                            var objConsultantRoleEntityAction = arrEntityActions.find(function (objEntityActionSection) {
                                return objEntityActionSection.entity_type === "agency-client-consultant-role";
                            });
                            var objAllowAction = objConsultantRoleEntityAction.actions.find(function (objAction) {
                                return objAction.action === "update_role" && objAction.allowed;
                            });
                            if (!a24Core.isEmpty(objAllowAction)) {
                                return true;
                            }
                        }
                        return false;
                    }
                };

                var objUpdateRoleStatusAction = {
                    sTitle: this.get("_objSSAgencyStringsService").getString("changeRoleStatus"),
                    sIcon: "swap_horiz",
                    executeCardAction: function executeCardAction() {
                        _this3._editRoleStatus(_this3.get("_objSelectedItem"));
                    },
                    funcFilterFunc: function funcFilterFunc() {
                        if (!a24Core.isEmpty(arrEntityActions)) {
                            var objConsultantRoleEntityAction = arrEntityActions.find(function (objEntityActionSection) {
                                return objEntityActionSection.entity_type === "agency-client-consultant-role";
                            });
                            var objAllowAction = objConsultantRoleEntityAction.actions.find(function (objAction) {
                                return objAction.action === "update_role" && objAction.allowed;
                            });
                            if (!a24Core.isEmpty(objAllowAction)) {
                                return true;
                            }
                        }
                        return false;
                    }
                };

                arrSingleItemActions.push(objUpdateAction);
                arrSingleItemActions.push(objUpdateRoleStatusAction);
            }

            this.set("_objDatagridConfig.arrSingleItemSelectActions", arrSingleItemActions);
        },

        _setupPageConfig: function _setupPageConfig() {

            var arrStatusDropdown = this.get("_objStaffshiftUsersEnumsService").getList("statuses").map(function (objItem) {
                return {
                    sValue: objItem.sValue,
                    sTitle: objItem.sLabel
                };
            });

            var arrDataGridHeaderConfig = [{
                iUniqueColNum: 1,
                sTitle: this.get("_objStringsService").getString("name"),
                sProperty: "name",
                sFilterType: "text",
                bHideEmptyCheck: true,
                bText: true,
                bIsClickable: true,
                bStopFiltering: true,
                iWidth: 20
            }, {
                iUniqueColNum: 2,
                sTitle: this.get("_objStringsService").getString("description"),
                sProperty: "description",
                sFilterType: "text",
                bHideEmptyCheck: true,
                bText: true,
                bStopFiltering: true,
                bStopColumnSort: true,
                iWidth: 35
            }, {
                iUniqueColNum: 3,
                sTitle: this.get("_objStringsService").getString("status"),
                sProperty: "status",
                bHideEmptyCheck: true,
                sFilterType: "dropdown",
                bMapping: true,
                arrMappingData: arrStatusDropdown,
                bStopColumnSort: true,
                iWidth: 15
            }, {
                iUniqueColNum: 4,
                sTitle: this.get("_objSSAgencyStringsService").getString("maxConsultants"),
                sProperty: "max_consultants",
                bHideEmptyCheck: true,
                sFilterType: "text",
                bText: true,
                bStopFiltering: true,
                bStopColumnSort: true,
                iWidth: 10
            }];

            var arrSingleActions = [{
                bLoader: true
            }];

            this.set("_arrSingleLoadingActions", arrSingleActions);

            this.set("_objDatagridConfig", {
                sDatagridHeader: this.get("_objSSAgencyStringsService").getString("clientRoles"),
                iMaxHeight: 40 * 27,
                bSelectColumn: true,
                bEmitSelectRowEvent: true,
                bHasReloadButton: true,
                arrSingleItemSelectActions: arrSingleActions,
                arrMultiItemSelectActions: [],
                arrHeaderConfig: arrDataGridHeaderConfig,
                bEmitWillDoServiceCall: true
            });

            this.set("_bPageLoading", false);
        },

        _editRole: function _editRole(objData) {
            this.set("_bReactPopupOpen", true);
            this.set("_objReactPopupPostData", {
                type: "trigger_route",
                data: {
                    route: "agency/client/consultantRolesManagementPopup",
                    data: {
                        consultantRoleId: objData._id,
                        name: objData.name,
                        description: objData.description,
                        maxConsultants: objData.max_consultants,
                        status: objData.status
                    }
                }
            });
        },

        _editRoleStatus: function _editRoleStatus(objData) {
            this.set("_bReactPopupOpen", true);
            this.set("_objReactPopupPostData", {
                type: "trigger_route",
                data: {
                    route: "agency/client/changeConsultantRoleStatusPopup",
                    data: {
                        consultantRoleId: objData._id,
                        status: objData.status
                    }
                }
            });
        },

        _onItemsSelect: function _onItemsSelect(arrSelectedItems) {
            if (!a24Core.isEmpty(arrSelectedItems) && arrSelectedItems.length === 1) {
                this.set("_objSelectedItem", arrSelectedItems[0]);
            } else {
                this.set("_objSelectedItem", null);
            }
            // Set the selected items
            this.set("_arrSelectedItems", arrSelectedItems);
        },

        _addRemoveSuccess: function _addRemoveSuccess() {
            var _this4 = this;

            this.set("_bReactPopupOpen", false);
            this.set("_bPageLoading", true);

            Ember.run.next(function () {
                if (a24Core.isEmpty(_this4)) {
                    return;
                }
                Ember.run.schedule("afterRender", function () {
                    _this4.set("_bPageLoading", false);
                });
            });
        },

        actions: {
            cardDatagridTableNoneSelected: function cardDatagridTableNoneSelected() {
                this._onItemsSelect(null);
            },
            cardDatagridTableOneSelected: function cardDatagridTableOneSelected(arrSelectedItems) {
                this._onItemsSelect(arrSelectedItems);
            },
            cardDatagridTableMultiSelected: function cardDatagridTableMultiSelected(arrSelectedItems) {
                this._onItemsSelect(arrSelectedItems);
            },
            cardDatagridTableCell: function cardDatagridTableCell(sColumnName, objRowData) {
                if (sColumnName === "name") {
                    this._editRole(objRowData);
                }
            },
            onRecieveData: function onRecieveData(objData) {
                if (objData.type === "app_ready") {
                    this.set("_bReactPopupLoading", false);
                } else if (objData.type === "refresh") {
                    this._addRemoveSuccess();
                } else if (objData.type === "close") {
                    this.set("_bReactPopupOpen", false);
                }
            }
        }
    });
});