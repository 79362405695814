define("a24-staffshift-subdomain-agency/controllers/index/agency-client-org-view", ["exports", "a24-staffshift-subdomain-agency/mixins/query-param-object-builder", "a24-staffshift-subdomain-agency/config/environment"], function (exports, _queryParamObjectBuilder, _environment) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_queryParamObjectBuilder.default, {
        objReactTriggerRoute: null,
        objReactIframeManualTrigger: null,

        _objEmailActivity: null,
        _objConversationActivity: null,
        _objNotificationActivity: null,

        _bShowEmailMessagePopup: false,
        _bShowConversationPreviewPopup: false,
        _bShowNotifcationsSentPopup: false,

        _sIFrameUrl: null,
        _objReactPopupPostData: null,
        _bShowReactPopup: false,
        _bReactPopupLoading: true,

        _objRestCandidateActivityEmailComm: Ember.inject.service("rest-agency-activity-email-communication-message"),
        _objRestCandidateActivityConversation: Ember.inject.service("rest-agency-activity-conversation"),
        _objRestCandidateActivityNotification: Ember.inject.service("rest-agency-activity-notification"),
        _objNavigation: Ember.inject.service("navigation"),

        init: function init() {
            this._super.apply(this, arguments);
            if (a24Core.isEmpty(this.get("_sIFrameUrl"))) {
                this.set("_sIFrameUrl", _environment.default.sReactGatewayUrl);
            }

            this.set("objReactTriggerRoute", {
                type: "trigger_route",
                data: {
                    route: "agency/client/organisationView",
                    data: {}
                }
            });
        },
        actions: {
            onRecieveData: function onRecieveData(objData) {
                if (objData.type === "contactPersonActivity") {
                    if (!a24Core.isEmpty(this.get("_objNavigation.objRouteQueryParams.contactPersonId"))) {
                        var sContactPersonId = this.get("_objNavigation.objRouteQueryParams.contactPersonId");
                        this.set("objReactIframeManualTrigger", {
                            type: objData.type + "_response",
                            data: {
                                contactPersonId: sContactPersonId
                            },
                            requestId: objData.requestId
                        });
                    }
                } else if (objData.type === "openViewVerifiedDocument") {
                    if (!a24Core.isEmpty(this.get("_objNavigation.objRouteQueryParams.viewVerifiedDocument"))) {
                        var objVerifiedDocs = JSON.parse(atob(this.get("_objNavigation.objRouteQueryParams.viewVerifiedDocument")));
                        this.set("objReactIframeManualTrigger", {
                            type: objData.type + "_response",
                            data: objVerifiedDocs,
                            requestId: objData.requestId
                        });
                    }
                } else if (objData.type === "open_preview_popup") {
                    var objEventData = objData.data.data;
                    if (objEventData.entity_type === "EmailCommunicationMessage") {
                        this.set("_objEmailActivity", {
                            sSubjectId: objEventData.subject_id,
                            sLinkedEntityId: objEventData.entity_id,
                            sActivityId: objEventData.activity_id,
                            sSubjectType: objEventData.subject_type
                        });
                        this.set("_bShowEmailMessagePopup", true);
                    } else if (objEventData.entity_type === "ChatConversation") {
                        this.set("_bShowReactPopup", true);
                        this.set("_objReactPopupPostData", {
                            type: "trigger_route",
                            data: {
                                route: "agency/conversation/previewPopup",
                                data: { conversationId: objEventData.entity_id }
                            }
                        });
                    } else if (objEventData.entity_type === "CandidateNotifications") {
                        this.set("_objNotificationActivity", {
                            sSubjectId: objEventData.subject_id,
                            sLinkedEntityId: objEventData.entity_id,
                            sActivityId: objEventData.activity_id,
                            sSubjectType: objEventData.subject_type
                        });
                        this.set("_bShowNotifcationsSentPopup", true);
                    }
                }
            },
            onEmailMessagePopupClose: function onEmailMessagePopupClose() {
                this.set("_bShowEmailMessagePopup", false);
            },
            onConversationPreviewPopupClose: function onConversationPreviewPopupClose() {
                this.set("_bShowConversationPreviewPopup", false);
            },
            onNotificationSentPopupClose: function onNotificationSentPopupClose() {
                this.set("_bShowNotifcationsSentPopup", false);
            },
            onRecievePopupData: function onRecievePopupData(objData) {
                if (objData.type === "app_ready") {
                    this.set("_bReactPopupLoading", false);
                } else if (objData.type === "refresh") {
                    // for if we need to do refresh
                } else if (objData.type === "close") {
                    this.set("_bShowReactPopup", false);
                }
            }
        }
    });
});