define("a24-staffshift-subdomain-agency/components/card-datagrid-table-cell-custom-availability", ["exports", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        classNames: ["card-datagrid-table-cell-custom-availability"],
        _objUserSession: Ember.inject.service("user-session"),
        _objCustomAvailabilityCellService: Ember.inject.service("card-datagrid-custom-cell-availability"),
        _objAgencyEnumService: Ember.inject.service("a24-enums-staffshift-agency"),
        objHeaderItem: null,
        objRowData: null,
        _arrDayData: null,
        init: function init() {
            this._super.apply(this, arguments);

            var arrDays = this.get("_objCustomAvailabilityCellService").getAvailDatesToShow(this.get("objHeaderItem"));
            var arrDisplayData = this.get("_objCustomAvailabilityCellService").getAvailDetails(arrDays, this.get("objRowData"));

            for (var i = 0; i < arrDisplayData.length; i++) {
                arrDisplayData[i].objShifts.sEarlyClass = this.getClassForShift(arrDisplayData[i].objShifts[this.get("_objAgencyEnumService.shiftOptions.EARLY.backend")]);
                arrDisplayData[i].objShifts.sLateClass = this.getClassForShift(arrDisplayData[i].objShifts[this.get("_objAgencyEnumService.shiftOptions.LATE.backend")]);
                arrDisplayData[i].objShifts.sNightClass = this.getClassForShift(arrDisplayData[i].objShifts[this.get("_objAgencyEnumService.shiftOptions.NIGHT.backend")]);
            }

            this.set("_arrDayData", arrDisplayData);
        },
        getClassForShift: function getClassForShift(sShift) {
            // Below "hardcoded" values are css classnames
            return sShift === this.get("_objAgencyEnumService.availabilityOptions.AVAILABLE.backend") ? "available" : sShift === this.get("_objAgencyEnumService.availabilityOptions.UNAVAILABLE.backend") ? "unavailable" : sShift === this.get("_objAgencyEnumService.availabilityOptions.BOOKED.backend") ? "booked" : "unknown";
        }
    });
});