define("a24-staffshift-subdomain-agency/components/non-shift-possible-work-notification-popup", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-lib/mixins/input-form-element"], function (exports, _didRenderChangesMixin, _inputFormElement) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, _inputFormElement.default, {

        objChildConfig: null,
        arrCandidateIds: null,
        bShowPossibleNonShiftsPopup: false,

        _bPossibleNonShiftsLoading: false,
        _bShowUnsavedData: false,

        _sForString: "",
        _sInString: "",
        _sShiftPatternString: "",
        _sRatesString: "",
        _sReplyWithString: "",

        _objRestBulkNotificationsPromise: null,
        _objOriginalData: null,
        _objPossibleShiftSpecialPopupAction: null,

        _objSnackbarService: Ember.inject.service("snackbar"),
        _objUserSession: Ember.inject.service("user-session"),
        _objRestBulkNotificationsService: Ember.inject.service("rest-agency-candidate-bulk-notifications"),

        _objStringsService: Ember.inject.service("a24-strings"),
        _objEmberLibString: Ember.inject.service("a24-strings-ember-lib"),
        _objSSAgencyStringsService: Ember.inject.service("a24-strings-staffshift-agency"),
        _objAvailabilityStrings: Ember.inject.service("a24-strings-staffshift-availability"),
        _objCandidateProfileStringsService: Ember.inject.service("a24-strings-candidate-profile"),

        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            this.set("_objPossibleShiftSpecialPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: function executeCardAction() {
                    _this._closeNonShiftPopup();
                }
            });

            var objValidationConfig = {};

            objValidationConfig["objChildren.for"] = function (objMixin) {
                a24Validation.addRequired(objMixin, true);
            };
            objValidationConfig["objChildren.in"] = function (objMixin) {
                a24Validation.addRequired(objMixin, true);
            };
            objValidationConfig["objChildren.shiftPattern"] = function (objMixin) {
                a24Validation.addRequired(objMixin, true);
            };
            objValidationConfig["objChildren.replyWith"] = function (objMixin) {
                a24Validation.addRequired(objMixin, true);
            };

            this.set("objChildConfig", objValidationConfig);
        },
        onInputValueChanged: function onInputValueChanged(arrPath) {
            // Get the reference of the field that changed
            var sLookup = arrPath.join(".");

            if (sLookup === "objChildren.for") {
                this.set("_sForString", this.get(sLookup + ".mValue"));
            } else if (sLookup === "objChildren.in") {
                this.set("_sInString", this.get(sLookup + ".mValue"));
            } else if (sLookup === "objChildren.shiftPattern") {
                this.set("_sShiftPatternString", this.get(sLookup + ".mValue"));
            } else if (sLookup === "objChildren.rates") {
                this.set("_sRatesString", this.get(sLookup + ".mValue"));
            } else if (sLookup === "objChildren.replyWith") {
                this.set("_sReplyWithString", this.get(sLookup + ".mValue"));
            }
        },

        _sPossibleNonShiftNotifPreview: Ember.computed("_sForString", "_sInString", "_sShiftPatternString", "_sRatesString", "_sReplyWithString", function () {
            var sString = this.get("_objSSAgencyStringsService").getString("shiftsAvailable") + ": " + (this.get("_objCandidateProfileStringsService").getString("for") + " ") + ((this.get("_sForString") ? this.get("_sForString") : this.get("_objSSAgencyStringsService").getString("emptyStringSpacer")) + " ") + (this.get("_objStringsService").getString("in").toLowerCase() + " ") + ((this.get("_sInString") ? this.get("_sInString") : this.get("_objSSAgencyStringsService").getString("emptyStringSpacer")) + ". ") + ((this.get("_sShiftPatternString") ? this.get("_sShiftPatternString") : this.get("_objSSAgencyStringsService").getString("emptyStringSpacer")) + ".");

            if (!a24Core.isEmpty(this.get("_sRatesString"))) {
                sString += " " + this.get("_sRatesString") + ".";
            }

            sString += " " + this.get("_objSSAgencyStringsService").getString("replyWith") + ": " + ((this.get("_sReplyWithString") ? this.get("_sReplyWithString") : this.get("_objSSAgencyStringsService").getString("emptyStringSpacer")) + ".");

            var sAgency = this.get("_objUserSession.objContextDetails.sDisplayName");

            sString += " " + sAgency;

            return sString;
        }),

        onPopupOpen: Ember.observer("bShowPossibleNonShiftsPopup", function () {
            var _this2 = this;

            if (this.get("bShowPossibleNonShiftsPopup")) {
                this.clearAllErrorMessages();
                this.clearForm();
                this.set("_sForString", "");
                this.set("_sInString", "");
                this.set("_sShiftPatternString", "");
                this.set("_sRatesString", "");
                this.set("_sReplyWithString", "");

                this.set("_bPossibleNonShiftsLoading", false);

                Ember.run.next(function () {
                    Ember.run.schedule("afterRender", function () {
                        _this2.set("_objOriginalData", _this2.getPlainFormValueObject());
                    });
                });
            }
        }),
        _sendBulkNotification: function _sendBulkNotification() {
            var _this3 = this;

            this.clearAllErrorMessages();

            if (!this.doFullValidation()) {
                this.set("_bPossibleNonShiftsLoading", true);

                var objData = this.getPlainFormValueObject();

                var sShiftDetails = this.get("_objCandidateProfileStringsService").getString("for") + " " + objData.for + " " + (this.get("_objStringsService").getString("in").toLowerCase() + " " + objData.in + ". " + objData.shiftPattern);

                if (!a24Core.isEmpty(objData.rates)) {
                    sShiftDetails += ". " + objData.rates;
                }

                this.get("_objRestBulkNotificationsService").sendBulkNotification(this, "_objRestBulkNotificationsPromise", {
                    type: "possible_work_notification_bulk",
                    data: {
                        shift_details: sShiftDetails,
                        reply_with: objData.replyWith
                    },
                    candidate_ids: this.get("arrCandidateIds")
                }, function () {
                    if (!a24Core.isEmpty(_this3.get("onPossibleNonShiftPopupClose"))) {
                        _this3.get("onPossibleNonShiftPopupClose")();
                    }
                    if (!a24Core.isEmpty(_this3.get("onShowSentMessage"))) {
                        _this3.get("onShowSentMessage")();
                    }
                }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function (sCode /*, sStatus, objErrorThrown, objError*/) {
                    _this3.set("_bPossibleNonShiftsLoading", false);

                    if (sCode === "403" || sCode === "401") {
                        _this3.get("_objSnackbarService").showSnackbarMessage(_this3.get("_objEmberLibString").getString("requiredPermission"));
                    } else {
                        _this3.get("_objSnackbarService").showSnackbarMessage(_this3.get("_objStringsService").getString("serverError"));
                    }
                }));
            }
        },
        _closeNonShiftPopup: function _closeNonShiftPopup() {
            if (this.get("_bPossibleNonShiftsLoading")) {
                return;
            }
            // Check if the form has any unsaved data
            var bDataMatches = a24Core.compareObjects(Ember, this.getPlainFormValueObject(), this.get("_objOriginalData"));

            if (bDataMatches) {
                if (!a24Core.isEmpty(this.get("onPossibleNonShiftPopupClose"))) {
                    this.get("onPossibleNonShiftPopupClose")();
                }
            } else {
                this.set("_bShowUnsavedData", true);
            }
        },
        actions: {
            onPopupClose: function onPopupClose() {
                this._closeNonShiftPopup();
            },
            onPopupSend: function onPopupSend() {
                this._sendBulkNotification();
            },
            onPopupUnsavedConfirm: function onPopupUnsavedConfirm() {
                if (!a24Core.isEmpty(this.get("onPossibleNonShiftPopupClose"))) {
                    this.get("onPossibleNonShiftPopupClose")();
                }
                this.set("_bShowUnsavedData", false);
            }
        }
    });
});