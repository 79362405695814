define("a24-staffshift-subdomain-agency/services/rest-previously-booked", ["exports", "a24-staffshift-subdomain-agency/config/environment"], function (exports, _environment) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({

        _objUserSession: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),

        getPreviouslyBookedCandidates: function getPreviouslyBookedCandidates(objContext, sProperty, objData, funcSuccess, objFailure) {
            var sAgencyId = this.get("_objUserSession").getUserContextReference().mId;

            a24RestUrlConstruct.setBaseUrl("/v1/agency/" + sAgencyId + "/advanced-search-report/booked-candidates");

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "POST", _environment.default.sRestApiBaseUrl + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure, objData));
        }
    });
});