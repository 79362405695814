define("a24-staffshift-subdomain-agency/controllers/index", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        bAllowContext: true,
        _objStatusErrorPageService: Ember.inject.service("status-error-page"),
        _objSSCoreStringsService: Ember.inject.service("a24-strings-staffshift-core"),
        init: function init() {
            this._super.apply(this, arguments);

            this.set("_objStatusErrorPageService.sIcon", "warning");
            this.set("_objStatusErrorPageService.sMessage", this.get("_objSSCoreStringsService").getString("contextWrongIp"));
        }
    });
});