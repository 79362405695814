define("a24-staffshift-subdomain-agency/services/rest-agency-user-plugin", ["exports", "a24-staffshift-subdomain-agency/config/environment"], function (exports, _environment) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),

        /**
         * This service method will be used to retrieve a specific plugin
         *
         * @param objContext - The ember object on which the property must exist, component / controller
         * @param sProperty The property name on which to get and set the promise. Can be send in as null for service calls
         *                  that specifically do no need to be canceled when making the same call again
         * @param sPluginId - The id of the plugin to load
         * @param funcSuccess - The callback function to execute when the service call is successful
         * @param objFailure - The object containing the callback function to execute when the service call is not
         *                     successful including the array of error codes that you want to handle
         *
         * @author Ruan Naude <ruan.naude@a24group.com>
         * @since  15 Oct 2020
         */
        getPlugin: function getPlugin(objContext, sProperty, sPluginId, funcSuccess, objFailure) {
            var sAgencyId = this.get("_objUserSession").getUserContextReference().mId;
            var sUserId = this.get("_objUserSession.objAuthData.context.sUserId");
            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "GET", _environment.default.sRestApiBaseUrl + "/v1/agency/" + sAgencyId + "/user/" + sUserId + "/plugin/" + sPluginId, funcSuccess, objFailure));
        }
    });
});