define('a24-staffshift-subdomain-agency/components/calendar-month', ['exports', 'a24-calendar/components/calendar-month'], function (exports, _calendarMonth) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _calendarMonth.default;
    }
  });
});