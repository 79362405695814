define("a24-staffshift-subdomain-agency/controllers/index/agency-tags", ["exports", "a24-staffshift-subdomain-agency/mixins/query-param-object-builder"], function (exports, _queryParamObjectBuilder) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_queryParamObjectBuilder.default, {
        queryParams: ["name", "created_at", "created_atFrom", "created_atTo", "created_by_user_id", "tag_type_id", "page", "sortBy", "vis_cols", "savedSearchKey"],
        name: "",
        created_at: "",
        created_atFrom: "",
        created_atTo: "",
        created_by_user_id: "",
        tag_type_id: "",
        page: "1",
        sortBy: "name::asc",
        vis_cols: "1,2,3",
        savedSearchKey: ""
    });
});