define("a24-staffshift-subdomain-agency/components/card-datagrid-custom-filter-hours-difference", ["exports", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        objHeaderItem: null,

        _bShowHourMinute: false,
        _arrHoursDiffOptions: null,
        _objSSAgencyEnumsService: Ember.inject.service("a24-enums-staffshift-agency"),

        init: function init() {
            this._super.apply(this, arguments);
            this.set("_arrHoursDiffOptions", this.get("_objSSAgencyEnumsService").getList("hoursDiff"));
            this.observeAllValues();
        },

        observeAllValues: Ember.observer("objHeaderItem._objHoursDiff", "objHeaderItem._iHour", "objHeaderItem._iMinute", function () {
            Ember.run.once(this, "_setFlags");
        }),

        observeHour: Ember.observer("objHeaderItem._iHour", function () {
            if (!a24Core.isEmpty(this.get("objHeaderItem._iHour")) && !a24Core.isEmpty(this.get("objHeaderItem._iMinute"))) {
                if (this.get("objHeaderItem._iHour") < 0 && this.get("objHeaderItem._iMinute") > 0) {
                    this.set("objHeaderItem._iMinute", this.get("objHeaderItem._iMinute") * -1);
                }
                if (this.get("objHeaderItem._iMinute") < 0 && this.get("objHeaderItem._iHour") > 0) {
                    this.set("objHeaderItem._iHour", this.get("objHeaderItem._iHour") * -1);
                }
            }
        }),

        observeMinute: Ember.observer("objHeaderItem._iMinute", function () {
            if (!a24Core.isEmpty(this.get("objHeaderItem._iHour")) && !a24Core.isEmpty(this.get("objHeaderItem._iMinute"))) {
                if (this.get("objHeaderItem._iMinute") < 0 && this.get("objHeaderItem._iHour") > 0) {
                    this.set("objHeaderItem._iHour", this.get("objHeaderItem._iHour") * -1);
                }
                if (this.get("objHeaderItem._iHour") < 0 && this.get("objHeaderItem._iMinute") > 0) {
                    this.set("objHeaderItem._iMinute", this.get("objHeaderItem._iMinute") * -1);
                }
            }
        }),

        _setFlags: function _setFlags() {
            if (!a24Core.isEmpty(this.get("objHeaderItem._objHoursDiff"))) {
                this.set("_bShowHourMinute", true);
                if (!a24Core.isEmpty(this.get("objHeaderItem._iHour")) || !a24Core.isEmpty(this.get("objHeaderItem._iMinute"))) {
                    this.customFilterDisplayAddButtonEvent(true);
                } else {
                    this.customFilterDisplayAddButtonEvent(false);
                }
            } else {
                this.set("_bShowHourMinute", false);
                this.customFilterDisplayAddButtonEvent(false);
            }
        }
    });
});