define('a24-staffshift-subdomain-agency/services/rest-checkauth', ['exports', 'a24-ember-staffshift-core/services/rest-checkauth'], function (exports, _restCheckauth) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _restCheckauth.default;
    }
  });
});