define("a24-staffshift-subdomain-agency/components/agency-group-view-edit-card", ["exports", "a24-ember-staffshift-users/components/agency-group-view-edit-card"], function (exports, _agencyGroupViewEditCard) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _agencyGroupViewEditCard.default;
    }
  });
});