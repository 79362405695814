define('a24-staffshift-subdomain-agency/services/rest-agency-lookup', ['exports', 'a24-ember-candidate-profile/services/rest-agency-lookup'], function (exports, _restAgencyLookup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _restAgencyLookup.default;
    }
  });
});