define("a24-staffshift-subdomain-agency/services/previously-booked", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({

        iDefaultDays: 180,

        _objUserSession: Ember.inject.service("user-session"),
        _objEmberLibConstants: Ember.inject.service("a24-constants-ember-lib"),
        _objEmberLibEnums: Ember.inject.service("a24-enums-ember-lib"),

        _momentHelper: null,

        init: function init() {
            this._super.apply(this, arguments);

            if (a24Core.isEmpty(this.get("_momentHelper"))) {
                this.set("_momentHelper", momentHelper);
            }
        },

        startDateFilter: function startDateFilter() {

            var objStartDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
            objStartDate.subtractTimeFromDate(this.get("_objEmberLibConstants.MOMENT_TIME_DAYS"), this.get("iDefaultDays"));

            objStartDate.setTimeToStartOfDay();

            return objStartDate;
        },

        getBookedDateDefaultQueryValues: function getBookedDateDefaultQueryValues(objQueryParams) {
            if (a24Core.isEmpty(objQueryParams.dtBookedForTo)) {
                objQueryParams.dtBookedForTo = {
                    sValue: "",
                    sDefault: ""
                };
            }
            if (a24Core.isEmpty(objQueryParams.dtBookedForFrom)) {
                objQueryParams.dtBookedForFrom = {
                    sValue: "",
                    sDefault: ""
                };
            }
            if (a24Core.isEmpty(objQueryParams.dtBookedFor)) {
                objQueryParams.dtBookedFor = {
                    sValue: "",
                    sDefault: ""
                };
            }
            objQueryParams.dtBookedForTo.sValue = "";
            objQueryParams.dtBookedForFrom.sValue = this.startDateFilter().getDateTimeFormat();
            objQueryParams.dtBookedFor.sValue = this.get("_objEmberLibEnums.dateFilterOptions.DATE_FILTER_CUSTOM_RANGE.backend");
            return objQueryParams;
        }
    });
});