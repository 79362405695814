define("a24-staffshift-subdomain-agency/components/page-agency-client-change-report", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-staffshift-subdomain-agency/config/environment"], function (exports, _didRenderChangesMixin, _environment) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        classNames: ["page-agency-client-change-report"],
        sUrlBase: "",
        objQueryParams: null,

        _bPageLoading: true,
        _objDatagridConfig: null,

        _objTitleBarService: Ember.inject.service("title-bar"),
        _objNavigation: Ember.inject.service("navigation"),
        _objSSAgencyEnums: Ember.inject.service("a24-enums-staffshift-agency"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objSSAgencyStrings: Ember.inject.service("a24-strings-staffshift-agency"),
        _objCandidateProfileStringsService: Ember.inject.service("a24-strings-candidate-profile"),
        _objAgencySiteService: Ember.inject.service("rest-agency-site"),
        _objAgencyWardService: Ember.inject.service("rest-agency-site-ward"),
        _objAgencyOrgService: Ember.inject.service("rest-agency-organisation"),
        _objSiteTypesService: Ember.inject.service("rest-site-type"),
        _objWardTypesService: Ember.inject.service("rest-ward-type"),
        _objFilterCustomWardService: Ember.inject.service("card-datagrid-custom-filter-ward"),
        _objRestEntityAction: Ember.inject.service("rest-entity-action-section"),
        _objEmberLibStringsService: Ember.inject.service("a24-strings-ember-lib"),
        _objAgencyExportService: Ember.inject.service("rest-agency-export"),
        _objQueryParamBuilderService: Ember.inject.service("query-param-object-builder"),
        _objRestLookupService: Ember.inject.service("rest-agency-lookup"),

        _objEntityActionPromise: null,
        _arrExtraActions: null,
        _bHasExportPermission: false,
        _objSnackbarService: Ember.inject.service("snackbar"),

        _bShowExportPopup: false,
        _bExportPopupLoading: false,
        _sQuery: null,
        _bShowLoadFailedPopup: false,
        _arrEntityActions: null,
        _objEventTypesResponse: null,
        init: function init() {
            this._super.apply(this, arguments);

            this.get("_objTitleBarService").setTitleBar(this.get("_objSSAgencyStrings").getString("clientChangeReport"));

            // Build up the url with context id
            this.set("sUrlBase", _environment.default.sRestApiBaseUrl + "/v1/agency/" + this.get("_objNavigation.sContextId") + "/advanced-search/agency-client-event-log");

            this.set("_arrExtraActions", [{
                bLoader: true
            }]);

            this.doServiceCalls();
        },
        doServiceCalls: function doServiceCalls() {
            var _this = this;

            this.set("_bPageLoading", true);
            var objThis = this;
            var objQuery = {
                sortBy: { mValue: "sSiteTypeName" },
                page: { mValue: 1 },
                items_per_page: { mValue: 1000 }
            };

            var objPromiseSiteTypes = this.get("_objSiteTypesService").getSiteTypes(this, "_objSiteTypesResponse", objQuery, function () {
                // Handled by RSVP
            }, a24RestCallHelper.createJsonAjaxFailureObject(["500", "502", "503", "504"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                objThis._handleServerErrors();
            }));

            objQuery.sortBy = { mValue: "sWardTypeName" };

            var objPromiseWardTypes = this.get("_objWardTypesService").getWardTypes(this, "_objWardTypesResponse", objQuery, function () {
                // Handled by RSVP
            }, a24RestCallHelper.createJsonAjaxFailureObject(["500", "502", "503", "504"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                objThis._handleServerErrors();
            }));

            var objPromiseEventTypes = this.get("_objRestLookupService").allInOneLookup(this, "_objEventTypesResponse", "event-types", function () {
                // Handled by RSVP
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode , sStatus, objErrorThrown, objError*/{
                objThis._handleServerErrors();
            }));

            var objPromiseEntityActions = this.get("_objRestEntityAction").getEntityActionsForSection(this, "_objEntityActionPromise", "agency-client-event-log", function () {
                // Handled by RSVP
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode , sStatus, objErrorThrown, objError*/{
                var objNoAction = {
                    "sIcon": "not_interested",
                    "sIconClass": "a24-grey-text-darken-1",
                    "sTitle": objThis.get("_objSSAgencyStrings").getString("noAction"),
                    executeCardAction: function executeCardAction() {}
                };

                _this.set("_arrExtraActions", [objNoAction]);
            }));

            var objPromiseList = {
                _objRSVPSiteTypes: objPromiseSiteTypes,
                _objRSVPWardTypes: objPromiseWardTypes,
                _objRSVPEventTypes: objPromiseEventTypes,
                _objRSVPEntityActions: objPromiseEntityActions
            };

            var arrRequiredResponses = ["_objRSVPSiteTypes", "_objRSVPWardTypes", "_objRSVPEventTypes"];

            a24RSVP.configure(this, objPromiseList, [{
                arrRequiredResponses: arrRequiredResponses,
                funcOnComplete: function funcOnComplete(objResponses) {
                    var arrSiteTypes = [];
                    if (!a24Core.isEmpty(objResponses._objRSVPSiteTypes)) {
                        arrSiteTypes = objResponses._objRSVPSiteTypes.objResponse;
                    }

                    var arrWardTypes = [];
                    if (!a24Core.isEmpty(objResponses._objRSVPWardTypes)) {
                        arrWardTypes = objResponses._objRSVPWardTypes.objResponse;
                    }

                    var arrEventTypes = [];
                    if (!a24Core.isEmpty(objResponses._objRSVPEventTypes) && !a24Core.isEmpty(objResponses._objRSVPEventTypes.objResponse)) {
                        arrEventTypes = objResponses._objRSVPEventTypes.objResponse;
                    }

                    objThis._setupPageConfig(arrSiteTypes, arrWardTypes, arrEventTypes);
                }
            }, {
                arrRequiredResponses: ["_objRSVPEntityActions"],
                funcOnComplete: function funcOnComplete(objResponses) {
                    var arrResponse = objResponses._objRSVPEntityActions.objResponse;
                    objThis.set("_arrEntityActions", arrResponse);

                    objThis._setExtraActions();
                }
            }]);
        },

        _setExtraActions: function _setExtraActions() {
            var sQuery = this.get("_sQuery");
            var arrEntityActions = this.get("_arrEntityActions");
            var objThis = this;

            if (!a24Core.isEmpty(sQuery) && !a24Core.isEmpty(arrEntityActions)) {

                var objShowExport = arrEntityActions.find(function (objEntityAction) {
                    var objExportAction = objEntityAction.actions.find(function (objAction) {
                        return objAction.action === "export";
                    });

                    if (!a24Core.isEmpty(objExportAction) && objExportAction.allowed) {
                        return true;
                    }

                    return false;
                });

                if (!a24Core.isEmpty(objShowExport)) {
                    this.set("_bHasExportPermission", true);

                    this.set("_arrExtraActions", [{
                        sTitle: this.get("_objEmberLibStringsService").getString("exportData"),
                        sIcon: "forward",
                        executeCardAction: function executeCardAction() {
                            // open the export popup
                            objThis.set("_bExportPopupLoading", true);
                            objThis.set("_bShowExportPopup", true);
                        }
                    }]);
                } else {
                    this.set("_bHasExportPermission", false);

                    var objNoAction = {
                        "sIcon": "not_interested",
                        "sIconClass": "a24-grey-text-darken-1",
                        "sTitle": objThis.get("_objSSAgencyStrings").getString("noAction"),
                        executeCardAction: function executeCardAction() {}
                    };

                    this.set("_arrExtraActions", [objNoAction]);
                }
            }
        },

        _handleServerErrors: function _handleServerErrors() {
            this.set("_bShowLoadFailedPopup", true);
        },

        _setupPageConfig: function _setupPageConfig(arrSiteTypes, arrWardTypes, arrEventTypes) {
            var _this2 = this;

            var arrNodeLevels = this.get("_objSSAgencyEnums").getList("nodeLevel");
            var arrNodeLevelsDropdown = [];
            for (var k = 0; k < arrNodeLevels.length; k++) {
                arrNodeLevelsDropdown.push({
                    sTitle: arrNodeLevels[k].sLabel,
                    sValue: arrNodeLevels[k].sValue
                });
            }

            var arrSiteTypesDropdown = [];
            var arrSiteTypesIds = [];
            for (var i = 0; i < arrSiteTypes.length; i++) {
                arrSiteTypesIds.push(arrSiteTypes[i]._id);
                arrSiteTypesDropdown.push({
                    sTitle: arrSiteTypes[i].name,
                    sValue: arrSiteTypes[i].name
                });
            }

            var arrWardTypesDropdown = [];
            var arrWardTypesIds = [];
            for (var j = 0; j < arrWardTypes.length; j++) {
                arrWardTypesIds.push(arrWardTypes[j]._id);
                arrWardTypesDropdown.push({
                    sTitle: arrWardTypes[j].name,
                    sValue: arrWardTypes[j].name
                });
            }

            var arrEventTypesDropdown = [];
            var arrEventTypesLookup = [];
            var objEventTypesCleanUp = {};

            for (var m = 0; m < arrEventTypes.length; m++) {
                arrEventTypesLookup[arrEventTypes[m].name] = arrEventTypes[m].metadata.display_name;

                if (!a24Core.isEmpty(objEventTypesCleanUp) && !a24Core.isEmpty(objEventTypesCleanUp[arrEventTypes[m].metadata.display_name])) {
                    objEventTypesCleanUp[arrEventTypes[m].metadata.display_name].push(arrEventTypes[m].name);
                    continue;
                }

                objEventTypesCleanUp[arrEventTypes[m].metadata.display_name] = [arrEventTypes[m].name];
            }
            var arrEventTypesKeys = Object.keys(objEventTypesCleanUp);
            for (var o = 0; o < arrEventTypesKeys.length; o++) {
                arrEventTypesDropdown.push({
                    sTitle: arrEventTypesKeys[o],
                    sValue: objEventTypesCleanUp[arrEventTypesKeys[o]].join(',')
                });
            }

            var sChange = this.get("_objSSAgencyStrings").getString("change");

            var arrHeaderConfig = [{
                iUniqueColNum: 1,
                sTitle: this.get("_objSSAgencyStrings").getString("organisationName"),
                sProperty: "organisation_name",
                sFilterType: "text",
                bStopFiltering: true,
                bStopColumnSort: true,
                bText: true,
                iWidth: 12
            }, {
                iUniqueColNum: 2,
                sTitle: this.get("_objSSAgencyStrings").getString("organisationName"),
                sProperty: "organisation_id",
                sFilterType: "suggest",
                bCustom: true,
                iWidth: 10,
                bSilent: true,
                iFilterSuggestAt: 3,
                iFilterSuggestDelay: 500,
                sFilterOptionsValueKey: "sOrganisationId",
                sFilterOptionsLabelKey: "sOrganisationName",
                bFilterShowLoaderDuringSuggest: true,
                bFilterAlwaysShowOnFocus: true,
                funcFilterGetSuggestions: function funcFilterGetSuggestions(sSuggestVal, funcCallback) {
                    var objQuery = {
                        sOrganisationName: {
                            mValue: sSuggestVal,
                            bLike: true,
                            bEncode: true
                        },
                        sortBy: {
                            mValue: "sOrganisationName"
                        },
                        items_per_page: {
                            mValue: 10
                        }
                    };

                    _this2.get("_objAgencyOrgService").getAgencyOrganisations(_this2, "_objGetOrgSuggestions", objQuery, function (arrData) {
                        // If the data is empty, ensure it's an empty array
                        if (a24Core.isEmpty(arrData)) {
                            arrData = [];
                        }

                        // Call the callback with the formatted data
                        funcCallback(arrData);
                    }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                        // Call the callback with empty
                        funcCallback([]);
                    }));
                }
            }, {
                iUniqueColNum: 3,
                sTitle: this.get("_objSSAgencyStrings").getString("siteName"),
                sProperty: "site_name",
                sFilterType: "text",
                bStopFiltering: true,
                bStopColumnSort: true,
                bIsClickable: true,
                bText: true,
                iWidth: 12
            }, {
                iUniqueColNum: 4,
                sTitle: this.get("_objSSAgencyStrings").getString("siteName"),
                sProperty: "site_id",
                sFilterType: "suggest",
                bText: true,
                iWidth: 10,
                bSilent: true,
                iFilterSuggestAt: 3,
                iFilterSuggestDelay: 500,
                sFilterOptionsValueKey: "sSiteId",
                sFilterOptionsLabelKey: "sSiteName",
                bFilterShowLoaderDuringSuggest: true,
                bFilterAlwaysShowOnFocus: true,
                funcFilterGetSuggestions: function funcFilterGetSuggestions(sSuggestVal, funcCallback) {
                    var objQuery = {
                        sSiteName: {
                            mValue: sSuggestVal,
                            bLike: true,
                            bEncode: true
                        },
                        bAgencyLinked: {
                            mValue: true
                        },
                        sortBy: {
                            mValue: "sSiteName"
                        },
                        items_per_page: {
                            mValue: 10
                        }
                    };

                    _this2.get("_objAgencySiteService").getLinkedClients(_this2, "_objGetSiteSuggestions", objQuery, function (arrData) {
                        // If the data is empty, ensure it's an empty array
                        if (a24Core.isEmpty(arrData)) {
                            arrData = [];
                        }

                        var arrFormatted = [];
                        for (var _i = 0; _i < arrData.length; _i++) {
                            var objDataItem = arrData[_i];
                            var sLabel = objDataItem.sOrganisationName + ": " + objDataItem.sSiteName;
                            objDataItem.sSiteName = sLabel;

                            arrFormatted.push(objDataItem);
                        }

                        // Call the callback with the formatted data
                        funcCallback(arrFormatted);
                    }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                        // Call the callback with empty
                        funcCallback([]);
                    }));
                }
            }, {
                iUniqueColNum: 5,
                sTitle: this.get("_objSSAgencyStrings").getString("wardName"),
                sProperty: "ward_name",
                sFilterType: "text",
                bText: true,
                bIsClickable: true,
                bStopFiltering: true,
                bStopColumnSort: true,
                iWidth: 12
            }, {
                iUniqueColNum: 6,
                sTitle: this.get("_objSSAgencyStrings").getString("wardName"),
                sProperty: "ward_id",
                sFilterType: "custom",
                bText: true,
                bFilterOr: true,
                bHideEmptyCheck: true,
                bSilent: true,
                objFilterSiteService: this.get("_objAgencySiteService"),
                objFilterWardService: this.get("_objAgencyWardService"),
                sFilterCustomComponentName: "card-datagrid-custom-filter-ward",
                objFilterCustomService: this.get("_objFilterCustomWardService")
            }, {
                iUniqueColNum: 7,
                sTitle: this.get("_objSSAgencyStrings").getString("nodeLevel"),
                sProperty: "client_type",
                sFilterType: "dropdownMulti",
                bHideEmptyCheck: true,
                bMapping: true,
                bStopColumnSort: true,
                arrMappingData: arrNodeLevelsDropdown,
                iWidth: 9
            }, {
                iUniqueColNum: 8,
                sTitle: this.get("_objSSAgencyStrings").getString("clientReference"),
                sProperty: "client_reference",
                sFilterType: "text",
                bText: true,
                bStopColumnSort: true,
                bStopFiltering: true,
                iWidth: 10
            }, {
                iUniqueColNum: 9,
                sTitle: this.get("_objSSAgencyStrings").getString("dateChanged"),
                sProperty: "actioned_at",
                sFilterType: "date",
                bFilterAllowTime: true,
                bFilterBlockRemove: true,
                bHideEmptyCheck: true,
                bStopColumnSort: true,
                bDate: true,
                iWidth: 10
            }, {
                iUniqueColNum: 10,
                sTitle: this.get("_objSSAgencyStrings").getString("changeType"),
                sProperty: "event_type",
                sFilterType: "dropdownMulti",
                bHideEmptyCheck: true,
                bMapping: true,
                bStopColumnSort: true,
                bCustom: !a24Core.isEmpty(arrEventTypesDropdown),
                arrMappingData: arrEventTypesDropdown,
                bText: a24Core.isEmpty(arrEventTypesDropdown), // in case there are no event types returned
                funcTableCustomVal: function funcTableCustomVal(objHeader, objRowData) {
                    if (!a24Core.isEmpty(arrEventTypesLookup[objRowData.event_type])) {
                        var sEventType = arrEventTypesLookup[objRowData.event_type];
                        return Ember.String.htmlSafe("<span title=\"" + sEventType + "\">" + sEventType + "</span>");
                    } else {
                        return Ember.String.htmlSafe("<span title=\"" + objRowData.event_type + "\">" + objRowData.event_type + "</span>");
                    }
                },
                funcFilterParseQueryValue: function funcFilterParseQueryValue(objQueryObject) {
                    var objRegQuery = new RegExp(",", "g");
                    objQueryObject.mValue = objQueryObject.mValue.replace(objRegQuery, "|");
                    return objQueryObject;
                },
                iWidth: 15
            }, {
                iUniqueColNum: 11, // this must always be the last column, because it needs to expand
                sTitle: this.get("_objSSAgencyStrings").getString("changed"),
                sProperty: "diff",
                bStopColumnSort: true,
                sFilterType: "text",
                bStopFiltering: true,
                bCustom: true,
                bWrapText: true,
                sExportColumn: "diff_after",
                sExportColumnName: sChange + " - " + this.get("_objSSAgencyStrings").getString("after"),
                sExportPreColumn: "diff_before",
                sExportPreColumnName: sChange + " - " + this.get("_objSSAgencyStrings").getString("before"),
                funcTableCustomVal: function funcTableCustomVal(objHeader, objRowData) {
                    var sBefore = null;
                    if (!a24Core.isEmpty(objRowData.diff_before)) {
                        sBefore = objRowData.diff_before;
                    }
                    var sAfter = null;
                    if (!a24Core.isEmpty(objRowData.diff_after)) {
                        sAfter = objRowData.diff_after;
                    }

                    // before and after
                    if (!a24Core.isEmpty(sBefore) && !a24Core.isEmpty(sAfter)) {
                        return Ember.String.htmlSafe("<div class=\"custom-cell-container\">\n                            <div class=\"custom-cell-item\" title=\"" + sBefore + "\">" + sBefore + "</div>\n                            <div class=\"custom-cell-item-no-wrap\">&nbsp;-->&nbsp;</div>\n                            <div class=\"custom-cell-item\" title=\"" + sAfter + "\">" + sAfter + "</div>\n                        </div>");
                    } else if (!a24Core.isEmpty(sBefore)) {
                        // only before
                        return Ember.String.htmlSafe("<span title=\"" + sBefore + "\"><s>" + sBefore + "</s></span>");
                    } else if (!a24Core.isEmpty(sAfter)) {
                        // only after
                        return Ember.String.htmlSafe("<span title=\"" + sAfter + "\">" + sAfter + "</span>");
                    }

                    return "";
                },
                iWidth: 20
            }];

            this.set("_objDatagridConfig", {
                sDatagridHeader: this.get("_objSSAgencyStrings").getString("clientChangeReport"),
                iMaxHeight: 10000,
                bDontUseVirtualization: true,
                bSelectColumn: false,
                bHasReloadButton: true,
                iResultCountLimit: 251,
                arrHeaderConfig: arrHeaderConfig,
                bEmitDidDoServiceCall: true,
                bEmitWillDoServiceCall: true,
                objPermanentQuery: {
                    organisation_name: {
                        mValue: "asc",
                        bSortBy: true
                    },
                    site_name: {
                        mValue: "asc",
                        bSortBy: true
                    },
                    ward_name: {
                        mValue: "asc",
                        bSortBy: true
                    },
                    actioned_at: {
                        mValue: "asc",
                        bSortBy: true
                    }
                }
            });

            this.set("_arrExtraActions", [{
                bLoader: true
            }]);

            this.set("_bPageLoading", false);
        },
        _viewClientInNewWindow: function _viewClientInNewWindow(sClientId, sType) {
            var sRoute = "";
            var objRouteParams = {};
            if (sType === this.get("_objSSAgencyEnums.nodeLevel.SITE.backend")) {
                sRoute = "agency-client-site-view";
            } else {
                sRoute = "agency-client-ward-view";
            }

            objRouteParams[sRoute] = [sClientId];
            var sLink = this.get("_objNavigation").navigateWithinContext(sRoute, objRouteParams, null, true);
            this.openLink(sLink);
        },
        openLink: function openLink(sLink) {
            window.open(sLink);
        },
        actions: {
            onDidDoServiceCall: function onDidDoServiceCall(arrTableData, jqXHR, objPOSTBody) {
                if (!a24Core.isEmpty(arrTableData)) {
                    // set the query
                    if (!a24Core.isEmpty(objPOSTBody)) {
                        delete objPOSTBody.items_per_page;
                        delete objPOSTBody.page;
                        delete objPOSTBody.sort;
                        if (!a24Core.isEmpty(objPOSTBody)) {
                            this.set("_sQuery", JSON.stringify(objPOSTBody));
                        }
                    }

                    this._setExtraActions();
                } else {
                    // no results
                    var objNoAction = {
                        "sIcon": "not_interested",
                        "sIconClass": "a24-grey-text-darken-1",
                        "sTitle": this.get("_objSSAgencyStrings").getString("noAction"),
                        executeCardAction: function executeCardAction() {}
                    };

                    this.set("_arrExtraActions", [objNoAction]);
                }

                for (var l = 0; l < arrTableData.length; l++) {
                    if (!a24Core.isEmpty(arrTableData[l].diff_before) || !a24Core.isEmpty(arrTableData[l].diff_after)) {
                        Ember.set(arrTableData[l], "diff", true);
                    }
                }

                if (!a24Core.isEmpty(this.get("onDidDoServiceCall"))) {
                    this.get("onDidDoServiceCall")(arrTableData, jqXHR, objPOSTBody);
                }
            },
            onWillDoServiceCall: function onWillDoServiceCall() {
                if (this.get("_bHasExportPermission")) {
                    this.set("_sQuery", null);

                    this.set("_arrExtraActions", [{
                        bLoader: true
                    }]);
                }
            },

            onExportPopupCancel: function onExportPopupCancel() {
                if (!this.get("_bExportPopupLoading")) {
                    this.set("_bShowExportPopup", false);
                }
            },
            onExportPopupLoadingStateChange: function onExportPopupLoadingStateChange(bLoading) {
                this.set("_bExportPopupLoading", bLoading);
            },
            onPageReload: function onPageReload() {
                this.set("_bShowLoadFailedPopup", false);
                this.doServiceCalls();
            },
            cardDatagridTableCell: function cardDatagridTableCell(sColumnName, objRowData) {
                if (sColumnName === "ward_name") {
                    if (!a24Core.isEmpty(objRowData.ward_id)) {
                        this._viewClientInNewWindow(objRowData.ward_id, this.get("_objSSAgencyEnums.nodeLevel.WARD.backend"));
                    }
                } else if (sColumnName === "site_name") {
                    if (!a24Core.isEmpty(objRowData.site_id)) {
                        this._viewClientInNewWindow(objRowData.site_id, this.get("_objSSAgencyEnums.nodeLevel.SITE.backend"));
                    }
                }
            }
        }
    });
});