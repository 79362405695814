define("a24-staffshift-subdomain-agency/mixins/outside-click-handler-mixin", ["exports", "a24-ember-lib/mixins/outside-click-handler-mixin"], function (exports, _outsideClickHandlerMixin) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _outsideClickHandlerMixin.default;
    }
  });
});