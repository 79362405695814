define("a24-staffshift-subdomain-agency/components/notification-preview", ["exports", "a24-ember-staffshift-core/components/notification-preview"], function (exports, _notificationPreview) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _notificationPreview.default;
    }
  });
});