define("a24-staffshift-subdomain-agency/controllers/index/agency-list-conversations", ["exports", "a24-staffshift-subdomain-agency/mixins/query-param-object-builder"], function (exports, _queryParamObjectBuilder) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_queryParamObjectBuilder.default, {
        queryParams: ["chatterbox_profiles_entity_id", "channel_attributes__conversation_id", "type", "status", "medium", "created_at", "created_atFrom", "created_atTo", "closed_by_user_id", "closed_at", "closed_atFrom", "closed_atTo", "updated_at", "updated_atFrom", "updated_atTo", "team_team_id", "team__team_id", "user_id", "page", "sortBy", "vis_cols", "savedSearchKey"],
        chatterbox_profiles_entity_id: "",
        channel_attributes__conversation_id: "",
        type: "",
        status: "closed",
        medium: "",
        created_at: "",
        created_atFrom: "",
        created_atTo: "",
        closed_by_user_id: "",
        closed_at: "dateFilterLast7Days",
        closed_atFrom: "",
        closed_atTo: "",
        updated_at: "",
        updated_atFrom: "",
        updated_atTo: "",
        team_team_id: "",
        team__team_id: "",
        user_id: "",
        page: "1",
        sortBy: "closed_at::desc",
        vis_cols: "2,3,4,5,6,7,8,9,11",
        savedSearchKey: ""
    });
});