define("a24-staffshift-subdomain-agency/controllers/index/previously-booked", ["exports", "a24-staffshift-subdomain-agency/mixins/query-param-object-builder"], function (exports, _queryParamObjectBuilder) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_queryParamObjectBuilder.default, {

        queryParams: ["sOrderId", "candidate_full_name", "booking_count", "last_shift_date", "last_shift_dateFrom", "last_shift_dateTo", "is_job_title_match", "compliance_status", "dtBookedFor", "dtBookedForFrom", "dtBookedForTo", "exclusion_status", "sortBy", "vis_cols", "savedSearchKey"],
        sOrderId: "",
        candidate_full_name: "",
        booking_count: "",
        last_shift_date: "",
        last_shift_dateFrom: "",
        last_shift_dateTo: "",
        is_job_title_match: "",
        compliance_status: "",
        dtBookedFor: "",
        dtBookedForFrom: "",
        dtBookedForTo: "",
        exclusion_status: "",
        page: "1",
        sortBy: "",
        vis_cols: "1,2,3,4,6,7",
        savedSearchKey: "",

        _objPreviouslyBookedService: Ember.inject.service("previously-booked"),

        init: function init() {
            //NOTE the controller init will only fire once, when you navigate away and back to this route it will not init
            //again. Below dates seems to be fine since it only needs to compute it once per day
            var objQueryParams = this.get("_objPreviouslyBookedService").getBookedDateDefaultQueryValues({});

            this.set("dtBookedForFrom", objQueryParams.dtBookedForFrom.sValue);
            this.set("dtBookedFor", objQueryParams.dtBookedFor.sValue);

            this._super.apply(this, arguments);
        }
    });
});