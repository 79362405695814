define("a24-staffshift-subdomain-agency/components/icon-label-value-table-component", ["exports", "a24-ember-lib/components/icon-label-value-table-component"], function (exports, _iconLabelValueTableComponent) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _iconLabelValueTableComponent.default;
    }
  });
});