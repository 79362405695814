define("a24-staffshift-subdomain-agency/services/a24-constants-window", ["exports", "a24-ember-window/services/a24-constants-window"], function (exports, _a24ConstantsWindow) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _a24ConstantsWindow.default;
    }
  });
});