define("a24-staffshift-subdomain-agency/components/page-agency-list-compliance-contract", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-staffshift-subdomain-agency/config/environment"], function (exports, _didRenderChangesMixin, _environment) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        objQueryParams: null,
        bDatagridLoading: false,

        _bPageLoading: true,

        _objSSAgencyStrings: Ember.inject.service("a24-strings-staffshift-agency"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objSSAgencyEnums: Ember.inject.service("a24-enums-staffshift-agency"),
        _objUserSession: Ember.inject.service("user-session"),
        _objTitleBarService: Ember.inject.service("title-bar"),
        _objNavigation: Ember.inject.service("navigation"),
        _objRestEntityActionSection: Ember.inject.service("rest-entity-action-section"),
        _objRestEntityAction: Ember.inject.service("rest-entity-action"),
        _objEntityActionSectionPromise: null,
        _objEntityActionContractsPromise: null,
        _objFabButtonService: Ember.inject.service("fab-button"),

        _bReactPopupOpen: false,
        _sIFrameUrl: "",
        _bReactPopupLoading: false,
        _objReactPopupPostData: null,
        init: function init() {
            this._super.apply(this, arguments);

            this.get("_objTitleBarService").setTitleBar(this.get("_objSSAgencyStrings").getString("complianceContracts"));

            this.set("_sIFrameUrl", _environment.default.sReactGatewayUrl);

            // Build up the url with context id.
            this.set("_sUrlBase", _environment.default.sRestApiBaseUrl + "/v1/agency/" + this.get("_objNavigation.sContextId") + "/compliance-contract");

            this._setupPageConfig();
        },

        _setupPageConfig: function _setupPageConfig() {
            var _this = this;

            var arrComplianceContractStatus = this.get("_objSSAgencyEnums").getList("complianceContractStatus");
            var arrComplianceContractStatusDropdown = [];
            for (var y = 0; y < arrComplianceContractStatus.length; y++) {
                arrComplianceContractStatusDropdown.push({
                    sTitle: arrComplianceContractStatus[y].sLabel,
                    sValue: arrComplianceContractStatus[y].sValue
                });
            }

            // This array contain the datagrid columns
            var arrDataGridHeaderConfig = [{
                iUniqueColNum: 1,
                sTitle: this.get("_objSSAgencyStrings").getString("contract"),
                sProperty: "name",
                sFilterType: "text",
                bText: true,
                bHideEmptyCheck: true,
                iWidth: 15,
                bIsClickable: true
            }, {
                iUniqueColNum: 2,
                sTitle: this.get("_objStringsService").getString("description"),
                sProperty: "description",
                sFilterType: "text",
                bText: true,
                bStopFiltering: true,
                bStopColumnSort: true,
                iWidth: 15
            }, {
                iUniqueColNum: 3,
                sTitle: this.get("_objStringsService").getString("status"),
                sProperty: "status",
                sFilterType: "dropdown",
                iWidth: 5,
                bMapping: true,
                bHideEmptyCheck: true,
                arrMappingData: arrComplianceContractStatusDropdown
            }];

            var objThis = this;
            var arrSingleItemActions = [{
                sTitle: this.get("_objSSAgencyStrings").getString("viewComplianceContract"),
                sIcon: "visibility",
                executeCardAction: function executeCardAction() {
                    var objComplianceContract = objThis.get("_arrSelectedItems")[0];
                    objThis._viewComplianceContract(objComplianceContract._id);
                }
            }, {
                bLoader: true
            }];

            this.set("_arrSingleLoadingActions", arrSingleItemActions);

            this.set("_objDatagridConfig", {
                sDatagridHeader: this.get("_objSSAgencyStrings").getString("complianceContracts"),
                iMaxHeight: 40 * 27,
                bSelectColumn: true,
                bEmitSelectRowEvent: true,
                bHasReloadButton: true,
                bEmitDidDoServiceCall: true,
                arrSingleItemSelectActions: arrSingleItemActions,
                arrHeaderConfig: arrDataGridHeaderConfig
            });

            this.get("_objRestEntityActionSection").getEntityActionsForSection(this, "_objEntityActionSectionPromise", "compliance-contract", function (arrResponse) {
                var objShowFab = arrResponse.find(function (objEntityAction) {
                    var objExportAction = objEntityAction.actions.find(function (objAction) {
                        return objAction.action === "create";
                    });

                    if (!a24Core.isEmpty(objExportAction) && objExportAction.allowed) {
                        return true;
                    }

                    return false;
                });

                if (!a24Core.isEmpty(objShowFab)) {
                    // add FAB button
                    _this.get("_objFabButtonService").showFabButton(function () {
                        // open add contract popup
                        objThis.set("_bReactPopupOpen", true);
                        objThis.set("_objReactPopupPostData", {
                            type: "trigger_route",
                            data: {
                                route: "agency/complianceContracts/managementPopup"
                            }
                        });
                    });
                }
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode , sStatus, objErrorThrown, objError*/{
                // do nothing
            }));

            this.set("_bPageLoading", false);
        },
        _setDatagridActions: function _setDatagridActions() {
            var objThis = this;
            var arrEntityActions = this.get("_arrEntityActions");

            var arrSingleItemActions = [{
                sTitle: this.get("_objSSAgencyStrings").getString("viewComplianceContract"),
                sIcon: "visibility",
                executeCardAction: function executeCardAction() {
                    if (!a24Core.isEmpty(objThis.get("_arrSelectedItems"))) {
                        var objComplianceContract = objThis.get("_arrSelectedItems")[0];
                        objThis._viewComplianceContract(objComplianceContract._id);
                    }
                }
            }];

            if (!a24Core.isEmpty(arrEntityActions)) {

                var objUpdateAction = {
                    sTitle: this.get("_objSSAgencyStrings").getString("editComplianceContract"),
                    sIcon: "edit",
                    executeCardAction: function executeCardAction() {
                        if (!a24Core.isEmpty(objThis.get("_arrSelectedItems"))) {
                            var objComplianceContract = objThis.get("_arrSelectedItems")[0];
                            objThis._viewComplianceContract(objComplianceContract._id);
                        }
                    },
                    funcFilterFunc: function funcFilterFunc(arrSelectedItems) {
                        var bEdit = false;

                        var _iteratorNormalCompletion = true;
                        var _didIteratorError = false;
                        var _iteratorError = undefined;

                        try {
                            for (var _iterator = arrSelectedItems[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                                var objItem = _step.value;

                                bEdit = arrEntityActions.find(function (objEntityAction) {
                                    var objUpdateAction = objEntityAction.actions.find(function (objAction) {
                                        return objAction.action === "update";
                                    });

                                    if (!a24Core.isEmpty(objUpdateAction)) {
                                        if (objEntityAction.entity_id === objItem._id && objUpdateAction.allowed) {
                                            return true;
                                        }
                                    }

                                    return false;
                                });
                                if (!bEdit) {
                                    break;
                                }
                            }
                        } catch (err) {
                            _didIteratorError = true;
                            _iteratorError = err;
                        } finally {
                            try {
                                if (!_iteratorNormalCompletion && _iterator.return) {
                                    _iterator.return();
                                }
                            } finally {
                                if (_didIteratorError) {
                                    throw _iteratorError;
                                }
                            }
                        }

                        return bEdit;
                    }
                };

                arrSingleItemActions.push(objUpdateAction);

                var objActivateAction = {
                    sTitle: this.get("_objSSAgencyStrings").getString("activateComplianceContract"),
                    sIcon: "assignment_turned_in",
                    executeCardAction: function executeCardAction() {
                        objThis.set("_bReactPopupOpen", true);
                        var objItem = objThis.get("_arrSelectedItems")[0];

                        objThis.set("_objReactPopupPostData", {
                            type: "trigger_route",
                            data: {
                                route: "agency/complianceContract/activatePopup",
                                data: {
                                    _id: objItem._id,
                                    name: objItem.name
                                }
                            }
                        });
                    },
                    funcFilterFunc: function funcFilterFunc(arrSelectedItems) {
                        var bActivate = false;
                        var sContractStatusInactive = objThis.get("_objSSAgencyEnums").getBackendValue("complianceContractStatus", objThis.get("_objStringsService").getString("inactive"));

                        var _iteratorNormalCompletion2 = true;
                        var _didIteratorError2 = false;
                        var _iteratorError2 = undefined;

                        try {
                            for (var _iterator2 = arrSelectedItems[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                                var objItem = _step2.value;

                                bActivate = arrEntityActions.find(function (objEntityAction) {
                                    var objActivateAction = objEntityAction.actions.find(function (objAction) {
                                        return objAction.action === "activate";
                                    });

                                    if (!a24Core.isEmpty(objActivateAction)) {
                                        if (objEntityAction.entity_id === objItem._id && objActivateAction.allowed && objItem.status === sContractStatusInactive) {
                                            return true;
                                        }
                                    }

                                    return false;
                                });
                                if (!bActivate) {
                                    break;
                                }
                            }
                        } catch (err) {
                            _didIteratorError2 = true;
                            _iteratorError2 = err;
                        } finally {
                            try {
                                if (!_iteratorNormalCompletion2 && _iterator2.return) {
                                    _iterator2.return();
                                }
                            } finally {
                                if (_didIteratorError2) {
                                    throw _iteratorError2;
                                }
                            }
                        }

                        return bActivate;
                    }
                };

                arrSingleItemActions.push(objActivateAction);

                this.set("_arrSingleItemActions", arrSingleItemActions);

                this.set("_objDatagridConfig.arrSingleItemSelectActions", arrSingleItemActions);
            }
        },
        _viewComplianceContract: function _viewComplianceContract(sComplianceContractId) {
            this.set("_bReactPopupOpen", false);
            this.get("_objNavigation").navigateWithinContext("agency-compliance-contract-view", {
                "agency-compliance-contract-view": [sComplianceContractId]
            });
        },
        _doEntityActionCalls: function _doEntityActionCalls(arrContractIds) {
            var _this2 = this;

            if (a24Core.isEmpty(arrContractIds)) {
                this._setDatagridActions();
            } else {
                this.set("_objDatagridConfig.arrSingleItemSelectActions", Ember.copy(this.get("_arrSingleLoadingActions"), true));
                var objQuery = {
                    entity_id: { mValue: arrContractIds.join(",") }
                };

                this.get("_objRestEntityAction").getEntityActions(this, "_objEntityActionContractsPromise", "compliance-contract", objQuery, function (arrResponse) {
                    _this2.set("_arrEntityActions", arrResponse);
                    _this2._setDatagridActions();
                }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode , sStatus, objErrorThrown, objError*/{
                    _this2.set("_arrEntityActions", []);
                    _this2._setDatagridActions();
                }));
            }
        },
        // For selecting items
        _onItemsSelect: function _onItemsSelect(arrSelectedItems) {
            if (!a24Core.isEmpty(arrSelectedItems) && arrSelectedItems.length === 1) {
                this.set("_objSelectedItem", arrSelectedItems[0]);
            }
            // Set the selected items
            this.set("_arrSelectedItems", arrSelectedItems);
        },
        actions: {
            cardDatagridTableNoneSelected: function cardDatagridTableNoneSelected() {
                this._onItemsSelect(null);
            },
            cardDatagridTableOneSelected: function cardDatagridTableOneSelected(arrSelectedItems) {
                this._onItemsSelect(arrSelectedItems);
            },
            cardDatagridTableMultiSelected: function cardDatagridTableMultiSelected(arrSelectedItems) {
                this._onItemsSelect(arrSelectedItems);
            },
            cardDatagridTableCell: function cardDatagridTableCell(sColumnName, objRowData) {
                if (sColumnName === "name") {

                    var sComplianceContractId = objRowData._id;

                    this._viewComplianceContract(sComplianceContractId);
                }
            },
            onDidDoServiceCall: function onDidDoServiceCall(arrTableData, jqXHR, objPOSTBody) {
                var arrContractIds = [];
                for (var i = 0; i < arrTableData.length; i++) {
                    arrContractIds.push(arrTableData[i]._id);
                }
                this._doEntityActionCalls(arrContractIds);
                if (!a24Core.isEmpty(this.get("onDidDoServiceCall"))) {
                    this.get("onDidDoServiceCall")(arrTableData, jqXHR, objPOSTBody);
                }
            },

            onRecieveDataReactPopup: function onRecieveDataReactPopup(objData) {
                var _this3 = this;

                if (objData.type === "app_ready") {
                    this.set("_bReactPopupLoading", false);
                } else if (objData.type === "close") {
                    this.set("_bReactPopupOpen", false);
                } else if (objData.type === "refresh") {
                    this.set("_bPageLoading", true);
                    Ember.run.next(function () {
                        Ember.run.schedule("afterRender", function () {
                            _this3.set("_bPageLoading", false);
                        });
                    });
                }
            }
        }
    });
});