define("a24-staffshift-subdomain-agency/controllers/index/agency-candidate-search-view", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        _objNavigationService: Ember.inject.service("navigation"),
        _bLoading: true,
        _sCanId: null,
        _bServerError: false,
        _arrReloadActions: null,
        _objTitleBarService: Ember.inject.service("title-bar"),
        _objStringsCandidateProfile: Ember.inject.service("a24-strings-candidate-profile"),

        //NOTE the controller init will only fire once, when you navigate away and back to this route it will not init
        //again.
        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);
            this.get("_objNavigationService.objCurrentRouteParams");
            this.get("_objNavigationService.objNewRouteParams");

            this.set("_arrReloadActions", [{
                sTitle: this.get("_objStringsCandidateProfile").getString("reload"),
                sIcon: "refresh",
                executeCardAction: function executeCardAction() {
                    _this.set("_bLoading", true);
                    _this.set("_bServerError", false);
                    Ember.run.next(function () {
                        if (a24Core.isEmpty(_this)) {
                            return;
                        }
                        Ember.run.schedule("afterRender", function () {
                            _this.set("_bLoading", false);
                            _this._setupTabs();
                        });
                    });
                }
            }]);
        },

        _onNewRouteChange: Ember.observer("_objNavigationService.objNewRouteParams", function () {
            var objCandidateSearchViewParams = this.get("_objNavigationService.objNewRouteParams")["index.agency-candidate-search-view"];

            if (a24Core.isEmpty(objCandidateSearchViewParams)) {
                // This means the route will deactivate
                this.get("_objTitleBarService").setTabItems();
                this.set("_sCanId", "");
                this.set("_bLoading", true);
            } else if ( //When going into new route and the candidate is different we first go into loading
            this.get("_sCanId") !== objCandidateSearchViewParams.candidate_id) {
                //If candidate id changes then we force reload the route.
                if (!a24Core.isEmpty(this.get("_sCanId"))) {
                    this.get("_objTitleBarService").setTitleBar(this.get("_objStringsCandidateProfile").getString("candidateProfile"));
                    this.set("_bLoading", true);
                }
                this.set("_sCanId", objCandidateSearchViewParams.candidate_id);
            }
        }),

        _onCurrentRouteChange: Ember.observer("_objNavigationService.objCurrentRouteParams", function () {
            //Once we are in the new route we remove the loader so everything renders from scratch
            if (!a24Core.isEmpty(this.get("_objNavigationService.objCurrentRouteParams")) && !a24Core.isEmpty(this.get("_objNavigationService.objCurrentRouteParams")["index.agency-candidate-search-view"])) {
                if (this.get("_bLoading")) {
                    //This is for when candidate ID changes in the url, then the modelHook gets called again.
                    //So we need to re-setup the tabs.
                    this._setupTabs();
                    this.set("_bLoading", false);
                }
            }
        }),

        _setupTabs: function _setupTabs() {
            var objCandidateSearchViewParams = null;
            if (!a24Core.isEmpty(this.get("_objNavigationService.objRouteParams"))) {
                objCandidateSearchViewParams = this.get("_objNavigationService.objRouteParams")["index.agency-candidate-search-view"];
            }

            if (!a24Core.isEmpty(objCandidateSearchViewParams) && !a24Core.isEmpty(objCandidateSearchViewParams.candidate_id)) {
                this.get("_objTitleBarService").setTabItems(null, null, {
                    type: "trigger_route",
                    data: {
                        route: "general/tabs/agency/candidate",
                        data: {
                            candidateId: objCandidateSearchViewParams.candidate_id
                        }
                    }
                });
            } else {
                this.get("_objTitleBarService").setTabItems();
            }
        },

        _onTitlebarReceiveMessage: Ember.observer("_objNavigationService.objReactTabsReceiveMessage", function () {
            if (this.get("_objNavigationService.objReactTabsReceiveMessage.type") === "react_error" && this.get("_objNavigationService.objReactTabsReceiveMessage.data.code") === "tab_loading") {
                this.get("_objTitleBarService").setTitleBar(this.get("_objStringsCandidateProfile").getString("candidateProfile"));
                this.set("_bServerError", true);
            }
        })
    });
});