define("a24-staffshift-subdomain-agency/components/chatter-right-session-data", ["exports", "a24-ember-staffshift-core/components/chatter-right-session-data"], function (exports, _chatterRightSessionData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _chatterRightSessionData.default;
    }
  });
});