define("a24-staffshift-subdomain-agency/services/card-datagrid-custom-filter-availability", ["exports", "a24-ember-lib/services/card-datagrid-filter-custom-base"], function (exports, _cardDatagridFilterCustomBase) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _cardDatagridFilterCustomBase.default.extend({
        _objLibConstants: Ember.inject.service("a24-constants-ember-lib"),
        _objSSAgencyEnums: Ember.inject.service("a24-enums-staffshift-agency"),
        _objSSAgencyStrings: Ember.inject.service("a24-strings-staffshift-agency"),
        _objStrings: Ember.inject.service("a24-strings"),
        _objUserSession: Ember.inject.service("user-session"),

        funcFilterCustomPrimaryQuery: function funcFilterCustomPrimaryQuery(objHeaderItem /* , objHeaderSecondaries */) {
            var objQuery = {};

            var sRangeStart = "";
            var sRangeEnd = "";

            if (!a24Core.isEmpty(objHeaderItem._sFromDate)) {
                sRangeStart = this.getDateStringForRest(objHeaderItem._sFromDate, true);
            }

            if (!a24Core.isEmpty(objHeaderItem._sToDate)) {
                sRangeEnd = this.getDateStringForRest(objHeaderItem._sToDate, true);
            }

            var sShiftPeriods = "";
            if (!a24Core.isEmpty(objHeaderItem._arrSelectedShiftOptions)) {
                sShiftPeriods = objHeaderItem._arrSelectedShiftOptions.mapBy("sValue").join("|");
            }

            var sAvailableStatus = "";

            if (!a24Core.isEmpty(objHeaderItem._sAvailability.sValue)) {
                sAvailableStatus = objHeaderItem._sAvailability.sValue;
            }

            var sForDayValue = "";
            if (!a24Core.isEmpty(objHeaderItem._objForDate)) {
                sForDayValue = objHeaderItem._objForDate.sValue;
            }

            if (sForDayValue !== this.get("_objSSAgencyEnums.availabilityFilterDateOptions.DATE_FILTER_ANY_DATES_IN_RANGE.backend")) {

                objQuery.bEncode = true;
                objQuery.mValue = sAvailableStatus;

                if (!a24Core.isEmpty(objHeaderItem._sSpecificDate)) {
                    sRangeEnd = this.getDateStringForRest(objHeaderItem._sSpecificDate, true);
                    sRangeStart = sRangeEnd;
                }

                objQuery.arSec = [{
                    sProp: "period",
                    bDelimited: true,
                    bEncode: true,
                    mValue: sShiftPeriods
                }, {
                    sFilterValue: sForDayValue,
                    sProp: "range_start",
                    bTo: true,
                    bEncode: true,
                    mValue: sRangeStart
                }, {
                    sProp: "range_end",
                    bFrom: true,
                    bEncode: true,
                    mValue: sRangeEnd
                }];
            } else {

                var sRangeDates = this.getDatesBetweenRange(sRangeStart, sRangeEnd);

                objQuery.bContainsAny = true;
                objQuery.arExt = [{
                    sProp: "status",
                    bEncode: true,
                    mValue: sAvailableStatus,
                    arSec: [{
                        sProp: "period",
                        bDelimited: true,
                        bEncode: true,
                        mValue: sShiftPeriods
                    }, {
                        sProp: "range_start",
                        bTo: true,
                        bEncode: true,
                        mValue: sRangeStart
                    }, {
                        sProp: "range_end",
                        bFrom: true,
                        bEncode: true,
                        mValue: sRangeEnd
                    }]
                }, {
                    sProp: "status",
                    bEncode: true,
                    mValue: sAvailableStatus,
                    arSec: [{
                        sProp: "period",
                        bDelimited: true,
                        bEncode: true,
                        mValue: sShiftPeriods
                    }, {
                        sProp: "range_start",
                        bDelimited: true,
                        bEncode: true,
                        mValue: sRangeDates
                    }]
                }, {
                    sProp: "status",
                    bEncode: true,
                    mValue: sAvailableStatus,
                    arSec: [{
                        sProp: "period",
                        bDelimited: true,
                        bEncode: true,
                        mValue: sShiftPeriods
                    }, {
                        sProp: "range_end",
                        bDelimited: true,
                        bEncode: true,
                        mValue: sRangeDates
                    }]
                }];
            }

            return objQuery;
        },

        getDateStringForRest: function getDateStringForRest(sDate) {
            var objDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));

            objDate.setDateFromBrowser(sDate, this.get("_objLibConstants.DATE_FORMAT"));

            return objDate.getDateStringForRest(true);
        },

        getDatesBetweenRange: function getDatesBetweenRange(sFromDate, sToDate) {
            var objCurrentDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));

            objCurrentDate.setDateFromBrowser(sFromDate, a24Constants.DATE_FORMAT_REST_FORMAT_DATE_ONLY);

            var sCurrentDate = objCurrentDate.getDateStringForRest(true);

            var arrDates = [];

            while (!momentHelper.isAfter(sCurrentDate, a24Constants.DATE_FORMAT_REST_FORMAT_DATE_ONLY, sToDate, a24Constants.DATE_FORMAT_REST_FORMAT_DATE_ONLY)) {
                arrDates.push(sCurrentDate);

                objCurrentDate.addTimeToDate(this.get("_objLibConstants.MOMENT_TIME_DAYS"), 1);

                sCurrentDate = objCurrentDate.getDateStringForRest(true);
            }

            return arrDates.join("|");
        },

        funcFilterCustomTag: function funcFilterCustomTag(objHeaderItem, objQuery, objHeadersSecondaries, bSkipTitle) {
            var sStrAvailabilityTagKey = "availabilityTag";
            if (bSkipTitle) {
                sStrAvailabilityTagKey = "availabilityTagNoTitle";
            }
            if (a24Core.isEmpty(objQuery.bContainsAny)) {
                var sAvailableStatus = "";
                if (!a24Core.isEmpty(objQuery.mValue)) {
                    sAvailableStatus = this.get("_objSSAgencyEnums").getFrontendValue("availabilityOptions", objQuery.mValue);
                }

                var sForDayValue = "";
                var sShiftPeriodFrontEndList = "";
                var arrShiftPeriods = "";
                var sRangeStart = "";
                var sRangeEnd = "";

                if (!a24Core.isEmpty(objQuery.arSec)) {
                    for (var i = 0; i < objQuery.arSec.length; i++) {
                        if (!a24Core.isEmpty(objQuery.arSec[i].sFilterValue)) {
                            sForDayValue = objQuery.arSec[i].sFilterValue;
                        }

                        if (!a24Core.isEmpty(objQuery.arSec[i].sProp) && objQuery.arSec[i].sProp === "period") {
                            arrShiftPeriods = objQuery.arSec[i].mValue.split("|");
                            for (var n = 0; n < arrShiftPeriods.length; n++) {
                                var sShiftPeriodFrontEnd = this.get("_objSSAgencyEnums").getFrontendValue("shiftOptions", arrShiftPeriods[n]);
                                if (n > 0) {
                                    sShiftPeriodFrontEndList = sShiftPeriodFrontEndList + ", " + sShiftPeriodFrontEnd;
                                } else {
                                    sShiftPeriodFrontEndList = sShiftPeriodFrontEnd;
                                }
                            }
                        }

                        if (!a24Core.isEmpty(objQuery.arSec[i].sProp) && objQuery.arSec[i].sProp === "range_start") {
                            sRangeStart = objQuery.arSec[i].mValue;
                        }
                        if (!a24Core.isEmpty(objQuery.arSec[i].sProp) && objQuery.arSec[i].sProp === "range_end") {
                            sRangeEnd = objQuery.arSec[i].mValue;
                        }
                    }
                }

                var sDatePart = "";
                var sSelectedFromDate = "";
                var sSelectedToDate = "";

                switch (sForDayValue) {
                    case this.get("_objSSAgencyEnums.availabilityFilterDateOptions.DATE_FILTER_TODAY.backend"):
                        sDatePart = this.get("_objStrings").getString("dateFilterToday");

                        break;
                    case this.get("_objSSAgencyEnums.availabilityFilterDateOptions.DATE_FILTER_TOMORROW.backend"):
                        sDatePart = this.get("_objSSAgencyStrings").getString("dateFilterTomorrow");

                        break;
                    case this.get("_objSSAgencyEnums.availabilityFilterDateOptions.DATE_FILTER_NEXT_5_DAYS.backend"):
                        sSelectedFromDate = momentHelper.stringFormat(sRangeStart, a24Constants.DATE_FORMAT_REST_FORMAT, this.get("_objLibConstants.DATE_FORMAT"));

                        sSelectedToDate = momentHelper.stringFormat(sRangeEnd, a24Constants.DATE_FORMAT_REST_FORMAT, this.get("_objLibConstants.DATE_FORMAT"));

                        sDatePart = this.get("_objSSAgencyStrings").getTokenString("next5Days", sSelectedFromDate, sSelectedToDate);
                        break;
                    case this.get("_objSSAgencyEnums.availabilityFilterDateOptions.DATE_FILTER_SPECIFIC_DATE.backend"):
                        sDatePart = momentHelper.stringFormat(sRangeStart, a24Constants.DATE_FORMAT_REST_FORMAT, this.get("_objLibConstants.DATE_FORMAT"));

                        break;
                    case this.get("_objSSAgencyEnums.availabilityFilterDateOptions.DATE_FILTER_ALL_DATES_IN_RANGE.backend"):

                        sSelectedFromDate = momentHelper.stringFormat(sRangeStart, a24Constants.DATE_FORMAT_REST_FORMAT, this.get("_objLibConstants.DATE_FORMAT"));

                        sSelectedToDate = momentHelper.stringFormat(sRangeEnd, a24Constants.DATE_FORMAT_REST_FORMAT, this.get("_objLibConstants.DATE_FORMAT"));

                        sDatePart = this.get("_objSSAgencyStrings").getTokenString("allDatesRange", sSelectedFromDate, sSelectedToDate);

                        break;
                    default:
                        break;
                }

                return {
                    sTag: this.get("_objSSAgencyStrings").getTokenString(sStrAvailabilityTagKey, sAvailableStatus, sDatePart, sShiftPeriodFrontEndList)
                };
            } else {
                //  any dates in range
                var sAnyDatesAvailableStatus = "";
                var sAnyDatesShiftPeriod = "";
                var arrAnyDatesShiftPeriods = null;
                var sAnyDatesShiftPeriodFrontEndList = "";
                var sDateRangeStart = "";
                var sDateRangeEnd = "";
                if (!a24Core.isEmpty(objQuery.arExt)) {
                    for (var _i = 0; _i < objQuery.arExt.length; _i++) {
                        if (!a24Core.isEmpty(objQuery.arExt[_i].arSec) && objQuery.arExt[_i].arSec.length === 3) {
                            sAnyDatesAvailableStatus = this.get("_objSSAgencyEnums").getFrontendValue("availabilityOptions", objQuery.arExt[_i].mValue);

                            for (var j = 0; j < objQuery.arExt[_i].arSec.length; j++) {
                                if (!a24Core.isEmpty(objQuery.arExt[_i].arSec[j].sProp) && objQuery.arExt[_i].arSec[j].sProp === "period") {
                                    sAnyDatesShiftPeriod = objQuery.arExt[_i].arSec[j].mValue;

                                    arrAnyDatesShiftPeriods = sAnyDatesShiftPeriod.split("|");

                                    for (var _n = 0; _n < arrAnyDatesShiftPeriods.length; _n++) {
                                        var sAnyDatesShiftPeriodFrontEnd = this.get("_objSSAgencyEnums").getFrontendValue("shiftOptions", arrAnyDatesShiftPeriods[_n]);
                                        if (_n > 0) {
                                            sAnyDatesShiftPeriodFrontEndList = sAnyDatesShiftPeriodFrontEndList + ", " + sAnyDatesShiftPeriodFrontEnd;
                                        } else {
                                            sAnyDatesShiftPeriodFrontEndList = sAnyDatesShiftPeriodFrontEnd;
                                        }
                                    }
                                }
                                if (!a24Core.isEmpty(objQuery.arExt[_i].arSec[j].sProp) && objQuery.arExt[_i].arSec[j].sProp === "range_start") {
                                    sDateRangeStart = objQuery.arExt[_i].arSec[j].mValue;
                                }
                                if (!a24Core.isEmpty(objQuery.arExt[_i].arSec[j].sProp) && objQuery.arExt[_i].arSec[j].sProp === "range_end") {
                                    sDateRangeEnd = objQuery.arExt[_i].arSec[j].mValue;
                                }
                            }
                        }
                    }
                }

                var sFromDate = momentHelper.stringFormat(sDateRangeStart, a24Constants.DATE_FORMAT_REST_FORMAT, this.get("_objLibConstants.DATE_FORMAT"));

                var sToDate = momentHelper.stringFormat(sDateRangeEnd, a24Constants.DATE_FORMAT_REST_FORMAT, this.get("_objLibConstants.DATE_FORMAT"));
                var sAnyDatePart = this.get("_objSSAgencyStrings").getTokenString("anyDatesRange", sFromDate, sToDate);

                return {
                    sTag: this.get("_objSSAgencyStrings").getTokenString(sStrAvailabilityTagKey, sAnyDatesAvailableStatus, sAnyDatePart, sAnyDatesShiftPeriodFrontEndList)
                };
            }
        },

        funcFilterCustomPrimaryReset: function funcFilterCustomPrimaryReset(objHeaderItem) {
            //Rest the state of the custom filter input
            Ember.set(objHeaderItem, "_sAvailability", null);
            Ember.set(objHeaderItem, "_sFromDate", null);
            Ember.set(objHeaderItem, "_sToDate", null);
            Ember.set(objHeaderItem, "_objForDate", null);
            Ember.set(objHeaderItem, "_sSpecificDate", null);
            Ember.set(objHeaderItem, "_arrSelectedShiftOptions", null);
        },

        funcFilterCustomFixupQuery: function funcFilterCustomFixupQuery(objHeader /*, objHeaderSecondaries */) {
            if (a24Core.isEmpty(objHeader.mFilterValue)) {
                return;
            }

            var sDateOption = "";
            var iRangeStartIndex = null;
            var iRangeEndIndex = null;
            for (var i = 0; i < objHeader.mFilterValue.length; i++) {
                if (a24Core.isEmpty(objHeader.mFilterValue[i].bContainsAny)) {
                    sDateOption = "";
                    iRangeStartIndex = null;
                    iRangeEndIndex = null;

                    for (var j = 0; j < objHeader.mFilterValue[i].arSec.length; j++) {
                        if (!a24Core.isEmpty(Ember.get(objHeader, "mFilterValue." + i + ".arSec." + j + ".sFilterValue"))) {
                            sDateOption = Ember.get(objHeader, "mFilterValue." + i + ".arSec." + j + ".sFilterValue");
                            iRangeStartIndex = j;
                        }
                        if (objHeader.mFilterValue[i].arSec[j].sProp == "range_end") {
                            iRangeEndIndex = j;
                        }
                    }

                    var objCurrentDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                    objCurrentDate.setTimeToStartOfDay();

                    switch (sDateOption) {
                        case this.get("_objSSAgencyEnums.availabilityFilterDateOptions.DATE_FILTER_TODAY.backend"):
                            Ember.set(objHeader, "mFilterValue." + i + ".arSec." + iRangeStartIndex + ".mValue", objCurrentDate.getDateStringForRest(true));
                            Ember.set(objHeader, "mFilterValue." + i + ".arSec." + iRangeEndIndex + ".mValue", objCurrentDate.getDateStringForRest(true));

                            break;
                        case this.get("_objSSAgencyEnums.availabilityFilterDateOptions.DATE_FILTER_TOMORROW.backend"):
                            objCurrentDate.addTimeToDate(this.get("_objLibConstants.MOMENT_TIME_DAYS"), 1);
                            Ember.set(objHeader, "mFilterValue." + i + ".arSec." + iRangeStartIndex + ".mValue", objCurrentDate.getDateStringForRest(true));
                            Ember.set(objHeader, "mFilterValue." + i + ".arSec." + iRangeEndIndex + ".mValue", objCurrentDate.getDateStringForRest(true));

                            break;
                        case this.get("_objSSAgencyEnums.availabilityFilterDateOptions.DATE_FILTER_NEXT_5_DAYS.backend"):
                            objCurrentDate.addTimeToDate(this.get("_objLibConstants.MOMENT_TIME_DAYS"), 1);
                            Ember.set(objHeader, "mFilterValue." + i + ".arSec." + iRangeStartIndex + ".mValue", objCurrentDate.getDateStringForRest(true));

                            objCurrentDate.addTimeToDate(this.get("_objLibConstants.MOMENT_TIME_DAYS"), 4);
                            Ember.set(objHeader, "mFilterValue." + i + ".arSec." + iRangeEndIndex + ".mValue", objCurrentDate.getDateStringForRest(true));
                            break;
                        case this.get("_objSSAgencyEnums.availabilityFilterDateOptions.DATE_FILTER_SPECIFIC_DATE.backend"):
                        case this.get("_objSSAgencyEnums.availabilityFilterDateOptions.DATE_FILTER_ALL_DATES_IN_RANGE.backend"):
                        case this.get("_objSSAgencyEnums.availabilityFilterDateOptions.DATE_FILTER_ANY_DATES_IN_RANGE.backend"):
                            //to cater for supported value
                            break;
                        default:
                            //Deal with unsupported values
                            Ember.set(objHeader, "mFilterValue." + i + ".arSec." + iRangeStartIndex + ".sFilterValue", this.get("_objSSAgencyEnums.availabilityFilterDateOptions.DATE_FILTER_ALL_DATES_IN_RANGE.backend"));
                            break;
                    }
                }
            }
        },

        funcFilterCustomSetEditState: function funcFilterCustomSetEditState(objHeaderItem, iIndex) {
            var objTag = objHeaderItem.mFilterValue[iIndex];

            if (objTag.bContainsAny) {
                Ember.set(objHeaderItem, "_objForDate", {
                    sValue: this.get("_objSSAgencyEnums.availabilityFilterDateOptions.DATE_FILTER_ANY_DATES_IN_RANGE.backend")
                });
                this._setHeaderItemValues(objHeaderItem, objTag.arExt[0]);
            } else {
                this._setHeaderItemValues(objHeaderItem, objTag);
            }
        },

        _setHeaderItemValues: function _setHeaderItemValues(objHeaderItem, objHeaderTag) {
            var arrAvailabilityOptions = this.get("_objSSAgencyEnums").getList("availabilityOptions");

            Ember.set(objHeaderItem, "_sAvailability", arrAvailabilityOptions.findBy("sValue", objHeaderTag.mValue));
            var sFilterValue = null;

            for (var j = 0; j < objHeaderTag.arSec.length; j++) {
                if (objHeaderTag.arSec[j].sProp === "range_start" && !a24Core.isEmpty(objHeaderTag.arSec[j].sFilterValue)) {
                    sFilterValue = objHeaderTag.arSec[j].sFilterValue;
                    Ember.set(objHeaderItem, "_objForDate", {
                        sValue: objHeaderTag.arSec[j].sFilterValue
                    });
                    break;
                }
            }

            for (var i = 0; i < objHeaderTag.arSec.length; i++) {
                var objSecondary = objHeaderTag.arSec[i];

                if (sFilterValue === this.get("_objSSAgencyEnums.availabilityFilterDateOptions.DATE_FILTER_SPECIFIC_DATE.backend")) {
                    if (objSecondary.sProp === "range_start") {
                        var sSpecificDate = momentHelper.stringTimezoneFormat(objSecondary.mValue, this.get("_objLibConstants.DATE_FORMAT_ISO_FORMAT"), this.get("_objUserSession.objContextDetails.sTimezone"), this.get("_objLibConstants.DATE_FORMAT"));
                        Ember.set(objHeaderItem, "_sSpecificDate", sSpecificDate);
                        break;
                    }
                } else if (a24Core.isEmpty(sFilterValue) || sFilterValue === this.get("_objSSAgencyEnums.availabilityFilterDateOptions.DATE_FILTER_ALL_DATES_IN_RANGE.backend") || sFilterValue === this.get("_objSSAgencyEnums.availabilityFilterDateOptions.DATE_FILTER_ANY_DATES_IN_RANGE.backend")) {
                    //Only needed to set the _sFromDate and _sToDate for the Any and All dates options since the rest will
                    //automatically set to correct days based on the sFilterValue
                    if (objSecondary.sProp === "range_start") {
                        var sFromDate = momentHelper.stringTimezoneFormat(objSecondary.mValue, this.get("_objLibConstants.DATE_FORMAT_ISO_FORMAT"), this.get("_objUserSession.objContextDetails.sTimezone"), this.get("_objLibConstants.DATE_FORMAT"));
                        Ember.set(objHeaderItem, "_sFromDate", sFromDate);
                    }

                    if (objSecondary.sProp === "range_end") {
                        var sToDate = momentHelper.stringTimezoneFormat(objSecondary.mValue, this.get("_objLibConstants.DATE_FORMAT_ISO_FORMAT"), this.get("_objUserSession.objContextDetails.sTimezone"), this.get("_objLibConstants.DATE_FORMAT"));
                        Ember.set(objHeaderItem, "_sToDate", sToDate);
                    }
                }

                if (objSecondary.sProp === "period") {
                    var arrPeriods = objSecondary.mValue.split("|");
                    var arrShiftsPeriods = [];
                    for (var m = 0; m < arrPeriods.length; m++) {
                        arrShiftsPeriods.push({
                            sValue: arrPeriods[m]
                        });
                    }
                    Ember.set(objHeaderItem, "_arrSelectedShiftOptions", arrShiftsPeriods);
                }
            }
        }
    });
});