define('a24-staffshift-subdomain-agency/services/rest-agency-group-status-details', ['exports', 'a24-ember-candidate-profile/services/rest-agency-group-status-details'], function (exports, _restAgencyGroupStatusDetails) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _restAgencyGroupStatusDetails.default;
    }
  });
});