define("a24-staffshift-subdomain-agency/components/page-agency-list-conversations", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-staffshift-subdomain-agency/config/environment"], function (exports, _didRenderChangesMixin, _environment) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        objQueryParams: null,
        bDatagridLoading: false,

        _bPageLoading: false,
        _bShowConversationPreviewPopup: false,
        _bShowLoadFailedPopup: false,

        _objSelectedItem: null,
        _objChatTeams: null,

        _arrChatTeams: null,

        _sUrlBase: null,
        _sConversationId: null,

        _sIFrameUrl: null,
        _objReactPopupPostData: null,
        _bShowReactPopup: false,
        _bReactPopupLoading: true,

        _objNavigation: Ember.inject.service("navigation"),
        _objTitleBarService: Ember.inject.service("title-bar"),
        _objSnackbarService: Ember.inject.service("snackbar"),

        _objChatTeamsService: Ember.inject.service("rest-chat-teams"),
        _objRestChatConversation: Ember.inject.service("rest-chat-conversations"),

        _objReactEmberUtils: Ember.inject.service("react-ember-utils"),

        _objSSCoreEnum: Ember.inject.service("a24-enums-staffshift-core"),
        _objSSAgencyEnums: Ember.inject.service("a24-enums-staffshift-agency"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objSSCoreStrings: Ember.inject.service("a24-strings-staffshift-core"),
        _objSSAgencyStrings: Ember.inject.service("a24-strings-staffshift-agency"),
        _objQueryParamBuilderService: Ember.inject.service("query-param-object-builder"),

        init: function init() {
            this._super.apply(this, arguments);

            this.get("_objTitleBarService").setTitleBar(this.get("_objSSCoreStrings").getString("listConversations"));

            this.set("_sIFrameUrl", _environment.default.sReactGatewayUrl);

            // Build up the url with context id.
            this.set("_sUrlBase", _environment.default.sRestApiBaseUrl + "/v1/chat/conversations");

            this._doServiceCalls();
        },

        _doServiceCalls: function _doServiceCalls() {
            var _this = this;

            this.set("_bPageLoading", true);

            var sAgencyId = this.get("_objNavigation.sContextId");
            a24RestUrlConstruct.setBaseUrl("/v1/agency/" + sAgencyId + "/user");
            a24RestUrlConstruct.addQueryParamObject({
                items_per_page: { mValue: 1000 }
            });

            var sUserEndpoint = _environment.default.sRestApiBaseUrl + a24RestUrlConstruct.getConstructedUrl();

            var objUsersPromise = this.get("_objReactEmberUtils").makeReactApiRequest({
                sEndpoint: sUserEndpoint,
                objOptions: {
                    noAbortError: true,
                    method: 'GET',
                    fetchAll: true,
                    useCache: 24,
                    cacheTimeUnit: 'hours'
                },
                objParams: {}
            });

            var objChatTeamsPromise = this.get("_objChatTeamsService").getTeams(this, "_objChatTeams", function () {}, // handled in rsvp
            a24RestCallHelper.createJsonAjaxFailureObject(["500", "502", "503", "504"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                _this._handleServerErrors();
            }));

            var objPromiseList = {
                _objUsersPromise: objUsersPromise,
                _objChatTeamsPromise: objChatTeamsPromise
            };

            var objThis = this;

            var arrRequiredResponses = ["_objUsersPromise", "_objChatTeamsPromise"];

            Ember.copy(a24RSVP).configure(this, objPromiseList, [{
                arrRequiredResponses: arrRequiredResponses,
                funcOnComplete: function funcOnComplete(objResponses) {
                    if (!a24Core.isEmpty(objResponses._objUsersPromise)) {
                        var arrUsers = objResponses._objUsersPromise.objResponse;

                        if (a24Core.isEmpty(arrUsers)) {
                            arrUsers = [];
                        }
                    } else {
                        objThis.set("_bShowLoadFailedPopup", true);
                    }

                    if (!a24Core.isEmpty(objResponses._objChatTeamsPromise)) {
                        var arrChatTeams = objResponses._objChatTeamsPromise.objResponse;

                        if (a24Core.isEmpty(arrChatTeams)) {
                            arrChatTeams = [];
                        }
                    } else {
                        objThis.set("_bShowLoadFailedPopup", true);
                    }

                    if (!a24Core.isEmpty(objResponses._objUsersPromise) && !a24Core.isEmpty(objResponses._objChatTeamsPromise)) {
                        objThis._setupPageConfig(arrUsers, arrChatTeams);
                    }
                }
            }]);
        },

        _setupPageConfig: function _setupPageConfig(arrUsers, arrChatTeams) {
            var _this2 = this;

            var arrUsersDropdown = [];
            for (var i = 0; i < arrUsers.length; i++) {
                arrUsersDropdown.push({
                    sTitle: arrUsers[i].sFirstName + " " + arrUsers[i].sLastName,
                    sValue: arrUsers[i].id
                });
            }

            var arrTeamsDropdown = [];
            arrTeamsDropdown.push({
                sTitle: this.get("_objStringsService").getString("any"),
                sValue: "*"
            });

            for (var l = 0; l < arrChatTeams.length; l++) {
                arrTeamsDropdown.push({
                    sTitle: arrChatTeams[l].name,
                    sValue: arrChatTeams[l]._id
                });
            }

            var arrConversationMedium = this.get("_objSSCoreEnum").getList("conversationMedium");
            var arrConversationMediumOptions = [];
            for (var j = 0; j < arrConversationMedium.length; j++) {
                arrConversationMediumOptions.push({
                    sTitle: arrConversationMedium[j].sLabel,
                    sValue: arrConversationMedium[j].sValue
                });
            }

            var arrCoversationStatuses = this.get("_objSSCoreEnum").getList("conversationStatus");
            var arrConversationStatusOptions = [];
            for (var n = 0; n < arrCoversationStatuses.length; n++) {
                arrConversationStatusOptions.push({
                    sTitle: arrCoversationStatuses[n].sLabel,
                    sValue: arrCoversationStatuses[n].sValue
                });
            }

            var arrConversationTypes = this.get("_objSSCoreEnum").getList("conversationTypes");
            var arrConversationTypesOptions = [];
            for (var k = 0; k < arrConversationTypes.length; k++) {
                arrConversationTypesOptions.push({
                    sTitle: arrConversationTypes[k].sLabel,
                    sValue: arrConversationTypes[k].sValue
                });
            }

            // This array contain the datagrid columns
            var arrDataGridHeaderConfig = [{
                iUniqueColNum: 1,
                sTitle: this.get("_objSSCoreStrings").getString("conversationWith"),
                sProperty: "chatterbox_profiles_entity_id",
                bStopColumnSort: true,
                iWidth: 15,
                bFilterOr: true,
                bSilent: true,
                sFilterType: "suggest",
                sReactFilterRoute: "datagrid/filters/entityCandidateClient",
                funcFilterParseQueryValue: function funcFilterParseQueryValue(objQueryObjectParam) {
                    var objQueryObject = Ember.copy(objQueryObjectParam, true);
                    objQueryObject = objQueryObject[0];

                    return objQueryObject;
                }
            }, {
                iUniqueColNum: 2,
                sTitle: this.get("_objSSCoreStrings").getString("conversationWith"),
                sProperty: "channel_attributes__conversation_id",
                bStopColumnSort: true,
                iWidth: 15,
                bStopFiltering: true,
                bIsClickable: true,
                bCustom: true,
                funcTableCustomVal: function funcTableCustomVal(objHeader, objRowData) {
                    if (!a24Core.isEmpty(objRowData.channel_attributes.inbox_conversation_name)) {
                        return Ember.String.htmlSafe("<span title=\"" + objRowData.channel_attributes.inbox_conversation_name + "\">" + objRowData.channel_attributes.inbox_conversation_name + "</span>");
                    } else if (!a24Core.isEmpty(objRowData.chatterbox_profiles) && !a24Core.isEmpty(objRowData.chatterbox_profiles[0].entity_name)) {
                        return Ember.String.htmlSafe("<span title=\"" + objRowData.chatterbox_profiles[0].entity_name + "\">" + objRowData.chatterbox_profiles[0].entity_name + "</span>");
                    }

                    return objRowData.channel_attributes.conversation_id;
                }
            }, {
                iUniqueColNum: 3,
                sTitle: this.get("_objSSCoreStrings").getString("type"),
                sProperty: "type",
                sFilterType: "dropdownMulti",
                bMapping: true,
                arrMappingData: arrConversationTypesOptions,
                iWidth: 10,
                bHideEmptyCheck: true,
                bStopColumnSort: true
            }, {
                iUniqueColNum: 4,
                sTitle: this.get("_objSSCoreStrings").getString("medium"),
                sProperty: "medium",
                sFilterType: "dropdownMulti",
                bMapping: true,
                arrMappingData: arrConversationMediumOptions,
                iWidth: 5,
                bHideEmptyCheck: true,
                bStopColumnSort: true
            }, {
                iUniqueColNum: 5,
                sTitle: this.get("_objStringsService").getString("status"),
                sProperty: "status",
                sFilterType: "dropdownMulti",
                bMapping: true,
                arrMappingData: arrConversationStatusOptions,
                iWidth: 5,
                bHideEmptyCheck: true,
                bStopColumnSort: true
            }, {
                iUniqueColNum: 6,
                sTitle: this.get("_objSSCoreStrings").getString("createdAt"),
                sProperty: "created_at",
                bStopColumnSort: true,
                bDate: true,
                sFilterType: "date",
                bFilterAllowTime: true,
                iWidth: 15
            }, {
                iUniqueColNum: 7,
                sTitle: this.get("_objSSCoreStrings").getString("updatedAt"),
                sProperty: "updated_at",
                bStopFiltering: true,
                bStopColumnSort: true,
                bDate: true,
                sFilterType: "date",
                bFilterAllowTime: true,
                iWidth: 10
            }, {
                iUniqueColNum: 8,
                sTitle: this.get("_objSSCoreStrings").getString("closedAt"),
                sProperty: "closed_at",
                bDate: true,
                sFilterType: "date",
                bFilterAllowTime: true,
                iWidth: 15
            }, {
                iUniqueColNum: 9,
                sTitle: this.get("_objSSCoreStrings").getString("closedBy"),
                sProperty: "closed_by_user_id",
                sFilterType: "suggest",
                iWidth: 10,
                bCustom: true,
                bStopColumnSort: true,
                bHideEmptyCheck: true,
                iFilterSuggestAt: 3,
                iFilterSuggestDelay: 500,
                sFilterOptionsValueKey: "_id",
                sFilterOptionsLabelKey: "name",
                bFilterShowLoaderDuringSuggest: true,
                bFilterAlwaysShowOnFocus: false,
                funcTableCustomVal: function funcTableCustomVal(objHeader, objRowData) {
                    // look up user
                    var objUser = arrUsersDropdown.find(function (objUser) {
                        return objUser.sValue === objRowData.closed_by_user_id;
                    });
                    if (!a24Core.isEmpty(objUser)) {
                        return Ember.String.htmlSafe("<span title=\"" + objUser.sTitle + "\">" + objUser.sTitle + "</span>");
                    }
                    return Ember.String.htmlSafe("<span title=\"" + objRowData.closed_by_user_id + "\">" + objRowData.closed_by_user_id + "</span>");
                },
                funcFilterGetSuggestions: function funcFilterGetSuggestions(sSuggestVal, funcCallback) {
                    var sAgencyId = _this2.get("_objNavigation.sContextId");
                    a24RestUrlConstruct.setBaseUrl("/v1/agency/" + sAgencyId + "/user");
                    var sSearch = "*" + encodeURIComponent(sSuggestVal) + "*";

                    var sUserEndpoint = _environment.default.sRestApiBaseUrl + a24RestUrlConstruct.getConstructedUrl();
                    var objUsersPromise = _this2.get("_objReactEmberUtils").makeReactApiRequest({
                        sEndpoint: sUserEndpoint,
                        objOptions: {
                            noAbortError: true,
                            method: 'GET',
                            fetchAll: false,
                            useCache: 24,
                            cacheTimeUnit: 'hours'
                        },
                        objParams: {
                            items_per_page: 10,
                            q: sSearch
                        }
                    });
                    objUsersPromise.then(function (arrData) {
                        var arrResult = [];
                        // If the data is empty, ensure it's an empty array
                        if (a24Core.isEmpty(arrData)) {
                            arrData = [];
                        }

                        for (var i = 0; i < arrData.length; i++) {
                            var sNameUserName = arrData[i].sFirstName + " " + arrData[i].sLastName + " - " + arrData[i].sUsername;

                            arrResult.push({
                                _id: arrData[i].id,
                                name: sNameUserName
                            });
                        }

                        // Call the callback with the formatted data
                        funcCallback(arrResult);
                    }).catch(function () {
                        a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                            // Call the callback with empty
                            funcCallback([]);
                        });
                    });
                }
            }, {
                iUniqueColNum: 10,
                sTitle: this.get("_objSSCoreStrings").getString("teamName"),
                sProperty: "team_team_id",
                sFilterType: "dropdownMulti",
                bStopColumnSort: true,
                bMapping: true,
                bSilent: true,
                arrMappingData: arrTeamsDropdown,
                iWidth: 10
            }, {
                iUniqueColNum: 11,
                sTitle: this.get("_objSSCoreStrings").getString("teamName"),
                sProperty: "team__team_id",
                sFilterType: "dropdownMulti",
                bStopFiltering: true,
                bStopColumnSort: true,
                bMapping: true,
                arrMappingData: arrTeamsDropdown,
                iWidth: 10
            }, {
                iUniqueColNum: 12,
                sTitle: this.get("_objSSCoreStrings").getString("participant"),
                sProperty: "user_id",
                sFilterType: "suggest",
                bSilent: true,
                iWidth: 10,
                bCustom: true,
                bStopColumnSort: true,
                bHideEmptyCheck: true,
                iFilterSuggestAt: 3,
                iFilterSuggestDelay: 500,
                sFilterOptionsValueKey: "_id",
                sFilterOptionsLabelKey: "name",
                bFilterShowLoaderDuringSuggest: true,
                bFilterAlwaysShowOnFocus: false,
                funcTableCustomVal: function funcTableCustomVal(objHeader, objRowData) {
                    // look up user
                    var objUser = arrUsersDropdown.find(function (objUser) {
                        return objUser.sValue === objRowData.user_id;
                    });
                    if (!a24Core.isEmpty(objUser)) {
                        return Ember.String.htmlSafe("<span title=\"" + objUser.sTitle + "\">" + objUser.sTitle + "</span>");
                    }
                },
                funcFilterGetSuggestions: function funcFilterGetSuggestions(sSuggestVal, funcCallback) {
                    var sAgencyId = _this2.get("_objNavigation.sContextId");
                    a24RestUrlConstruct.setBaseUrl("/v1/agency/" + sAgencyId + "/user");
                    var sSearch = "*" + encodeURIComponent(sSuggestVal) + "*";

                    var sUserEndpoint = _environment.default.sRestApiBaseUrl + a24RestUrlConstruct.getConstructedUrl();
                    var objUsersPromise = _this2.get("_objReactEmberUtils").makeReactApiRequest({
                        sEndpoint: sUserEndpoint,
                        objOptions: {
                            noAbortError: true,
                            method: 'GET',
                            fetchAll: false,
                            useCache: 24,
                            cacheTimeUnit: 'hours'
                        },
                        objParams: {
                            items_per_page: 10,
                            q: sSearch
                        }
                    });
                    objUsersPromise.then(function (arrData) {
                        var arrResult = [];
                        // If the data is empty, ensure it's an empty array
                        if (a24Core.isEmpty(arrData)) {
                            arrData = [];
                        }

                        for (var i = 0; i < arrData.length; i++) {
                            var sNameUserName = arrData[i].sFirstName + " " + arrData[i].sLastName + " - " + arrData[i].sUsername;

                            arrResult.push({
                                _id: arrData[i].id,
                                name: sNameUserName
                            });
                        }

                        // Call the callback with the formatted data
                        funcCallback(arrResult);
                    }).catch(function () {
                        a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                            // Call the callback with empty
                            funcCallback([]);
                        });
                    });
                }

            }];

            var arrSingleItemActions = [{
                sTitle: this.get("_objSSCoreStrings").getString("viewConversationPreview"),
                sIcon: "open_in_browser",
                executeCardAction: function executeCardAction() {
                    var objConversation = _this2.get("_arrSelectedItems")[0];
                    _this2._viewConversationPreview(objConversation.channel_attributes.conversation_id);
                }
            }];

            this.set("_objDatagridConfig", {
                sDatagridHeader: this.get("_objSSCoreStrings").getString("listConversations"),
                iMaxHeight: 40 * 27,
                bSelectColumn: true,
                bEmitSelectRowEvent: true,
                bHasReloadButton: true,
                bEmitWillDoServiceCall: true,
                sReactIFrameUrl: _environment.default.sReactGatewayUrl,
                arrSingleItemSelectActions: arrSingleItemActions,
                arrHeaderConfig: arrDataGridHeaderConfig
            });

            this.set("_bPageLoading", false);
        },

        _viewConversationPreview: function _viewConversationPreview(sConversationId) {
            this.set("_bShowReactPopup", true);
            this.set("_objReactPopupPostData", {
                type: "trigger_route",
                data: {
                    route: "agency/conversation/previewPopup",
                    data: { conversationId: sConversationId }
                }
            });
        },

        // For selecting items
        _onItemsSelect: function _onItemsSelect(arrSelectedItems) {
            if (!a24Core.isEmpty(arrSelectedItems) && arrSelectedItems.length === 1) {
                this.set("_objSelectedItem", arrSelectedItems[0]);
            }
            // Set the selected items
            this.set("_arrSelectedItems", arrSelectedItems);
        },
        _handleServerErrors: function _handleServerErrors() {
            var _this3 = this;

            Ember.run.next(function () {
                if (a24Core.isEmpty(_this3)) {
                    return;
                }
                _this3.set("_bShowLoadFailedPopup", true);
            });
        },
        _refresh: function _refresh() {
            var _this4 = this;

            this.set("_bShowReactPopup", false);
            // Put the page in loading
            this.set("_bPageLoading", true);

            Ember.run.next(function () {
                if (a24Core.isEmpty(_this4)) {
                    return;
                }
                Ember.run.schedule("afterRender", function () {
                    // Take page out of loading
                    _this4.set("_bPageLoading", false);
                });
            });
        },
        _viewCandidateInNewWindow: function _viewCandidateInNewWindow(sCandidateId) {
            var sLink = this.get("_objNavigation").navigateWithinContext("agency-candidate-search-view", {
                "agency-candidate-search-view": [sCandidateId]
            }, null, true);

            this.openLink(sLink);
        },
        _viewClientInNewWindow: function _viewClientInNewWindow(sClientId) {
            var _this5 = this;

            var sAgencyId = this.get("_objNavigation.sContextId");
            a24RestUrlConstruct.setBaseUrl("/v1/agency/" + sAgencyId + "/advanced-search/agency-client");
            a24RestUrlConstruct.addQueryParamObject({
                items_per_page: { mValue: 1000 }
            });

            var sClientEndpoint = _environment.default.sRestApiBaseUrl + a24RestUrlConstruct.getConstructedUrl();

            var objClientPromise = this.get("_objReactEmberUtils").makeReactApiRequest({
                sEndpoint: sClientEndpoint,
                objOptions: {
                    noAbortError: true,
                    method: 'POST',
                    fetchAll: true,
                    useCache: 1,
                    cacheTimeUnit: 'hours',
                    body: {
                        query: {
                            and: [{ client_id: { equal: sClientId } }]
                        },
                        items_per_page: 1
                    }
                },
                objParams: {}
            });

            objClientPromise.then(function (arrResponse) {
                var sRoute = "";
                var objRouteParams = {};
                switch (arrResponse[0].node_level) {
                    case _this5.get("_objSSAgencyEnums.nodeLevel.SITE.backend"):
                        sRoute = "agency-client-site-view";
                        break;
                    case _this5.get("_objSSAgencyEnums.nodeLevel.WARD.backend"):
                        sRoute = "agency-client-ward-view";
                        break;
                    case _this5.get("_objSSAgencyEnums.nodeLevel.ORGANISATION.backend"):
                        sRoute = "agency-client-org-view";
                        break;
                    default:
                        break;
                }

                objRouteParams[sRoute] = [sClientId];
                var sLink = _this5.get("_objNavigation").navigateWithinContext(sRoute, objRouteParams, null, true);
                _this5.openLink(sLink);
            });
        },
        openLink: function openLink(sLink) {
            window.open(sLink);
        },

        actions: {
            cardDatagridTableNoneSelected: function cardDatagridTableNoneSelected() {
                this._onItemsSelect(null);
            },
            cardDatagridTableOneSelected: function cardDatagridTableOneSelected(arrSelectedItems) {
                this._onItemsSelect(arrSelectedItems);
            },
            cardDatagridTableMultiSelected: function cardDatagridTableMultiSelected(arrSelectedItems) {
                this._onItemsSelect(arrSelectedItems);
            },
            cardDatagridTableCell: function cardDatagridTableCell(sColumnName, objRowData) {
                if (sColumnName === "channel_attributes__conversation_id") {

                    if (!a24Core.isEmpty(objRowData.channel_attributes) && !a24Core.isEmpty(objRowData.channel_attributes.chatterbox_profile)) {
                        var objProfile = objRowData.channel_attributes.chatterbox_profile;
                        if (objProfile.entity_type === "client") {
                            this._viewClientInNewWindow(objProfile.entity_id);
                        } else if (objProfile.entity_type === "candidate") {
                            this._viewCandidateInNewWindow(objProfile.entity_id);
                        } else {
                            this.get("_objSnackbarService").showSnackbarMessage(this.get("_objSSAgencyStrings").getString("unableToOpenProfile"));
                        }
                    } else {
                        this.get("_objSnackbarService").showSnackbarMessage(this.get("_objSSAgencyStrings").getString("unableToOpenProfile"));
                    }
                }
            },
            onWillDoServiceCall: function onWillDoServiceCall(arrTableData, objUrlConstantPart, objFilterParamObject) {
                if (!a24Core.isEmpty(objFilterParamObject.created_at)) {
                    if (objFilterParamObject.created_at.bFrom) {
                        objFilterParamObject.created_at_from = {
                            mValue: encodeURIComponent(objFilterParamObject.created_at.mValue)
                        };
                    } else if (objFilterParamObject.created_at.bNotWithinLast) {
                        objFilterParamObject.created_at_from = {
                            mValue: encodeURIComponent(objFilterParamObject.created_at.mValue.sFrom)
                        };
                    } else if (objFilterParamObject.created_at.bNotWithinNext) {
                        objFilterParamObject.created_at_to = {
                            mValue: encodeURIComponent(objFilterParamObject.created_at.mValue.sTo)
                        };
                    } else if (objFilterParamObject.created_at.bTo) {
                        objFilterParamObject.created_at_to = {
                            mValue: encodeURIComponent(objFilterParamObject.created_at.mValue)
                        };
                    } else if (objFilterParamObject.created_at.bFromAndTo) {
                        objFilterParamObject.created_at_from = {
                            mValue: encodeURIComponent(objFilterParamObject.created_at.mValue.sFrom)
                        };
                        objFilterParamObject.created_at_to = {
                            mValue: encodeURIComponent(objFilterParamObject.created_at.mValue.sTo)
                        };
                    }
                    delete objFilterParamObject.created_at;
                }
                if (!a24Core.isEmpty(objFilterParamObject.closed_at)) {

                    if (objFilterParamObject.closed_at.bFrom) {
                        objFilterParamObject.closed_at = {
                            mValue: encodeURIComponent(objFilterParamObject.closed_at.mValue)
                        };
                    } else if (objFilterParamObject.closed_at.bNotWithinLast) {
                        objFilterParamObject.closed_at_from = {
                            mValue: encodeURIComponent(objFilterParamObject.closed_at.mValue.sFrom)
                        };
                    } else if (objFilterParamObject.closed_at.bNotWithinNext) {
                        objFilterParamObject.closed_at_to = {
                            mValue: encodeURIComponent(objFilterParamObject.closed_at.mValue.sTo)
                        };
                    } else if (objFilterParamObject.closed_at.bTo) {
                        objFilterParamObject.closed_at_to = {
                            mValue: encodeURIComponent(objFilterParamObject.closed_at.mValue)
                        };
                    } else if (objFilterParamObject.closed_at.bFromAndTo) {
                        objFilterParamObject.closed_at_from = {
                            mValue: encodeURIComponent(objFilterParamObject.closed_at.mValue.sFrom)
                        };
                        objFilterParamObject.closed_at_to = {
                            mValue: encodeURIComponent(objFilterParamObject.closed_at.mValue.sTo)
                        };
                    }
                    delete objFilterParamObject.closed_at;
                }
                if (!a24Core.isEmpty(objFilterParamObject.team_team_id) && objFilterParamObject.team_team_id.mValue.includes("*")) {
                    objFilterParamObject.team_team_id_exists = {
                        mValue: true
                    };
                    delete objFilterParamObject.team_team_id;
                } else if (!a24Core.isEmpty(objFilterParamObject.team_team_id) && objFilterParamObject.team_team_id.mValue === "null") {
                    objFilterParamObject.team_team_id_exists = {
                        mValue: false
                    };
                    delete objFilterParamObject.team_team_id;
                }
            },
            onPageReload: function onPageReload() {
                this.set("_bShowLoadFailedPopup", false);
                this._doServiceCalls();
            },
            onRecieveData: function onRecieveData(objData) {
                if (objData.type === "app_ready") {
                    this.set("_bReactPopupLoading", false);
                } else if (objData.type === "refresh") {
                    this._refresh();
                } else if (objData.type === "close") {
                    this.set("_bShowReactPopup", false);
                }
            },

            onConversationPreviewPopupClose: function onConversationPreviewPopupClose() {
                this.set("_bShowConversationPreviewPopup", false);
            }
        }
    });
});