define("a24-staffshift-subdomain-agency/components/page-agency-organisation-exclusions", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-staffshift-subdomain-agency/config/environment", "a24-ember-lib/mixins/input-form-element"], function (exports, _didRenderChangesMixin, _environment, _inputFormElement) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, _inputFormElement.default, {
        objQueryParams: null,
        bDatagridLoading: false,

        _sUrlBase: null,
        _bPageLoading: true,
        _sOrganisationId: false,

        // Services
        _objTitleBarService: Ember.inject.service("title-bar"),
        _objNavigation: Ember.inject.service("navigation"),
        _objCandidateProfileStringsService: Ember.inject.service("a24-strings-candidate-profile"),
        _objSSCoreStringsService: Ember.inject.service("a24-strings-staffshift-core"),
        _objCandidateProfileEnumsService: Ember.inject.service("a24-enums-candidate-profile"),
        _objReactEmberUtils: Ember.inject.service("react-ember-utils"),
        _objRestEntityAction: Ember.inject.service("rest-entity-action"),
        _objStringsService: Ember.inject.service("a24-strings"),

        _sIFrameUrl: "",

        // react popup
        _bReactPopupOpen: false,
        _bReactPopupLoading: false,
        _objReactPopupPostData: null,

        _objSelectedItem: null,
        _arrSelectedItems: null,
        _arrComplaintType: null,
        _objEntityActionPromise: null,
        _bEntityActionPending: false,
        init: function init() {
            this._super.apply(this, arguments);

            this.get("_objTitleBarService").setTitleBar(this.get("_objCandidateProfileStringsService").getString("exclusions"));

            var objOrganisationViewParams = this.get("_objNavigation.objRouteParams")["index.agency-client-org-view"];

            if (!a24Core.isEmpty(objOrganisationViewParams)) {
                this.set("_sOrganisationId", objOrganisationViewParams.organisation_id);
            }

            this.set("_sIFrameUrl", _environment.default.sReactGatewayUrl);

            // Build up the url with context id.
            this.set("_sUrlBase", _environment.default.sClientApiBaseUrl + "/v1/agency/" + this.get("_objNavigation.sContextId") + "/organisation/" + this.get("_sOrganisationId") + "/client-initiated-exclusion");

            this.doServiceCalls();
        },

        doServiceCalls: function doServiceCalls() {
            this.set("_bPageLoading", true);
            a24RestUrlConstruct.setBaseUrl('/v1/system/complaint-type');

            a24RestUrlConstruct.addQueryParamObject({
                items_per_page: { mValue: 1000 }
            });

            var sComplaintTypeEndpoint = _environment.default.sClientApiBaseUrl + a24RestUrlConstruct.getConstructedUrl();

            var objPromiseComplaintType = this.get("_objReactEmberUtils").makeReactApiRequest({
                sEndpoint: sComplaintTypeEndpoint,
                objOptions: {
                    noAbortError: true,
                    method: 'GET',
                    fetchAll: true,
                    useCache: 24,
                    cacheTimeUnit: 'hours'
                },
                objParams: {
                    sortBy: 'name'
                }
            });

            var objPromiseList = {
                _objPromiseComplaintType: objPromiseComplaintType
            };

            var objThis = this;

            Ember.copy(a24RSVP).configure(this, objPromiseList, [{
                arrRequiredResponses: ["_objPromiseComplaintType"],
                funcOnComplete: function funcOnComplete(objResponses) {
                    if (!a24Core.isEmpty(objResponses._objPromiseComplaintType) && !a24Core.isEmpty(objResponses._objPromiseComplaintType.objResponse)) {
                        var arrComplaintType = objResponses._objPromiseComplaintType.objResponse;

                        if (a24Core.isEmpty(arrComplaintType)) {
                            arrComplaintType = [];
                        }
                        objThis.set("_arrComplaintType", arrComplaintType);
                        objThis._setupPageConfig(arrComplaintType);
                    }
                }
            }]);
        },

        _setupPageConfig: function _setupPageConfig(arrComplaintType) {
            var objThis = this;

            var arrClientType = this.get("_objCandidateProfileEnumsService").getList("clientType");
            var arrClientTypeOptions = [];
            for (var i = 0; i < arrClientType.length; i++) {
                arrClientTypeOptions.push({
                    sTitle: arrClientType[i].sLabel,
                    sValue: arrClientType[i].sValue
                });
            }

            var arrSeverity = this.get("_objCandidateProfileEnumsService").getList("severity");
            var arrSeverityOptions = [];
            for (var j = 0; j < arrSeverity.length; j++) {
                arrSeverityOptions.push({
                    sTitle: arrSeverity[j].sLabel,
                    sValue: arrSeverity[j].sValue
                });
            }

            var arrComplaintTypeOptions = [];
            for (var k = 0; k < arrComplaintType.length; k++) {
                arrComplaintTypeOptions.push({
                    sTitle: arrComplaintType[k].name,
                    sValue: arrComplaintType[k]._id
                });
            }

            // This array contain the datagrid columns
            var arrDataGridHeaderConfig = [{
                iUniqueColNum: 1,
                sTitle: this.get("_objCandidateProfileStringsService").getString("site"),
                sProperty: "site_name",
                bText: true,
                iWidth: 15,
                bStopFiltering: true,
                bStopColumnSort: true
            }, {
                iUniqueColNum: 2,
                sTitle: this.get("_objCandidateProfileStringsService").getString("site"),
                sProperty: "site_id",
                bSilent: true,
                sFilterType: "suggest",
                bFilterOr: true,
                sReactFilterRoute: "datagrid/filters/client",
                funcFilterParseQueryValue: function funcFilterParseQueryValue(objQueryObjectParam) {
                    var objQueryObject = Ember.copy(objQueryObjectParam, true);
                    objQueryObject = objQueryObject[0];

                    return objQueryObject;
                }
            }, {
                iUniqueColNum: 3,
                sTitle: this.get("_objCandidateProfileStringsService").getString("ward"),
                sProperty: "ward_name",
                bText: true,
                iWidth: 15,
                bStopFiltering: true,
                bStopColumnSort: true
            }, {
                iUniqueColNum: 4,
                sTitle: this.get("_objSSCoreStringsService").getString("candidate"),
                sProperty: "candidate_id",
                bSilent: true,
                sFilterType: "suggest",
                bFilterOr: true,
                sReactFilterRoute: "datagrid/filters/candidate",
                funcFilterParseQueryValue: function funcFilterParseQueryValue(objQueryObjectParam) {
                    var objQueryObject = Ember.copy(objQueryObjectParam, true);
                    objQueryObject = objQueryObject[0];

                    return objQueryObject;
                }
            }, {
                iUniqueColNum: 5,
                sTitle: this.get("_objSSCoreStringsService").getString("candidate"),
                sProperty: "candidate_full_name",
                bText: true,
                iWidth: 15,
                bStopFiltering: true
            }, {
                iUniqueColNum: 6,
                sTitle: this.get("_objCandidateProfileStringsService").getString("appliesTo"),
                sProperty: "client_type",
                bMapping: true,
                arrMappingData: arrClientTypeOptions,
                iWidth: 10,
                bStopFiltering: true,
                bStopColumnSort: true
            }, {
                iUniqueColNum: 7,
                sTitle: this.get("_objCandidateProfileStringsService").getString("severity"),
                sProperty: "severity",
                sFilterType: "dropdownMulti",
                bHideEmptyCheck: true,
                iWidth: 5,
                bMapping: true,
                arrMappingData: arrSeverityOptions,
                bStopColumnSort: true
            }, {
                iUniqueColNum: 8,
                sTitle: this.get("_objCandidateProfileStringsService").getString("type"),
                sProperty: "complaint_type_name",
                bText: true,
                iWidth: 10,
                bStopFiltering: true,
                bStopColumnSort: true
            }, {
                iUniqueColNum: 9,
                sTitle: this.get("_objCandidateProfileStringsService").getString("type"),
                sProperty: "complaint_type_id",
                bHideEmptyCheck: true,
                bSilent: true,
                sFilterType: "dropdownMulti",
                bMapping: true,
                arrMappingData: arrComplaintTypeOptions
            }, {
                iUniqueColNum: 10,
                sTitle: this.get("_objCandidateProfileStringsService").getString("dateExcluded"),
                sProperty: "date_excluded",
                bHideEmptyCheck: true,
                bDate: true,
                sFilterType: "date",
                bFilterAllowTime: false,
                iWidth: 10,
                bStopColumnSort: true
            }, {
                iUniqueColNum: 11,
                sTitle: this.get("_objCandidateProfileStringsService").getString("lifted"),
                sProperty: "fully_lifted__status",
                bHideEmptyCheck: true,
                bBool: true,
                sFilterType: "dropdown",
                bMapping: true,
                arrMappingData: [{
                    sValue: "yes",
                    sTitle: this.get("_objStringsService").getString("yes")
                }, {
                    sValue: "no",
                    sTitle: this.get("_objStringsService").getString("no")
                }],
                iWidth: 5,
                bStopColumnSort: true
            }];

            var arrSingleItemActions = [{
                sTitle: this.get("_objCandidateProfileStringsService").getString("viewExclusion"),
                sIcon: "visibility",
                executeCardAction: function executeCardAction() {
                    var objExclusion = objThis.get("_arrSelectedItems")[0];
                    objThis._viewExclusion(objExclusion._id);
                }
            }];

            this.set("_arrSingleLoadingActions", arrSingleItemActions);

            this.set("_objDatagridConfig", {
                sDatagridHeader: this.get("_objCandidateProfileStringsService").getString("exclusions"),
                iMaxHeight: 40 * 27,
                bSelectColumn: true,
                bEmitSelectRowEvent: true,
                bHasReloadButton: true,
                bEmitDidDoServiceCall: true,
                bEmitWillDoServiceCall: true,
                sReactIFrameUrl: _environment.default.sReactGatewayUrl,
                arrSingleItemSelectActions: arrSingleItemActions,
                arrHeaderConfig: arrDataGridHeaderConfig,
                bFormatDatesToContextTimezone: true
            });

            this.set("_bPageLoading", false);
        },
        _viewExclusion: function _viewExclusion(sBookingRequestId) {
            this.set("_bReactPopupOpen", true);
            this.set("_objReactPopupPostData", {
                type: "trigger_route",
                data: {
                    route: "agency/client/complaints/clientInitiatedExclusions/viewPopup",
                    data: {
                        exclusionId: sBookingRequestId
                    }
                }
            });
        },
        _doEntityActionCall: function _doEntityActionCall(arrExclusionIds) {
            // Sets the loading state on the actions
            //this.set("_objDatagridConfig.arrSingleItemSelectActions", copy(this.get("_arrLoadingActions"), true));
            if (!a24Core.isEmpty(arrExclusionIds)) {
                var objQuery = {
                    entity_id: { mValue: arrExclusionIds.join(",") }
                };

                var objPromiseEntityActions = this.get("_objRestEntityAction").getEntityActions(this, "_objEntityActionPromise", "client-initiated-exclusion", objQuery, function () {
                    // Handled by RSVP
                }, a24RestCallHelper.createJsonAjaxFailureObject(["500", "502", "503", "504"], function () /*sCode, sStatus, objErrorThrown, objError*/{}));

                var objPromiseList = {
                    _objEntityActions: objPromiseEntityActions
                };

                var arrRequiredResponses = ["_objEntityActions"];

                var objThis = this;

                Ember.copy(a24RSVP).configure(this, objPromiseList, [{
                    arrRequiredResponses: arrRequiredResponses,
                    funcOnComplete: function funcOnComplete(objResponses) {
                        if (!a24Core.isEmpty(objResponses._objEntityActions) && !a24Core.isEmpty(objResponses._objEntityActions.objResponse)) {
                            objThis.set("_bEntityActionPending", false);
                        }
                    }
                }]);
            }
        },
        _onItemsSelect: function _onItemsSelect(arrSelectedItems) {
            if (!a24Core.isEmpty(arrSelectedItems) && arrSelectedItems.length === 1) {
                this.set("_objSelectedItem", arrSelectedItems[0]);
            }

            // Set the selected items
            this.set("_arrSelectedItems", arrSelectedItems);
        },
        actions: {
            cardDatagridTableNoneSelected: function cardDatagridTableNoneSelected() {
                this._onItemsSelect(null);
            },
            cardDatagridTableOneSelected: function cardDatagridTableOneSelected(arrSelectedItems) {
                this._onItemsSelect(arrSelectedItems);
            },
            cardDatagridTableMultiSelected: function cardDatagridTableMultiSelected(arrSelectedItems) {
                this._onItemsSelect(arrSelectedItems);
            },
            cardDatagridTableCell: function cardDatagridTableCell() /*sColumnName, objRowData*/{},
            onPageReload: function onPageReload() {
                this._doServiceCalls();
            },
            onWillDoServiceCall: function onWillDoServiceCall(arrTableData, objUrlConstantPart, objFilterParamObject) {
                this.set("_bEntityActionPending", true);

                if (!a24Core.isEmpty(objFilterParamObject.date_excluded)) {
                    if (objFilterParamObject.date_excluded.bFrom) {
                        objFilterParamObject.date_excluded_from = {
                            mValue: encodeURIComponent(objFilterParamObject.date_excluded.mValue)
                        };
                    } else if (objFilterParamObject.date_excluded.bNotWithinLast) {

                        objFilterParamObject.date_excluded_from = {
                            mValue: encodeURIComponent(objFilterParamObject.date_excluded.mValue.sFrom)
                        };
                    } else if (objFilterParamObject.date_excluded.bNotWithinNext) {
                        objFilterParamObject.date_excluded_to = {
                            mValue: encodeURIComponent(objFilterParamObject.date_excluded.mValue.sTo)
                        };
                    } else if (objFilterParamObject.date_excluded.bTo) {
                        objFilterParamObject.date_excluded_to = {
                            mValue: encodeURIComponent(objFilterParamObject.date_excluded.mValue)
                        };
                    } else if (objFilterParamObject.date_excluded.bFromAndTo) {
                        objFilterParamObject.date_excluded_from = {
                            mValue: encodeURIComponent(objFilterParamObject.date_excluded.mValue.sFrom)
                        };
                        objFilterParamObject.date_excluded_to = {
                            mValue: encodeURIComponent(objFilterParamObject.date_excluded.mValue.sTo)
                        };
                    }
                    delete objFilterParamObject.date_excluded;
                }
                if (!a24Core.isEmpty(objFilterParamObject.fully_lifted__status)) {
                    objFilterParamObject.fully_lifted_status = {
                        mValue: objFilterParamObject.fully_lifted__status.mValue
                    };
                    delete objFilterParamObject.fully_lifted__status;
                }
            },
            onDidDoServiceCall: function onDidDoServiceCall(arrTableData) {
                var arrExclusionIds = [];
                for (var i = 0; i < arrTableData.length; i++) {
                    arrExclusionIds.push(arrTableData[i]._id);
                }

                this._doEntityActionCall(arrExclusionIds);
            },
            onRecieveReactData: function onRecieveReactData(objData) {
                if (objData.type === "app_ready") {
                    this.set("_bReactPopupLoading", false);
                } else if (objData.type === "close") {
                    this.set("_bReactPopupOpen", false);
                }
            }
        }
    });
});