define("a24-staffshift-subdomain-agency/components/card-datagrid-custom-filter-availability", ["exports", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        objHeaderItem: null,

        _arrAvailabilityOptions: null,
        _arrShiftOptions: null,
        _arrForOptions: null,
        _bShowForDates: false,
        _bShowDateFields: false,
        _bShowSingleDate: false,
        _bShowShiftField: false,

        _objUserSession: Ember.inject.service("user-session"),
        _objLibConstants: Ember.inject.service("a24-constants-ember-lib"),
        _objSSAgencyEnums: Ember.inject.service("a24-enums-staffshift-agency"),
        _objSSAgencyStrings: Ember.inject.service("a24-strings-staffshift-agency"),

        init: function init() {
            this._super.apply(this, arguments);

            this.set("_arrAvailabilityOptions", this.get("_objSSAgencyEnums").getList("availabilityOptions"));
            this.set("_arrForOptions", this.get("_objSSAgencyEnums").getList("availabilityFilterDateOptions"));
            this.set("_arrShiftOptions", this.get("_objSSAgencyEnums").getList("shiftOptions"));

            var objMinDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));

            var objMaxDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));

            objMaxDate.addTimeToDate(this.get("_objLibConstants.MOMENT_TIME_MONTHS"), 3);

            this.set("_objMinDate", objMinDate);
            this.set("_objMaxDate", objMaxDate);

            this.addObserver("objHeaderItem._sToDate", this, this.get("observeFromAndToDates"));
            this.addObserver("objHeaderItem._sFromDate", this, this.get("observeFromAndToDates"));

            this.observeAllValues();
        },
        _observeDateFor: function _observeDateFor() {
            if (!a24Core.isEmpty(this.get("objHeaderItem._objForDate"))) {
                this.removeObserver("objHeaderItem._sToDate", this, this.get("observeFromAndToDates"));
                this.removeObserver("objHeaderItem._sFromDate", this, this.get("observeFromAndToDates"));

                var objCurrentDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                objCurrentDate.setTimeToStartOfDay();

                switch (this.get("objHeaderItem._objForDate.sValue")) {
                    case this.get("_objSSAgencyEnums.availabilityFilterDateOptions.DATE_FILTER_TODAY.backend"):
                        //No need for setTimeToEndOfDay or setTimeToStartOfDay when doing the getDateFormat since that does
                        //not have time on it
                        this.set("objHeaderItem._sToDate", objCurrentDate.getDateFormat());
                        this.set("objHeaderItem._sFromDate", objCurrentDate.getDateFormat());

                        if (!a24Core.isEmpty(this.get("objHeaderItem._sSpecificDate"))) {
                            this.set("objHeaderItem._sSpecificDate", null);
                        }

                        break;
                    case this.get("_objSSAgencyEnums.availabilityFilterDateOptions.DATE_FILTER_TOMORROW.backend"):
                        objCurrentDate.addTimeToDate(this.get("_objLibConstants.MOMENT_TIME_DAYS"), 1);
                        //No need for setTimeToEndOfDay or setTimeToStartOfDay when doing the getDateFormat since that does
                        //not have time on it
                        this.set("objHeaderItem._sToDate", objCurrentDate.getDateFormat());
                        this.set("objHeaderItem._sFromDate", objCurrentDate.getDateFormat());

                        if (!a24Core.isEmpty(this.get("objHeaderItem._sSpecificDate"))) {
                            this.set("objHeaderItem._sSpecificDate", null);
                        }

                        break;
                    case this.get("_objSSAgencyEnums.availabilityFilterDateOptions.DATE_FILTER_NEXT_5_DAYS.backend"):
                        objCurrentDate.addTimeToDate(this.get("_objLibConstants.MOMENT_TIME_DAYS"), 1);
                        this.set("objHeaderItem._sFromDate", objCurrentDate.getDateFormat());

                        objCurrentDate.addTimeToDate(this.get("_objLibConstants.MOMENT_TIME_DAYS"), 4);
                        this.set("objHeaderItem._sToDate", objCurrentDate.getDateFormat());

                        if (!a24Core.isEmpty(this.get("objHeaderItem._sSpecificDate"))) {
                            this.set("objHeaderItem._sSpecificDate", null);
                        }

                        break;
                    case this.get("_objSSAgencyEnums.availabilityFilterDateOptions.DATE_FILTER_SPECIFIC_DATE.backend"):
                        if (!a24Core.isEmpty(this.get("objHeaderItem._sFromDate"))) {
                            this.set("objHeaderItem._sFromDate", null);
                        }
                        if (!a24Core.isEmpty(this.get("objHeaderItem._sToDate"))) {
                            this.set("objHeaderItem._sToDate", null);
                        }

                        break;
                }

                this.addObserver("objHeaderItem._sToDate", this, this.get("observeFromAndToDates"));
                this.addObserver("objHeaderItem._sFromDate", this, this.get("observeFromAndToDates"));
            } else {
                this.set("_bShowDateFields", false);
                this.set("_bShowSingleDate", false);
                this.set("_bShowShiftField", false);
            }
        },
        observeFromAndToDates: function observeFromAndToDates() {
            var sForDate = this.get("objHeaderItem._objForDate.sValue");

            if (sForDate === this.get("_objSSAgencyEnums.availabilityFilterDateOptions.DATE_FILTER_TODAY.backend") || sForDate === this.get("_objSSAgencyEnums.availabilityFilterDateOptions.DATE_FILTER_TOMORROW.backend") || sForDate === this.get("_objSSAgencyEnums.availabilityFilterDateOptions.DATE_FILTER_NEXT_5_DAYS.backend")) {
                this.set("objHeaderItem._objForDate", this.get("_arrForOptions").findBy("sValue", this.get("_objSSAgencyEnums.availabilityFilterDateOptions.DATE_FILTER_ALL_DATES_IN_RANGE.backend")));
            }
        },
        observeAllValues: Ember.observer("objHeaderItem._sAvailability", "objHeaderItem._objForDate", "objHeaderItem._sToDate", "objHeaderItem._sFromDate", "objHeaderItem._sSpecificDate", "objHeaderItem._arrSelectedShiftOptions", function () {
            Ember.run.once(this, "_observeDateFor");
            Ember.run.once(this, "_setFlags");
        }),
        _setFlags: function _setFlags() {
            if (!a24Core.isEmpty(this.get("objHeaderItem._sAvailability"))) {
                this.set("_bShowForDates", true);

                if (!a24Core.isEmpty(this.get("objHeaderItem._objForDate"))) {
                    if (this.get("objHeaderItem._objForDate.sValue") === this.get("_objSSAgencyEnums.availabilityFilterDateOptions.DATE_FILTER_SPECIFIC_DATE.backend")) {
                        this.set("_bShowSingleDate", true);
                        this.set("_bShowDateFields", false);
                    } else {
                        this.set("_bShowDateFields", true);
                        this.set("_bShowSingleDate", false);
                    }

                    if (this.get("_bShowSingleDate") && !a24Core.isEmpty(this.get("objHeaderItem._sSpecificDate")) || !a24Core.isEmpty(this.get("objHeaderItem._sToDate")) && !a24Core.isEmpty(this.get("objHeaderItem._sFromDate"))) {
                        this.set("_bShowShiftField", true);

                        if (!a24Core.isEmpty(this.get("objHeaderItem._arrSelectedShiftOptions"))) {
                            this.customFilterDisplayAddButtonEvent(true);
                        } else {
                            this.customFilterDisplayAddButtonEvent(false);
                        }
                    } else {
                        this.set("_bShowShiftField", false);
                        this.customFilterDisplayAddButtonEvent(false);
                    }
                } else {
                    this.set("_bShowDateFields", false);
                    this.set("_bShowSingleDate", false);
                    this.set("_bShowShiftField", false);
                    this.customFilterDisplayAddButtonEvent(false);
                }
            } else {
                this.set("_bShowForDates", false);
                this.set("_bShowDateFields", false);
                this.set("_bShowSingleDate", false);
                this.set("_bShowShiftField", false);
                this.customFilterDisplayAddButtonEvent(false);
            }
        }
    });
});