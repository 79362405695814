define("a24-staffshift-subdomain-agency/components/page-agency-list-booking-requests", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-staffshift-subdomain-agency/config/environment"], function (exports, _didRenderChangesMixin, _environment) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        objQueryParams: null,
        bDatagridLoading: false,
        _bPageLoading: false,
        _objSelectedItem: null,
        _arrConsultantRoles: null,
        _sUrlBase: null,
        _bShowLoadFailedPopup: false,
        _arrBookingRequestPermissionedAction: null,

        _objTitleBarService: Ember.inject.service("title-bar"),
        _objNavigation: Ember.inject.service("navigation"),
        _objSSAgencyStrings: Ember.inject.service("a24-strings-staffshift-agency"),
        _objStringsCandidateProfile: Ember.inject.service("a24-strings-candidate-profile"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objSSAgencyEnum: Ember.inject.service("a24-enums-staffshift-agency"),
        _objConsultantRoleService: Ember.inject.service("rest-agency-consultant-role"),
        _objQueryParamBuilderService: Ember.inject.service("query-param-object-builder"),
        _objAgencyUsersService: Ember.inject.service("rest-agency-users"),
        _objFilterCustomConsultantRoleService: Ember.inject.service("card-datagrid-custom-filter-consultant-role"),
        _objRestPermissionedActions: Ember.inject.service("rest-permissioned-actions"),
        _objFabButtonService: Ember.inject.service("fab-button"),
        _objSSCoreEnum: Ember.inject.service("a24-enums-staffshift-core"),

        _objGetConsultantRole: null,
        _objGetPermissionedActions: null,

        // react component
        _objChatterIframeComService: Ember.inject.service("chatter-iframe-com"),
        _sIFrameUrl: null,
        _bReactPopupLoading: true,
        _bShowReactPopup: false,
        _objReactPopupPostData: null,

        init: function init() {
            this._super.apply(this, arguments);

            this.get("_objTitleBarService").setTitleBar(this.get("_objSSAgencyStrings").getString("bookingRequests"));

            this.set("_sIFrameUrl", _environment.default.sReactGatewayUrl);

            // Build up the url with context id.
            this.set("_sUrlBase", _environment.default.sStaffingGatewayUrl + "/v1/agency/" + this.get("_objNavigation.sContextId") + "/advanced-search/booking-request");

            if (!a24Core.isEmpty(this.get("_objNavigation.objRouteQueryParams.booking_request_id"))) {
                this.set("_bShowReactPopup", true);
                this.set("_objReactPopupPostData", {
                    type: "trigger_route",
                    data: {
                        route: "staffing/directBookingRequest/viewPopup",
                        data: {
                            bookingRequestId: this.get("_objNavigation.objRouteQueryParams.booking_request_id"),
                            isFromBookingRequest: true
                        }
                    }
                });
            }

            if (!a24Core.isEmpty(this.get("_objNavigation.objRouteQueryParams.encodedShiftBookingRequest"))) {
                // decoding the booking request data since its base64 encoded
                var objShiftBookingRequest = JSON.parse(atob(this.get("_objNavigation.objRouteQueryParams.encodedShiftBookingRequest")));
                this.set("_bShowReactPopup", true);
                this.set("_objReactPopupPostData", {
                    type: "trigger_route",
                    data: {
                        route: "staffing/directShiftBookingWizard",
                        data: objShiftBookingRequest
                    }
                });
            }

            this._doServiceCalls();
        },

        _doServiceCalls: function _doServiceCalls() {
            var _this = this;

            this.set("_bPageLoading", true);

            var objPromiseConsultantRole = this.get("_objConsultantRoleService").getConsultantRoles(this, "_objGetConsultantRole", function () {}, // handled in rsvp
            a24RestCallHelper.createJsonAjaxFailureObject(["500", "502", "503", "504"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                _this._handleServerErrors();
            }));

            var objBookingRequestPermissionedActionsPromise = this.get("_objRestPermissionedActions").getPermissionedActionsForSection(this, "_objGetPermissionedActions", "booking-request", function () {
                // do nothing since rsvp will handle it
            }, a24RestCallHelper.createJsonAjaxFailureObject(["401", "500", "502", "503", "504"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                _this.set("_arrBookingRequestPermissionedAction", []);
            }));

            var objPromiseList = {
                _objConsultantRolePromise: objPromiseConsultantRole,
                _objRSVPBookingRequestPermissionedAction: objBookingRequestPermissionedActionsPromise
            };

            var objThis = this;

            Ember.copy(a24RSVP).configure(this, objPromiseList, [{
                arrRequiredResponses: ["_objConsultantRolePromise"],
                funcOnComplete: function funcOnComplete(objResponses) {
                    var arrConsultantRoles = objResponses._objConsultantRolePromise.objResponse;

                    if (a24Core.isEmpty(arrConsultantRoles)) {
                        arrConsultantRoles = [];
                    }
                    objThis.set("_arrConsultantRoles", arrConsultantRoles);

                    objThis._setupPageConfig(arrConsultantRoles);
                }
            }, {
                arrRequiredResponses: ["_objRSVPBookingRequestPermissionedAction"],
                funcOnComplete: function funcOnComplete(objResponses) {
                    var arrBookingRequestPermissionedAction = objResponses._objRSVPBookingRequestPermissionedAction.objResponse;

                    if (a24Core.isEmpty(arrBookingRequestPermissionedAction)) {
                        arrBookingRequestPermissionedAction = [];
                    }

                    objThis.set("_arrBookingRequestPermissionedAction", arrBookingRequestPermissionedAction);

                    objThis._setFabButton();
                }
            }]);
        },
        _setFabButton: function _setFabButton() {
            var _this2 = this;

            var arrPermissionedActions = this.get("_arrBookingRequestPermissionedAction");

            var bAllowAction = false;
            if (!a24Core.isEmpty(arrPermissionedActions)) {
                var objPermissionedAction = arrPermissionedActions.find(function (objPermissionedActionSection) {
                    return objPermissionedActionSection.entity_type === "booking-request";
                });
                var arrAllowedActions = [];
                if (!a24Core.isEmpty(objPermissionedAction.actions)) {
                    objPermissionedAction.actions.forEach(function (objItem) {
                        if (objItem.allowed) {
                            arrAllowedActions.push(objItem.action);
                        }
                    });
                }
                bAllowAction = arrAllowedActions.includes("create");
            }

            if (bAllowAction) {
                var sPunchClockSvg = this.get("_objSSCoreEnum").getMaterialSvgIcon("punch_clock_white");
                // hard coding string and svg since this will change in future but this is
                // temporary for now. This will change once the booking popup is done and working
                this.get("_objFabButtonService").showFabButton(function () {
                    _this2.set("_bShowReactPopup", true);
                    _this2.set("_objReactPopupPostData", {
                        type: "trigger_route",
                        data: {
                            route: "staffing/directBookingWizard",
                            data: { isFromBookingRequest: true }
                        }
                    });
                }, null, this.get("_objSSAgencyStrings").getString("addBookingRequest"), null, null, null, null, sPunchClockSvg);
            }
        },

        _setupPageConfig: function _setupPageConfig(arrConsultantRoles) {
            var _this3 = this;

            var arrBookingRequestStatuses = this.get("_objSSAgencyEnum").getList("bookingRequestStatus");

            var arrBookingRequestStatusOptions = [];
            for (var i = 0; i < arrBookingRequestStatuses.length; i++) {
                arrBookingRequestStatusOptions.push({
                    sTitle: arrBookingRequestStatuses[i].sLabel,
                    sValue: arrBookingRequestStatuses[i].sValue
                });
            }

            var arrConsultantRolesDropdown = [];
            arrConsultantRolesDropdown.push({
                sTitle: this.get("_objStringsService").getString("any"),
                sValue: "*"
            });

            for (var l = 0; l < arrConsultantRoles.length; l++) {
                arrConsultantRolesDropdown.push({
                    sTitle: arrConsultantRoles[l].name,
                    sValue: arrConsultantRoles[l]._id
                });
            }

            // This array contain the datagrid columns
            var arrDataGridHeaderConfig = [{
                iUniqueColNum: 1,
                sTitle: this.get("_objStringsCandidateProfile").getString("candidate"),
                sProperty: "candidate_id",
                bStopColumnSort: true,
                iWidth: 15,
                bCustom: true,
                sFilterType: "suggest",
                bFilterOr: true,
                sReactFilterRoute: "datagrid/filters/candidate",
                funcTableCustomVal: function funcTableCustomVal(objHeader, objRowData) {
                    if (!a24Core.isEmpty(objRowData.candidate_first_name) && !a24Core.isEmpty(objRowData.candidate_last_name)) {
                        return Ember.String.htmlSafe("<span title=\"" + objRowData.candidate_first_name + " " + objRowData.candidate_last_name + "\">" + objRowData.candidate_first_name + " " + objRowData.candidate_last_name + "</span>");
                    }
                    return "";
                }
            }, {
                iUniqueColNum: 2,
                sTitle: this.get("_objSSAgencyStrings").getString("siteName"),
                sProperty: "site_id",
                bSilent: true,
                sFilterType: "suggest",
                bFilterOr: true,
                sReactFilterRoute: "datagrid/filters/client",
                iWidth: 10
            }, {
                iUniqueColNum: 3,
                sTitle: this.get("_objSSAgencyStrings").getString("siteName"),
                sProperty: "site_name",
                bText: true,
                iWidth: 10,
                bStopFiltering: true
            }, {
                iUniqueColNum: 4,
                sTitle: this.get("_objSSAgencyStrings").getString("wardName"),
                sProperty: "ward_id",
                iWidth: 10,
                bStopFiltering: true,
                bSilent: true
            }, {
                iUniqueColNum: 5,
                sTitle: this.get("_objSSAgencyStrings").getString("wardName"),
                sProperty: "ward_name",
                bText: true,
                iWidth: 10,
                bStopFiltering: true
            }, {
                iUniqueColNum: 6,
                sTitle: this.get("_objSSAgencyStrings").getString("firstShiftDate"),
                sProperty: "first_shift_start_date",
                bDate: true,
                bFilterAllowTime: true,
                iWidth: 10,
                bStopFiltering: true
            }, {
                iUniqueColNum: 7,
                sTitle: this.get("_objSSAgencyStrings").getString("lastShiftDate"),
                sProperty: "last_shift_start_date",
                bDate: true,
                bFilterAllowTime: true,
                iWidth: 10,
                bStopFiltering: true
            }, {
                iUniqueColNum: 8,
                sTitle: this.get("_objStringsCandidateProfile").getString("status"),
                sProperty: "status",
                sFilterType: "dropdownMulti",
                iWidth: 5,
                bMapping: true,
                arrMappingData: arrBookingRequestStatusOptions
            }, {
                iUniqueColNum: 9,
                sTitle: this.get("_objStringsService").getString("createdBy"),
                sProperty: "created_by_user_id",
                sFilterType: "suggest",
                iWidth: 10,
                bCustom: true,
                bStopColumnSort: true,
                iFilterSuggestAt: 3,
                iFilterSuggestDelay: 500,
                sFilterOptionsValueKey: "_id",
                sFilterOptionsLabelKey: "name",
                bFilterShowLoaderDuringSuggest: true,
                bFilterAlwaysShowOnFocus: false,
                funcTableCustomVal: function funcTableCustomVal(objHeader, objRowData) {
                    if (!a24Core.isEmpty(objRowData.created_by_user_first_name) && !a24Core.isEmpty(objRowData.created_by_user_last_name)) {
                        return Ember.String.htmlSafe("<span title=\"" + objRowData.created_by_user_first_name + " " + objRowData.created_by_user_last_name + "\">" + objRowData.created_by_user_first_name + " " + objRowData.created_by_user_last_name + "</span>");
                    }
                    return "";
                },
                funcFilterGetSuggestions: function funcFilterGetSuggestions(sSuggestVal, funcCallback) {
                    _this3.get("_objAgencyUsersService").suggestUsers(_this3, "_objUsersPromise", _this3.get("_objNavigation.sContextId"), sSuggestVal, function (arrData) {
                        var arrResult = [];
                        // If the data is empty, ensure it's an empty array
                        if (a24Core.isEmpty(arrData)) {
                            arrData = [];
                        }

                        for (var i = 0; i < arrData.length; i++) {
                            var sNameUserName = arrData[i].sFirstName + " " + arrData[i].sLastName + " - " + arrData[i].sUsername;

                            arrResult.push({
                                _id: arrData[i].id,
                                name: sNameUserName
                            });
                        }

                        // Call the callback with the formatted data
                        funcCallback(arrResult);
                    }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                        // Call the callback with empty
                        funcCallback([]);
                    }));
                }
            }, {
                iUniqueColNum: 10,
                sTitle: this.get("_objSSAgencyStrings").getString("createdDate"),
                sProperty: "created_by_user_date",
                bDate: true,
                sFilterType: "date",
                bFilterAllowTime: true,
                iWidth: 5
            }, {
                iUniqueColNum: 11,
                sTitle: this.get("_objSSAgencyStrings").getString("shiftStartDate"),
                sProperty: "all_shifts___start_date",
                sFilterType: "date",
                bDate: true,
                bFilterAllowTime: true,
                bSilent: true,
                iWidth: 10
            }, {
                iUniqueColNum: 12,
                sTitle: this.get("_objStringsCandidateProfile").getString("consultantRole"),
                sProperty: "client_consultants___consultant_role_id",
                bMapping: true,
                sFilterType: "custom",
                arrConsultantRoles: arrConsultantRolesDropdown,
                iWidth: 15,
                bSilent: true,
                bFilterAnd: true,
                bFilterOr: true,
                sSecondaryProperty: "consultant_id",
                sSecondaryValueProperty: "_id",
                sFilterCustomComponentName: "card-datagrid-custom-filter-consultant-role",
                objFilterCustomService: this.get("_objFilterCustomConsultantRoleService"),
                funcFilterGetSuggestions: function funcFilterGetSuggestions(sSuggestVal, funcCallback) {
                    _this3.get("_objAgencyUsersService").suggestUsers(_this3, "_objUsersPromise", _this3.get("_objNavigation.sContextId"), sSuggestVal, function (arrData) {
                        var arrResult = [];
                        // If the data is empty, ensure it's an empty array
                        if (a24Core.isEmpty(arrData)) {
                            arrData = [];
                        }

                        for (var i = 0; i < arrData.length; i++) {
                            var sNameUserName = arrData[i].sFirstName + " " + arrData[i].sLastName + " - " + arrData[i].sUsername;

                            arrResult.push({
                                _id: arrData[i].id,
                                name: sNameUserName
                            });
                        }

                        // Call the callback with the formatted data
                        funcCallback(arrResult);
                    }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], function () /*sCode, sStatus, objErrorThrown, objError*/{
                        // Call the callback with empty
                        funcCallback([]);
                    }));
                }
            }];

            if (!a24Core.isEmpty(arrConsultantRoles)) {
                var objQueryParams = this.get("objQueryParams");
                var objQueryParamsUpdate = {};
                var arrQueryParamsNew = [];
                var arrQueryParams = [];
                var arrConsultantRolesToShow = [];

                var _loop = function _loop(c) {
                    var sProp = "client_consultants_" + arrConsultantRoles[c].name.toLowerCase().replace(/ /g, "-") + "_" + arrConsultantRoles[c]._id;
                    arrQueryParams.push(sProp);
                    objQueryParamsUpdate[sProp] = "";

                    objQueryParamsUpdate.queryParams = arrQueryParams;
                    // Because the backend can decide to remove/add any role at any time
                    // we wont know the exact number. So we use the id instead
                    arrQueryParamsNew.push({
                        iUniqueColNum: arrConsultantRoles[c]._id,
                        sTitle: arrConsultantRoles[c].name,
                        sProperty: sProp,
                        bCustom: true,
                        sFilterType: "text",
                        bStopFiltering: true,
                        bStopColumnSort: true,
                        iWidth: 10,
                        funcTableCustomVal: function funcTableCustomVal(objHeader, objRowData) {
                            if (!a24Core.isEmpty(objRowData[sProp])) {
                                var sConsultants = objRowData[sProp].map(function (objConsultant) {
                                    return objConsultant.consultant_name;
                                }).join(", ");
                                return Ember.String.htmlSafe("<span title=\"" + sConsultants + "\">" + sConsultants + "</span>");
                            }
                            return "";
                        }
                    });

                    objQueryParamsUpdate = Ember.Object.create(objQueryParamsUpdate);

                    if (arrConsultantRoles[c].name === _this3.get("_objSSAgencyStrings").getString("portfolioManager") || arrConsultantRoles[c].name === _this3.get("_objSSAgencyStrings").getString("portfolioConsultant")) {
                        arrConsultantRolesToShow.push(arrConsultantRoles[c]._id);
                    }
                };

                for (var c = 0; c < arrConsultantRoles.length; c++) {
                    _loop(c);
                }

                var objQueryParamsExisting = this.get("objQueryParams");
                if (!a24Core.isEmpty(arrConsultantRolesToShow)) {
                    // make portfolio manager and portfolio consultant columns visible
                    objQueryParamsUpdate["vis_cols"] = objQueryParamsExisting.vis_cols.sValue + "," + arrConsultantRolesToShow.join(",");
                    objQueryParamsUpdate.queryParams.push("vis_cols");
                }

                if (!a24Core.isEmpty(this.get("addQueryParamsAction"))) {
                    this.get("addQueryParamsAction")(arrQueryParams);
                }
                arrDataGridHeaderConfig.push.apply(arrDataGridHeaderConfig, arrQueryParamsNew);
                this.set("objQueryParams", this.get("_objQueryParamBuilderService").createQueryParamObject(arrQueryParams, objQueryParamsUpdate, objQueryParams, false, true));
            }

            var objThis = this;
            var arrSingleItemActions = [{
                sTitle: this.get("_objSSAgencyStrings").getString("viewBookingRequest"),
                sIcon: "visibility",
                executeCardAction: function executeCardAction() {
                    var objBookingRequest = objThis.get("_arrSelectedItems")[0];
                    objThis._viewBookingRequest(objBookingRequest.booking_request_id);
                }
            }, {
                sTitle: this.get("_objSSAgencyStrings").getString("cancelBookingRequest"),
                sIcon: "cancel",
                executeCardAction: function executeCardAction() {
                    var objBookingRequest = objThis.get("_arrSelectedItems")[0];
                    objThis._cancelBookingRequest(objBookingRequest.booking_request_id);
                },
                funcFilterFunc: function funcFilterFunc(arrSelectedItems) {
                    var arrPermissionedActions = objThis.get("_arrBookingRequestPermissionedAction");
                    var bAllowCancelAction = false;
                    if (!a24Core.isEmpty(arrPermissionedActions)) {
                        var objPermissionedAction = arrPermissionedActions.find(function (objPermissionedActionSection) {
                            return objPermissionedActionSection.entity_type === "booking-request";
                        });
                        var arrAllowedActions = [];
                        if (!a24Core.isEmpty(objPermissionedAction.actions)) {
                            objPermissionedAction.actions.forEach(function (objItem) {
                                if (objItem.allowed) {
                                    arrAllowedActions.push(objItem.action);
                                }
                            });
                        }
                        bAllowCancelAction = arrAllowedActions.includes("cancel_booking_request");
                    }

                    var objPossibleAction = arrSelectedItems[0].possible_actions.find(function (objPossibleAction) {
                        return objPossibleAction.action === "cancel_booking_request";
                    });
                    if (bAllowCancelAction && arrSelectedItems[0].status !== "booking_request_cancelled" && !a24Core.isEmpty(objPossibleAction) && objPossibleAction.allowed) {
                        return true;
                    }
                    return false;
                }
            }];

            this.set("_arrSingleLoadingActions", arrSingleItemActions);

            this.set("_objDatagridConfig", {
                sDatagridHeader: this.get("_objSSAgencyStrings").getString("bookingRequests"),
                iMaxHeight: 40 * 27,
                bSelectColumn: true,
                bEmitSelectRowEvent: true,
                bHasReloadButton: true,
                bEmitDidDoServiceCall: true,
                sReactIFrameUrl: _environment.default.sReactGatewayUrl,
                arrSingleItemSelectActions: arrSingleItemActions,
                arrHeaderConfig: arrDataGridHeaderConfig,
                bFormatDatesToContextTimezone: true
            });

            this.set("_bPageLoading", false);
        },
        _viewBookingRequest: function _viewBookingRequest(sBookingRequestId) {
            this.set("_bShowReactPopup", true);
            this.set("_objReactPopupPostData", {
                type: "trigger_route",
                data: {
                    route: "staffing/directBookingRequest/viewPopup",
                    data: {
                        bookingRequestId: sBookingRequestId,
                        isFromBookingRequest: true
                    }
                }
            });
        },

        _cancelBookingRequest: function _cancelBookingRequest(sBookingRequestId) {
            this.set("_bShowReactPopup", true);
            this.set("_objReactPopupPostData", {
                type: "trigger_route",
                data: {
                    route: "staffing/directBookingRequest/cancelPopup",
                    data: {
                        bookingRequestId: sBookingRequestId
                    }
                }
            });
        },
        // For selecting items
        _onItemsSelect: function _onItemsSelect(arrSelectedItems) {
            if (!a24Core.isEmpty(arrSelectedItems) && arrSelectedItems.length === 1) {
                this.set("_objSelectedItem", arrSelectedItems[0]);
            }
            // Set the selected items
            this.set("_arrSelectedItems", arrSelectedItems);
        },
        _handleServerErrors: function _handleServerErrors() {
            var _this4 = this;

            Ember.run.next(function () {
                if (a24Core.isEmpty(_this4)) {
                    return;
                }
                _this4.set("_bShowLoadFailedPopup", true);
            });
        },
        _refresh: function _refresh() {
            var _this5 = this;

            this.set("_bShowReactPopup", false);
            // Put the page in loading
            this.set("_bPageLoading", true);

            Ember.run.next(function () {
                if (a24Core.isEmpty(_this5)) {
                    return;
                }
                Ember.run.schedule("afterRender", function () {
                    // Take page out of loading
                    _this5.set("_bPageLoading", false);
                });
            });
        },
        actions: {
            cardDatagridTableNoneSelected: function cardDatagridTableNoneSelected() {
                this._onItemsSelect(null);
            },
            cardDatagridTableOneSelected: function cardDatagridTableOneSelected(arrSelectedItems) {
                this._onItemsSelect(arrSelectedItems);
            },
            cardDatagridTableMultiSelected: function cardDatagridTableMultiSelected(arrSelectedItems) {
                this._onItemsSelect(arrSelectedItems);
            },
            cardDatagridTableCell: function cardDatagridTableCell() /*sColumnName, objRowData*/{},
            onDidDoServiceCall: function onDidDoServiceCall(arrTableData, jqXHR, objPOSTBody) {
                var arrConsultantRoles = this.get("_arrConsultantRoles");
                for (var l = 0; l < arrTableData.length; l++) {
                    if (!a24Core.isEmpty(arrTableData[l].client_consultants)) {
                        var _loop2 = function _loop2(i) {
                            var arrData = arrTableData[l].client_consultants.filter(function (objEntry) {
                                return objEntry.consultant_role_id === arrConsultantRoles[i]._id;
                            });
                            if (!a24Core.isEmpty(arrData)) {
                                var _sProp = "client_consultants_" + arrConsultantRoles[i].name.toLowerCase().replace(/ /g, "-") + "_" + arrConsultantRoles[i]._id;
                                arrTableData[l][_sProp] = arrData;
                            }
                        };

                        for (var i = 0; i < arrConsultantRoles.length; i++) {
                            _loop2(i);
                        }
                    }
                }

                if (!a24Core.isEmpty(this.get("onDidDoServiceCall"))) {
                    this.get("onDidDoServiceCall")(arrTableData, jqXHR, objPOSTBody);
                }
            },

            onPageReload: function onPageReload() {
                this.set("_bShowLoadFailedPopup", false);
                this._doServiceCalls();
            },
            addQueryParamsAction: function addQueryParamsAction(arrAddedParams) {
                if (!a24Core.isEmpty(this.get("addQueryParamsAction"))) {
                    this.get("addQueryParamsAction")(arrAddedParams);
                }
            },
            onRecieveData: function onRecieveData(objData) {
                if (objData.type === "app_ready") {
                    this.set("_bReactPopupLoading", false);
                } else if (objData.type === "refresh") {
                    this._refresh();
                } else if (objData.type === "close") {
                    this.set("_bShowReactPopup", false);
                }
            }
        }
    });
});