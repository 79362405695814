define("a24-staffshift-subdomain-agency/components/react-page-datagrid-component", ["exports", "a24-ember-lib/components/react-page-datagrid-component"], function (exports, _reactPageDatagridComponent) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _reactPageDatagridComponent.default;
    }
  });
});