define("a24-staffshift-subdomain-agency/controllers/index/agency-dashboard-portfolio", ["exports", "a24-staffshift-subdomain-agency/mixins/query-param-object-builder", "a24-staffshift-subdomain-agency/config/environment"], function (exports, _queryParamObjectBuilder, _environment) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_queryParamObjectBuilder.default, {
        objReactTriggerRoute: null,

        _sIFrameUrl: null,

        init: function init() {
            this._super.apply(this, arguments);

            // for if we want to post route triggers
            this.set("objReactTriggerRoute", {
                type: "fake_trigger",
                data: {
                    route: "dashboard",
                    data: {}
                }
            });

            if (a24Core.isEmpty(this.get("_sIFrameUrl"))) {
                this.set("_sIFrameUrl", _environment.default.sReportsGatewayUrl + "/dashboards/agency-client-consultant-hours");
            }
        },
        actions: {
            onRecieveData: function onRecieveData() /*objData*/{
                // For if we want back and forth communication
                // if (objData.type === "someType") {
                // }
            }
        }
    });
});