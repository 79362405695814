define("a24-staffshift-subdomain-agency/controllers/index/agency-list-booking-restriction-checks", ["exports", "a24-staffshift-subdomain-agency/mixins/query-param-object-builder"], function (exports, _queryParamObjectBuilder) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_queryParamObjectBuilder.default, {
        queryParams: ["candidate_id", "site_id", "site_name", "ward_name", "tracker_type", "tracker_type_created_by_user_id", "tracker_type_first_shift_date", "tracker_type_first_shift_dateFrom", "tracker_type_first_shift_dateTo", "tracker_type_last_shift_date", "tracker_type_last_shift_dateFrom", "tracker_type_last_shift_dateTo", "tracker_considered_shifts___start_date", "tracker_considered_shifts___start_dateFrom", "tracker_considered_shifts___start_dateTo", "client_consultants___consultant_role_id", "outstanding_warnings", "completed", "page", "sortBy", "vis_cols", "savedSearchKey"],
        candidate_id: "",
        site_id: "",
        site_name: "",
        tracker_type: "",
        tracker_type_created_by_user_id: "",
        tracker_type_first_shift_date: "",
        tracker_considered_shifts___start_date: "",
        client_consultants___consultant_role_id: "",
        outstanding_warnings: "true",
        completed: "false",
        page: "1",
        sortBy: "tracker_type_first_shift_date::asc",
        vis_cols: "1,3,4,5,6,7,8,10,11,12",
        savedSearchKey: ""
    });
});