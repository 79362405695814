define('a24-staffshift-subdomain-agency/services/rest-candidate-policy', ['exports', 'a24-ember-policy-library/services/rest-candidate-policy'], function (exports, _restCandidatePolicy) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _restCandidatePolicy.default;
    }
  });
});