define("a24-staffshift-subdomain-agency/router", ["exports", "a24-staffshift-subdomain-agency/config/environment"], function (exports, _environment) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var Router = Ember.Router.extend({
        location: _environment.default.locationType,
        _objNavigation: Ember.inject.service('navigation'),
        _objGoogleAnalytics: Ember.inject.service("google-analytics"),
        didTransition: function didTransition() {
            this._super.apply(this, arguments);
            var objThis = this;
            var arrAnalyticsBlockedPages = ["index.index"];
            //Page url only updated in next loop, so have to do page view hit in next loop
            Ember.run.next(function () {
                //Set the new route details on the navigation service for later use
                //Note that iRouteTransitions has to be set last otherwise anything observing it will get the old
                //value for sCurrentRouteName and sCurrentUrl when doing get
                objThis.set('_objNavigation.sCurrentRouteName', objThis.get('currentRouteName'));
                objThis.set('_objNavigation.sCurrentUrl', objThis.get('url'));
                objThis.set('_objNavigation.iRouteTransitions', objThis.get('_objNavigation.iRouteTransitions') + 1);
                objThis.set('_objNavigation.objCurrentRouteParams', Ember.copy(objThis.get('_objNavigation.objRouteParams'), true));
                objThis.set('_objNavigation.objCurrentRouteQueryParams', Ember.copy(objThis.get('_objNavigation.objRouteQueryParams'), true));

                // Check if the current route is not in the blocked list. Then do google analytics call
                if (arrAnalyticsBlockedPages.indexOf(objThis.get('currentRouteName')) === -1) {
                    objThis.get('_objGoogleAnalytics').doPageViewHit(objThis.get('url'));
                }
            });
        }
    });

    Router.map(function () {
        this.route('index', {
            path: '/'
        }, function () {
            //this is needed for ember to see the index route without an id as a valid route this will go to 404 page
            //since the checkauth will fail
        });

        this.route('index', {
            path: '/:context_id'
        }, function () {
            this.route('welcome');
            this.route('agency-user-list');
            this.route('agency-user');
            this.route('acl-group-list');
            this.route('acl-group');
            this.route('agency-ownership');
            this.route('agency-candidate-list');
            this.route('agency-candidate-details');
            this.route('agency-candidate-linked-info');
            this.route('agency-candidate-profile', { path: '/agency-candidate-profile/:candidate_id' });
            this.route('agency-candidate-employment-history-list');
            this.route('agency-whitelist-list');
            this.route('agency-add-whitelist');
            this.route('branch-list');
            this.route('branch-invoices');
            this.route('branch-details');
            this.route('agency-invoices');
            this.route('company-info');
            this.route('agency-time-sheet-settings');
            this.route('agency-orders');
            this.route('agency-staffing-order-details');
            this.route('agency-order-no-po-number');
            this.route('agency-shift-no-shift-ref-number');
            this.route('agency-contracted-orders');
            this.route('agency-timesheet-list');
            this.route('agency-timesheet-details');
            this.route('agency-timesheet-list-contract');
            this.route('agency-timesheet-list-no-contract');
            this.route('agency-timesheet-import');
            this.route('agency-vacancy-list');
            this.route('agency-vacancy-details');
            this.route('agency-vacancy-view');
            this.route('agency-vacancy-payment-declined');
            this.route('agency-vacancy-payment-confirmed');
            this.route('agency-vacancy-featured-confirmation');
            this.route('agency-client-list');
            this.route('client-timesheet-settings');
            this.route('agency-client-search');
            this.route('agency-billing-path');
            this.route('link-info-organisation');
            this.route('billing-path-list');
            this.route('client-site-list');
            this.route('client-ward-list');
            this.route('link-info-site');
            this.route('link-info-ward');
            this.route('contract-list');
            this.route('contract-link-create');
            this.route('contract-link-job-titles');
            this.route('contract-link-expenses');
            this.route('agency-contract-list');
            this.route('agency-contract-create');
            this.route('agency-contract-time-bands');
            this.route('agency-contract-public-holidays');
            this.route('agency-hourly-job-titles');
            this.route('agency-contract-expenses');
            this.route('contract-client-list');
            this.route('contract-client-job-titles');
            this.route('contract-client-expenses');
            this.route('agency-contract-view');
            this.route('contract-job-title-time-range');
            this.route('agency-exclusion-list');
            this.route('agency-add-exclusion');
            this.route('agency-candidate-search');
            this.route('whitelist-user-ip');
            this.route('agency-candidate-search-view', {
                path: '/agency-candidate-search-view/:candidate_id'
            }, function () {
                this.route('other-names');
                this.route('misc-details');
                this.route('equality');
                this.route('next-of-kin');
                this.route('languages');
                this.route('documents');
                this.route('work-history');
                this.route('skills', {
                    path: '/skills'
                }, function () {
                    this.route('skill', { path: '/:candidate_type' });
                    this.route('index', { path: '/' });
                });
                this.route('qualifications', {
                    path: '/qualifications'
                }, function () {
                    this.route('qualification', { path: '/:candidate_type' });
                    this.route('index', { path: '/' });
                });
                this.route('professional-registrations', {
                    path: '/professional-registrations'
                }, function () {
                    this.route('professional-registration', { path: '/:candidate_type' });
                    this.route('index', { path: '/' });
                });
                this.route('references');
                this.route('dismissals');
                this.route('position-held');
                this.route('social-links');
                this.route('calendar');
                this.route('notifications-preferences');
                this.route('tags');
                this.route('payroll-number');
                this.route('external-info');
                this.route('compliance-passport');
                this.route('exclusions');
                this.route('health-info');
                this.route('compliance-exemptions');
                this.route('right-to-work');
                this.route('occupational-health');
                this.route('crb-check');
            });
            this.route('agency-chatter-inbox');
            this.route('agency-assignments');
            this.route('agency-staffing-orders-shifts-list');
            this.route('agency-search-shift');
            this.route('agency-linked-client-search');
            this.route('plugin', {
                path: '/plugin/:plugin_id'
            });
            this.route('agency-tags');
            this.route('agency-compliance-contract-list');
            this.route('agency-compliance-contract-view', { path: '/agency-compliance-contract-view/:contract_id' });
            this.route('react-easter-time');
            this.route('agency-compliance-rules');
            this.route('agency-compliance-rule-view', { path: '/agency-compliance-rule-view/:compliance_rule_id' });
            this.route('previously-booked');
            this.route('agency-client-ward-view', {
                path: '/agency-client-ward-view/:ward_id'
            }, function () {
                this.route('consultants');
                this.route('contact-info');
                this.route('list-shifts');
                this.route('contact-persons');
                this.route('financials');
                this.route('booking-settings');
                this.route('compliance-exemptions');
                this.route('documents');
            });
            this.route('agency-client-site-view', {
                path: '/agency-client-site-view/:site_id'
            }, function () {
                this.route('consultants');
                this.route('contact-info');
                this.route('list-shifts');
                this.route('contact-persons');
                this.route('financials');
                this.route('booking-settings');
                this.route('compliance-exemptions');
                this.route('documents');
            });
            this.route('agency-client-change-report');
            this.route('agency-roles');
            this.route('agency-client-org-view', {
                path: '/agency-client-org-view/:organisation_id'
            }, function () {
                this.route('consultants');
                this.route('contact-info');
                this.route('list-shifts');
                this.route('contact-persons');
                this.route('financials');
                this.route('exclusions');
                this.route('compliance-exemptions');
                this.route('documents');
            });
            this.route('agency-dashboard-portfolio');
            this.route('agency-dashboard-client-portfolio');
            this.route('agency-dashboard-consultant-activities');
            this.route('agency-dashboard-all-consultant-activities');
            this.route('agency-dashboard-shifts-bookings');
            this.route('agency-list-booking-requests');
            this.route('agency-list-shift-requests');
            this.route('agency-list-booking-restriction-audit');
            this.route('agency-list-booking-restriction-checks');

            this.route('agency-list-conversations');
            this.route('agency-chatter-inbox-old');
            this.route('agency-document-groupings');
            this.route('agency-document-types');
            this.route('agency-verify-candidate-documents');
            this.route('agency-verify-client-documents');
            this.route('agency-approve-references');
            this.route('agency-document-actions');
        });

        //Handles any invalid url for the application
        this.route('invalid-route', {
            path: '/*bad_url' //the bad_url allows you access in the error route class to what was entered in the url
        });

        this.route('access-denied');
    });

    exports.default = Router;
});