define("a24-staffshift-subdomain-agency/routes/invalid-route", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        _objNavigation: Ember.inject.service("navigation"),

        init: function init() {
            this._super.apply(this, arguments);

            this.get("_objNavigation").navigateToGWTPage("error-not-found");
        }
    });
});