define('a24-staffshift-subdomain-agency/services/url-trickery', ['exports', 'a24-ember-lib/services/url-trickery'], function (exports, _urlTrickery) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _urlTrickery.default;
    }
  });
});