define("a24-staffshift-subdomain-agency/routes/index/agency-chatter-inbox", ["exports", "a24-ember-staffshift-core/routes/abstractRoute"], function (exports, _abstractRoute) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _abstractRoute.default.extend({
        _objTriageMenu: Ember.inject.service("triage-menu"),

        /**
         * This function will be called after the abstract model code before it resolves, thus devs can use this
         * function the same way that they would have used the model function
         *
         * @returns Null or a promise object, in case of promise abstract model will wait till promise is resolved
         *     before resolving its own promise
         */
        modelHook: function modelHook() {},

        activate: function activate() {
            this._super.apply(this, arguments);
            this.set("_objTriageMenu.sTopBarComponent", null);
            this.set("_objTriageMenu.iTopBarComponentHeight", 0);
            Ember.run.schedule("afterRender", function () {
                $(".main-container").addClass("page-chatter-inbox");
            });
        },
        deactivate: function deactivate() {
            $(".main-container").removeClass("page-chatter-inbox");
            this._super.apply(this, arguments);
            this.set("_objTriageMenu.sTopBarComponent", "title-bar");
        }
    });
});