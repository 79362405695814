define("a24-staffshift-subdomain-agency/services/rest-chat-teams", ["exports", "a24-ember-staffshift-core/services/rest-chat-teams"], function (exports, _restChatTeams) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _restChatTeams.default;
    }
  });
});