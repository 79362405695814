define("a24-staffshift-subdomain-agency/services/rest-agency-candidate-profile-agency-info", ["exports", "a24-staffshift-subdomain-agency/config/environment"], function (exports, _environment) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),

        /**
         * Get candidate agency linked info
         *
         * @param objCaller - The the caller this function
         * @param sProperty - The Property name to get the promise object
         * @param sCandidateId - The id of the candidate details to retrieve
         * @param funcSuccess - The success function
         * @param objFailure (Optional) - The object that will deal with failed service calls
         *
         * @author Michael Barnard <michael.barnard@a24group.com>
         * @since  11 December 2018
         */
        getCandidateAgencyLinkedInfo: function getCandidateAgencyLinkedInfo(objContext, sProperty, sCandidateId, funcSuccess, objFailure) {
            var sAgencyId = this.get("_objUserSession").getUserContextReference().mId;

            a24RestUrlConstruct.setBaseUrl("/rest/agency/" + sAgencyId + "/candidate/profile/agency-info/" + sCandidateId);

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, this.get("_objUserSession"), this.get("_objNavigation"), "GET", _environment.default.sTriageBaseRest + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure, null, null, false, true));
        }
    });
});