define('a24-staffshift-subdomain-agency/services/google-analytics', ['exports', 'a24-ember-lib/services/google-analytics'], function (exports, _googleAnalytics) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _googleAnalytics.default;
    }
  });
});