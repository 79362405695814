define("a24-staffshift-subdomain-agency/routes/index", ["exports", "a24-ember-staffshift-core/routes/abstractRoute"], function (exports, _abstractRoute) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _abstractRoute.default.extend({
        _objTriageMenu: Ember.inject.service("triage-menu"),
        _objNavBarService: Ember.inject.service("navigation-bar-component-service"),
        _objStaffshiftCoreStringsService: Ember.inject.service("a24-strings-staffshift-core"),
        _objChatterBoxService: Ember.inject.service("chatter-box-service"),
        _objHelpBoxService: Ember.inject.service("help-box-service"),
        _objNavigation: Ember.inject.service("navigation"),
        _objTimeout: null,
        _iTimeout: 5000,
        /**
         * This function will be called after the abstract model code before it resolves, thus devs can use this
         * function the same way that they would have used the model function
         *
         * @returns Null or a promise object, in case of promise abstract model will wait till promise is resolved
         *     before resolving its own promise
         */
        modelHook: function modelHook() {
            //At this point all needed js and css should have been loaded for the app and we can remove the app loader.
            //Better to have it here since this will display the loader until the menu service call is also done
            //The internal loading per page then displays until service calls are done
            $("#a24AppLoading").remove();
        },

        init: function init() {
            this._super.apply(this, arguments);
            this.set("_objTriageMenu.sTopBarComponent", "title-bar");
            this.set("_objTriageMenu.iTopBarComponentHeight", 46);
            // This is to instantiate the service so we can observe on properties on them
            this.get("_objChatterBoxService");
        },

        _setupRightSideActions: function _setupRightSideActions() {
            var objThis = this;
            var arrRightSideActions = [];

            var objChatAction = {
                sTitle: this.get("_objStaffshiftCoreStringsService").getString("speakToAConsultant"),
                sIcon: "question_answer",
                sIconClass: "a24-white-text",
                bLightWaves: true,
                sToolTipPosition: "bottom",
                sToolTipClass: "nav-bar-chat-icon",
                bShowNotification: this.get("_objChatterBoxService.bShowNotificationIcon"),
                objTooltipElement: null,
                funcGetTooltipElement: function funcGetTooltipElement(objTooltipElement) {
                    objChatAction.objTooltipElement = objTooltipElement;
                },
                executeCardAction: function executeCardAction() {
                    objThis.get("_objHelpBoxService").hideChatBox();
                    objThis.get("_objChatterBoxService").toggleChatBox();
                }
            };
            arrRightSideActions.push(objChatAction);

            var objHelpAction = {
                sTitle: this.get("_objStaffshiftCoreStringsService").getString("needAnyHelp"),
                sIcon: "help",
                sIconClass: "a24-white-text",
                bLightWaves: true,
                sToolTipPosition: "bottom",
                sToolTipClass: "nav-bar-chat-icon",
                bShowNotification: this.get("_objHelpBoxService.bShowNotificationIcon"),
                objTooltipElement: null,
                funcGetTooltipElement: function funcGetTooltipElement(objTooltipElement) {
                    objChatAction.objTooltipElement = objTooltipElement;
                },
                executeCardAction: function executeCardAction() {
                    objThis.get("_objChatterBoxService").hideChatBox();
                    objThis.get("_objHelpBoxService").toggleChatBox();
                }
            };
            arrRightSideActions.push(objHelpAction);

            this.get("_objNavBarService").set("arrRightSideActions", arrRightSideActions);
        },

        activate: function activate() {
            this._setupRightSideActions();
        },

        findRightSideAction: function findRightSideAction(sTitle) {
            var arrRightSideActions = this.get("_objNavBarService.arrRightSideActions");
            if (a24Core.isEmpty(arrRightSideActions)) {
                return null;
            }
            return arrRightSideActions.find(function (objAction) {
                return objAction.sTitle === sTitle;
            });
        },
        observeChatterBoxNotificationShow: Ember.observer("_objChatterBoxService.bShowNotificationIcon", function () {
            var objAction = this.findRightSideAction(this.get("_objStaffshiftCoreStringsService").getString("speakToAConsultant"));
            if (!a24Core.isEmpty(objAction)) {
                Ember.set(objAction, "bShowNotification", this.get("_objChatterBoxService.bShowNotificationIcon"));
            }
        }),
        observeHelpBoxNotificationShow: Ember.observer("_objHelpBoxService.bShowNotificationIcon", function () {
            var objAction = this.findRightSideAction(this.get("_objStaffshiftCoreStringsService").getString("needAnyHelp"));
            if (!a24Core.isEmpty(objAction)) {
                Ember.set(objAction, "bShowNotification", this.get("_objHelpBoxService.bShowNotificationIcon"));
            }
        }),

        deactivate: function deactivate() {
            this.get("_objNavBarService").set("arrRightSideActions", []);
            Ember.run.cancel(this.get("_objTimeout"));
        },

        hideTooltip: function hideTooltip(objAction) {
            this.set("_objTimeout", Ember.run.later(this, function () {
                if (!a24Core.isEmpty(objAction.objTooltipElement)) {
                    objAction.objTooltipElement.tooltip("hide");
                }
            }, this.get("_iTimeout")));
        },
        handleTransition: function handleTransition(objTransition) {
            var _this = this;

            var sTargetName = objTransition.targetName;
            var sCurrentUrl = this.get("_objNavigation").get("sCurrentRouteName");
            if (!sTargetName.includes("welcome") && sCurrentUrl.includes("welcome")) {
                var arrRightSideActions = this.get("_objNavBarService").get("arrRightSideActions");
                arrRightSideActions.forEach(function (objAction) {
                    if (objAction.sTitle === _this.get("_objStaffshiftCoreStringsService").getString("speakToAConsultant")) {
                        if (!a24Core.isEmpty(objAction.objTooltipElement)) {
                            objAction.objTooltipElement.tooltip("show");
                            _this.hideTooltip(objAction);
                        }
                    }
                });
            }
        },
        actions: {
            willTransition: function willTransition(objTransition) {
                this.handleTransition(objTransition);
            }
        }
    });
});