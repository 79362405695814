define("a24-staffshift-subdomain-agency/components/card-datagrid-custom-filter-timesheet-break-hours", ["exports", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        objHeaderItem: null,

        _arrYesNoOptions: null,
        _objEnumsService: Ember.inject.service("a24-enums"),

        init: function init() {
            this._super.apply(this, arguments);
            this.set("_arrYesNoOptions", this.get("_objEnumsService").getList("yesNo"));
            this.observeAllValues();
        },

        observeAllValues: Ember.observer("objHeaderItem._objYesNo", function () {
            Ember.run.once(this, "_setFlags");
        }),

        _setFlags: function _setFlags() {
            if (!a24Core.isEmpty(this.get("objHeaderItem._objYesNo"))) {
                this.customFilterDisplayAddButtonEvent(true);
            } else {
                this.customFilterDisplayAddButtonEvent(false);
            }
        }
    });
});